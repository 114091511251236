import { Collapse, Slider, Switch } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { modifyPaintFilmRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";

type TProps = {
    minDiameter: number,
    maxDiameter: number,
    showAllMasks: boolean,
    showOriginalImage: boolean,
    setDiameterRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>,
    setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>,
    setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>,
    showBBox: boolean,
    setShowBBox: React.Dispatch<React.SetStateAction<boolean>>,
}

const DEFAULT_MIN_RANGE = 8;

const PaintFilmAnalysisDetailsSidebar = ({ minDiameter, maxDiameter, showAllMasks, showOriginalImage, setDiameterRangeThreshold, setShowAllMasks, setShowOriginalImage, showBBox, setShowBBox }: TProps) => {
    const dispatch = useDispatch();
    const params = useParams<any>();
    const [diameterRange, setDiameterRange] = useState<any[]>([]);
    const [bilateralThreshold, setBilateralThreshold] = useState<number>(9);
    const { semAnalysisMaskData } = useSelector((state: StoreState) => state.semAnalysis);

    const resetDiameter = useCallback(() => {
        let minVal = DEFAULT_MIN_RANGE;

        setDiameterRange([minVal, maxDiameter]);
        setDiameterRangeThreshold([minVal, maxDiameter]);

        if (semAnalysisMaskData?.parameters) {
            const parameters = semAnalysisMaskData?.parameters
            setDiameterRange([parameters?.diameter_threshold, maxDiameter]);
            setDiameterRangeThreshold([parameters?.diameter_threshold, maxDiameter]);
            setBilateralThreshold(parameters?.bilateral_threshold);
        }
    }, [maxDiameter, semAnalysisMaskData?.parameters, setDiameterRangeThreshold]);

    useEffect(() => {
        resetDiameter();
    }, [resetDiameter]);

    const modify = () => {
        dispatch(modifyPaintFilmRequest({
            batch_id: params.batchId,
            file_id: params.fileId,
            thresholds: { bilateral_threshold: bilateralThreshold, diameter_threshold: diameterRange[0] }
        }));
    }

    const resetAll = () => {
        setBilateralThreshold(9);
        resetDiameter();

        let minVal = DEFAULT_MIN_RANGE;

        dispatch(modifyPaintFilmRequest({
            batch_id: params.batchId,
            file_id: params.fileId,
            thresholds: { bilateral_threshold: 9, diameter_threshold: minVal }
        }));
    }

    return (
        <div className="sidebar" style={{ width: "240px" }}>
            <div className="content" style={{ width: "240px" }}>
                <div className="option">
                    <div className="label">Show Original Image</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showOriginalImage}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowOriginalImage(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Show All Masks</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showAllMasks}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowAllMasks(val)
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Show Bounding Box</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showBBox}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowBBox(val)
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>
                <Collapse
                    expandIconPosition="end"
                    rootClassName="setting-collapse"
                    items={[
                        {
                            key: "1",
                            label: "Advanced Settings",
                            children: (
                                <>
                                    <div className="option">
                                        <div className="label">Diameter</div>
                                        <Slider
                                            range
                                            step={0.1}
                                            min={0}
                                            max={maxDiameter}
                                            value={diameterRange}
                                            onChange={(val) => setDiameterRange(val)}
                                            onChangeComplete={(val) => {
                                                setDiameterRangeThreshold(val)
                                            }}
                                            style={{ width: 200 }}
                                            rootClassName="sem-slider"
                                        />
                                    </div>

                                    <div className="option">
                                        <div className="label">Sensitivity</div>
                                        <Slider
                                            reverse
                                            step={1}
                                            min={4}
                                            max={14}
                                            value={bilateralThreshold}
                                            onChange={(val) => setBilateralThreshold(val)}
                                            style={{ width: 200, color: "white" }}
                                            marks={{
                                                4: "Strong",
                                                9: "Medium",
                                                14: "Weak"
                                            }}
                                            rootClassName="sem-slider sem-slider-no-track"
                                        />
                                    </div>

                                    <div className="option">
                                        <StyledButton
                                            size="small"
                                            type="primary"
                                            onClick={modify}
                                        >
                                            Apply
                                        </StyledButton>
                                        <StyledButton
                                            size="small"
                                            onClick={resetAll}
                                        >
                                            Reset to Default
                                        </StyledButton>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default memo(PaintFilmAnalysisDetailsSidebar);