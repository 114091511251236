import { DownloadOutlined, InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Descriptions,
  Drawer,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AsyncStates } from "src/constants";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { sortByOrder } from "src/utils/general/sort";
import useTranslate from "src/utils/useTranslate";
import relativeTime from "dayjs/plugin/relativeTime";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
dayjs.extend(relativeTime);

export enum FileListColumns {
  NAME = "file_name",
  WORK_ORDER = "work_order_id",
  STAGE = "work_order_stage",
  UPLOADED_BY = "uploaded_by",
  UPLOADED_ON = "uploaded_on",
  DELETE = "delete",
  DOWNLOAD = "download",
}

interface FileListViewProps {
  fileList: any[];
  onDelete: any;
  uploadStatus: AsyncStates;
  columns?: FileListColumns[];
}

interface FileState {
  user_id: string;
  work_order_id: string;
  project_id: string;
  work_order_stage: string;
  created: string;
  updated: string;
  download_link: string;
  file_id: string;
  s3_bucket: string;
  s3_file_key: string;
  filename: string;
  file_size: string;
  file_type: string;
  access: string;
  procedure_file: string;
}

const initialFileState = {
  user_id: "",
  work_order_id: "",
  project_id: "",
  work_order_stage: "",
  created: "",
  updated: "",
  download_link: "",
  file_id: "",
  s3_bucket: "",
  s3_file_key: "",
  filename: "",
  file_size: "",
  file_type: "",
  access: "",
  procedure_file: "",
};

export const FileListView = ({
  fileList,
  onDelete,
  uploadStatus,
  columns,
}: FileListViewProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslate();

  const userList = useSelector((state: StoreState) => state.teams.data);

  const getUsername = useCallback(
    (userId: string) =>
      userList.find((user) => user.user_id === userId)?.user_name,
    [userList]
  );

  const [isDrawerOpen, setisDrawerOpen] = useState<boolean>(false);
  const [fileDetails, setfileDetails] = useState<FileState>(initialFileState);
  const [sortTooltip, setSortTooltip] = useState<any>({
    work_order: undefined,
    name: undefined,
    stage: undefined,
    uploaded_by: undefined,
    uploaded_on: undefined,
  });

  const openDrawer = useCallback((record: FileState) => {
    setfileDetails(record);
    setisDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setisDrawerOpen(false);
  }, []);

  const woList = useSelector((state: StoreState) => state.workOrders.data);
  const stages = useSelector(
    (state: StoreState) => state.displayNames.data?.stages
  );

  const showColumn = useCallback(
    (column: FileListColumns) =>
      columns?.includes(column) || typeof columns === "undefined",
    [columns]
  );

  const getWorkOrderKey = useCallback(
    (work_order_id: string, key: string) =>
      woList.find((wo: any) => wo.work_order_id === work_order_id)?.[key],
    [woList]
  );
  const getStageName = useCallback(
    (work_order_stage: string) => stages?.[work_order_stage],
    [stages]
  );
  const openWO = useCallback(
    (work_order_id: string) => {
      dispatch(resetWorkOrder());
      history.push(`/work-orders/details/${work_order_id}/`);
    },
    [dispatch, history]
  );

  const sort = (valueA: string, valueB: string, type: string) => {
    if (type === "ascend") {
      return valueB?.toLocaleLowerCase() > valueA?.toLocaleLowerCase() ? -1 : 1;
    } else {
      return valueB?.toLocaleLowerCase() < valueA?.toLocaleLowerCase() ? 1 : -1;
    }
  };

  return (
    <>
      <Table
        size="small"
        columns={[
          ...(showColumn(FileListColumns.NAME)
            ? [
                {
                  title: t("common.name"),
                  dataIndex: "filename",
                  key: "name",
                  sorter: (a: any, b: any, c: any) =>
                    sortByOrder(
                      a.filename.toLowerCase(),
                      b.filename.toLowerCase(),
                      c
                    ),
                  showSorterTooltip: {
                    title: !!sortTooltip.name
                      ? sortTooltip.name === "descend"
                        ? t("common.Clicktocancelsort")
                        : t("table.sort.ZA")
                      : t("common.ClicktosortfromA-Z"),
                  },
                  render: (name: string) => (
                    <Typography.Text
                      style={{ width: 200 }}
                      ellipsis={{ tooltip: name }}
                    >
                      {name}
                    </Typography.Text>
                  ),
                  width: 200,
                },
              ]
            : []),
          ...(showColumn(FileListColumns.WORK_ORDER)
            ? [
                {
                  title: t("common.workOrder"),
                  dataIndex: "work_order_id",
                  key: "work_order",
                  filters: [
                    ...new Map(
                      fileList
                        .map((value) => ({
                          text: value.work_order_name,
                          value: value.work_order_name,
                        }))
                        .map((item) => [item.value, item])
                    ).values(),
                  ],
                  sorter: (a: any, b: any, c: any) =>
                    sort(a.work_order_name, b.work_order_name, c),
                  showSorterTooltip: {
                    title: !!sortTooltip.work_order
                      ? sortTooltip.work_order === "descend"
                        ? t("common.Clicktocancelsort")
                        : t("table.sort.ZA")
                      : t("common.ClicktosortfromA-Z"),
                  },
                  onFilter: (value: any, record: any) =>
                    record.work_order_name?.startsWith(value),
                  render: (woId: string) => (
                    <Tooltip title={getWorkOrderKey(woId, "work_order_name")}>
                      <StyledButton
                        style={{ padding: 0 }}
                        onClick={() => openWO(woId)}
                        type="link"
                      >
                        <Typography.Text
                          style={{ width: 100, color: "unset", padding: 0 }}
                          ellipsis
                        >
                          {getWorkOrderKey(woId, "work_order_name")}
                        </Typography.Text>
                      </StyledButton>
                    </Tooltip>
                  ),
                  width: 100,
                },
              ]
            : []),
          ...(showColumn(FileListColumns.STAGE)
            ? [
                {
                  title: t("common.stage"),
                  dataIndex: "work_order_stage",
                  key: "stage",
                  sorter: (a: any, b: any, c: any) =>
                    sort(
                      getStageName(a.work_order_stage),
                      getStageName(b.work_order_stage),
                      c
                    ),
                  showSorterTooltip: {
                    title: !!sortTooltip.stage
                      ? sortTooltip.stage === "descend"
                        ? t("common.Clicktocancelsort")
                        : t("table.sort.ZA")
                      : t("common.ClicktosortfromA-Z"),
                  },
                  filters: [
                    ...new Map(
                      fileList
                        .map((value) => ({
                          text: getStageName(value.work_order_stage),
                          value: getStageName(value.work_order_stage),
                        }))
                        .map((item) => [item.value, item])
                    ).values(),
                  ],
                  onFilter: (value: any, record: any) =>
                    getStageName(record.work_order_stage).startsWith(value),
                  render: (stage: string) => (
                    <Typography.Text
                      ellipsis={{ tooltip: getStageName(stage) }}
                    >
                      {getStageName(stage)}
                    </Typography.Text>
                  ),
                  width: 100,
                },
              ]
            : []),
          ...(showColumn(FileListColumns.UPLOADED_BY)
            ? [
                {
                  title: t("common.uploadedBy"),
                  dataIndex: "user_id",
                  key: "uploaded_by",

                  filters: [
                    ...new Map(
                      fileList
                        .map((value) => ({
                          text: getUsername(value.user_id),
                          value: getUsername(value.user_id),
                        }))
                        .map((item) => [item.value, item])
                    ).values(),
                  ],
                  sorter: (a: any, b: any, c: any) =>
                    sort(getUsername(a.user_id), getUsername(b.user_id), c),
                  showSorterTooltip: {
                    title: !!sortTooltip.uploaded_by
                      ? sortTooltip.uploaded_by === "descend"
                        ? t("common.Clicktocancelsort")
                        : t("table.sort.ZA")
                      : t("common.ClicktosortfromA-Z"),
                  },
                  onFilter: (value: any, record: any) =>
                    getUsername(record.user_id).startsWith(value),
                  render: (text: string) => getUsername(text),
                  width: 100,
                },
              ]
            : []),
          ...(showColumn(FileListColumns.UPLOADED_ON)
            ? [
                {
                  title: t("common.uploadedOn"),
                  dataIndex: "created",
                  key: "uploaded_on",
                  sorter: (a: any, b: any, c: any) =>
                    new Date(b.created) > new Date(a.created)
                      ? -1
                      : new Date(b.created) < new Date(a.created)
                      ? 1
                      : 0,
                  showSorterTooltip: {
                    title: !!sortTooltip.uploaded_on
                      ? sortTooltip.uploaded_on === "descend"
                        ? t("common.Clicktocancelsort")
                        : t("file.clickToSortLatestToEarliest")
                      : t("file.clickToSortEarliestToLatest"),
                  },
                  render: (text: string) => dayjs(text).fromNow(),
                  width: 100,
                },
              ]
            : []),
          ...(showColumn(FileListColumns.DOWNLOAD) ||
          showColumn(FileListColumns.DELETE)
            ? [
                {
                  title: t("common.actions"),
                  dataIndex: "download_link",
                  key: "download_link",
                  render: (_: string, record: FileState) => (
                    <StyledButton
                      onClick={() => openDrawer(record)}
                      type="text"
                      icon={<MoreOutlined />}
                    />
                  ),
                  width: 100,
                },
              ]
            : []),
        ]}
        dataSource={fileList.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        )}
        onChange={(pagination, filters, sorter: any) => {
          setSortTooltip({ ...sortTooltip, [sorter.columnKey]: sorter.order });
        }}
      />
      <Drawer
        closable={true}
        open={isDrawerOpen}
        extra={<Typography.Text type="secondary">File</Typography.Text>}
        title={fileDetails.filename ?? "-"}
        placement="right"
        onClose={closeDrawer}
      >
        <Space>
          <StyledButton
            icon={<DownloadOutlined />}
            type="primary"
            href={fileDetails.download_link}
          >
            {t("notifications.download")}
          </StyledButton>
          <Popconfirm
            title={t("common.Areyousuretodeletethisattachment")}
            onConfirm={() => {
              onDelete(fileDetails);
              setisDrawerOpen(false);
            }}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            placement="bottomLeft"
          >
            <StyledButton
              icon={<StyledDeleteIcon />}
              loading={uploadStatus === AsyncStates.LOADING}
              type="primary"
              danger
            >
              {t("common.delete")}
            </StyledButton>
          </Popconfirm>
        </Space>
        <br />
        <br />
        <Descriptions
          column={1}
          bordered
          title={
            <Space>
              <InfoCircleOutlined />
              <Typography.Text>{t("common.fileDetails")}</Typography.Text>
            </Space>
          }
        >
          {/* <Descriptions.Item label="Work Order" labelStyle={{ alignItems: 'center' }}>{<StyledButton style={{ padding: 0 }} onClick={() => openWO(fileDetails.work_order_id)} type="link"><Typography.Text style={{ width: 200, color: 'unset', padding: 0 }} ellipsis={{ tooltip: getWorkOrderKey(fileDetails.work_order_id, 'work_order_name') }}>{getWorkOrderKey(fileDetails.work_order_id, 'work_order_name')}</Typography.Text></StyledButton>}</Descriptions.Item> */}
          <Descriptions.Item span={3} label={t("common.stage")}>
            {getStageName(fileDetails.work_order_stage)}
          </Descriptions.Item>
          <Descriptions.Item label={t("common.size")}>{`${Number(
            fileDetails.file_size
          )?.toFixed(2)} kb`}</Descriptions.Item>
          <Descriptions.Item label={t("common.UploadedBy")}>
            {getUsername(fileDetails.user_id)}
          </Descriptions.Item>
          <Descriptions.Item label={t("common.UploadedOn")}>
            <Tooltip
              title={dayjs(fileDetails.created).format("DD-MM-YYYY, hh:mm a")}
            >
              {dayjs(fileDetails.created).fromNow()}
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};
