import {
  CopyOutlined,
  DownOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Form,
  Checkbox,
  Card,
  Col,
  Collapse,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  message,
  Popconfirm,
  Typography,
  Pagination,
  Modal,
  Menu,
  Dropdown,
  Skeleton,
  Spin,
  // Steps
  InputNumber,
} from 'antd';
import Big from 'big.js';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates, permissions, projectStatus, EXP_PAGE_SIZE, antdTheme } from 'src/constants';
import {
  editDisplayIdReset, editDisplayIdRequest,
  setEditingState, plotsDataRequest, graphsDataHistoryRequest, editExperimentSuccess, archiveFormulationRequest, cellChangesClear, savePartialRequest, findAndUpdateSpecificExperimentDataRequest
} from 'src/store/actions/workOrderDetails';
import { StoreState } from 'src/store/configureStore';
import { Dataset } from 'src/utils/getHeaders';
import { IngredientsDetailsModal } from 'src/utils/useDatasheet';
import { useCharacterizationDatasheet } from 'src/utils/useCharacterizationDatasheet';
import useTranslate, { TranslateFunc } from 'src/utils/useTranslate';
import { IDataSheet } from '../IDataSheet';
import { Cell } from '../IDataSheet/IDataSheet';
import styles from './Datasheet.module.scss';
import { FileUploads } from "./Pluss/FileUploads"
import { PropertiesPlots } from "./Pluss/PropertiesPlots"
import { Sorter } from '../Sorter'
import { apply, add_operation } from 'json-logic-js'
import { setSelectedTrials } from 'src/store/actions/workOrders';
import { CreateWoModal } from './CreateWoModal';
import { TrialDetailsDrawer } from './TrialDetailsDrawer';
import { StyledButton } from 'src/styled_components/StyledButton';
import { Characterizations } from './Characterizations/Characterizations';
import { usePermission, userAccessInterface } from 'src/utils/usePermissions';
import { CopyTrialsModal } from './CopyTrialsModal';
import { fetchExperimentRequest, fetchLinkedExperimentDetailsRequest } from 'src/store/actions/experiment';
import { NestleProfiles } from './Processing/NestleProfiles';
import { BaseCategorySelectionModal } from './CategorySelectionModal';
import { updateExperimentUnitCleanup } from 'src/store/actions/woUnitConversion';
import { unitListRequest } from 'src/store/actions/conversion';
import { AddUnitModal } from '../UnitsConversion/modals';
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { fetchMethodListRequest } from 'src/store/actions/characterizationMethods';
import { IngredientModal } from 'src/modules/InventoryV2/components/IngredientModal';
import { blue } from '@ant-design/colors';
import { CustomParameterModal } from '../CustomParameterModal';
import { useValue } from 'src/utils/useValue';
import { fetchAllCategoriesRequest } from 'src/store/actions/repository';
import { RawFileUpload } from './RawData/RawFileUpload';
import { RawPlots } from './RawData/RawPlots';
import { Layout, TabNode } from 'flexlayout-react';
import 'flexlayout-react/style/light.css';
import { useLayoutModel } from './useLayoutModel';
import PropertiesDrawer from 'src/modules/InventoryV2/Repository/Properties/PropertiesDrawer';
import "./react-datasheet.css"
import { Stages } from 'src/utils/mapDataset';
import { PredictedProperties } from '../WorkOrderDetails/PredictedProperties';
import { CharacterizationsPlots } from './Pluss/CharacterizationsPlots';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';


const { Text } = Typography

export function getDatasetTranslated(dataset: Dataset, t: TranslateFunc) {
  if (dataset === Dataset.ingredients) return t("common.ingredients")
  if (dataset === Dataset.characterizations) return t("common.characterizations")
  if (dataset === Dataset.processing) return t("common.processing")
  if (dataset === Dataset.properties) return t("common.properties")
  return dataset
}

// const datasheetSubscription = graphql`
// 	subscription DatasheetSubscription($user_id: String!) {
// 		initFormulations(user_id: $user_id) {
// 			message
// 			data
//             total
//             display_id_list
// 		}
// 	}
// `

interface FrozenColumnSpecification {
  colName: string;
  widthInPx: number;
}

interface DatasheetProps {
  dataset: Dataset
  showTotals?: boolean
  userAccess: userAccessInterface
  loadData: boolean
  setLoadData: any
  initialTrials: any[]
  title?: string | null
  currentExpIdIndex: number
  frozenColumnSpecifications?: FrozenColumnSpecification[]
  dataUploadFBTaskStatus?: string;
  setHasParams?: any
}

interface FrozenColumnData {
  [key: number]: {
    offset: number,
    widthInPx: number
  }
}


export const EditableField = ({ prefix = '', formulationId, displayText, displayIdList, setValue, trialSetDataIndex, suffix = '', type, extraValue = 0, userAccess, setDuplicateTrials, expIndex }: {
  prefix?: string, suffix?: string, formulationId: string, displayIdList: any[], displayText: string | number, setValue: React.Dispatch<React.SetStateAction<string[]>> | React.Dispatch<React.SetStateAction<{
    value_grams: string;
    pref_unit: string;
  }[]>>, trialSetDataIndex: number, type: 'DISPLAY_ID' | 'BATCH_SIZE', extraValue?: string | number, userAccess: boolean, setDuplicateTrials: React.Dispatch<React.SetStateAction<any>>, expIndex?: any
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const { unitList } = useSelector((state: StoreState) => state.conversion)
  const unitOptions = useMemo(
    () =>
      unitList.filter((unit) => unit.category === "weight" && !["mol", "mol%", "phr"].includes(unit.name)).map(({ name: value }) => ({ label: value, value })),
    [unitList]
  )
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  )

  const editStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editDisplayIdStatus
  )
  const configs = useSelector((state: StoreState) => state.configs.features)
  const [visible, setVisible] = useState(false)
  const [newValue, setNewValue] = useState<any>(displayText)
  const [newUnit, setNewUnit] = useState(suffix)
  const [applyBatchSizeToAll, setApplyBatchSizeToAll] = useState(false)
  const { getDecimalSeparator, getValue } = useValue()

  useEffect(() => {
    setNewValue(displayText)
  }, [displayText])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)

  const onFinish = () => {
    if (type === "DISPLAY_ID") {
      if (displayIdList.includes(newValue)) {
        return message.error(t("common.trialNameAlreadyExists"))
      }
      if (formulationId) {
        dispatch(
          editDisplayIdRequest({
            formulation_id: formulationId,
            formulation_display_id: newValue,
            work_order_id: work_order_id,
          })
        )
        // quick fix need to check
        setValue((state: any) => {
          state[trialSetDataIndex] = newValue
          return [...state]
        })
      } else {
        setDuplicateTrials((state: any) => {
          if (!!Object.keys(state?.[displayText] || {}).length) {
            state[newValue] = state[displayText]
            delete state[displayText]
          }
          return ({ ...state })
        })
        setValue((state: any) => {
          state[trialSetDataIndex] = newValue
          return [...state]
        })
        setVisible(false)
      }
    } else if (type === "BATCH_SIZE") {
      dispatch(setEditingState(true))
      if (applyBatchSizeToAll) {
        setValue((state: any) => {
          return state.map((val: any) => ({
            value_grams: newValue,
            pref_unit: newUnit,
          }))
        })
        setVisible(false)
      } else {
        setValue((state: any) => {
          state[trialSetDataIndex].value_grams = newValue
          state[trialSetDataIndex].pref_unit = newUnit
          return [
            ...state.slice(0, trialSetDataIndex),
            { ...state[trialSetDataIndex] },
            ...state.slice(trialSetDataIndex + 1),
          ]
        })
        setVisible(false)
      }
    }
  }

  const onUnitSelection = useCallback(
    (value: string) => {
      const conversion = `${newUnit}_to_${value}`
      const formula = unitList.find((res: any) => res.name === newUnit)?.conversion_metric?.[conversion]
      if (formula) {
        setNewValue((state: any) => {
          // @ts-ignore
          const convertedValue = apply(formula, [state])
          return convertedValue
        })
      }
      setNewUnit(value)
    },
    [unitList, newUnit]
  )

  const content = (
    <Form onFinish={onFinish} style={{ maxWidth: 140 }} disabled={userAccess}>
      <Form.Item noStyle>
        {type === "BATCH_SIZE" ?
          <InputNumber
            decimalSeparator={getDecimalSeparator()}
            value={newValue}
            onChange={(e: any) => setNewValue(e)}
            addonAfter={type === "BATCH_SIZE" &&
              <Select value={newUnit} options={unitOptions} defaultValue="g" popupMatchSelectWidth={100} onChange={onUnitSelection} />} />
          : <Input value={newValue} onChange={onChange} />}

      </Form.Item>
      <Form.Item noStyle style={{ padding: 10 }}>
        {type === "BATCH_SIZE" && (
          <Checkbox
            style={{ marginBottom: 8 }}
            checked={applyBatchSizeToAll}
            className={styles.applyBatchSizeForAll}
            onChange={e => setApplyBatchSizeToAll(e.target.checked)}
          >Apply to all trials</Checkbox>)}
      </Form.Item>
      <Space>
        <Form.Item noStyle>
          <StyledButton type="primary" ghost htmlType="submit" disabled={!String(newValue).trim()} loading={editStatus === AsyncStates.LOADING}>{t("common.save")}</StyledButton>
        </Form.Item>
        {editStatus === AsyncStates.ERROR && <Text type="warning">{t("datasheet.failedToEdit")}</Text>}
      </Space>
    </Form>
  )
  useEffect(() => {
    if (editStatus === AsyncStates.SUCCESS && visible) {
      type === "DISPLAY_ID" && setValue((state: any) => { state[trialSetDataIndex] = newValue; return [...state] })
      dispatch(editDisplayIdReset())
      setVisible(false)
    }
  }, [editStatus, dispatch, newValue, setValue, trialSetDataIndex, visible, type])

  const onVisibleChange = (visible: boolean) => {
    visible &&
      editStatus !== AsyncStates.INITIAL &&
      dispatch(editDisplayIdReset())
    setVisible(visible)
  }

  const formatDisplayText = useMemo(() => {
    const displayValue = getValue(displayText);

    if (displayValue && String(displayValue).length >= 20)
      return <Tooltip title={displayValue}>{String(displayValue).substring(0, 20)}...</Tooltip>
    else return displayValue;
  }, [displayText, getValue])

  return (
    Boolean(configs?.processing_profiles) && expIndex >= 1 ?
      <Text
        style={{ width: "max-content" }}
      >{prefix} {formatDisplayText || ""} {suffix}
      </Text>
      :
      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={onVisibleChange}
      >
        <Text
          editable={{ editing: false, tooltip: t("common.edit") }}
          style={{ width: "max-content" }}
        >{prefix} {formatDisplayText || ""} {suffix}</Text>
      </Popover>
  )
}

const plus = (a: number, b: number) => Big(a).plus(b).toNumber()
const div = (a: number, b: number) => Big(a).div(b).toNumber()
const mul = (a: number, b: number) => Big(a).mul(b).toNumber()
const minus = (a: number, b: number) => Big(a).minus(b).toNumber()
const mulAndDiv = (a: number, b: number, c: number) => Big(a).mul(b).div(c).toNumber()
add_operation("plus", plus)
add_operation("div", div)
add_operation("mul", mul)
add_operation("minus", minus)
add_operation("mul_and_div", mulAndDiv)



export const CharacterizationDatasheet = ({ dataset, showTotals = false, userAccess, initialTrials, title = null, loadData, setLoadData, currentExpIdIndex, frozenColumnSpecifications = [], dataUploadFBTaskStatus, setHasParams }: DatasheetProps) => {

  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { getValue } = useValue()
  const disabled = useMemo(() => userAccess.permission === permissions.viewer || userAccess.status !== projectStatus.in_progress, [userAccess])
  const configs = useSelector((state: StoreState) => state.configs.features)
  const status = useSelector((state: StoreState) => state.workOrderDetails.editFormulationsStatus)
  // const autoSaveStatus = useSelector((state: StoreState) => state.workOrderDetails.autoSaveFormulationsStatus)
  const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder || {})
  const experimentStatus = useSelector((state: StoreState) => state.workOrderDetails.experimentStatus)
  const linkedTrials = useSelector((state: StoreState) => state.workOrderDetails.linkedExperiment)
  const editingState = useSelector((state: StoreState) => state.workOrderDetails.editingState)
  const saveFirebaseTaskId = useSelector((state: StoreState) => state.workOrderDetails.firebase_task_id)
  const displayNamesStatus = useSelector((state: StoreState) => state.displayNames.statusWithoutSpinner)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const selectedTrials = useSelector((state: StoreState) => state.workOrders.selectedTrials || [])
  const [createWoModalVisible, setCreateWoModalVisible] = useState<boolean>(false)
  const [showNewParamModal, setShowNewParamModal] = useState<boolean>(false)
  const [copyTrialVisible, setCopTrialsVisible] = useState(false)
  const [current, setCurrent] = useState<number>(1)
  const expTotal = useSelector((state: StoreState) => state.workOrderDetails.total)
  const [total, setTotal] = useState(expTotal || 1)
  const editWOStatus = useSelector((state: StoreState) => state.workOrderDetails.editWOStatus);
  const addingTrial = useSelector(
    (state: StoreState) => state.workOrderDetails.createTrialStatus
  )

  const [showBaseCategoryModal, setShowBaseCategoryModal] = useState({
    isModalVisible: false,
    toUnit: null,
    fromUnit: null,
    isDirectConversionPossible: false,
    base_categories: [],
    base_ingredients: []
  });

  const cellChanges = useSelector((state: StoreState) => state.workOrderDetails.cellChanges);
  const savePartialStatus = useSelector((state: StoreState) => state.workOrderDetails.savePartialStatus);

  const [saveTaskStatus, setSaveTaskStatus] = useState<AsyncStates>(AsyncStates.INITIAL)
  const [propertiesModalOpen, setPropertiesModalOpen] = useState(false)
  const [ingredientDetailInventoryId, setIngredientDetailInventoryId] = useState<string | null>(null)

  // init formulations task
  useEffect(() => {
    let unsub: Unsubscribe
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${saveFirebaseTaskId}`)
      unsub = onSnapshot(taskDocRef, (doc) => {
        const taskDoc = doc.data()
        if (taskDoc) {
          const workOrderExpId = workOrder.experiment_id?.[currentExpIdIndex]
          const taskExpId = taskDoc?.experiment_id
          const taskStatus = taskDoc?.status
          if (taskStatus === "IN_PROGRESS" && taskExpId === workOrderExpId) {
            setSaveTaskStatus(AsyncStates.LOADING)
          }
          if (taskStatus === "COMPLETED" && taskExpId === workOrderExpId) {
            setSaveTaskStatus(AsyncStates.SUCCESS)
            unsub()
          }
        }
      })
    }
    if (saveFirebaseTaskId) {
      listenToFileUploadTaskStatus()
    }
    return () => {
      (saveTaskStatus === AsyncStates.SUCCESS) && unsub()
    }
  }, [saveTaskStatus, saveFirebaseTaskId, workOrder?.experiment_id, currentExpIdIndex])

  // for adding new trial from previous pages
  const [addData, setAddData] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    setCurrent(1)
  }, [dataset])

  useEffect(() => {
    dispatch(fetchMethodListRequest({ method_type: "characterizations" }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTrials([]))
    }
  }, [dispatch])

  // const config = useMemo(
  //     () => ({
  //         variables: { user_id },
  //         subscription: datasheetSubscription,
  //         onNext: (msg: any) => {
  //             if (msg?.initFormulations?.message === "Success") {
  //                 dispatch(editExperimentSuccess({
  //                     data: JSON.parse(msg?.initFormulations?.data || "[]"),
  //                     total: JSON.parse(msg?.initFormulations?.total ?? 1),
  //                     display_id_list: msg?.initFormulations?.display_id_list
  //                 }))
  //                 if (Boolean(configs?.characterization_methods)) {
  //                     dispatch(saveCharacterizationSetsSuccess())
  //                 }
  //                 message.success(t("common.changesSaved"))
  //             } else {
  //                 dispatch(editExperimentFailure())
  //                 message.error(m('internal_server_error'))
  //             }
  //         },
  //     }),
  //     [user_id, dispatch, t, m, configs]
  // )
  // useSubscription(config)

  //modes
  const [batchMode, setBatchMode] = useState(false)
  const [showCosting, setShowCosting] = useState(false)

  useEffect(() => {
    dataset !== Dataset.ingredients && setBatchMode(false)
  }, [dataset])

  //modal datasheet hook
  const [modalTrial, setModalTrial] = useState<string | undefined>()

  const [trialType, setTrialType] = useState("")
  const showModal = useCallback(
    (value: string) => {
      form.setFieldsValue({
        comment:
          initialTrials.find(
            (res: any) => res?.id_set?.formulation_id === value
          )?.meta?.comment || "",
      })
      setTrialType("current")
      setModalTrial(value)
      setIsModalVisible(true)
      dispatch(findAndUpdateSpecificExperimentDataRequest({ formulation_id: [value] }))
    },
    [form, initialTrials, dispatch]
  )

  const showLinkedModal = useCallback(
    (value: string) => {
      dispatch(fetchLinkedExperimentDetailsRequest({ formulation_id: [value] }))
      form.setFieldsValue({
        comment:
          linkedTrials.find((res: any) => res?.formulation_id === value)
            ?.meta?.comment || "",
      })
      setTrialType("linked")
      setModalTrial(value)
      setIsModalVisible(true)
    },
    [dispatch, form, linkedTrials]
  )

  const [copyTrials, setCopyTrials] = useState({
    ingredients: true,
    processing: true,
    characterizations: true,
    properties: true,
  })

  // datasheet hook
  // const [newTrial, setNewTrial] = useState<{
  //   keyword: string
  //   count: number
  // }>({ keyword: t("common.trial"), count: 1 })

  const initialTrialSetList = useMemo(() => (
    dataset === "ingredients" ? initialTrials : initialTrials.flatMap((trialSet: any) => trialSet?.[dataset])) || [],
    [dataset, initialTrials])

  const initialParameterList = useMemo(() => {
    const parameterSet = initialTrialSetList.reduce((acc: any, trialSet: any) => {
      const trialsData = Object.keys(trialSet[dataset] || {}).reduce((trialAcc, curr) => {
        return {
          ...trialAcc,
          [curr]: trialSet[dataset][curr],
        }
      }, {})
      return { ...acc, ...trialsData }
    }, {})
    const parameters = Object.keys(parameterSet || {}).length
      ? Object.keys(parameterSet || {})
      : Array(5)
        .fill({})
        .map((o, idx) => `${t("workOrderDetails.newParameter")} ${idx + 1}`)
    const units = Object.entries(parameterSet).reduce(
      (acc, [parameter, { unit }]: any) => ({
        ...acc,
        [parameter]: unit,
      }),
      {}
    )
    return { parameters, units, parameterData: parameterSet }
  }, [dataset, initialTrialSetList, t])
  const displayIdListSub = useMemo(() => initialTrials?.map((trial: any) => trial.meta.display_id), [initialTrials]);

  const [displayIdList, setDisplayIdList] = useState<string[]>(displayIdListSub ?? []);

  useEffect(() => {
    setDisplayIdList(displayIdListSub);
  }, [
    displayIdListSub
  ])

  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const newIdSet = useSelector((state: StoreState) => state.workOrderDetails.addParameterState.data)
  const properties = useSelector((state: StoreState) => state.repository.properties.data)

  const [parameterList, setParameterList] = useState<string[]>([])
  const [parameterDataList, setParameterDataList] = useState<any>({})

  useEffect(() => {
    setParameterDataList((state: any) => parameterList
      .reduce((a, p) => ({
        ...a,
        [p]: {
          ...(state[p] ?
            state[p] :
            {
              propertyId: initialParameterList.parameterData[p]?.property_id,
              methodId: initialParameterList.parameterData[p]?.method_id,
              standard: initialParameterList.parameterData[p]?.standard,
              specimen: initialParameterList.parameterData[p]?.specimen,
              unit: initialParameterList?.parameterData?.[p]?.unit ?? properties?.find(property => property.property_id === p)?.unit,
            })
        }
      }), {}))
  }, [initialParameterList.parameterData, parameterList, properties])

  useEffect(() => {
    if (newIdSet.identifier) {
      setParameterList(state => {
        const newState = [...state]
        const oldParameterIndex = state.findIndex(parameter => parameter === newIdSet?.temp_identifier)
        newState[oldParameterIndex] = newIdSet.identifier
        return newState
      })
      setParameterDataList((state: any) => {
        const newState = { ...state }
        const oldParameterData = state?.[newIdSet?.temp_identifier]
        newState[newIdSet?.identifier] = oldParameterData
        delete newState[newIdSet?.temp_identifier]
        return newState
      })
    }
  }, [newIdSet.identifier, newIdSet?.temp_identifier])


  const updateParameterDataList = useCallback((parameter: string, data: any) => {
    setParameterDataList((state: any) => ({ ...state, [parameter]: data }))
  }, [])


  const propertyIds = useMemo(() => parameterList.map(para => displayNames.properties?.[para]?.repo_property_id || parameterDataList[para]?.propertyId), [displayNames.properties, parameterDataList, parameterList])
  const [trialSetDataList, setTrialSetDataList] = useState<{ [key: string]: string }[]>([])
  const { updateExperimentWithUnitStatus, unitConvertedData } = useSelector((state: StoreState) => state.woExperimentUnitConversion);


  useEffect(() => {
    const unknownParameters = parameterList.filter((parameter) =>
      parameter.includes(t("workOrderDetails.newParameter"))
    );
    if (unknownParameters.length !== parameterList.length) {
      setHasParams(true);
    } else {
      setHasParams(false);
    }
  }, [parameterList, setHasParams, t]);

  useEffect(() => {
    if (experimentStatus === AsyncStates.SUCCESS && loadData && addData) {
      let idList: any[] = displayIdList;
      if (expTotal % EXP_PAGE_SIZE === 0) {
        setTotal(expTotal)
        setCurrent(Math.ceil(expTotal / 20) + 1)
        // idList = [`${newTrial.keyword} ${expTotal + 1}`]
        // setDisplayIdList(idList)
        const parameters = initialParameterList.parameters
        setParameterList(parameters)
        setTrialSetDataList(idList.map((id, idx) => ({
          ...parameters.reduce(
            (acc, parameter) => ({
              ...acc,
              [parameter]: null,
            }),
            {}
          ),
        })))
        dispatch(editExperimentSuccess({
          data: [],
          total: expTotal + 1
        }))
      } else {
        setTotal(expTotal)
        setCurrent(Math.ceil(expTotal / 20))
        // idList = initialDisplayIdList.length ? [...initialDisplayIdList, `${newTrial.keyword} ${expTotal + 1}`] : [`${newTrial.keyword} ${expTotal + 1}`]
        // setDisplayIdList(idList)
        const parameters = initialParameterList.parameters
        setParameterList(parameters)
        setTrialSetDataList(idList.map((id, idx) => ({
          ...parameters.reduce(
            (acc, parameter) => ({
              ...acc,
              [parameter]: getValue(initialTrialSetList[idx]?.[dataset]?.[parameter]?.value ?? null),
            }),
            {}
          ),
        })))
      }
      setAddData(false)
      setLoadData(false)
      dispatch(setEditingState(false))
    }
  }, [dispatch, experimentStatus, total, loadData, initialParameterList, displayIdList, initialTrialSetList, dataset, setLoadData, expTotal, addData, getValue])

  useEffect(() => {
    dispatch(fetchAllCategoriesRequest())
  }, [dispatch])

  useEffect(() => {
    if (experimentStatus === AsyncStates.SUCCESS && loadData && !addData) {
      // let idList: any[]
      setTotal(expTotal)
      // idList = initialDisplayIdList.length ? initialDisplayIdList : [`${newTrial.keyword} ${expTotal + 1}`]
      // setDisplayIdList(idList)
      const parameters = initialParameterList.parameters
      setParameterList(parameters)
      setTrialSetDataList(displayIdList.map((id, idx) => ({
        ...parameters.reduce(
          (acc, parameter) => ({
            ...acc,
            [parameter]: getValue(initialTrialSetList[idx]?.[dataset]?.[parameter]?.value ?? null),
          }),
          {}
        ),
      })))
      setAddData(false)
      setLoadData(false)
      dispatch(setEditingState(false))
    }
  }, [dispatch, experimentStatus, total, loadData, dataset, displayIdList, initialParameterList, initialTrialSetList, setLoadData, expTotal, addData, getValue])

  useEffect(() => {
    return () => {
      dispatch(updateExperimentUnitCleanup())
    }
  }, [dispatch])

  useEffect(() => {
    if (updateExperimentWithUnitStatus === AsyncStates.SUCCESS) { // For setting Value after Unit Conversion
      setTrialSetDataList((prev) => {
        return prev.map((curr, index) => {
          return Object.keys(curr || {}).reduce((acc, key) => {
            return {
              ...acc,
              [key]: getValue(unitConvertedData?.[index]?.input_data?.[key]?.value) ?? getValue(initialTrialSetList[index]?.[dataset]?.[key]?.value) ?? null,
            }
          }, {})
        })
      })
    }
  }, [updateExperimentWithUnitStatus, initialTrialSetList, dataset, unitConvertedData, getValue])


  // for highlighting columns when graph points are clicked (Plusspolymers)
  const [highlightIndex, setHighlightIndex] = useState<number>(-1)

  useEffect(() => {
    if (workOrder.work_order_stage === "properties_stage") {
      dispatch(plotsDataRequest({ work_order_id: workOrder.work_order_id, experiment_id: workOrder.experiment_id?.[currentExpIdIndex] }))
      dispatch(graphsDataHistoryRequest({ work_order_id: workOrder.work_order_id, experiment_id: workOrder.experiment_id?.[currentExpIdIndex] }))
    }
  }, [dispatch, workOrder.work_order_id, workOrder.experiment_id, workOrder.work_order_stage, currentExpIdIndex])

  const [inventoryFlag, setInventoryFlag] = useState<Boolean>(false)
  const [colState, setColState] = useState<any>({ supplier: true, batch_number: true, sub_category: true })

  useEffect(() => {
    if (!Object.values(colState).includes(true)) {
      setInventoryFlag(false)
      setColState({ supplier: true, batch_number: true, sub_category: true })
    }
  }, [colState])

  useEffect(() => {
    if (dataset !== Dataset.ingredients) {
      setInventoryFlag(false)
      setColState({ supplier: true, batch_number: true, sub_category: true })
    }
  }, [dataset])

  const colOffsetValues: FrozenColumnData = useMemo(() => {
    let colOffsetValues: any = {}
    let currColIdx = 0;
    let acc = 0
    for (let i = 0; i < frozenColumnSpecifications.length; i++) {
      let c = frozenColumnSpecifications[i]
      if (i === 0) {
        colOffsetValues[currColIdx] = {
          offset: acc,
          widthInPx: frozenColumnSpecifications[i]["widthInPx"]
        };
        currColIdx++;
        acc += frozenColumnSpecifications[i]["widthInPx"]
      } else if (batchMode && c.colName === "Unit") {
        // We skip the Unit column in batch mode
        continue;
      } else if (c.colName === "Ingredients") {
        // First add the column data for the Ingredients column
        colOffsetValues[currColIdx] = {
          offset: acc,
          widthInPx: frozenColumnSpecifications[i]["widthInPx"]
        }
        currColIdx++
        acc += frozenColumnSpecifications[i]["widthInPx"]

        // Check if the Ingredients tab is expanded. If it is, we need to include additional columns in the frozen column list
        if (inventoryFlag) {
          const ingredients = Object.keys(colState)
          for (let i = 0; i < ingredients.length; i++) {
            if (colState[ingredients[i]]) {
              colOffsetValues[currColIdx] = {
                offset: acc,
                widthInPx: 100, // Each "ingredient" column is 100px
              }
              currColIdx++;
              acc += 100; // Add 100px for each additional column 
            }
          }
        }
      } else {
        colOffsetValues[currColIdx] = {
          offset: acc,
          widthInPx: frozenColumnSpecifications[i]["widthInPx"]
        }
        currColIdx++
        acc += frozenColumnSpecifications[i]["widthInPx"]
      }
    }
    return colOffsetValues
  }, [inventoryFlag, frozenColumnSpecifications, batchMode, colState])


  const frozenColumnIds: number[] = useMemo(() => Object.keys(colOffsetValues).map(Number), [colOffsetValues])


  //Updated the logic for InitialBatchSizeList
  const initialBatchSizeList = useMemo(() => initialTrials.map((trial: any) => ({
    ...(trial?.meta?.batch_size || { value_grams: 100, pref_unit: "g" }),
  })), [initialTrials])

  const [batchSizeList, setBatchSizeList] = useState<{ value_grams: string; pref_unit: string }[]>(() => displayIdList.map((id: any, idx: any) => initialBatchSizeList?.[idx] || { value_grams: 100, pref_unit: "g" }))
  const [unitData, setUnitData] = useState<{ [key: string]: any }>([])
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    setBatchSizeList((state) =>
      updateExperimentWithUnitStatus === AsyncStates.SUCCESS ? unitConvertedData.map((res: any) => res?.input_meta?.batch_size || { value_grams: 100, pref_unit: "g" }) :
        displayIdList.map(
          (id: any, idx: any) =>
            state?.[idx] || initialBatchSizeList?.[idx] || { value_grams: 100, pref_unit: "g" }
        )
    )
  }, [displayIdList, initialBatchSizeList, unitConvertedData, updateExperimentWithUnitStatus])

  const {
    save,
    addTrial,
    addParameter,
    datasheet,
    onCellChanges,
    createCopiedTrials,
    getParameterUnit
  } = useCharacterizationDatasheet(dataset, {
    updateParameterDataList,
    showTotals,
    showModal,
    showLinkedModal,
    styles,
    batchMode,
    showCosting,
    displayIdList,
    setDisplayIdList,
    initialTrialSetList,
    parameterList,
    parameterDataList,
    setParameterList,
    trialSetDataList,
    setTrialSetDataList,
    initialParameterList,
    highlightIndex,
    setHighlightIndex,
    setShowNewParamModal,
    userAccess: disabled,
    copyTrials,
    setCopTrialsVisible,
    current,
    inventoryColState: colState,
    inventoryStateFlag: [inventoryFlag, setInventoryFlag],
    initialTrials,
    showBaseCategoryModal,
    setShowBaseCategoryModal,
    batchSizeList,
    setBatchSizeList,
    unitData,
    setUnitData,
    setShowCard,
    setTotal,
    setPropertiesModalOpen,
    setIngredientDetailInventoryId,
    total
  })

  useEffect(() => {
    if (updateExperimentWithUnitStatus !== AsyncStates.SUCCESS) {
      const interval = setInterval(() => {
        if (cellChanges?.length > 0) {
          const savePayload =
          {
            "data": cellChanges?.map((cellChange) => {
              return {
                ...cellChange.cell.meta,
                value: cellChange.value,
                unit: getParameterUnit(cellChange.cell.meta.parameter_identifier),
                method_id: parameterDataList[cellChange.cell.meta.parameter_identifier]?.methodId,
                standard: parameterDataList[cellChange.cell.meta.parameter_identifier]?.standard,
                specimen: parameterDataList[cellChange.cell.meta.parameter_identifier]?.specimen
              }
            })
          };
          dispatch(savePartialRequest(savePayload));
          // console.log("TMP", savePayload);
          dispatch(cellChangesClear());
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [cellChanges, dataset, dispatch, displayNames, getParameterUnit, parameterDataList, updateExperimentWithUnitStatus]);

  const removeTrials = useCallback(() => {
    const trials = initialTrials.filter((trial: any, index: number) => selectedTrials.includes(index))
      .map((res: any, index) => res?.id_set?.formulation_id)
    if (!!trials.length) {
      dispatch(archiveFormulationRequest({
        formulation_id: trials
      }))
    }
    setDisplayIdList(state => state.filter((res: any, index: number) => !selectedTrials.includes(index)))
    setTrialSetDataList(state => state.filter((res: any, index: number) => !selectedTrials.includes(index)))
    dispatch(setSelectedTrials([]))
    setTotal(state => state - selectedTrials.length)
  }, [dispatch, initialTrials, selectedTrials])

  const onSelectAll = useCallback((e: any) => {
    if (e.target.checked) {
      dispatch(setSelectedTrials(trialSetDataList.map((res: any, index: any) => index)))
    } else {
      dispatch(setSelectedTrials([]))
    }
  }, [dispatch, trialSetDataList])

  const handleBatchMode = useCallback((value: boolean) => {
    setBatchMode(value);
  }, []);

  useEffect(() => {
    return () => {
      if (batchMode && editWOStatus === AsyncStates.SUCCESS) {
        setBatchMode(false);
      }
    }
  }, [batchMode, editWOStatus])

  const menu = useCallback(() => (
    <Menu onClick={({ key }: any) => {
      setColState((prevState: any) => ({ ...prevState, [key]: !prevState[key] }))
    }} >
      <Menu.Item key='supplier' ><Checkbox checked={colState.supplier} onChange={(checkedValues) => setColState({ ...colState, supplier: checkedValues.target.checked })} style={{ marginRight: '1rem' }} />Supplier</Menu.Item>
      <Menu.Item key='batch_number'><Checkbox checked={colState.batch_number} onChange={(checkedValues) => setColState({ ...colState, batch_number: checkedValues.target.checked })} style={{ marginRight: '1rem' }} />Lot Number</Menu.Item>
      <Menu.Item key='sub_category'><Checkbox checked={colState.sub_category} onChange={(checkedValues) => setColState({ ...colState, sub_category: checkedValues.target.checked })} style={{ marginRight: '1rem' }} />Sub-Category</Menu.Item>
    </Menu >
  ), [colState])

  const companyId = useSelector((state: StoreState) => state.login.loginResponse.company_id)

  const linkedTrialIdDisplayName = useMemo(() => {
    return parameterList?.reduce(
      (acc, parameter) => ({
        ...acc,
        [parameter]: linkedTrials.find((trial) => trial.access && trial.formulation_id === parameter)?.formulation_display_id ?? displayNames[dataset]?.[parameter]?.name ?? parameter
      }),
      {},
    )
  }, [parameterList, linkedTrials, displayNames, dataset])

  const valueRenderer = useCallback(cell => cell.value, [])
  const cellRenderer = useCallback(props => {
    if (props.row === 0) {
      return <FrozenHeaderCell {...props} frozenColumnIds={frozenColumnIds} colOffsetValues={colOffsetValues} /> as any
    } else if (props.col in frozenColumnIds) {
      return <FrozenCell {...props} frozenColumnIds={frozenColumnIds} colOffsetValues={colOffsetValues} /> as any
    } else {
      return <NormalCell {...props} /> as any
    }
  }, [frozenColumnIds, colOffsetValues])

  const handleSorter = useCallback((value: string[]) => {
    setParameterList(value)
    dispatch(setEditingState(true))
  }, [dispatch])

  const getDatasheet = useCallback(() => {
    if ([Dataset.characterizations, Dataset.properties].includes(dataset) && (Boolean(configs?.characterization_methods) || (companyId === "COMP351PS01HQE2023"))) {
      return (
        <Characterizations initialDisplayIdList={displayIdList} initialTrialSetList={initialTrialSetList}
          dataset={dataset} userAccess={disabled} showModal={showModal} setLoadData={setLoadData} />
      )
    }
    else if (Dataset.processing === dataset && Boolean(configs?.processing_profiles)) {
      return <NestleProfiles initialTrials={initialTrials} disabled={false} size={"large"} />
    } else {
      return (
        <Space direction="vertical" style={{ width: '100%' }} className='gap-8' size="large">
          <Row justify="space-between">
            <Row justify="start">
              {!disabled && (
                <div style={{ display: "flex", gap: "4px" }}>
                  <Sorter
                    list={parameterList}
                    onChange={handleSorter}
                    dataset={dataset}
                    linkedTrialIdDisplayName={linkedTrialIdDisplayName}
                  />
                  <StyledButton size='small' onClick={addParameter} icon={<PlusOutlined />}>
                    {t("workOrderDetails.addMore")}{" "}
                    {t(`common.${dataset}`) ?? dataset}
                  </StyledButton>
                  {!!workOrder?.work_order_stage.length && !disabled && (
                    <StyledButton
                      icon={<PlusOutlined />}
                      size='small'
                      disabled={addingTrial === AsyncStates.LOADING}
                      onClick={(e) => {
                        if (displayIdList.length === 20) {
                          if (editingState) {
                            Modal.warning({
                              title:
                                "Unsaved changes present. Please save to add more trials",
                              onCancel: () => { },
                              onOk: () => { },
                            });
                          } else {
                            if (expTotal % EXP_PAGE_SIZE !== 0) {
                              dispatch(
                                fetchExperimentRequest({
                                  experiment_id: [
                                    workOrder.experiment_id?.[
                                    currentExpIdIndex
                                    ],
                                  ],
                                  page_num: Math.ceil(expTotal / EXP_PAGE_SIZE),
                                  page_size: EXP_PAGE_SIZE,
                                  // stages: [workOrder.work_order_stage]
                                })
                              );
                            }
                            setAddData(true);
                            setLoadData(true);
                          }
                        } else {
                          addTrial(e, {
                            keyword: t("common.trial"),
                            count: total + 1,
                          });
                          setTotal((state) => state + 1);
                        }
                      }}
                    >
                      {t("workOrderDetails.newTrial")}
                    </StyledButton>
                  )}
                </div>
              )}
            </Row>
            <Row justify="end">
              {
                dataUploadFBTaskStatus === "PARTIAL" ? (
                  <Text strong type={"warning"}>
                    {t("workorder.info.processingRemainingExperiments")}
                  </Text>
                ) : (
                  <Pagination
                    size="small"
                    total={total}
                    current={current}
                    pageSize={20}
                    showSizeChanger={false}
                    onChange={(e) => {
                      if (editingState || cellChanges?.length) {
                        Modal.confirm({
                          title: "Unsaved changes present",
                          onCancel: () => { },
                          onOk: () => {
                            setCurrent(e);
                            setLoadData(true);
                            dispatch(updateExperimentUnitCleanup())
                            dispatch(setSelectedTrials([]));
                            dispatch(
                              fetchExperimentRequest({
                                experiment_id: [
                                  workOrder.experiment_id?.[currentExpIdIndex],
                                ],
                                page_num: e,
                                page_size: EXP_PAGE_SIZE,
                                // stages: [workOrder.work_order_stage]
                              })
                            );
                          },
                        });
                      } else {
                        setCurrent(e);
                        setLoadData(true);
                        dispatch(setSelectedTrials([]));
                        dispatch(
                          fetchExperimentRequest({
                            experiment_id: [
                              workOrder.experiment_id?.[currentExpIdIndex],
                            ],
                            page_num: e,
                            page_size: EXP_PAGE_SIZE,
                            // stages: [workOrder.work_order_stage]
                          })
                        );
                      }
                    }}
                  />
                )
              }

            </Row>
          </Row>
          {(experimentStatus === AsyncStates.SUCCESS && displayNamesStatus === AsyncStates.SUCCESS) ?
            <IDataSheet
              className={styles.datasheet}
              data={datasheet}
              valueRenderer={valueRenderer}
              cellRenderer={cellRenderer}
              onCellsChanged={onCellChanges as ReactDataSheet.CellsChangedHandler<Cell, string>}
            /> : <Skeleton active />}
          <Row justify={"end"}>
            <Col style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              {(dataset === Dataset.ingredients) && <>
                <Tooltip title={!["wt%", "phr"].includes((unitConvertedData?.[0]?.input_data?.[parameterList?.[0]]?.unit || initialTrialSetList[0]?.[dataset]?.[parameterList?.[0]]?.unit || Object.values(unitData || {})?.[0])) ? t("common.Absolutemodecanonlybeactivatedwhenwt%andphrisselectedasunits") : null}>
                  <Switch
                    checked={batchMode}
                    disabled={!["wt%", "phr"].includes((unitConvertedData?.[0]?.input_data?.[parameterList?.[0]]?.unit || initialTrialSetList[0]?.[dataset]?.[parameterList?.[0]]?.unit || Object.values(unitData || {})?.[0]))}
                    onChange={(value) => {
                      handleBatchMode(value);
                    }}
                    checkedChildren={t("workOrderDetails.editBatchSizesOn")}
                    unCheckedChildren={t(
                      "workOrderDetails.editBatchSizesOff"
                    )}
                  />
                </Tooltip>
                <Switch checked={showCosting} onChange={() => setShowCosting(state => !state)} checkedChildren={t("common.Hidecosting")} unCheckedChildren={t("common.showcosting")} />
              </>
              }
            </Col>
          </Row>
        </Space>
      )
    }
  }, [dataset, configs?.characterization_methods, configs?.processing_profiles, companyId, displayIdList, initialTrialSetList, disabled, showModal, setLoadData, initialTrials, parameterList, addParameter, t, workOrder?.work_order_stage.length, workOrder.experiment_id, addingTrial, dataUploadFBTaskStatus, total, current, experimentStatus, datasheet, valueRenderer, cellRenderer, onCellChanges, unitConvertedData, unitData, batchMode, showCosting, editingState, expTotal, dispatch, currentExpIdIndex, addTrial, cellChanges?.length, handleBatchMode, linkedTrialIdDisplayName, displayNamesStatus, handleSorter])

  const factory = (node: TabNode) => {
    var component = node.getComponent();

    if (component === "properties") {
      return (
        <PropertiesDrawer filter={propertyIds} />
      );
    }
    if (component === "datasheet") {
      return (
        <Card
          size="small"
          type="inner"
          headStyle={{ padding: 0 }}
          bodyStyle={{ padding: "12px", maxHeight: 560, position: "relative" }}
          title={
            <Space>
              <span style={{ marginLeft: "1rem" }}>
                {t("formulations.total")} {t("common.trials")}:
              </span>
              <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
                {total}
              </span>
              {!!title && (
                <Text
                  style={{ marginLeft: 10 }}
                  strong
                  type={"secondary"}
                >{`( ${title} )`}</Text>
              )}
            </Space>
          }
          extra={
            <div style={{ padding: "0.5rem", display: "inline-flex" }}>
              {!disabled && (
                <Row justify="end">
                  {(Dataset.ingredients === dataset ||
                    ([Dataset.characterizations, Dataset.properties].includes(
                      dataset
                    ) &&
                      !Boolean(configs?.characterization_methods)) ||
                    (Dataset.processing === dataset &&
                      !Boolean(configs?.processing_profiles))) &&
                    !disabled && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {editingState ? (
                          <Text style={{ lineHeight: 1 }} type="warning">
                            {t("workOrderDetails.unsavedChanges")}
                          </Text>
                        ) : null}
                        <Space>
                          <Spin spinning={(cellChanges.length > 0 || savePartialStatus === AsyncStates.LOADING) && updateExperimentWithUnitStatus !== AsyncStates.SUCCESS} indicator={<LoadingOutlined />} />
                          {(cellChanges.length > 0 || savePartialStatus === AsyncStates.LOADING) && updateExperimentWithUnitStatus !== AsyncStates.SUCCESS &&
                            <Text strong type={"secondary"}>{t("workOrder.trialsUpdateBackground")}</Text>
                          }
                        </Space>
                        <Space>
                          <Spin spinning={saveTaskStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />} />
                          {saveTaskStatus === AsyncStates.LOADING &&
                            <Text strong type={"secondary"}>{t("workOrder.trialsUpdateBackground")}</Text>
                          }
                        </Space>
                        <StyledButton
                          size="middle"
                          style={
                            editingState
                              ? {
                                marginInline: 8,
                                backgroundColor: "green",
                                borderColor: "green",
                                color: "white",
                              }
                              : {
                                marginInline: 8,
                                backgroundColor: "lightgray",
                                borderColor: "lightgray",
                                color: "darkgray",
                              }
                          }
                          type="primary"
                          onClick={save}
                          disabled={!editingState}
                          loading={status === AsyncStates.LOADING}
                          icon={<SaveOutlined />}
                          ghost
                        >
                          {t("common.save")}
                        </StyledButton>
                      </div>
                    )}
                  <Space>
                    {!!selectedTrials.length ? (
                      <Text type="secondary">{`${selectedTrials.length} selected`}</Text>
                    ) : (
                      ""
                    )}
                    <Checkbox
                      checked={
                        displayIdList.length === selectedTrials.length &&
                        !!selectedTrials.length
                      }
                      onChange={onSelectAll}
                    >
                      {t("common.selectAll")}
                    </Checkbox>
                    {inventoryFlag && (
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <StyledButton
                          icon={<DownOutlined />}
                          type="primary"
                          ghost
                          onClick={(e) => e.preventDefault()}
                        >
                          {"Hide Columns"}
                        </StyledButton>
                      </Dropdown>
                    )}
                    <Popconfirm
                      title={`${t("common.deleteSelectedTrials")}?`}
                      onConfirm={removeTrials}
                    >
                      <Tooltip
                        title={
                          !selectedTrials.length
                            ? t("message.trials")
                            : t("common.delete")
                        }
                      >
                        <span
                          style={{
                            cursor: !selectedTrials.length
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          <StyledButton
                            style={{
                              pointerEvents: !selectedTrials.length
                                ? "none"
                                : "auto",
                            }}
                            disabled={!selectedTrials.length}
                            icon={<StyledDeleteIcon />}
                            danger
                          >
                            {t("common.delete")}
                          </StyledButton>
                        </span>
                      </Tooltip>
                    </Popconfirm>
                    <Tooltip
                      title={
                        !selectedTrials.length
                          ? t("message.trials")
                          : `${t("common.copyTrial(s)")}`
                      }
                    >
                      <span
                        style={{
                          cursor: !selectedTrials.length
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <StyledButton
                          style={{
                            pointerEvents: !selectedTrials.length
                              ? "none"
                              : "auto",
                          }}
                          disabled={!selectedTrials.length}
                          onClick={() => setCopTrialsVisible(true)}
                          icon={<CopyOutlined />}
                          type="primary"
                          ghost
                        >
                          {t("common.copyTrial(s)")}
                        </StyledButton>
                      </span>
                    </Tooltip>
                  </Space>
                </Row>
              )}
            </div>
          }
        >
          {getDatasheet()}
        </Card>
      );
    }
  }

  const model = useLayoutModel()

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div style={{ height: 620, position: "relative" }}>
          <Layout
            model={model}
            factory={factory} />
        </div>
        {workOrder?.work_order_stage === Stages.properties_stage &&
          initialTrials.some((res: any) => !!Object.keys(res?.predicted_properties || {})?.length) &&
          (!!workOrder?.suggested_experiment_id?.length || !!workOrder?.prediction_experiment_ids?.length) ? (
          <Collapse defaultActiveKey={"predicted_properties"}>
            <Collapse.Panel
              header={
                <Typography.Text type="secondary" strong>{`${t(
                  "common.predictedProperties"
                )} (${t(
                  "closedWO.basedOnAIRecommendation"
                )})`}</Typography.Text>
              }
              key="predicted_properties"
            >
              <PredictedProperties experiments={initialTrials} />
            </Collapse.Panel>
          </Collapse>
        ) : null}
        <TrialDetailsDrawer
          initialTrials={initialTrials}
          linkedTrials={linkedTrials}
          modalTrial={modalTrial}
          trialType={trialType}
          setTrialType={setTrialType}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          workOrder={workOrder}
          form={form}
        />
        {workOrder.work_order_stage === "characterization_stage" && Boolean(configs?.polymers_graphs) && (
          <Collapse defaultActiveKey={["graphs"]}>
            <Collapse.Panel header={"Graphs"} key="graphs">
              <CharacterizationsPlots from={"work_order"} />
            </Collapse.Panel>
          </Collapse>
        )}
        {workOrder.work_order_stage === "properties_stage" && Boolean(configs?.pcm_graphs) && (
          <Collapse defaultActiveKey={["files", "graphs"]}>
            <Collapse.Panel header={"Graphs"} key="graphs">
              <PropertiesPlots
                parameterList={parameterList}
                displayIdList={displayIdList}
                setHighlightIndex={setHighlightIndex}
                setTrialSetDataList={setTrialSetDataList}
              />
            </Collapse.Panel>
            <Collapse.Panel header={"File Upload"} key="files">
              <FileUploads displayIdList={displayIdList} />
            </Collapse.Panel>
          </Collapse>
        )}
        {(workOrder.work_order_stage === "properties_stage" && !Boolean(configs?.pcm_graphs) && !Boolean(configs?.polymers_graphs)) && (
          <Collapse defaultActiveKey={["files", "graphs"]}>
            <Collapse.Panel header={"Graphs"} key="graphs">
              <RawPlots currentExpIdIndex={currentExpIdIndex} componentType={"crud"} />
            </Collapse.Panel>
            <Collapse.Panel header={"File Upload"} key="files">
              <RawFileUpload displayIdList={displayIdList} initialTrialSetList={initialTrialSetList} currentExpIdIndex={currentExpIdIndex} componentType={"crud"} />
            </Collapse.Panel>
          </Collapse>
        )}
      </Space>
      <CopyTrialsModal
        visible={copyTrialVisible}
        setVisible={setCopTrialsVisible}
        selectedTrials={selectedTrials}
        displayIdList={displayIdList}
        createCopiedTrials={createCopiedTrials}
        copyTrials={copyTrials}
        setCopyTrials={setCopyTrials}
      />
      {dataset === "ingredients" ? <IngredientModal
        open={showNewParamModal}
        setOpen={setShowNewParamModal}
        mode={"create"}
        from="work-order"
      /> :
        <CustomParameterModal
          dataset={dataset}
          showNewParamModal={showNewParamModal}
          setShowNewParamModal={setShowNewParamModal}
        />}
      <CreateWoModal
        createWoModalVisible={createWoModalVisible}
        setCreateWoModalVisible={setCreateWoModalVisible}
        initialTrials={initialTrials}
        selectedTrials={selectedTrials}
        from="openWo"
      />

      <BaseCategorySelectionModal //for selecting base catergories for unit conversion
        showBaseCategoryModal={showBaseCategoryModal}
        setShowBaseCategoryModal={setShowBaseCategoryModal}
        trialSetDataList={trialSetDataList}
        initialTrialSetList={initialTrialSetList}
        parameterList={parameterList}
        batchSizeList={batchSizeList}
        dataset={dataset}
        setBatchMode={setBatchMode}
        displayIdList={displayIdList}
      />
      <AddUnitModal
        refetch={() => dispatch(unitListRequest({ "category": "" }))}
        isAddNewUnitModal={showCard}
        closeModal={() => setShowCard(false)}
      />

      {ingredientDetailInventoryId && <IngredientsDetailsModal propertiesModalOpen={propertiesModalOpen} setPropertiesModalOpen={setPropertiesModalOpen} setIngredientDetailInventoryId={setIngredientDetailInventoryId} inventory_id={ingredientDetailInventoryId} />}
    </>
  );
}

const FrozenCell = memo((props: any) => {
  const setWidth = props.colOffsetValues[props.col]["widthInPx"].toString() + 'px'
  const offsetValue = props.colOffsetValues[props.col]["offset"]
  return (
    <td
      {...props}
      style={{
        whiteSpace: "break-spaces",
        paddingRight: "2px",
        paddingLeft: "2px",
        minWidth: setWidth,
        maxWidth: setWidth,
        width: setWidth,
        position: 'sticky',
        left: offsetValue,
        zIndex: 1,
        backgroundColor: props?.cell?.readOnly ? props?.style?.backgroundColor : 'white',
        ...props.style,
      }}
    >
      {props.children}
    </td>
  );
})

const FrozenHeaderCell = memo((props: any) => {
  const setWidth = props?.colOffsetValues?.[props.col]?.["widthInPx"].toString() + 'px'
  const offsetValue = props.colOffsetValues?.[props.col]?.["offset"]
  let zIndex = 1;
  if (props.col in props.frozenColumnIds && props.row === 0) zIndex = 2;

  return (
    <td
      {...props}
      style={{
        whiteSpace: "break-spaces",
        paddingRight: "2px",
        paddingLeft: "2px",
        minWidth: setWidth ?? "auto",
        maxWidth: setWidth ?? "auto",
        width: setWidth ?? "auto",
        position: 'sticky',
        top: 0,
        left: offsetValue ?? "",
        zIndex: zIndex,
        backgroundColor: props?.cell?.readOnly ? props?.style?.backgroundColor : 'white',
        ...props.style,
      }}
    >
      {props.children}
    </td>
  );
})

const NormalCell = memo((props: any) => {
  return (
    <td
      {...props}
    >
      {props.children}
    </td>
  );
})

export const RemoveBtn = memo((props: any) => {
  const [t] = useTranslate()
  const userAccess = usePermission();
  const disabled = useMemo(() => userAccess.permission === permissions.viewer || userAccess.status !== projectStatus.in_progress, [userAccess])

  return (
    <Row align="middle" style={{ flexWrap: "nowrap" }}>
      <Col flex="32px">
        <Tooltip title={t("common.remove")}>
          <MinusCircleOutlined
            style={{ color: blue.primary, fontSize: antdTheme.fontSizeHeading4, paddingInline: 10, cursor: !disabled ? "pointer" : "none" }}
            onClick={() => !disabled ? props.removeParameter(props.parameterIndex) : null}
          />
        </Tooltip>
      </Col>
    </Row>
  )
})

