import { handleActions } from "redux-actions";
import {
  CustomTrainActionTypes,
  FilterDataActionTypes,
  DeleteModelsActionTypes,
  CustomTraingsActionTypes,
  EquationModelActionTypes,
  EquationModelListActionTypes,
  EquationActionTypes,
  CompareEquationActionTypes,
  DownloadEquationModelReportActionTypes,
} from "../actions/customML";
import { AsyncStates } from "../../constants";

export type CustomMlState = {
  filterDataStatus: AsyncStates;
  customDataTrainStatus: AsyncStates;
  deleteModelStatus: AsyncStates;
  filterData: any;
  customTrainData: any;
  customTrainingsData: any;
  customTrainingsStatus: AsyncStates;
  formulationfilterDataStatus: AsyncStates;
  formulationfilterData: any;
  equationModelListStatus: AsyncStates;
  equationModelListData: any[];
  equationModelStatus: AsyncStates;
  equationModelData: any;
  equationStatus: AsyncStates;
  equationData: any;
  compareEquationStatus: AsyncStates;
  compareEquationData: any;
  downloadEquationModelReportStatus: AsyncStates,
  downloadEquationModelReportData: any,
};

const defaultState: CustomMlState = {
  filterDataStatus: AsyncStates.INITIAL,
  customDataTrainStatus: AsyncStates.INITIAL,
  deleteModelStatus: AsyncStates.INITIAL,
  filterData: { dataframe: [], version: "" },
  customTrainData: "",
  customTrainingsData: [],
  customTrainingsStatus: AsyncStates.INITIAL,
  formulationfilterData: { dataframe: [], version: "" },
  formulationfilterDataStatus: AsyncStates.INITIAL,
  equationModelListStatus: AsyncStates.INITIAL,
  equationModelListData: [],
  equationModelStatus: AsyncStates.INITIAL,
  equationModelData: "",
  equationStatus: AsyncStates.INITIAL,
  equationData: {},
  compareEquationStatus: AsyncStates.INITIAL,
  compareEquationData: {},
  downloadEquationModelReportStatus: AsyncStates.INITIAL,
  downloadEquationModelReportData: "",
};

const customMlReducer = handleActions(
  {
    [FilterDataActionTypes.REQUEST]: (state) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.LOADING,
    }),
    [FilterDataActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        filterDataStatus: AsyncStates.SUCCESS,
        filterData: action.payload,
      };
    },
    [FilterDataActionTypes.FORMULATION_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        formulationfilterDataStatus: AsyncStates.SUCCESS,
        formulationfilterData: action.payload,
      };
    },
    [FilterDataActionTypes.FAILURE]: (state, action) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.ERROR,
    }),
    [FilterDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      filterDataStatus: AsyncStates.INITIAL,
    }),
    [CustomTraingsActionTypes.REQUEST]: (state) => ({
      ...state,
      customTrainingsStatus: AsyncStates.LOADING,
    }),
    [CustomTraingsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      customTrainingsStatus: AsyncStates.SUCCESS,
      customTrainingsData: action.payload,
    }),
    [CustomTraingsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      customTrainingsData: { dataframe: [], version: "" },
      customTrainingsStatus: AsyncStates.ERROR,
    }),
    [CustomTraingsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      customTrainingsData: { dataframe: [], version: "" },
      customTrainingsStatus: AsyncStates.INITIAL,
    }),
    [CustomTrainActionTypes.REQUEST]: (state) => ({
      ...state,
      customDataTrainStatus: AsyncStates.LOADING,
    }),
    [CustomTrainActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.SUCCESS,
      customTrainData: action.payload,
    }),
    [CustomTrainActionTypes.FAILURE]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.ERROR,
    }),
    [CustomTrainActionTypes.CLEAR]: (state, action) => ({
      ...state,
      customDataTrainStatus: AsyncStates.INITIAL,
    }),
    [CompareEquationActionTypes.REQUEST]: (state) => ({
      ...state,
      compareEquationStatus: AsyncStates.LOADING,
    }),
    [CompareEquationActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      compareEquationStatus: AsyncStates.SUCCESS,
      compareEquationData: action.payload,
    }),
    [CompareEquationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      compareEquationStatus: AsyncStates.ERROR,
    }),
    [CompareEquationActionTypes.CLEAR]: (state, action) => ({
      ...state,
      compareEquationStatus: AsyncStates.INITIAL,
      compareEquationData: {}
    }),
    [EquationActionTypes.REQUEST]: (state) => ({
      ...state,
      equationStatus: AsyncStates.LOADING,
    }),
    [EquationActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      equationStatus: AsyncStates.SUCCESS,
      equationData: action.payload,
    }),
    [EquationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      equationStatus: AsyncStates.ERROR,
    }),
    [EquationActionTypes.CLEAR]: (state, action) => ({
      ...state,
      equationStatus: AsyncStates.INITIAL,
      equationData: []
    }),
    [EquationModelListActionTypes.REQUEST]: (state) => ({
      ...state,
      equationModelListStatus: AsyncStates.LOADING,
    }),
    [EquationModelListActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      equationModelListStatus: AsyncStates.SUCCESS,
      equationModelListData: action.payload,
    }),
    [EquationModelListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      equationModelListStatus: AsyncStates.ERROR,
    }),
    [EquationModelListActionTypes.CLEAR]: (state, action) => ({
      ...state,
      equationModelListStatus: AsyncStates.INITIAL,
      equationModelListData: []
    }),
    [EquationModelActionTypes.REQUEST]: (state) => ({
      ...state,
      equationModelStatus: AsyncStates.LOADING,
    }),
    [EquationModelActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.SUCCESS,
      equationModelData: action.payload,
    }),
    [EquationModelActionTypes.FAILURE]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.ERROR,
    }),
    [EquationModelActionTypes.CLEAR]: (state, action) => ({
      ...state,
      equationModelStatus: AsyncStates.INITIAL,
      equationModelData: []
    }),
    [DownloadEquationModelReportActionTypes.REQUEST]: (state) => ({
      ...state,
      downloadEquationModelReportStatus: AsyncStates.LOADING,
    }),
    [DownloadEquationModelReportActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      downloadEquationModelReportStatus: AsyncStates.SUCCESS,
      downloadEquationModelReportData: action.payload,
    }),
    [DownloadEquationModelReportActionTypes.FAILURE]: (state, action) => ({
      ...state,
      downloadEquationModelReportStatus: AsyncStates.ERROR,
    }),
    [DownloadEquationModelReportActionTypes.CLEAR]: (state, action) => ({
      ...state,
      downloadEquationModelReportStatus: AsyncStates.INITIAL,
      downloadEquationModelReportData: ""
    }),
    [DeleteModelsActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteModelStatus: AsyncStates.LOADING,
    }),
    [DeleteModelsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteModelStatus: AsyncStates.SUCCESS,
    }),
    [DeleteModelsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteModelStatus: AsyncStates.ERROR,
    }),
  },
  defaultState
);

export default customMlReducer;
