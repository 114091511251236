import { green } from "@ant-design/colors";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  EyeOutlined,
  CloseOutlined,
  FileExcelOutlined,
  ImportOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseCircleTwoTone,
  BulbOutlined,
  HeatMapOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Space,
  Steps,
  Modal,
  Typography,
  Tooltip,
  Collapse,
  Skeleton,
  Drawer,
  Table,
  Tag,
  Card,
  Spin,
  message,
} from "antd";
import {
  AsyncStates,
  defaultStages,
  permissions,
  projectStatus,
  EXP_PAGE_SIZE,
  projectType,
  currentPlatform,
} from "src/constants";
import { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "src/analytics";
import { DataMapper } from "src/components/DataManagement/DataMapper/DataMapper";
import { KurarayFileUpload } from "src/components/DataManagement/DataMapper/KurarayFileUpload";
// import { FileUpload } from "src/components/DataManagement/DataMapper/FileUpload";
import { Datasheet } from "src/components/Datasheet";
import {
  fetchExperimentRequest,
  fetchLinkedExperimentCleanup,
  fetchLinkedExperimentDetailsCleanup,
  fetchLinkedExperimentRequest,
} from "src/store/actions/experiment";
import { setIsEditing } from "src/store/actions/isEditing";
import useTranslate from "src/utils/useTranslate";
import {
  editWorkOrderRequest,
  setEditingState,
  dataExportRequest,
  dataSanityRequest,
  dataSanityClear,
  stageExistsRequest,
} from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import { getStageName } from "src/utils/decorator";
import { WoReportPreview } from "../WoReportPreview";
import { WorkOrderContainer } from "../WorkOrderContainer";
import { WoStatus } from "../WoStatus";
import { mapDataset, Stages } from "src/utils/mapDataset";
import { usePermission } from "src/utils/usePermissions";
import { WorkOrderDetails as CelsureWorkOrderDetails } from "src/components/Celsure/WorkOrderDetails/WorkOrderDetails";
import { CustomStagesModal } from "../CustomStagesModal";
import ClosedWoStages from "./ClosedWoStages";
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { displayNamesRequest } from "src/store/actions/displayNames";
import { useMesssage } from "src/utils/hooks"
import "./Stages.scss"
import { createIngredientFromTrialClear } from "src/store/actions/inventoryV2";
import { ExcelMapper } from "src/components/DataManagement/DataMapper/ExcelMapper";
import { CharacterizationDatasheet } from "src/components/Datasheet/CharacterizationDatasheet";
import { Dataset } from "src/utils/getHeaders";
import { useVariableValue } from "@devcycle/devcycle-react-sdk";

const StagesWO = () => {
  const { Step } = Steps;
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const [drawerState, setDrawerState] = useState("");
  const [stagesModalVisisble, setStagesModalVisible] = useState(false);
  const editWOStatus = useSelector((state: StoreState) => state.workOrderDetails.editWOStatus)

  const [stages, setStages] = useState(() =>
    JSON.parse(JSON.stringify(defaultStages))
  );
  const user_id = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const projectId = useSelector((state: StoreState) => state.projects.current);
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const dataExportStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.dataExportStatus
  );
  const experiments = useSelector(
    (state: StoreState) => state.workOrderDetails.experiment
  );
  const createIngredientFromTrialStatus = useSelector(
    (state: StoreState) => state.inventoryV2.createIngredientFromTrial.createIngredientFromTrialStatus
  );
  const stagesData = useSelector(
    (state: StoreState) => state.displayNames.data?.stages || {}
  );
  const projects = useSelector(
    (state: StoreState) => state.projects.projectList
  );

  const project_type = useSelector(
    (state: StoreState) => state.login.loginResponse.project_type
  );

  const editingState = useSelector(
    (state: StoreState) => state.workOrderDetails.editingState
  );
  const cellChanges = useSelector(
    (state: StoreState) => state.workOrderDetails.cellChanges
  );
  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress ||
      !!cellChanges.length
    ,
    [userAccess, cellChanges]
  );

  const [open, setOpen] = useState(false);
  const [showStagePreview, setShowStagePreview] = useState(false);

  const stageDataExist = useSelector((state: StoreState) => state.workOrderDetails.stageDataExist)
  const stageExistLoading = useSelector((state: StoreState) => state.workOrderDetails.stageExistLoading)

  useEffect(() => {
    if (stageExistLoading === AsyncStates.SUCCESS && Array.isArray(stageDataExist) && stageDataExist.length > 0) {
      setStagesModalVisible(true);
    }
  }, [stageDataExist, stageExistLoading])

  useEffect(() => {
    if (createIngredientFromTrialStatus === AsyncStates.SUCCESS && !showStagePreview) {
      dispatch(displayNamesRequest())
    }
  }, [createIngredientFromTrialStatus, dispatch, showStagePreview])

  useEffect(() => {
    return () => {
      dispatch(createIngredientFromTrialClear())
    }
  }, [dispatch])

  const onClose = () => {
    setOpen(false);
  };

  const currentProject = useMemo(
    () => projects.find((project: any) => project.project_id === projectId),
    [projects, projectId]
  );
  const initialTrials = useSelector(
    (state: StoreState) => state.workOrderDetails.experiment
  );
  const user_role = useSelector(
    (state: StoreState) => state.login.loginResponse.user_role
  );
  const configs = useSelector((state: StoreState) => state.configs.features);

  const [showImportData, setShowImportData] = useState(false);
  const [hasParams, setHasParams] = useState<boolean>(false);
  const [unsavedDataImport, setUnsavedDataImport] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [sanityChecks, setSanityChecks] = useState(false);
  const [savedSanityColumnData, setSavedSanityColumnData] = useState<any>({});
  const [showSanityData, setShowSanityData] = useState(false);
  const dataUploadStatus = useSelector(
    (state: StoreState) => state.files.uploadFile.status || {}
  );
  const { m } = useMesssage()
  const dataSanityData = useSelector(
    (state: StoreState) => state.workOrderDetails.dataSanityData
  );

  const dataSanityColumnData = useSelector(
    (state: StoreState) => state.workOrderDetails.dataSanityColumnData
  );
  const { updateExperimentWithUnitStatus } = useSelector((state: StoreState) => state.woExperimentUnitConversion);
  useEffect(() => {
    if (dataSanityColumnData) setSavedSanityColumnData(dataSanityColumnData);
  }, [dataSanityColumnData, dataSanityData]);

  useEffect(() => {
    if (dataUploadStatus === AsyncStates.SUCCESS) {
      setShowImportData(false);
    }
  }, [dataUploadStatus]);

  const dataUploadFirebaseTaskId = useSelector(
    (state: StoreState) => state.files.uploadFile.data?.firebase_task_id || workOrder?.firebase_task_id
  );

  const userStages: any = useMemo(() => {
    if (user_role === "admin" || currentProject.created_by === user_id) {
      return stagesData;
    } else {
      const newStages: any = {};
      for (let i in stagesData) {
        if (
          currentProject?.members
            ?.find((member: any) => member.user_id === user_id)
            ?.stages?.includes(i)
        ) {
          newStages[i] = stagesData[i];
        }
      }
      return newStages;
    }
  }, [user_id, user_role, stagesData, currentProject]);

  const allStages = useMemo(
    () =>
      !!workOrder?.work_order_id
        ? {
          ...(!!userStages?.[Stages.work_order_initiated] && {
            [Stages.work_order_initiated]: t("common.WorkOrderInitiated"),
          }),
          ...workOrder.stages.reduce(
            (obj: any, element: any, index: number) => ({
              ...obj,
              [element.identifier]: getStageName(element?.name, index, t),
            }),
            {}
          ),
          ...(!!userStages?.[Stages.report_preview] && {
            [Stages.report_preview]: t("common.PreviewStage"),
          }),
          ...(!!userStages?.[Stages.work_order_status] && {
            [Stages.work_order_status]: t("common.WorkOrderCompleted"),
          }),
        }
        : {},
    [workOrder?.work_order_id, workOrder.stages, userStages, t]
  );

  const stagesWithIndex = useMemo(
    () =>
      Object.keys(allStages || {}).reduce(
        (acc: any, element: any, index: number) => ({
          ...acc,
          [element]: index,
        }),
        {}
      ),
    [allStages]
  );

  //Datasheet tab selection
  const [dataset, setDataset] = useState<any>();
  useEffect(() => {
    setDataset(mapDataset(workOrder.work_order_stage));
  }, [workOrder.work_order_stage]);
  const currentExpIdIndex = useMemo(
    () =>
      workOrder?.stages?.findIndex(
        (stage: any) => stage?.identifier === workOrder?.work_order_parent_stage
      ),
    [workOrder?.work_order_parent_stage, workOrder?.stages]
  );

  const [uploadTaskStatus, setUploadTaskStatus] = useState<string>("");
  useEffect(() => {
    setStages(workOrder?.stages)
    if (workOrder.work_order_parent_stage === 'closed') {
      setLoadData(true);
    }
  }, [workOrder])

  useEffect(() => {
    let unsub: Unsubscribe;
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(
        firestoreDb,
        `${FB_COLLECTION_NAME_TASKERS}/${dataUploadFirebaseTaskId}`);
      unsub = onSnapshot(taskDocRef, (doc: any) => {
        const taskDoc = doc.data();
        if (taskDoc) {
          const taskType = taskDoc?.type
          if (taskType !== "prediction") {
            const experimentId = workOrder?.experiment_id?.[currentExpIdIndex];
            const taskStatus = taskDoc["status"];
            setUploadTaskStatus(taskStatus);
            if (taskStatus === "PARTIAL") {
              if (!!experimentId) {
                dispatch(displayNamesRequest({ backgroundFetch: true }));
                dispatch(
                  fetchExperimentRequest({
                    experiment_id: [experimentId],
                    page_num: 1,
                    page_size: EXP_PAGE_SIZE,
                    stages: [workOrder.work_order_stage]
                  })
                );
                setLoadData(true);
              }
            }
            if (taskStatus === "COMPLETED") {
              if (!!experimentId) {
                unsub();
                dispatch(displayNamesRequest({ backgroundFetch: true }));
                dispatch(
                  fetchExperimentRequest({
                    experiment_id: [experimentId],
                    page_num: 1,
                    page_size: EXP_PAGE_SIZE,
                    stages: [workOrder.work_order_stage]
                  })
                );
                setLoadData(true);
              }
            }
          }
        }
      });
    };

    if (dataUploadFirebaseTaskId && workOrder?.work_order_parent_stage !== 'closed') {
      listenToFileUploadTaskStatus();
    }

    return () => {
      uploadTaskStatus === 'COMPLETED' && unsub && unsub();
    };
  }, [uploadTaskStatus, dataUploadFirebaseTaskId, currentExpIdIndex, dispatch, workOrder.experiment_id, workOrder.work_order_stage, workOrder?.work_order_parent_stage]);

  // generate work order task
  const [predictedWoStatus, setPredictedWoStatus] = useState<AsyncStates>(AsyncStates.INITIAL)
  useEffect(() => {
    let unsub: Unsubscribe
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${workOrder?.firebase_task_id}`)
      unsub = onSnapshot(taskDocRef, (doc: any) => {
        const taskDoc = doc.data()
        if (taskDoc) {
          const taskExpId = taskDoc?.experiment_id
          const taskWoId = taskDoc?.work_order_id
          const taskStatus = taskDoc?.status
          const taskType = taskDoc?.type
          if (taskType === "prediction") {
            if (taskStatus === "IN_PROGRESS" && workOrder?.work_order_id === taskWoId) {
              setPredictedWoStatus(AsyncStates.LOADING)
            }
            if (taskStatus === "FAILED" && workOrder?.work_order_id === taskWoId) {
              setPredictedWoStatus(AsyncStates.INITIAL)
              message.error(m('internal_server_error'))
              unsub()
            }
            if (taskStatus === "COMPLETED" && workOrder?.work_order_id === taskWoId) {
              dispatch(fetchExperimentRequest({
                experiment_id: [taskExpId?.[currentExpIdIndex]],
                page_num: 1,
                page_size: EXP_PAGE_SIZE,
                stages: [workOrder.work_order_stage]
              }))
              setPredictedWoStatus(AsyncStates.SUCCESS)
              unsub()
            }
          }
        }
      })
    }
    if (workOrder?.firebase_task_id && workOrder?.work_order_parent_stage !== 'closed') {
      listenToFileUploadTaskStatus()
    }
    return () => {
      (predictedWoStatus === AsyncStates.SUCCESS && unsub !== undefined && unsub !== null) && unsub()
    }
  }, [m, dispatch, predictedWoStatus, workOrder?.work_order_id, workOrder?.firebase_task_id, workOrder?.work_order_stage, currentExpIdIndex, workOrder?.work_order_parent_stage])

  const isStageCompleted = useMemo(
    () => workOrder?.stages?.[currentExpIdIndex]?.status === "closed",
    [workOrder?.stages, currentExpIdIndex]
  );

  const setStageStatus = (stage: string) => {
    const stageSeq = Object.keys(stagesWithIndex);
    const stageIdx = stageSeq.indexOf(stage);
    const index = stageSeq.indexOf(workOrder.work_order_parent_stage);
    const curStageIdx =
      index === -1
        ? workOrder?.stages?.findIndex(
          (stage: any) =>
            stage?.identifier === workOrder?.work_order_parent_stage
        ) + 1
        : index;
    if (stageIdx === curStageIdx) {
      return { status: "process", desc: t("common.inProgress") };
    } else if (stageIdx < curStageIdx) {
      return { status: "finish", desc: t("common.completed") };
    } else if (stageIdx > curStageIdx || !workOrder?.work_order_id) {
      return { status: "wait", desc: t("stages.status.awaiting") };
    } else {
      return { status: "error", decs: t("common.disabled") };
    }
  };

  const getCurrent = useCallback(() => {
    return stagesWithIndex?.[workOrder.work_order_parent_stage];
  }, [workOrder, stagesWithIndex]);

  const getStageTranslation = useCallback((stageKey) => {

    switch (stageKey) {
      case "formulation_stage":
        return t("common.formulation")
      case "processing_stage":
        return t("common.processing")
      case "characterization_stage":
        return t("common.characterization")
      case "properties_stage":
        return t("common.properties")

      default:
        return stageKey
    }
  }, [t]);

  const getTabList = useCallback(() => {

    const userStagesKeys = Object.keys(userStages);
    if (
      !!workOrder?.stages.find(
        (res: any) => res.identifier === workOrder.work_order_parent_stage
      )
    ) {
      return workOrder?.stages
        .find(
          (res: any) => res.identifier === workOrder.work_order_parent_stage
        )
        ?.data.filter((res: any) => userStagesKeys.includes(res.type))
        ?.map((res: any) => ({ key: res.type, tab: getStageTranslation(res.type), disabled } || []));
    } else {
      return [];
    }
  }, [userStages, workOrder?.stages, workOrder.work_order_parent_stage, getStageTranslation, disabled]);

  const enablePropertyStandards = useVariableValue('property-standards', false)

  const getWordOrderData = useCallback(() => {
    if (showStagePreview) {
      return (
        <WoReportPreview
          currentExpIdIndex={currentExpIdIndex}
          from="stage_preview"
        />
      );
    } else if (showImportData) {
      if (Boolean(configs?.kuraray_data_upload)) {
        return <KurarayFileUpload currentExpIdIndex={currentExpIdIndex} />;
      }
      return (
        <DataMapper
          workOrder={workOrder}
          currentExpIdIndex={currentExpIdIndex}
          unsaved={unsavedDataImport}
          setUnsaved={setUnsavedDataImport}
        />
      );
    } else if (dataUploadStatus !== AsyncStates.SUCCESS) {
      return (
        <Spin spinning={updateExperimentWithUnitStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />} tip={"Conversion in progress."}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            {enablePropertyStandards && dataset === Dataset.properties ? <CharacterizationDatasheet
              dataset={dataset}
              showTotals={["ingredients"].includes(dataset)}
              userAccess={userAccess}
              initialTrials={experiments}
              loadData={loadData}
              setLoadData={setLoadData}
              currentExpIdIndex={currentExpIdIndex}
              dataUploadFBTaskStatus={uploadTaskStatus}
              frozenColumnSpecifications={[
                {
                  colName: "",
                  widthInPx: 50,
                },
                {
                  colName: "No",
                  widthInPx: 50,
                },
                {
                  colName: "Category",
                  widthInPx: 110,
                },
                {
                  colName: "Ingredients",
                  widthInPx: 150,
                },
                {
                  colName: "Standards",
                  widthInPx: 120,
                },
                {
                  colName: "Unit",
                  widthInPx: 70,
                },
              ]}
              setHasParams={setHasParams}
            /> : <Datasheet
              dataset={dataset}
              showTotals={["ingredients"].includes(dataset)}
              userAccess={userAccess}
              initialTrials={experiments}
              loadData={loadData}
              setLoadData={setLoadData}
              currentExpIdIndex={currentExpIdIndex}
              dataUploadFBTaskStatus={uploadTaskStatus}
              frozenColumnSpecifications={[
                {
                  colName: "",
                  widthInPx: 50,
                },
                {
                  colName: "No",
                  widthInPx: 50,
                },
                {
                  colName: "Category",
                  widthInPx: 110,
                },
                {
                  colName: "Ingredients",
                  widthInPx: 150,
                },
                {
                  colName: "Unit",
                  widthInPx: 70,
                },
              ]}
              setHasParams={setHasParams}
            />}

          </Space>
        </Spin>
      );
    } else {
      return <Skeleton active />;
    }
  }, [showStagePreview, showImportData, dataUploadStatus, currentExpIdIndex, configs?.kuraray_data_upload, workOrder, unsavedDataImport, updateExperimentWithUnitStatus, enablePropertyStandards, dataset, userAccess, experiments, loadData, uploadTaskStatus]);

  const dataSanityCheck = () => {
    setSanityChecks(true);
    setShowSanityData(true);
    dispatch(
      dataSanityRequest({
        experiment_id: workOrder.experiment_id?.[currentExpIdIndex],
      })
    );
  };

  const showDrawer = (type: string) => {
    setDrawerState(type);
    setOpen(true);
  };

  const dataSanityErrorCount = useSelector(
    (state: StoreState) =>
      Array.from(
        new Set(
          state.workOrderDetails.dataSanityData
            .filter((check) => check.type === "Error")
            .map((check) => check.ui_message_id)
        )
      )?.length
  );
  const dataSanityWarningCount = useSelector(
    (state: StoreState) =>
      Array.from(
        new Set(
          state.workOrderDetails.dataSanityData
            .filter((check) => check.type === "Warning")
            .map((check) => check.ui_message_id)
        )
      )?.length
  );

  const dataSanityDataTypes = useMemo(
    () => Array.from(new Set(dataSanityData.map((check) => check.type))),
    [dataSanityData]
  );

  const uniqueMessages = new Map();

  dataSanityData.forEach((item) => {
    if (!uniqueMessages.has(item.ui_message_id)) {
      uniqueMessages.set(item.ui_message_id, item.type);
    }
  });

  const currentDataSanityDataTypes = useMemo(
    () => dataSanityDataTypes.filter((type) => type === drawerState),
    [dataSanityDataTypes, drawerState]
  );
  const errorGrps = useMemo(
    () =>
      currentDataSanityDataTypes.map((label) => {
        const filteredCheckTypes = dataSanityData.filter(
          (checks) => checks.type === label
        );
        const uniqueChecks = Array.from(
          new Set(filteredCheckTypes.map((checks) => checks.ui_message_id))
        );

        const items = uniqueChecks.map((check, index) => {
          const filteredChecks = filteredCheckTypes.filter(
            (_check) => _check.ui_message_id === check
          );
          const dataSource = filteredChecks.map((_check: any, idx) => ({
            key: idx,
            trial: _check.data.trial_name,
            parameter: _check.data.item_name,
            type: _check.data.item_type,
          }));
          const trialsExists = filteredChecks.some(
            (_check) => _check.data.trial_name
          );
          const parametersExists = filteredChecks.some(
            (_check) => _check.data.item_name
          );

          const columns = [
            ...(trialsExists
              ? [
                {
                  title: "Trials",
                  dataIndex: "trial",
                  key: "trial",
                },
              ]
              : []),
            ...(parametersExists
              ? [
                {
                  title: "Parameters",
                  dataIndex: "parameter",
                  key: "parameter",
                },
              ]
              : []),
            ...(parametersExists
              ? [
                {
                  title: "Type",
                  dataIndex: "type",
                  key: "type",
                },
              ]
              : []),
          ];
          const label = filteredChecks?.[0]?.message;
          const solution = filteredChecks?.[0]?.data.next_actions;
          const key = filteredChecks?.[0]?.ui_message_id;
          return {
            key,
            label,
            children: (
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                align="start"
              >
                <Table
                  size="small"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  footer={() => (
                    <Typography.Text type="secondary">
                      count: {dataSource?.length}
                    </Typography.Text>
                  )}
                />
                <Tag
                  bordered={false}
                  color="processing"
                  style={{ maxWidth: "100%", whiteSpace: "normal" }}
                >
                  {t("common.recommendation")}: {solution}
                </Tag>
              </Space>
            ),
          };
        });

        return {
          label,
          key: label,
          children: (
            <Collapse
              bordered={false}
              expandIconPosition="end"
              items={items}
              defaultActiveKey={uniqueChecks}
            />
          ),
        };
      }),
    [currentDataSanityDataTypes, dataSanityData, t]
  );

  const dataSanityMessageTypes = Array.from(
    uniqueMessages,
    ([ui_message_id, type]) => ({ ui_message_id, type })
  );

  const dataSanityStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.dataSanityStatus
  );

  const dataSanityDataStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.dataSanityDataStatus
  );

  // Define a utility function to determine the icon based on conditions
  function getIcon(uiMessageId: string) {
    const errorColor = "red";
    const warningColor = "#ff954d";
    const successColor = "#52c41a";
    const matchingError = dataSanityMessageTypes.find(
      (item) =>
        item.ui_message_id === uiMessageId &&
        item.type.toLowerCase() === "error"
    );

    const matchingWarning = dataSanityMessageTypes.find(
      (item) =>
        item.ui_message_id === uiMessageId &&
        item.type.toLowerCase() === "warning"
    );

    if (
      savedSanityColumnData[uiMessageId]
        ? savedSanityColumnData[uiMessageId].status === "in_progress"
        : dataSanityDataStatus === AsyncStates.LOADING
    ) {
      return <LoadingOutlined color={"blue"} />;
    } else if (savedSanityColumnData["column_group_check"] === "failed") {
      return <CloseCircleTwoTone twoToneColor={errorColor} />;
    } else if (
      savedSanityColumnData[uiMessageId]
        ? savedSanityColumnData[uiMessageId]["Error"] > 0
        : matchingError
    ) {
      return <CloseCircleTwoTone twoToneColor={errorColor} />;
    } else if (
      savedSanityColumnData[uiMessageId]
        ? savedSanityColumnData[uiMessageId]["Warning"] > 0
        : matchingWarning
    ) {
      return <ExclamationCircleTwoTone twoToneColor={warningColor} />;
    } else {
      return <CheckCircleTwoTone twoToneColor={successColor} />;
    }
  }

  function getPercentage(type: string) {
    const totalCount = 9;
    const typeCount = dataSanityMessageTypes.filter(
      (item) => item.type.toLowerCase() === type
    ).length;
    if (savedSanityColumnData["column_group_check"] === "failed") {
      if (type === "error") return "9/9";
      else return "0/9";
    } else if (type === "success")
      return (
        totalCount -
        dataSanityMessageTypes.filter(
          (item) => item.type.toLowerCase() === "error"
        ).length -
        dataSanityMessageTypes.filter(
          (item) => item.type.toLowerCase() === "warning"
        ).length +
        "/" +
        totalCount
      );
    return typeCount + "/" + totalCount;
  }

  useEffect(() => {
    if (!!workOrder.work_order_id) {
      if (currentExpIdIndex !== -1) {
        dispatch(
          fetchExperimentRequest({
            experiment_id: [workOrder.experiment_id?.[currentExpIdIndex]],
            page_num: 1,
            page_size: EXP_PAGE_SIZE,
            // stages: [workOrder.work_order_stage]
          })
        );
        setLoadData(true);
      }
      if (currentExpIdIndex > 0) {
        dispatch(
          fetchLinkedExperimentRequest({
            experiment_id: workOrder.experiment_id.slice(0, currentExpIdIndex),
            access: false,
            linked_stages: true,
          })
        );
      } else {
        dispatch(fetchLinkedExperimentCleanup());
        dispatch(fetchLinkedExperimentDetailsCleanup());
      }
    }
  }, [
    dispatch,
    workOrder.work_order_id,
    workOrder.experiment_id,
    workOrder.work_order_parent_stage,
    currentExpIdIndex,
    workOrder.work_order_stage
  ]);

  const changeTab = (tabKey: string) => {
    setShowSanityData(false);
    dispatch(
      editWorkOrderRequest({
        work_order_id: workOrder.work_order_id,
        data: { work_order_stage: tabKey },
        updateDisplayNames: false
      })
    );
    dispatch(setIsEditing(false));
  }

  const companyId = useSelector((state: StoreState) => state.login.loginResponse.company_id)

  const [showExcelMapper, setShowExcelMapper] = useState(false)

  return (
    <WorkOrderContainer>
      {project_type === projectType.celsure ? (
        <CelsureWorkOrderDetails />
      ) : workOrder?.status === "closed" ? (
        <ClosedWoStages />
      ) : predictedWoStatus === AsyncStates.LOADING ?
        <Spin spinning={predictedWoStatus === AsyncStates.LOADING} tip={t("workOrder.experiments.generated")}
          indicator={<LoadingOutlined />}>
          <div style={{ width: "100%", height: "300px" }}></div>
        </Spin>
        : (
          <>
            {!!workOrder.work_order_id && (
              <Space direction="vertical" style={{ width: "100%", marginTop: '0.5rem' }} size="small">
                {dataset && !isStageCompleted && (
                  <Space
                    style={{
                      marginBottom: 5,
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    {!showImportData && (
                      <Tooltip placement="top" title={t("common.EditStages")}>
                        <StyledButton
                          type="default"
                          icon={<EditOutlined />}
                          loading={stageExistLoading === AsyncStates.LOADING}
                          onClick={() => {
                            if (!!workOrder.work_order_id) {
                              dispatch(stageExistsRequest({
                                work_order_id: workOrder?.work_order_id
                              }))
                            }
                            setStages(workOrder?.stages);
                          }}
                        />
                      </Tooltip>
                    )}
                    {(!!initialTrials?.length || disabled) && hasParams && (
                      <>
                        <Tooltip placement="top" title={showStagePreview ? t("common.Closepreview") : t("common.PreviewStage")}>
                          <StyledButton
                            type="default"
                            danger={showStagePreview ? true : false}
                            icon={
                              !showStagePreview ? (
                                <EyeOutlined />
                              ) : (
                                <CloseOutlined />
                              )
                            }
                            onClick={() => {
                              if (editingState && !showStagePreview) {
                                return Modal.confirm({
                                  width: 500,
                                  title: t("common.modal.unsavedwarning"),
                                  onCancel() { },
                                  onOk: () => {
                                    dispatch(setEditingState(false));
                                    dispatch(
                                      fetchExperimentRequest({
                                        experiment_id: [
                                          workOrder.experiment_id?.[
                                          currentExpIdIndex
                                          ],
                                        ],
                                        page_num: 1,
                                        page_size: EXP_PAGE_SIZE,
                                      })
                                    );
                                    setLoadData(true);
                                    setShowStagePreview((prevState) => !prevState);
                                  },
                                });
                              } else {
                                dispatch(
                                  fetchExperimentRequest({
                                    experiment_id: [
                                      workOrder.experiment_id?.[currentExpIdIndex],
                                    ],
                                    page_num: 1,
                                    page_size: EXP_PAGE_SIZE,
                                    stages: showStagePreview ? [workOrder.work_order_stage] : undefined
                                  })
                                );
                                setLoadData(true);
                                setShowStagePreview((prevState) => !prevState);
                              }
                            }}
                          />
                        </Tooltip>
                        {!showImportData && (
                          <Tooltip placement="top" title={t("workOrderDetails.exportDatasheet")}>
                            <StyledButton
                              type="default"
                              loading={dataExportStatus === AsyncStates.LOADING}
                              icon={<FileExcelOutlined />}
                              onClick={() =>
                                dispatch(
                                  dataExportRequest({
                                    experiment_id:
                                      workOrder?.experiment_id?.[currentExpIdIndex],
                                  })
                                )
                              }
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    {companyId === "COMP351PS01HQE2023" && (<> {(
                      <StyledButton
                        type="primary"
                        icon={<ImportOutlined />}
                        onClick={() => {
                          if (unsavedDataImport && showImportData) {
                            return Modal.confirm({
                              width: 500,
                              title: t("common.modal.unsavedwarning"),
                              onCancel() { },
                              onOk: () => {
                                document.body.style.overflow = 'hidden'; document.body.style.overscrollBehaviorX = 'contain';
                                setShowExcelMapper((prevState) => !prevState);
                                setUnsavedDataImport(false);
                              },
                            });
                          } else {
                            document.body.style.overflow = 'hidden'; document.body.style.overscrollBehaviorX = 'contain';
                            setShowExcelMapper((prevState) => !prevState);
                            setUnsavedDataImport(false);
                          }
                        }}
                      >
                        {`${showImportData
                          ? t("common.cancelImport")
                          : t("dataManagement.importData")
                          }`}
                      </StyledButton>
                    )}</>)}
                    {companyId !== "COMP351PS01HQE2023" && (<> {(
                      !Boolean(configs?.toyoink_configs) && !Boolean(configs?.nestle_configs) && !hasParams && !disabled && <StyledButton
                        type="primary"
                        danger={showImportData ? true : false}
                        icon={
                          !showImportData ? <ImportOutlined /> : <CloseOutlined />
                        }
                        onClick={() => {
                          if (unsavedDataImport && showImportData) {
                            return Modal.confirm({
                              width: 500,
                              title: t("common.modal.unsavedwarning"),
                              onCancel() { },
                              onOk: () => {
                                setShowImportData((prevState) => !prevState);
                                setUnsavedDataImport(false);
                              },
                            });
                          } else {
                            setShowImportData((prevState) => !prevState);
                            setUnsavedDataImport(false);
                          }
                        }}
                      >
                        {`${showImportData
                          ? t("common.cancelImport")
                          : t("dataManagement.importData")
                          }`}
                      </StyledButton>
                    )}</>)}
                  </Space>
                )}
                <StyledCard
                  bodyStyle={{
                    paddingTop: 0,
                    paddingRight: 32,
                    paddingLeft: 16,
                    paddingBottom: "0px",
                  }}
                >
                  <Steps
                    type="navigation"
                    size="small"
                    style={{ paddingTop: "0px", flexWrap: "wrap" }}
                    current={getCurrent()}
                    onChange={function (current: number) {
                      const stage = Object.keys(stagesWithIndex).find(
                        (key) => stagesWithIndex[key] === current
                      );
                      let newStage = {
                        identifier: "",
                        name: "",
                        data: [{ type: "" }],
                      };
                      if (stage === Stages.work_order_initiated)
                        newStage = {
                          identifier: Stages.work_order_initiated,
                          name: Stages.work_order_initiated,
                          data: [{ type: Stages.work_order_initiated }],
                        };
                      else if (stage === Stages.report_preview)
                        newStage = {
                          identifier: Stages.report_preview,
                          name: Stages.report_preview,
                          data: [{ type: Stages.report_preview }],
                        };
                      else if (stage === Stages.work_order_status)
                        newStage = {
                          identifier: Stages.work_order_status,
                          name: Stages.work_order_status,
                          data: [{ type: Stages.work_order_status }],
                        };
                      else
                        newStage = workOrder?.stages?.find(
                          (res: any) => res?.identifier === stage
                        );
                      Modal.confirm({
                        title: `${editingState
                          ? `${t("workOrderDetails.unsavedChangesPresent")}.`
                          : ""
                          } ${t("workOrderDetails.confirmStageChange")}`,
                        icon: <ExclamationCircleOutlined />,
                        content: `${allStages?.[workOrder.work_order_parent_stage] ||
                          workOrder?.stages?.find(
                            (res: any) =>
                              res?.identifier ===
                              workOrder?.work_order_parent_stage
                          )?.name
                          } > ${allStages?.[newStage?.identifier]}`,
                        onOk() {
                          setShowSanityData(false);
                          setShowStagePreview(false);
                          trackEvent(user_id, "Work Order updated", {
                            "WO details": { newStage },
                          });
                          dispatch(
                            editWorkOrderRequest({
                              work_order_id: workOrder.work_order_id,
                              data: {
                                work_order_parent_stage: newStage.identifier,
                                work_order_stage: newStage?.data?.[0]?.type,
                              },
                              updateDisplayNames: true
                            })
                          );
                          if (createIngredientFromTrialStatus === AsyncStates.SUCCESS) {
                            dispatch(displayNamesRequest())
                            dispatch(createIngredientFromTrialClear());
                          }

                        },
                      });
                    }}
                  >
                    {Object.entries(allStages || {}).map(([key, value]: any) => (
                      <Step
                        key={key}
                        style={{
                          flexBasis: 220,
                          flexGrow: 0,
                          paddingTop: 10,
                          textAlign: "left",
                          paddingLeft: 48,
                        }}
                        title={
                          <Typography.Text strong ellipsis style={{ width: 160 }}>
                            {value}
                          </Typography.Text>
                        }
                        status={setStageStatus(key).status as any}
                        description={setStageStatus(key).desc}
                        disabled={!workOrder.work_order_stage || disabled}
                      />
                    ))}
                  </Steps>
                </StyledCard>
                {dataset && !isStageCompleted && (
                  <StyledCard
                    id="workorder__datasheet"
                    style={{ background: '#ffffff' }}
                    tabProps={{
                      size: "small",
                      style: { padding: 0 },
                      activeKey: workOrder?.work_order_stage,
                      onTabClick: (activeKey: string) => {
                        const unsaved = editingState;
                        if (unsaved) {
                          Modal.confirm({
                            title: `${t("workOrderDetails.unsavedChangesPresent")}.`,
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                              changeTab(activeKey);
                            },
                            onCancel() {
                              return;
                            }
                          });
                        } else {
                          changeTab(activeKey);
                        }
                      }
                    }}
                    tabList={
                      showStagePreview || showImportData ? [] : getTabList()
                    }
                    tabBarExtraContent={currentPlatform !== "connect" && !showImportData &&
                      <StyledButton
                        onClick={dataSanityCheck}
                        loading={
                          dataSanityDataStatus === AsyncStates.LOADING &&
                          showSanityData
                        }
                        danger={
                          dataSanityStatus === AsyncStates.SUCCESS &&
                            dataSanityData?.length && showSanityData ? true : false
                        }
                        type={"primary"}
                        icon={
                          dataSanityData?.length && showSanityData ? (
                            <CloseCircleOutlined />
                          ) : dataSanityDataStatus === AsyncStates.SUCCESS &&
                            showSanityData ? (
                            <CheckCircleOutlined />
                          ) : <HeatMapOutlined />
                        }
                        style={
                          !dataSanityData?.length &&
                            dataSanityDataStatus === AsyncStates.SUCCESS &&
                            showSanityData
                            ? {
                              background: green.primary,
                              color: "white",
                            }
                            : {}
                        }
                        size={"small"}
                      >{t("common.ValidateDataforAI")}
                      </StyledButton>
                    }
                    headStyle={{ padding: "16px", borderBottom: "none" }}
                    bodyStyle={{ padding: "0px 16px 8px 16px" }}
                  >
                    <Spin spinning={editWOStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                      {dataSanityStatus !== AsyncStates.INITIAL && <Card
                        headStyle={{ backgroundColor: "gray", cursor: "pointer" }}
                        bodyStyle={{
                          paddingTop: "1rem"
                        }}
                        title={<div onClick={() => showSanityData ? setShowSanityData(false) : setShowSanityData(true)}>
                          <CloseOutlined onClick={() => { dispatch(dataSanityClear()) }} />
                          <span style={{ color: "#000000", marginLeft: "0.5rem" }}>{t("common.Datavalidation")}</span></div>}
                        extra={
                          [
                            !showSanityData && <UpOutlined style={{ marginLeft: "auto" }} onClick={() => setShowSanityData(true)} />,
                            showSanityData && <DownOutlined style={{ marginLeft: "auto" }} onClick={() => { setShowSanityData(false) }} />
                          ]
                        }
                        style={{
                          width: "44vw",
                          zIndex: 10,
                          marginInline: "auto",
                          position: 'fixed',
                          bottom: 0,
                          right: "5rem",
                          height: showSanityData ? "70vh" : "8vh",
                        }}
                      >
                        <Space size={"small"} style={{ width: "100%", display: "flex", flexDirection: "column" }} >
                          <div style={{ display: "inline-flex", alignItems: "center" }}>
                            {!!dataSanityData?.length &&
                              showSanityData &&
                              dataSanityDataStatus === AsyncStates.SUCCESS ? (
                              <div>
                                <Typography.Text type="secondary">
                                  Found{" "}
                                </Typography.Text>
                                {!!dataSanityErrorCount && (
                                  <StyledButton
                                    style={{ padding: 0 }}
                                    onClick={() => showDrawer("Error")}
                                    type="link"
                                  >
                                    {dataSanityErrorCount} {" "} {t("common.error")}
                                    {dataSanityErrorCount > 1 ? "s" : ""}
                                  </StyledButton>
                                )}
                                {!!dataSanityWarningCount &&
                                  !!dataSanityErrorCount && (
                                    <Typography.Text type="secondary">
                                      {" "}
                                      and{" "}
                                    </Typography.Text>
                                  )}
                                {!!dataSanityWarningCount && (
                                  <StyledButton
                                    style={{ padding: 0 }}
                                    onClick={() => showDrawer("Warning")}
                                    type="link"
                                  >
                                    {" "}
                                    {dataSanityWarningCount} {" "} {t("common.warning")}
                                    {dataSanityWarningCount > 1 ? "s" : ""}
                                  </StyledButton>
                                )}
                              </div>
                            ) : dataSanityDataStatus === AsyncStates.SUCCESS &&
                              showSanityData ? (
                              <Typography.Text type="secondary">
                                {t("common.Datavalidatedsuccessfully")}
                              </Typography.Text>
                            ) : null}
                            {(!!dataSanityWarningCount || !!dataSanityErrorCount) &&
                              showSanityData && (
                                <Tooltip
                                  title={
                                    <span>
                                      <p>
                                        {t("common.Errors-Thesesignifyseriousproblemsthatwillpreventmodelsfromtrainingproperly.")}
                                      </p>
                                      <p>
                                        {t("common.Warnings-Lesscriticalissues,butstillworthreviewing.")}
                                      </p>
                                      <p>
                                        {t("common.Addressingerrorsismandatorybeforefurthermodeling.Warningsmayrequireinvestigationbutwon'tnecessarilyblockprogress.Thegoalistodetectandresolvedataproblemsearly.")}
                                      </p>
                                    </span>
                                  }
                                >
                                  {"  "}
                                  <InfoCircleFilled style={{ marginLeft: "5px" }} />
                                </Tooltip>
                              )}
                          </div>
                          <div>
                            {(sanityChecks ||
                              dataSanityDataStatus === AsyncStates.SUCCESS) &&
                              showSanityData && (
                                <div style={{ marginLeft: "auto" }}>
                                  <Steps
                                    direction="horizontal"
                                    size="small"
                                    current={-1}
                                    items={[
                                      {
                                        title:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING
                                            ? ` ${t("common.Passed")}`
                                            : getPercentage("success") + ` ${t("common.Passed")}`,
                                        icon:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING ? (
                                            <LoadingOutlined color={"green"} />
                                          ) : (
                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                          ),
                                      },
                                      {
                                        title:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING
                                            ? "  Warnings"
                                            : getPercentage("warning") +
                                            " Warnings",
                                        icon:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING ? (
                                            <LoadingOutlined color={"#ff954d"} />
                                          ) : (
                                            <ExclamationCircleTwoTone twoToneColor="#ff954d" />
                                          ),
                                        onClick: () => showDrawer("Warning"),
                                        style: {
                                          cursor: "pointer",
                                          width: "100%",
                                          paddingRight: "10px",
                                        },
                                      },
                                      {
                                        title:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING
                                            ? "  Errors"
                                            : getPercentage("error") + " Errors",
                                        icon:
                                          dataSanityDataStatus ===
                                            AsyncStates.LOADING ? (
                                            <LoadingOutlined color={"red"} />
                                          ) : (
                                            <CloseCircleTwoTone twoToneColor="red" />
                                          ),
                                        onClick: () => showDrawer("Error"),
                                        style: { cursor: "pointer" },
                                      },
                                    ]}
                                  />
                                </div>
                              )}
                          </div>
                        </Space>
                        {(sanityChecks ||
                          dataSanityDataStatus === AsyncStates.SUCCESS) &&
                          showSanityData && (
                            <Collapse ghost defaultActiveKey={["sanity_checks"]}>
                              <Collapse.Panel
                                key="sanity_checks"
                                header={
                                  <Typography.Text strong>
                                    {t("common.DataValidityChecks")}
                                  </Typography.Text>
                                }
                              >
                                <div style={{ height: "36vh", overflowY: 'auto' }}>
                                  <Steps
                                    direction="vertical"
                                    size="small"
                                    current={
                                      dataSanityDataStatus === AsyncStates.SUCCESS
                                        ? 9
                                        : 0
                                    }
                                  >
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for continuous data`}
                                      icon={getIcon("binary_columns")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for variance in data`}
                                      icon={getIcon("variance_issue")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for a minimum of 4 data points per identifier`}
                                      icon={getIcon("count_categorical_values")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } data type consistency`}
                                      icon={getIcon("data_type_mismatch")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for duplicity in formulations`}
                                      icon={getIcon("duplicate_formulations")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for null values within acceptance range`}
                                      icon={getIcon("too_many_null_values")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for outliers`}
                                      icon={getIcon("outliers")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for sanity of categorical values`}
                                      icon={getIcon("categorical_imbalance")}
                                    />
                                    <Steps.Step
                                      title={`${dataSanityDataStatus === AsyncStates.LOADING
                                        ? "Checking"
                                        : "Checked"
                                        } for minimum number of trials required for better modelling and higher accuracy`}
                                      icon={getIcon("less_data")}
                                    />
                                  </Steps>
                                </div>
                              </Collapse.Panel>
                            </Collapse>
                          )}
                      </Card>}
                      {getWordOrderData()}
                    </Spin>
                  </StyledCard>
                )}
              </Space>
            )}
            {isStageCompleted && dataset && (
              <WoReportPreview
                currentExpIdIndex={currentExpIdIndex}
                from={"stage_preview"}
              />
            )}

            {workOrder?.work_order_stage === Stages.report_preview && (
              <WoReportPreview from={"report_preview"} />
            )}
            {workOrder?.work_order_stage === Stages.work_order_status && (
              <WoStatus workOrder={workOrder} />
            )}
            <CustomStagesModal
              stagesModalVisisble={stagesModalVisisble}
              setStagesModalVisible={setStagesModalVisible}
              stages={stages}
              setStages={setStages}
              workOrder={workOrder}
            />
            <Drawer
              title="Data Issues"
              placement="right"
              onClose={onClose}
              open={open}
              width={"45%"}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {drawerState === "Warning" && (
                  <Typography.Text type="secondary">
                    <BulbOutlined /> Less critical issues, but still worth
                    reviewing.
                  </Typography.Text>
                )}
                {drawerState === "Error" && (
                  <Typography.Text type="secondary">
                    <BulbOutlined /> These signify serious problems that will
                    prevent models from training properly.
                  </Typography.Text>
                )}
                <Collapse
                  expandIconPosition="end"
                  items={errorGrps}
                  defaultActiveKey={["Warning", "Error"]}
                />
              </Space>
            </Drawer>
            <Modal footer={null} centered open={showExcelMapper} width={'100%'} style={{ height: '100%', maxWidth: 'unset', overflow: 'auto', maxHeight: '100vh' }} styles={{ body: { height: '100%', overflow: 'auto', padding: 0, maxHeight: '100vh' }, content: { height: '100%', maxWidth: 'unset', overflow: '100%', padding: 0, paddingTop: 20, maxHeight: '100vh' } }} onCancel={() => {
              document.body.style.overflow = 'auto'; document.body.style.overscrollBehaviorX = 'auto';
              setShowExcelMapper(false)
            }}>
              <ExcelMapper callbacks={{ setUnsaved: unsavedDataImport }} />
            </Modal>
          </>
        )
      }
    </WorkOrderContainer >
  );
};
export default StagesWO;
