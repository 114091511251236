import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { StoreState } from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import "./Compare.scss";
import {
    Space, Table, Select, Checkbox, Row, Popover,
    Typography
} from "antd";
import { grey } from "@ant-design/colors";
import Text from "antd/lib/typography/Text";
import useTranslate from "src/utils/useTranslate";
import { sortArray } from "src/utils/general/sort"
import { UnitSelection } from "../../UnitSelection";
import { apply, add_operation } from 'json-logic-js'
import Big from 'big.js';
import { useValue } from "src/utils/useValue";
import InventoryUtils from "src/modules/InventoryV2/utils";
import { AsyncStates } from "src/constants";
import { fetchAllMethodsRequest } from "src/store/actions/repository";

const plus = (a: number, b: number) => Big(a).plus(b).toNumber()
const div = (a: number, b: number) => Big(a).div(b).toNumber()
const mul = (a: number, b: number) => Big(a).mul(b).toNumber()
const minus = (a: number, b: number) => Big(a).minus(b).toNumber()
add_operation("plus", plus)
add_operation("div", div)
add_operation("mul", mul)
add_operation("minus", minus)


export const IngredientsComparisonTable = memo(({ formulationList, checkbox, formulationsCheck, checkBoxChange, unit, setUnit }: { formulationList: any[], checkbox?: boolean, formulationsCheck?: any, checkBoxChange?: any, unit: string, setUnit: any }) => {
    const ingredientsLabels = useSelector((state: StoreState) => state.displayNames.data?.ingredients || {})
    const linkedFormulationDetailsData = useSelector((state: StoreState) => state.compareFormulations.linkedFormulationDetailsData)
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)

    const [t] = useTranslate()
    const { getValue } = useValue()

    const formulationsWithIngredients = useMemo(() => { return formulationList.filter((formulation) => !!Object.keys(formulation?.ingredients || {})?.length) }, [formulationList])

    const ingredientCategories = useMemo(() => {
        return Array.from(new Set(formulationsWithIngredients.flatMap(({ ingredients }) => Object.values(ingredients || {})).map((ele: any) => {
            return ele?.category
        })))
    }, [formulationsWithIngredients])

    const getUnitOptions = useCallback(() => {
        const categoryUnits = unitList.filter(({ category }) => ["weight", "ratio"].includes(category))
        return categoryUnits?.map(({ name: value }) => ({ label: value, value, disabled: ["mol", "mol%"].includes(value) }))
    }, [unitList])
    const [tableData, setTableData] = useState<any>([])


    useEffect(() => {
        setTableData(Array.from(new Set(formulationsWithIngredients.flatMap(({ ingredients }) => Object.keys(ingredients || {}))))
            .map((name: string) => ({
                category: formulationsWithIngredients.map(({ ingredients }: any) => ingredients).find((formulation) => !!Object.keys(formulation?.[name] || {})?.length)?.[name]?.category || "-",
                name: linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === name)?.meta?.display_id || name,
                unit: unit || "-",
                ...formulationsWithIngredients.reduce(
                    (acc, formulation, index: number) => {
                        let convertedValue = formulation.ingredients?.[name]?.value
                        let parametrValue = formulation.ingredients?.[name]?.value
                        if (unit === "wt%") {
                            if (formulation.ingredients?.[name]?.unit !== "wt%") {
                                let batchSizeConversionValue: any = formulation?.meta?.batch_size?.value_grams ?? 100
                                let batchSizeUnit = formulation?.meta?.batch_size?.pref_unit ?? 'g'
                                if (batchSizeUnit !== formulation.ingredients?.[name]?.unit) {
                                    const batchSizeConversionString = `${batchSizeUnit}_to_${formulation.ingredients?.[name]?.unit}`
                                    const batchSizeFormula = unitList.find((res: any) => res.name === batchSizeUnit)?.conversion_metric?.[batchSizeConversionString]
                                    if (batchSizeFormula) {
                                        batchSizeConversionValue = !!formulation?.meta?.batch_size?.value_grams ? apply(batchSizeFormula, [formulation?.meta?.batch_size?.value_grams]) : formulation?.meta?.batch_size?.value_grams
                                    }
                                }
                                if (!!batchSizeConversionValue && !!parametrValue && !isNaN(parametrValue)) {
                                    convertedValue = Number((parametrValue * 100) / batchSizeConversionValue)
                                }
                            }
                        }
                        else if (formulation.ingredients?.[name]?.unit === "wt%") {
                            if (unit !== "wt%") {
                                let batchSizeConversionValue: any = formulation?.meta?.batch_size?.value_grams ?? 100
                                let batchSizeUnit = formulation?.meta?.batch_size?.pref_unit ?? 'g'
                                if (batchSizeUnit !== unit) {
                                    const batchSizeConversionString = `${batchSizeUnit}_to_${unit}`
                                    const batchSizeFormula = unitList.find((res: any) => res.name === batchSizeUnit)?.conversion_metric?.[batchSizeConversionString]
                                    if (batchSizeFormula) {
                                        batchSizeConversionValue = !!formulation?.meta?.batch_size?.value_grams ? apply(batchSizeFormula, [formulation?.meta?.batch_size?.value_grams]) : formulation?.meta?.batch_size?.value_grams
                                    }
                                }
                                if (batchSizeConversionValue && !!parametrValue && !isNaN(parametrValue)) {
                                    convertedValue = Number((parametrValue * batchSizeConversionValue) / 100)
                                }
                            }
                        }
                        else {
                            const conversionString = `${formulation.ingredients?.[name]?.unit}_to_${unit}`
                            const formula = unitList.find((res: any) => res.name === formulation.ingredients?.[name]?.unit)?.conversion_metric?.[conversionString]
                            convertedValue = !!formula && !!parametrValue && !isNaN(parametrValue) ? apply(formula, [parametrValue]) : parametrValue
                        }
                        return ({
                            ...acc,
                            [formulation.id_set.formulation_id]: (
                                <Row justify="center">
                                    {getValue(convertedValue) || "-"}
                                </Row>
                            )
                                || "-",
                        })
                    },
                    {}
                ),
            })))
    }, [linkedFormulationDetailsData, unit, unitList, formulationsWithIngredients, getValue])

    const ingredientFilterOptions = useMemo(() => {
        const data = Array.from(
            new Set(
                formulationsWithIngredients.flatMap(({ ingredients }) => Object.keys(ingredients || {})
                ).map((ingredient) => linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === ingredient)?.meta?.display_id ?? ingredient)
            )
        )
        return data
    }, [linkedFormulationDetailsData, formulationsWithIngredients])

    return (
        <Table
            bordered
            className="report-table"
            title={() => <Row justify="space-between"><Text>{t("common.formulations")}</Text>
                <Select options={getUnitOptions()}
                    value={unit}
                    onSelect={setUnit}
                    placeholder={t("compare.selectUnits")} style={{ width: 200 }} />
            </Row>}
            size="small"
            pagination={false}
            columns={[
                {
                    title: t("common.category"),
                    dataIndex: "category",
                    filters: ingredientCategories.map((category) => ({
                        text: String(category),
                        value: String(category),
                    })
                    ).sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.category.indexOf(value) === 0,
                    sorter: (a, b) => a.category.localeCompare(b.category),
                    sortDirections: ["ascend", "descend"],
                    fixed: "left",
                    width: 150,
                    align: "center" as any,
                },
                {
                    title: t("common.ingredient"),
                    render: (text: any, record: any, index: any) => {
                        const ingredientName = text.name
                        return <Popover trigger={"hover"} content={
                            (!!ingredientsLabels?.[ingredientName]?.lot_no?.trim() || !!ingredientsLabels?.[ingredientName]?.supplier?.trim() || !!ingredientsLabels?.[ingredientName]?.sub_category?.trim()) ?
                                <Space direction="vertical">
                                    {!!ingredientsLabels?.[ingredientName]?.sub_category?.trim() &&
                                        <Text strong>{"Category/Sub Category :"} {ingredientsLabels?.[ingredientName]?.sub_category}</Text>
                                    }
                                    {!!ingredientsLabels?.[ingredientName]?.lot_no?.trim() &&
                                        <Text strong>{"Lot No :"} {ingredientsLabels?.[ingredientName]?.lot_no}</Text>
                                    }
                                    {!!ingredientsLabels?.[ingredientName]?.supplier?.trim() &&
                                        <Text strong>{"Supplier :"} {ingredientsLabels?.[ingredientName]?.supplier}</Text>
                                    }
                                </Space> :
                                <Text strong>{linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === ingredientName)?.meta?.display_id || ingredientsLabels[ingredientName]?.name || ingredientName}</Text>}>
                            <Text style={{ wordBreak: "break-word" }}>
                                {linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === ingredientName)?.meta?.display_id || ingredientsLabels[ingredientName]?.name || ingredientName}
                            </Text>
                        </Popover>
                    },
                    fixed: "left",
                    width: 200,
                    align: "center" as any,
                    sorter: (a, b) => a?.name.localeCompare(b.name),
                    filters: ingredientFilterOptions.map((ingredient) => ({
                        value: String(ingredient),
                        text: String(ingredientsLabels[ingredient]?.name ?? ingredient),
                    })),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.name === value
                    },
                    sortDirections: ["ascend", "descend"],
                },
                {
                    title: t("common.unit"),
                    dataIndex: "unit",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                },
                ...formulationsWithIngredients.map(
                    (
                        { id_set: { formulation_id }, meta: { display_id } },
                        index: any
                    ) => ({
                        title: () => (
                            <Space
                                style={{ width: "100%" }}
                                align="center"
                                size="small"
                                direction="vertical"
                            >
                                {checkbox && (
                                    <Checkbox
                                        checked={formulationsCheck.includes(formulation_id)}
                                        onChange={(e) => checkBoxChange(e, formulation_id)}
                                    />
                                )}
                                <Typography.Title level={5} style={{ color: grey[1] }}>
                                    {display_id}
                                </Typography.Title>
                            </Space>
                        ),
                        dataIndex: formulation_id,
                        width: 150,
                        align: "center" as any,
                    })
                ),
            ]}
            dataSource={tableData}
            scroll={{ x: 450 }}
        />
    );
})

export const ProcessingComparisonTable = memo(({ formulationList, checkbox, checkBoxChange, formulationsCheck, baseUnits, setbaseUnits }: { formulationList: any[], checkbox?: any, checkBoxChange?: any, formulationsCheck?: any, baseUnits: any[], setbaseUnits: any }) => {
    const [t] = useTranslate()
    const { getValue } = useValue()
    const processingLabels = useSelector((state: StoreState) => state.displayNames?.data?.processing || {})
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)

    const parameterSet = useMemo(() => {
        return Array.from(
            new Set(
                formulationList.flatMap(({ processing }) =>
                    Object.keys(
                        processing.reduce(
                            (acc: any, { processing }: any) => ({ ...acc, ...processing }),
                            {}
                        )
                    )
                )
            )
        )
    }, [formulationList])

    useEffect(() => {
        setbaseUnits(parameterSet.flatMap((key: any) => [...new Set(formulationList
            .flatMap(({ processing }: any) => processing
                .map((res: any) => res?.processing?.[key]?.unit)
                .filter((res: any) => !!res)))]))
    }, [parameterSet, formulationList, setbaseUnits])


    return (
        <Table
            bordered
            title={() => t("compare.processingSets")}
            size="small"
            pagination={false}
            className="report-table"
            columns={[
                {
                    title: t("common.category"),
                    dataIndex: "category",
                    filters: Array.from(new Set(Object.values(processingLabels || {})?.map((res: any) => res?.category))).map(
                        (category) => ({
                            text: String(category),
                            value: String(category),
                        })
                    ).sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.category.indexOf(value) === 0,
                    sorter: (a, b) => a.category.localeCompare(b.category),
                    sortDirections: ["ascend", "descend"],
                    fixed: "left",
                    width: 150,
                    align: "center" as any,
                },
                {
                    title: t("common.processing"),
                    dataIndex: "processing",
                    fixed: "left",
                    width: 200,
                    align: "center" as any,
                    sorter: (a, b) => a.processing.localeCompare(b.processing),
                    filters: Array.from(new Set(Object.values(processingLabels || {})?.map((res: any) => res?.name))).map(
                        (processing) => ({
                            text: String(processing),
                            value: String(processing),
                        })
                    ).sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.processing.indexOf(value) === 0,
                    sortDirections: ["ascend", "descend"],
                },
                {
                    title: t("common.unit"),
                    dataIndex: "unit",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                    render: (text: any, record: any, index: any) => {
                        return <UnitSelection baseUnits={baseUnits} setbaseUnits={setbaseUnits} paramIndex={index} />
                    }
                },
                ...formulationList.flatMap(
                    (
                        {
                            id_set: { formulation_id },
                            processing,
                            meta: { display_id },
                        }: any,
                        index: any
                    ) =>
                        processing.map(({ id_set: { processing_id } }: any) => ({
                            title: () => (
                                <Space
                                    style={{ width: "100%" }}
                                    align="center"
                                    size="small"
                                    direction="vertical"
                                >
                                    {checkbox && (
                                        <Checkbox
                                            checked={formulationsCheck.includes(formulation_id)}
                                            onChange={(e) => checkBoxChange(e, formulation_id)}
                                        />
                                    )}
                                    <Typography.Title level={5} style={{ color: grey[1] }}>
                                        {display_id}
                                    </Typography.Title>
                                </Space>
                            ),
                            dataIndex: processing_id,
                            width: 150,
                            align: "center" as any,
                        }))
                ),
            ]}
            dataSource={parameterSet.map((parameter: string, paramIndex) => ({
                category: processingLabels[parameter]?.category || "-",
                processing: processingLabels[parameter]?.name || parameter,
                unit: baseUnits[paramIndex],
                ...formulationList.reduce(
                    (acc, { processing: trials }: any) => ({
                        ...acc,
                        ...trials.reduce(
                            (acc: any, _processing: any) => {
                                const value = _processing?.processing?.[parameter]?.value
                                const unit = _processing?.processing?.[parameter]?.unit
                                const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${baseUnits[paramIndex]}`]
                                // @ts-ignore
                                const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
                                return ({
                                    ...acc, [_processing.id_set.processing_id]: getValue(convertedValue) || "-"
                                })
                            },
                            {}
                        ),
                    }),
                    {}
                ),
            }))}
            scroll={{ x: 450 }}
        />
    );
})

export const CharacterizationsComparisonTable = memo(({ formulationList, checkbox, checkBoxChange, formulationsCheck, baseUnits, setbaseUnits }: { formulationList: any[], checkbox?: any, checkBoxChange?: any, formulationsCheck?: any, baseUnits: any[], setbaseUnits: any }) => {
    const characterizationLabels = useSelector((state: StoreState) => state.displayNames.data?.characterizations || {})
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)
    const [t] = useTranslate()
    const { getValue } = useValue()

    const parameterSet = useMemo(() => {
        return Array.from(
            new Set(
                formulationList.flatMap(({ characterizations }) =>
                    Object.keys(
                        characterizations.reduce(
                            (acc: any, { characterizations }: any) => ({ ...acc, ...characterizations }),
                            {}
                        )
                    )
                )
            )
        )
    }, [formulationList])

    useEffect(() => {
        setbaseUnits(parameterSet.flatMap((key: any) => [...new Set(formulationList
            .flatMap(({ characterizations }: any) => characterizations
                .map((res: any) => res?.characterizations?.[key]?.unit)
                .filter((res: any) => !!res)))]))
    }, [parameterSet, formulationList, setbaseUnits])


    return (
        <Table
            bordered
            title={() => t("compare.characterizationSets")}
            size="small"
            pagination={false}
            className="report-table"
            columns={[
                {
                    title: t("common.category"),
                    dataIndex: 'category',
                    filters: Array.from(new Set(Object.values(characterizationLabels || {})?.map((res: any) => res?.category)))
                        .map(category => ({
                            text: String(category),
                            value: String(category)
                        })).sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.category.indexOf(value) === 0,
                    sorter: (a, b) => a.category.localeCompare(b.category),
                    sortDirections: ['ascend', 'descend'],
                    fixed: 'left',
                    width: 150,
                    align: "center" as any
                },
                {
                    title: t("common.characterization"), dataIndex: 'characterization', fixed: 'left', width: 200,
                    align: "center" as any
                },
                {
                    title: t("common.unit"),
                    dataIndex: "unit",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                    render: (text: any, record: any, index: any) => {
                        return <UnitSelection baseUnits={baseUnits} setbaseUnits={setbaseUnits} paramIndex={index} />
                    }
                },
                ...formulationList.flatMap(({ id_set: { formulation_id }, characterizations, meta: { display_id } }: any, index: any) => characterizations.map(({ id_set: { processing_id, characterization_id } }: any) => ({
                    title: () => <Space style={{ width: "100%" }} align="center" size="small" direction="vertical">
                        {checkbox && <Checkbox checked={formulationsCheck.includes(formulation_id)} onChange={(e) => checkBoxChange(e, formulation_id)} />}
                        <Typography.Title level={5} style={{ color: grey[1] }}>{display_id}</Typography.Title></Space>,
                    dataIndex: characterization_id,
                    width: 150,
                    align: "center" as any
                })))
            ]}
            dataSource={parameterSet.map((parameter: string, paramIndex) => ({
                category: characterizationLabels[parameter]?.category || "-",
                characterization: characterizationLabels[parameter]?.name || parameter,
                unit: baseUnits[paramIndex],
                ...formulationList.reduce(
                    (acc, { characterizations: trials }: any) => ({
                        ...acc,
                        ...trials.reduce(
                            (acc: any, _characterization: any) => {
                                const value = _characterization?.characterizations?.[parameter]?.value
                                const unit = _characterization?.characterizations?.[parameter]?.unit
                                const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${baseUnits[paramIndex]}`]
                                // @ts-ignore
                                const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
                                return ({
                                    ...acc, [_characterization.id_set.characterization_id]: getValue(convertedValue) || "-"
                                })
                            },
                            {}
                        ),
                    }),
                    {}
                ),
            }))}
            scroll={{ x: 450 }}
        />
    );
})

export const PropertiesComparisonTable = memo(({ formulationList, checkbox, checkBoxChange, formulationsCheck, baseUnits, setbaseUnits }: { formulationList: any[], checkbox?: any, checkBoxChange?: any, formulationsCheck?: any, baseUnits: any[], setbaseUnits: any }) => {
    const [t] = useTranslate()
    const { getValue } = useValue()
    const propertyLabels = useSelector((state: StoreState) => state.displayNames.data?.properties || {})
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)
    const showWorkOrderData = useSelector((state: StoreState) => state.configs.features?.show_work_order_data)
    const dispatch = useDispatch()

    const allMethodList = useSelector((state: StoreState) => state.repository.allMethods.data)
    const allMethodStatus = useSelector((state: StoreState) => state.repository.allMethods.status)


    useEffect(() => {
        if (allMethodStatus === AsyncStates.INITIAL) {
            dispatch(fetchAllMethodsRequest())
        }
    }, [allMethodStatus, dispatch])


    const parameterSet = useMemo(() => {
        return Array.from(
            new Set(
                formulationList.flatMap(({ properties }) =>
                    Object.keys(
                        properties.reduce(
                            (acc: any, { properties }: any) => ({ ...acc, ...properties }),
                            {}
                        )
                    )
                )
            )
        )
    }, [formulationList])

    const propertyData = useMemo(() => {
        const data = formulationList.reduce((acc, formulation) => {
            if (!!Object.keys(formulation?.properties?.[0]?.properties ?? {}).length) {
                return {
                    ...acc,
                    ...formulation.properties[0].properties
                }
            }
            return acc
        }, {})
        return data

    }, [formulationList])

    const getPropertyLabel = useCallback((propertyId: any) => {
        const displayNamesProperty = propertyLabels?.[propertyId];
        const method = allMethodList?.find(
            (method) => method.method_id === displayNamesProperty?.method_id
        );
        return !!method
            ? InventoryUtils.getNameWithParameters(
                displayNamesProperty?.name,
                method.parameters,
                getValue
            )
            : InventoryUtils.getNameWithoutParameters(displayNamesProperty);
    }, [allMethodList, getValue, propertyLabels]);

    useEffect(() => {
        setbaseUnits(parameterSet.flatMap((parameter) => propertyData?.[parameter]?.unit))
    }, [parameterSet, propertyData, setbaseUnits])

    return (
        <Table
            bordered
            title={() => t("compare.propertySets")}
            size="small"
            pagination={false}
            className="report-table"
            columns={[
                {
                    title: t("common.category"),
                    dataIndex: "category",
                    filters: Array.from(new Set(Object.values(propertyData || {})?.map((res: any) => res?.category))).map(
                        (category) => ({
                            text: String(category),
                            value: String(category),
                        })
                    ).sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.category.indexOf(value) === 0,
                    sorter: (a, b) => a.category.localeCompare(b.category),
                    sortDirections: ["ascend", "descend"],
                    fixed: "left",
                    width: 150,
                    align: "center" as any,
                },
                {
                    title: t("common.property"),
                    dataIndex: "property",
                    fixed: "left",
                    width: 200,
                    align: "center" as any,
                    sorter: (a, b) => a.property.localeCompare(b.property),
                    filters:
                        // Array.from(new Set(Object.values(propertyLabels || {})?.map((res: any) => res?.name)))
                        parameterSet?.map((property) => {
                            const propertyNameWithParams = getPropertyLabel(property);
                            return { text: propertyNameWithParams, value: propertyNameWithParams };
                        })?.sort((value1, value2) => sortArray(value1.text.toLowerCase(), value2.text.toLowerCase())),
                    filterSearch: true,
                    onFilter: (value, record) => record.property.indexOf(value) === 0,
                    sortDirections: ["ascend", "descend"],
                },
                ...(!!showWorkOrderData?.standard ? [{
                    title: t("common.standard"),
                    dataIndex: "standard",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                    render: (text: any, record: any, index: any) => {
                        return <Typography.Text
                            ellipsis={{
                                tooltip: text
                            }}
                            style={{ width: 100 }}
                        >
                            {text}
                        </Typography.Text>
                    }
                }] : []),
                ...(!!showWorkOrderData?.specimen ? [{
                    title: t("inventory.specimen"),
                    dataIndex: "specimen",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                    render: (text: any, record: any, index: any) => {
                        return <Typography.Text
                            ellipsis={{
                                tooltip: text
                            }}
                            style={{ width: 100 }}
                        >
                            {text}
                        </Typography.Text>
                    }
                }] : []),
                {
                    title: t("common.unit"),
                    dataIndex: "unit",
                    fixed: "left",
                    width: 100,
                    align: "center" as any,
                    render: (text: any, record: any, index: any) => {
                        return <UnitSelection baseUnits={baseUnits} setbaseUnits={setbaseUnits} paramIndex={index} />
                    }
                },
                ...formulationList.flatMap(
                    (
                        {
                            id_set: { formulation_id },
                            properties,
                            meta: { display_id },
                        }: any,
                        index: any
                    ) =>
                        properties.map(
                            ({ id_set: { properties_id } }: any) => ({
                                title: () => (
                                    <Space
                                        style={{ width: "100%" }}
                                        align="center"
                                        size="small"
                                        direction="vertical"
                                    >
                                        {checkbox && (
                                            <Checkbox
                                                checked={formulationsCheck.includes(formulation_id)}
                                                onChange={(e) => checkBoxChange(e, formulation_id)}
                                            />
                                        )}
                                        <Typography.Title level={5} style={{ color: grey[1] }}>
                                            {display_id}
                                        </Typography.Title>
                                    </Space>
                                ),
                                dataIndex: properties_id,
                                width: 150,
                                align: "center" as any,
                            })
                        )
                ),
            ]}
            dataSource={parameterSet.map((parameter: string, paramIndex) => ({
                category: propertyData?.[parameter]?.category || propertyLabels[parameter]?.category || "-",
                property: getPropertyLabel(parameter) || parameter,
                unit: baseUnits[paramIndex],
                ...(!!showWorkOrderData?.standard && {
                    standard: propertyData?.[parameter]?.standard || "-",
                }),
                ...(!!showWorkOrderData?.specimen && {
                    specimen: propertyData?.[parameter]?.specimen || "-",
                }),
                ...formulationList.reduce(
                    (acc, { properties: trials }: any) => ({
                        ...acc,
                        ...trials.reduce(
                            (acc: any, _properties: any) => {
                                const value = _properties?.properties?.[parameter]?.value
                                const unit = _properties?.properties?.[parameter]?.unit
                                const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${baseUnits[paramIndex]}`]
                                const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
                                return ({
                                    ...acc, [_properties.id_set.properties_id]: getValue(convertedValue) || "-"
                                })
                            },
                            {}
                        ),
                    }),
                    {}
                ),
            }))}
            scroll={{ x: 450 }}
        />
    );
})