import { createActions } from "redux-actions"

export enum SmileSummaryActionTypes {
	REQUEST = "SMILE_SUMMARY_REQUEST",
	SUCCESS = "SMILE_SUMMARY_SUCCESS",
	FAILURE = "SMILE_SUMMARY_FAILURE",
	CLEAR = "SMILE_SUMMARY_CLEAR",
}

export enum SmileSummaryResultActionTypes {
	REQUEST = "SMILE_SUMMARY_RESULT_REQUEST",
	SUCCESS = "SMILE_SUMMARY_RESULT_SUCCESS",
	FAILURE = "SMILE_SUMMARY_RESULT_FAILURE",
	CLEAR = "SMILE_SUMMARY_RESULT_CLEAR",
}

export enum SmileSummaryHistoryActionTypes {
	REQUEST = "SMILE_SUMMARY_HISTORY_REQUEST",
	SUCCESS = "SMILE_SUMMARY_HISTORY_SUCCESS",
	FAILURE = "SMILE_SUMMARY_HISTORY_FAILURE",
	CLEAR = "SMILE_SUMMARY_HISTORY_CLEAR",
}

export enum SmileSummaryHistoryDeleteActionTypes {
	REQUEST = "SMILE_SUMMARY_HISTORY_DELETE_REQUEST",
	SUCCESS = "SMILE_SUMMARY_HISTORY_DELETE_SUCCESS",
	FAILURE = "SMILE_SUMMARY_HISTORY_DELETE_FAILURE"
}

export const {
	smileSummaryRequest,
	smileSummarySuccess,
	smileSummaryFailure,
	smileSummaryClear,

	smileSummaryResultRequest,
	smileSummaryResultSuccess,
	smileSummaryResultFailure,
	smileSummaryResultClear,

	smileSummaryHistoryRequest,
	smileSummaryHistorySuccess,
	smileSummaryHistoryFailure,
	smileSummaryHistoryClear,

	smileSummaryHistoryDeleteRequest,
	smileSummaryHistoryDeleteSuccess,
	smileSummaryHistoryDeleteFailure
} = createActions({
	[SmileSummaryActionTypes.REQUEST]: (payload) => payload,
	[SmileSummaryActionTypes.SUCCESS]: (data) => (data),
	[SmileSummaryActionTypes.FAILURE]: (error) => ({ error }),
	[SmileSummaryActionTypes.CLEAR]: () => { },

	[SmileSummaryResultActionTypes.REQUEST]: (payload) => payload,
	[SmileSummaryResultActionTypes.SUCCESS]: (data) => (data),
	[SmileSummaryResultActionTypes.FAILURE]: (error) => ({ error }),
	[SmileSummaryResultActionTypes.CLEAR]: () => { },

	[SmileSummaryHistoryActionTypes.REQUEST]: (payload) => payload,
	[SmileSummaryHistoryActionTypes.SUCCESS]: (data) => (data),
	[SmileSummaryHistoryActionTypes.FAILURE]: (error) => ({ error }),
	[SmileSummaryHistoryActionTypes.CLEAR]: () => { },

	[SmileSummaryHistoryDeleteActionTypes.REQUEST]: (payload) => payload,
	[SmileSummaryHistoryDeleteActionTypes.SUCCESS]: (data) => (data),
	[SmileSummaryHistoryDeleteActionTypes.FAILURE]: (error) => ({ error })
})
