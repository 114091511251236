import { useEffect, useMemo } from "react"
import {
  Col,
  message,
  Row,
  Table,
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
  equationModelListRequest,
} from "src/store/actions/customML"
import { useHistory } from "react-router-dom"
import "./CustomML.scss"
import dayjs from "dayjs"
import { setProject } from "src/store/actions/projects"


/**
 * selectedwo
 * selectedformulations
 * ismultistage
 * project
 */

export function EquationModelHistory({
  customMlDrawerVisible,
  setCustomMlDrawerVisible,
  isRedirect,
  ...props
}: any) {
  const { push } = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(equationModelListRequest())
  }, [dispatch])
  const currentProject = useSelector((state: StoreState) => state.projects?.current)
  const runList = useSelector((state: StoreState) => state.CustomML.equationModelListData)
  const members = useSelector((state: StoreState) => state.teams.data)
  const runRows = useMemo(() => runList.filter(run => run.project_id === currentProject).map(({ title, Type, project_name, project_id, created_by, created, status, run_id, work_orders }) => ({ title, Type, project_name, project_id, created_by, created, status, run_id, work_orders })).reverse(), [currentProject, runList])
  return (
    <Row gutter={8}>
      <Col span={24}>
        {/* <Row justify={"space-between"}>
          <Col>
            {`Item(s) ${runList.length}`}
          </Col>
          <Col>
            <StyledButton>Compare</StyledButton>
          </Col>
        </Row><br /> */}
        <Row>
          <Col span={24}>
            <Table
              size="large"
              onRow={(record, rowIndex) => ({
                onClick: e => {
                  if (true) {
                    dispatch(setProject(record.project_id));
                    const { title, Type, project_name, project_id, created_by, created, status, run_id, work_orders } = record
                    push(`/ai-engine/new_model/eq_model/${record.run_id}`, { title, Type, project_name, project_id, created_by, created, status, run_id, work_orders })
                  } else {
                    message.warning({ content: "Run was not completed." })
                  }
                }, style: { cursor: "pointer" }
              })}
              columns={[
                {
                  title: "Model Name",
                  key: "title",
                  dataIndex: "title"
                },
                {
                  title: "Custom",
                  key: "Type",
                  dataIndex: "Type",
                  render: text => text ? "Yes" : "No"
                },
                {
                  title: "Project",
                  key: "project_name",
                  dataIndex: "project_name"
                },
                {
                  title: "Created by",
                  key: "created_by",
                  dataIndex: "created_by",
                  render: (text) => members.find(member => member.user_id === text)?.user_name
                },
                {
                  title: "Added on",
                  key: "created",
                  dataIndex: "created",
                  render: text => dayjs(text).format("DD MMM YYYY HH:mm")
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status"
                },
              ]}
              dataSource={runRows}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
