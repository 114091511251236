import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { List, Modal, Row, Space, Spin, Typography, message } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { deletePropertyRequest } from 'src/store/actions/inventory'
import { deleteIngredientClear, deleteIngredientRequest, getIngredientsRequest } from 'src/store/actions/inventoryV2'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography


export const DeleteModal = ({ visible, setVisible, type, selectedRows, dataExistsCheck }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate();

    const { status } = useSelector(
        (state: StoreState) => state.inventoryV2.deleteIngredient
    );

    useEffect(() => {
        if (status === AsyncStates.SUCCESS) {
            message.success(t("inventory.IngredientDeleted"));
            setVisible(false)
            dispatch(deleteIngredientClear())
        }
        return () => { dispatch(getIngredientsRequest({})) }
    }, [dispatch, setVisible, status, t])

    const deletePropertyStatus = useSelector((state: StoreState) => state.inventory.deletePropertyStatus)
    const projectList = useSelector((state: StoreState) => state.projects.projectList)

    return (
        <Modal maskClosable={false}
            visible={visible}
            bodyStyle={{ minHeight: 100, maxHeight: 500, overflowY: "auto" }}
            title={<Space>
                <InfoCircleOutlined />
                {dataExistsCheck ?
                    <Text strong type="warning"> {`Deletion of selected ${type} is restricted`} </Text> :
                    <Text strong type="danger"> {`Delete selected ${type} ?`} </Text>}

            </Space>}
            footer={null}
            onCancel={() => setVisible(false)}>
            <Spin spinning={deletePropertyStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                {selectedRows.some((res: any) => !!res?.project_id?.length) ?
                    <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                        <Text type="secondary" strong>{`${selectedRows.filter((res: any) => !!res?.project_id?.length).map((res: any) => res?.name)?.join(", ")} is used in -`}</Text>
                        <List dataSource={projectList.filter((project: any) => selectedRows.flatMap((res: any) => res?.project_id)?.includes(project?.project_id))}
                            renderItem={(item: any) => <List.Item><Text strong>{item?.name}</Text></List.Item>} />
                    </Space> :
                    <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                        <Text strong>{`Confirm delete selected ${type}? This will permanently delete ${selectedRows.map((res: any) => res?.name)?.join(", ")} from the inventory.`}</Text>
                        <Row justify="end">
                            <StyledButton
                                onClick={() => {
                                    type === 'ingredients' ?
                                        dispatch(deleteIngredientRequest({ inventory_id: selectedRows[0]?.inventory_id })) :
                                        dispatch(deletePropertyRequest({ type, identifiers: selectedRows.map((sr: any) => sr.identifier) }))

                                }} type='primary' loading={status === AsyncStates.LOADING} danger>
                                {t("common.confirmDelete")}
                            </StyledButton>
                        </Row>
                    </Space>
                }
            </Spin>
        </Modal>
    )
}
