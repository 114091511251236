import { notification } from "antd";
import { END, eventChannel } from "redux-saga";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  fetchVersionList,
  multistageInverseModelStream,
  newInverseModelStream,
  zeonFetchVersionList,
  zeonInverseModelStream,
} from "src/services/inverseModel";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { getPredictionSuccess } from "src/store/actions/inverseModel";
import {
  fetchVersionListFailure,
  fetchVersionListRequest,
  fetchVersionListSuccess,
  newInverseAck,
  newInverseModelFailure,
  newInverseModelRequest,
  newInverseModelSuccess,
} from "../actions/newInverseModel";
import { reconnect } from "../actions/connection";
import { suggestedExperimentsRequest } from "../actions/suggestedExp";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";

function createInverseModelChannel(socket: WebSocket) {
  return eventChannel((emit) => {

    socket.onmessage = (event: any) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

// function isValid(data: any) {
//   try {
//     JSON.parse(JSON.parse(data));
//   } catch {
//     return false;
//   }
//   return true;
// }

export let socket: WebSocket;
export let socketChannel: any;
function* newInverseModelSaga(payload: any): Generator<any, any, any> {
  const { payload: inputPayload, isMultiStage } = payload.payload

  // const token = jwtManager.getToken()
  const { user_id: key } = yield select((state) => state.login.loginResponse)
  const configs = yield select((state: StoreState) => state.configs.features)
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current,
  )
  // const { defaultHeaders } = yield select((state) => state)
  // const headers = { ...defaultHeaders, token: jwtManager.getToken() }

  socket = yield call(
    Boolean(configs?.ai_engine_with_methods)
      ? zeonInverseModelStream
      : isMultiStage
        ? multistageInverseModelStream
        : newInverseModelStream,
    key,
  )
  socketChannel = yield call(createInverseModelChannel, socket)

  socket.send(JSON.stringify(inputPayload))
  while (true) {
    try {
      const res = yield take(socketChannel);
      const ack = JSON.parse(res);
      if (ack?.type === "inverse_run" && !ack?.success) {
        // This Check is for Kurita Only
        notification.info({
          duration: 5,
          message: ack?.message,
        })
        yield put(newInverseModelFailure())
      } else {
        notification.info({
          duration: 5,
          message: messages[ln].ai_engine_inverse_started,
        })
        yield put(newInverseAck(ack))
      }
      if (ack?.success) {
        yield put(newInverseModelSuccess({
          firestore_collection: ack.firestore_collection,
          prediction_id: ack.prediction_id
        }))
        socket.close();
      } else {
        throw new Error("")
      }
    } catch (error) {
      notification.error({
        message: messages[ln].internal_server_error,
      })
      yield put(reconnect(error))
    }
  }
}

function* fetchVersionListSaga({ payload }: any): Generator<any, any, any> {
  const currentLanguage: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const configs = yield select((state: StoreState) => state.configs.features);

  const inverseModel = yield select((state: StoreState) => state.inverseModel)

  const userId = yield select((state) => state.login.loginResponse.user_id)

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, versions, variations_versions },
      },
    } = yield call(
      Boolean(configs?.ai_engine_with_methods)
        ? zeonFetchVersionList
        : fetchVersionList,
      payload,
      headers
    );

    const versionsData = Boolean(configs?.ai_engine_with_methods)
      ? variations_versions
      : versions;
    const additionalPayload = Boolean(configs?.ai_engine_with_methods)
      ? {
        variation: variations_versions?.[0]?.variation,
        version: variations_versions?.[0]?.versions?.[0],
      }
      : {
        version: versionsData?.[0],
      };

    if (status === "Success") {
      yield put(fetchVersionListSuccess(versionsData));
      yield put(
        suggestedExperimentsRequest({
          prediction_id: payload?.prediction_id,
          ...additionalPayload,
          pageNum: 1,
        })
      );

      // Change read recipt in main table - Optimistic UI

      const updatedPredList = [...inverseModel?.predictionIdsData?.data]
      updatedPredList.forEach(pred => {
        if (pred?.prediction_id === payload?.prediction_id) {
          pred.read = pred?.read?.length > 0 ? [...pred.read, userId] : [userId]
        }
      })

      yield put(getPredictionSuccess({
        total: inverseModel.predictionIdsData.total,
        data: updatedPredList
      }))


    } else {
      yield put(fetchVersionListFailure(message));
      notification.error({
        message,
      });
    }
  } catch (error) {
    notification.error({
      message: messages[currentLanguage].internal_server_error,
    });
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(newInverseModelRequest, newInverseModelSaga);
  yield takeLatest(fetchVersionListRequest, fetchVersionListSaga);
}
