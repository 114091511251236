import React from 'react'
import { Alert, Typography } from "antd"
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'

const { Title, Text } = Typography

type DroppedPropertyWarningTypes = {
    missingProperties: string[]
}

export const DroppedPropertyWarning = ({ missingProperties }: DroppedPropertyWarningTypes) => {
    const propertiesDisplayNames = useSelector((state: StoreState) => state.displayNames.data?.properties)
    const [t] = useTranslate();

    return (
        <>
            <Alert
                style={{ backgroundColor: "#FAFAFA", border: "none", padding: 16 }}
                message={
                    <Title level={4} type='danger' style={{ margin: 0 }} >
                        <Text type='danger'><span style={{ fontWeight: "bold" }}>{t("aiEngine.modelAnalysis.Nomodelproperty")} </span>
                            <span style={{ fontWeight: 500 }}>{missingProperties.map((property) => propertiesDisplayNames?.[property]?.name ?? property)?.join(", ")}</span>
                        </Text>
                    </Title>}
                description={<Text type='secondary'>{t("aiEngine.noisyDatasetWarning")}</Text>}
            />
        </>
    )
}