import { DeleteOutlined, EditOutlined, LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { Collapse, Dropdown, Empty, notification, Spin, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";

import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import RepositoryService from "src/services/repository/v3";
import jwtManager from "src/utils/jwtManager";
import { Headers } from "src/services/interface";
import PropertyStandardModal from "../Common/PropertyStandardModal";
import { DeleteSpecimenAndStandardModal } from "./DeleteSpecimenAndStandardModal/DeleteSpecimenAndStandardModal";
import { ShowDeleteModalTypes } from "./PropertySpecimens";
import { AsyncStates } from "src/constants";
import { messages } from "src/utils/hooks";

const { Text } = Typography;

type TProps = {
  selectedProperty: string;
};

const PropertyStandards = ({ selectedProperty }: TProps) => {
  const [t] = useTranslate();
  const [showModal, setShowModal] = useState(false);
  const [standards, setStandards] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showDeleteStandardModalData, setShowDeleteStandardModalData] = useState<ShowDeleteModalTypes>({
    type: "standard",
    title: null,
    data: null,
    showModal: false
  });
  const [deleteStandardLoadingState, setDeleteStandardLoadingState] = useState<{ [key: string]: AsyncStates }>({});

  const language = useSelector((state: StoreState) => state.language.current)


  useEffect(() => {

    return () => {
      setShowDeleteStandardModalData({
        type: "standard",
        title: null,
        data: null,
        showModal: false
      })
      setDeleteStandardLoadingState({})
    }
  }, [])

  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const fetchStandards = useCallback(async () => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const standardsResponse: any = await RepositoryService.getStandards(
      selectedProperty,
      headers
    );
    setLoading(false);
    setStandards(standardsResponse.data?.result?.data?.standards ?? []);
  }, [defaultHeaders, selectedProperty]);

  useEffect(() => {
    fetchStandards();
  }, [fetchStandards]);

  const dropdownMenu = useCallback(() => {
    return [
      {
        label: (
          <div style={{ color: "#F5222D" }}>
            <DeleteOutlined /> {t("common.delete")}
          </div>
        ),
        key: "delete",
      },
    ];
  }, [t]);

  const handleDeleteStandard = useCallback(async () => {
    const payload = { standard: showDeleteStandardModalData.data?.standard }

    setDeleteStandardLoadingState((prev) => ({
      ...prev,
      [payload.standard]: AsyncStates.LOADING
    }))
    try {
      const headers = {
        ...defaultHeaders,
        token: jwtManager.getToken(),
      } as Headers;
      const { data: { result: { status, message } } }: any = await RepositoryService.deleteStandards(
        selectedProperty,
        payload,
        headers
      );

      if (status === "Success") {
        setDeleteStandardLoadingState((prev) => ({
          ...prev,
          [payload.standard]: AsyncStates.SUCCESS
        }))
        setStandards((prev) => prev.filter((standard) => standard !== payload.standard))
        setShowDeleteStandardModalData({
          type: "standard",
          title: null,
          data: null,
          showModal: false
        })
        notification.success({
          message: message
        })
      } else {
        setDeleteStandardLoadingState((prev) => ({
          ...prev,
          [payload.standard]: AsyncStates.ERROR
        }))
        notification.error({
          message: message
        })
      }
    } catch (error) {
      setDeleteStandardLoadingState((prev) => ({
        ...prev,
        [payload.standard]: AsyncStates.ERROR
      }))
      notification.error({
        message: messages[language].internal_server_error
      })
    }
  }, [defaultHeaders, selectedProperty, showDeleteStandardModalData, language]);

  const takeAction = useCallback((action: string, standard: string) => {
    if (action === "delete") {
      setShowDeleteStandardModalData({
        type: "standard",
        title: standard,
        showModal: true,
        data: {
          standard: standard,
          property_id: selectedProperty
        },
      })
    }
  }, [selectedProperty]);

  const handleDeleteModalClose = () => {
    setShowDeleteStandardModalData({
      type: "standard",
      title: null,
      data: null,
      showModal: false
    })
  }

  return (
    <div className="property-standard-container">
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              showArrow: false,
              collapsible: "icon",
              label: (
                <div className="collapse-header-container">
                  <span className="collapse-header">
                    {t("common.standards")}
                  </span>
                  <span
                    className="add-new-cta"
                    onClick={() => setShowModal(true)}
                  >
                    <EditOutlined /> {t("common.update")}
                  </span>
                </div>
              ),
              children: (
                <>
                  {standards.length === 0 && loading && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}

                  {standards.sort((a, b) => a.localeCompare(b))
                    .map((standard, i) => (
                      <div key={standard} className="standard-list">
                        <span>{standard}</span>
                        <div>
                          <Dropdown
                            menu={{
                              items: dropdownMenu(),
                              onClick: (event) =>
                                takeAction(event.key, standard),
                            }}
                            trigger={["click"]}
                          >
                            <MoreOutlined />
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                </>
              ),
            },
          ]}
        />
      </Spin>
      <PropertyStandardModal
        key={selectedProperty}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedPropertyId={selectedProperty}
        standards={standards}
        setStandards={setStandards}
      />

      {
        !!showDeleteStandardModalData.showModal &&
        <DeleteSpecimenAndStandardModal
          title={
            <Text>
              {t("common.deleteConfirmation")}{" "}
              <Text strong>{`${showDeleteStandardModalData.title}`}</Text>?
            </Text>
          }
          showDeleteModalData={showDeleteStandardModalData}
          handleModalClose={handleDeleteModalClose}
          errorContent={null}
          loadingState={deleteStandardLoadingState?.[showDeleteStandardModalData?.data?.standard] === AsyncStates.LOADING}
          onConfim={handleDeleteStandard}
        />
      }
    </div>
  );
};

export default PropertyStandards;
