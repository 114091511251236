import { Input, InputProps } from 'antd'
export const StyledInput: React.FC<InputProps> = (props) => {
    const { style, ...rest } = props
    return (
        <Input
            style={{
                borderRadius: 8,
                ...style
            }}
            {...rest}
        />
    )
}
