import { useEffect, useMemo, useState } from "react";
import {
  Cascader,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { antdTheme, AsyncStates } from "src/constants"
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
  forwardPredDeleteRequest,
  forwardPredListRequest,
  forwardPredResultClear
} from "src/store/actions/formulate"
import { ModelConfig } from "src/typings"
import { useHistory } from "react-router-dom"
import { ColumnsType } from "antd/es/table"
import {
  generateVersionName,
  predictionStatus,
  predictionStatusColorText,
  predictionStatusIcon,
} from "src/utils/decorator"
import { FilterForward } from "../AIEngine"
import { useTranslationLabel } from "src/utils/hooks/useTranslationLabel"
import { AvatarComponent, dateSorter, stringSorter } from "src/components/DashboardUpdated/Dashboard"
import { useMemberName } from "src/utils/useMemberName"
import dayjs from "dayjs"
import { AiOutlineSisternode } from "react-icons/ai"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import StyledBadge from "src/styled_components/StyledBadge";
const { Search } = Input

export const ForwardResults = ({ forwardFilters, setForwardFilters, forwardCurrent, setForwardCurrent }: any) => {
  const [t] = useTranslate()
  const { getName } = useMemberName()
  const [forwardForm] = Form.useForm<FormData>()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const configData = useSelector(
    (state: StoreState) => state.formulate.configData,
  ) as ModelConfig[]

  const projectsUsedInPredictions = useSelector((state: StoreState) => state.formulate.projectsUsedInPredictions?.forward)

  const {
    configStatus,
    forwardPredList: { forwardData, forwardTotal },
    forwardPredListStatus,
    forwardPredDeleteStatus,
  } = useSelector((state: StoreState) => state.formulate)

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )
  const teams = useSelector((state: StoreState) => state.teams.data)
  const [forwardPageSize, setForwardPageSize] = useState(10)
  const [deletePredictionIds, setDeletePredictionIds] = useState<string[]>([])
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [filterSelections, setFilterSelections] = useState<any[]>([])
  const [serachField, setSearchField] = useState<string>("")

  useEffect(() => {
    if (forwardPredDeleteStatus === AsyncStates.SUCCESS) {
      dispatch(forwardPredListRequest(forwardFilters ?? {}))
      setForwardCurrent(1)
      setForwardPageSize(10)
    }
  }, [dispatch, forwardFilters, forwardPredDeleteStatus, setForwardCurrent])

  function clearFilters() {
    forwardForm.resetFields()
    setForwardFilters(null)
    setForwardCurrent(1)
    setForwardPageSize(10)
    dispatch(forwardPredListRequest({ pageNum: 1 }))
  }

  function deleteObjectives() {
    if (deletePredictionIds.length) {
      dispatch(
        forwardPredDeleteRequest({ prediction_ids: deletePredictionIds }),
      )
      setForwardCurrent(1)
      setForwardPageSize(10)
    } else message.error(t("aiEngine.recordNotSelected"))
  }


  const options: any[] = useMemo(() => [
    {
      value: "versions",
      label: t("common.model"),
      children: configData
        .filter((res) => !!res.is_custom)
        .map((res) => ({
          value: res.version,
          label: (
            <Typography.Text
              style={{ width: 250 }}
              ellipsis={{ tooltip: generateVersionName(res.version, configData) }}
            >{generateVersionName(res.version, configData)}</Typography.Text>
          ),
        })),
    },
    {
      value: "status",
      label: t("aiEngine.status"),
      children: [
        {
          value: "Completed",
          label: t("common.completed"),
        },
        {
          value: "In Progress",
          label: t("common.inProgress"),
        },
        {
          value: "Failed",
          label: t("common.failed"),
        },
      ],
    },
    {
      value: "model_type",
      label: t("aiEngine.modelType"),
      children: [
        {
          value: "Single Stage",
          label: t("aiEngine.singleStage"),
        },
        {
          value: "Multi Stage",
          label: t("aiEngine.multiStage"),
        },
      ],
    },
    {
      value: "user_ids",
      label: t("aiEngine.user"),
      children: teams?.map((res: any) => ({
        value: res.user_id,
        label: res?.user_name,
      })),
    },
    ...(!!projectsUsedInPredictions?.length ?
      [{
        value: "project_ids",
        label: t("inventory.Projects"),
        children: projectsUsedInPredictions?.map((project) => ({
          value: project.project_id,
          label: (
            <Typography.Text
              style={{ width: 250 }}
              ellipsis={{ 
                tooltip: <>{project?.project_name}  {!!project?.category_name?.length && <Typography.Text style={{ color: "#8C8C8C" }}>&#x2022; {project.category_name}</Typography.Text>}</> }}
            >
              {project?.project_name}  {!!project?.category_name?.length && <Typography.Text style={{ color: "#8C8C8C" }}>&#x2022; {project.category_name}</Typography.Text>} 
            </Typography.Text>
          ),
        })),
      }] : []),
  ], [configData, t, teams, projectsUsedInPredictions])


  function applyFilters(values: string[]) {
    let interimPayload: { [key: string]: string[] } = {}
    for (const value of values) {
      if (value.length === 1) {
        interimPayload[value[0]] = options
          .find((op) => op.value === value[0])
          .children.map((child: any) => child.value)
      }
      if (value.length === 2) {
        if (interimPayload[value[0]]) {
          interimPayload[value[0]].push(value[1])
        } else {
          interimPayload[value[0]] = [value[1]]
        }
      }
    }
    const { status, versions, user_ids, model_type, project_ids } = interimPayload

    const payload = {
      pageNum: 1,
      user_ids: user_ids || [],
      model_type: model_type || [],
      status: status || [],
      versions: versions || [],
      title: serachField || "",
      project_ids: project_ids || []
    }
    setForwardCurrent(1)
    setForwardPageSize(10)
    setForwardFilters(payload)
    dispatch(forwardPredListRequest(payload))
    setIsFilterOpen(false)
  }

  function pageChange(pageNum: number, pageSize: number) {
    const payload = { ...forwardFilters, pageNum, pageSize }
    setForwardCurrent(pageNum)
    setForwardPageSize(pageSize)
    dispatch(forwardPredListRequest(payload))
  }

  const tLabel = useTranslationLabel()

  const forwardResultsColumns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: `${t("aiEngine.objective")} / ${t("common.title")}`,
      dataIndex: "title",
      key: "title",
      sorter: (a: any, b: any) => {
        return a.title.localeCompare(b.title)
      },
      render: (value, record) => {
        return (
          <Space
            size={"small"}
            style={{
              color: "black",
              ...(record?.notRead ? { fontWeight: "bold" } : {}),
            }}
          >
            <StyledBadge dot color={record?.notRead ? antdTheme.colorPrimary : "#fff"} />
            <Typography.Text
              ellipsis={{
                tooltip: value,
              }}
              style={{ width: "250px" }}
            >
              {value}
            </Typography.Text>
          </Space>
        )
      },
      width: 250,
    },
    {
      title: t("common.model"),
      dataIndex: "model",
      key: "model",
      sorter: (a: any, b: any) => {
        return a.model.localeCompare(b.model)
      },
      render: (text: any, record: any) => {
        return (
          <Space size={'small'} style={{
            verticalAlign:'middle',
          }} >
            <Tooltip title={record.model_type}>
                <AiOutlineSisternode
                  color={record?.notRead ? antdTheme.colorPrimary : "black"}
                  style={{
                    display: 'flex',
                    fontSize: antdTheme.fontSizeHeading4,
                    visibility:
                      record.model_type === 'Single Stage'
                        ? 'hidden'
                        : 'visible',
                    ...(!record?.notRead ? { opacity: '0.65' } : {})
                  }}
                />
            </Tooltip>
          <Typography.Text
            ellipsis={{
              tooltip: text,
            }}
            style={
              record?.notRead
                ? { fontWeight: "bold", width: "200px" }
                : { width: "200px" }
            }
          >
            {text}
          </Typography.Text>
          </Space>
        )
      },
      width: 200,
    },
    {
      title: t("aiEngine.status"),
      key: "status",
      dataIndex: "status",
      sorter: (a: any, b: any) => {
        return a.status.localeCompare(b.status)
      },
      render: (tag: string) => {
        return (
          <Tag
            color={predictionStatusColorText[tag]}
            icon={predictionStatusIcon[tag]}
            style={{
              textTransform: "capitalize",
              border: "1px solid",
              fontWeight: "bold",
            }}
          >
            {tLabel(predictionStatus[tag])}
          </Tag>
        )
      },
    },

    {
      title: t("common.project"),
      dataIndex: "project_name",
      key: "project_name",
      sorter: (a: any, b: any) => {
        return a?.["project_name"]?.localeCompare(b?.["project_name"])
      },
      render: (text: any, record: any) => {
        return (
          <Typography.Text
            ellipsis={{
              tooltip: text,
            }}
            style={record?.notRead ? { fontWeight: "bold", width: 100 } : { width: 100 }}
          >
            {text}
          </Typography.Text>
        )
      },
    },
    {
      title: t("aiEngine.requestedOn"),
      dataIndex: "requested_on",
      key: "requested_on",
      sorter: (a: any, b: any) => {
        return dateSorter(a.requested_on, b.requested_on)
      },
      render: (text: any, record: any) => {
        return <Typography.Text style={record?.notRead ? { fontWeight: 'bold' } : {}}>{
          dayjs(text).format('DD MMM YYYY HH:mm')
        }</Typography.Text>
      }
    },
    {
      title: t("common.createdBy"),
      dataIndex: "created_by",
      key: "created_by",
      sorter: (a: any, b: any, c: any) => {
        return stringSorter(getName(a.created_by), getName(b.created_by))
      },
      render: (text: any, record: any) => <AvatarComponent id={text} />
    },
  ]

  const forwardResultsData = useMemo(() => {
    return forwardData?.predictions_list?.map((item: any, idx: number) => {
      const isMultiStage = item?.is_multistage

      return {
        key: item.prediction_id,
        title: item?.objective || "Prediction",
        model: generateVersionName(item?.version, configData),
        model_type: isMultiStage
          ? t("aiEngine.multiStage")
          : t("aiEngine.singleStage"),
        created_by: item.user_id,
        requested_on: item.updated,
        status: item?.status || "Completed",
        notRead: !item?.read?.includes(userId),
        project_name: item?.project_name
      }
    })
  }, [configData, forwardData?.predictions_list, t, userId])

  useEffect(() => {
    const initialFilters = Object.keys(forwardFilters || {})
      .filter((x) => x !== "pageNum" && x !== "title")
      .map((key: string) => {
        return forwardFilters?.[
          key as keyof Omit<FilterForward, "pageNum" | "title">
        ]?.map((value: string) => {
          return [key, value]
        })
      })
      .flat()
    setFilterSelections(initialFilters)
  }, [forwardFilters])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* Top action bar */}
      <Space style={{ justifyContent: "flex-end", width: "100%" }}>
        <Popconfirm
          title={`${t("common.delete")} ${t("aiEngine.selectedRecords")}`}
          icon={<StyledDeleteIcon />}
          okText={t("common.confirm")}
          cancelText={t("common.cancel")}
          onConfirm={() => deleteObjectives()}
        >
          <StyledButton
            loading={forwardPredDeleteStatus === AsyncStates.LOADING}
            icon={<StyledDeleteIcon />}
            disabled={deletePredictionIds.length <= 0}
          >
            {t("aiEngine.deleteRecords")}
          </StyledButton>
        </Popconfirm>
        <Popover
          content={
            <Space direction="vertical">
              <Cascader.Panel
                multiple
                options={options}
                onChange={(value: any, selections: any) => {
                  setFilterSelections(value)
                }}
                value={filterSelections}
                style={{ maxHeight: '300px', overflow: "auto" }}
              />
              <Row justify="end" gutter={16}>
                <Col>
                  <StyledButton
                    type="primary"
                    onClick={() => {
                      applyFilters(filterSelections)
                    }}
                  >
                    {t("aiEngine.button.apply")}
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton onClick={() => clearFilters()} type="dashed">
                    {t("common.clear")}
                  </StyledButton>
                </Col>
              </Row>
            </Space>
          }
          placement="bottomLeft"
          trigger={"click"}
          open={isFilterOpen}
          onOpenChange={(visible) => setIsFilterOpen(visible)}
        >
          <StyledButton
            icon={<FilterOutlined />}
            className={isFilterOpen ? "action-button-active" : ""}
          >
            {t("common.filters")}
          </StyledButton>
        </Popover>
        <Search
          placeholder={t("common.search")}
          value={serachField}
          style={{ width: 300 }}
          onChange={(e) => {
            setSearchField(e.target.value)
          }}
          onSearch={() => {
            applyFilters(filterSelections)
          }}
        />
      </Space>

      {/* Prediction history table */}
      <Table
        columns={forwardResultsColumns}
        dataSource={forwardResultsData}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch(forwardPredResultClear())
              push(
                `/ai-engine/history/properties_prediction?predId=${record?.key}`,
              )
            },
            style: { cursor: "pointer" },
          }
        }}
        showSorterTooltip={false}
        pagination={{
          current: forwardCurrent,
          pageSize: forwardPageSize,
          showSizeChanger: true,
          responsive: true,
          position: ["bottomRight"],
          total: forwardTotal,
          onChange: pageChange,
        }}
        className="predictions-table"
        loading={
          forwardPredListStatus === AsyncStates.LOADING ||
          configStatus === AsyncStates.LOADING
        }
        rowSelection={{
          onChange: (ids) => {
            setDeletePredictionIds(ids as string[])
          },
          getCheckboxProps: (record) => {
            return {
              disabled: record?.status === "In Progress",
            }
          },
        }}
        style={{ width: "100%", overflow: "auto", background: "white" }}
      />
    </Space>
  )
}
