import { InfoCircleOutlined } from "@ant-design/icons";
import { Tag, Space, Typography, Switch, Tooltip } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import InventoryUtils from "src/modules/InventoryV2/utils";
import { fetchAllMethodsRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { getStageName } from "src/utils/decorator";
import { getDisplayNameElement } from "src/utils/general/getDisplayNameElement";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";

const { Text } = Typography;

const getAndOr = (operation: string) => {
  return operation.toUpperCase()
};

const FilterDisplay = ({
  filters,
  setFilters,
  clearFilters,
  badgeColors,
  setAppliedFiltersIndices,
  setCreateNewFilterModalVisible,
  setCurrentInnerIndex
}: any) => {
  const [t] = useTranslate();
  const [switchStateOuter, setSwitchStateOuter] = useState<boolean>(
    filters.selector === "or",
  );

  useEffect(() => {
    setSwitchStateOuter(filters.selector === "or",)
  }, [filters.selector])

  useEffect(() => {
    setFilters((prevState: any) => {
      const updatedState = JSON.parse(JSON.stringify(prevState));
      const selector = switchStateOuter ? "or" : "and";
      updatedState.selector = selector;
      return updatedState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchStateOuter]);

  return (
    <Space style={{ flexWrap: 'wrap' }}>
      {filters?.data?.map((outer: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <FilterTags
              data={outer.data}
              selector={outer.selector}
              badgeColors={badgeColors}
              setFilters={setFilters}
              outerIndex={index}
              clearFilters={clearFilters}
              setAppliedFiltersIndices={setAppliedFiltersIndices}
              setCurrentInnerIndex={setCurrentInnerIndex}
            />
            {filters.selector && index !== filters.data.length - 1 && (
              <Switch
                style={{ marginRight: 0, marginLeft: 8, outline: "none" }}
                size={"small"}
                checked={switchStateOuter}
                checkedChildren={getAndOr("or")}
                unCheckedChildren={getAndOr("and")}
                onChange={(val: boolean) => {
                  setSwitchStateOuter(val);
                }}
              />
            )}
          </React.Fragment>
        );
      })}
      <StyledButton
        size="small"
        disabled={Object.keys(filters ?? {}).length < 1}
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          setCreateNewFilterModalVisible(true);
        }}
        style={{ borderRadius: 4 }}
      >
        {t("common.saveFilter")}
      </StyledButton>
      <StyledButton
        size="small"
        disabled={Object.keys(filters ?? {}).length < 1}
        type="default"
        onClick={(e) => {
          e.stopPropagation();
          clearFilters();
          setAppliedFiltersIndices((prevState: any) => {
            const updatedState = JSON.parse(JSON.stringify(prevState));
            Object.keys(updatedState).forEach((state) => {
              updatedState[state] = -1;
            });
            setCurrentInnerIndex(0)
            return updatedState;
          });
        }}
        style={{ borderRadius: 4 }}
      >
        {t("common.clearFilter")}
      </StyledButton>
      <Tooltip title={t("formulations.useAdvancedTooltip")}>
        <InfoCircleOutlined />
      </Tooltip>
    </Space>
  );
};

const getOperatorText = (op: string, value: any): string => {
  let result = "";
  switch (op) {
    case "eq":
      result = "=";
      break;
    case "lt":
      result = "<";
      break;
    case "gt":
      result = ">";
      break;
    case "exists":
      result = value ? "exists" : "doesn't exist";
      break;
    case "range":
      result = "between";
      break;
    case "in":
      result = "in";
      break;
    default:
      break;
  }
  return result;
};



const FilterTags = ({
  data,
  selector,
  badgeColors,
  setFilters,
  clearFilters,
  outerIndex,
  setAppliedFiltersIndices,
  setCurrentInnerIndex,
}: any) => {
  return (
    <Space
      style={{
        padding: 4,
        border: "1px solid #4096ff",
        borderRadius: 8,
        gap: 8,
        flexWrap: "wrap",
      }}
    >
      {data?.map((inner: any, index: number) => {
        return (
          <FilterTag
            tagData={inner}
            tagSelector={selector}
            badgeColors={badgeColors}
            outerIndex={outerIndex}
            innerIndex={index}
            setFilters={setFilters}
            clearFilters={clearFilters}
            setAppliedFiltersIndices={setAppliedFiltersIndices}
            setCurrentInnerIndex={setCurrentInnerIndex}
          />
        );
      })}
    </Space>
  );
};

const FilterTag = ({
  tagData,
  tagSelector,
  outerIndex,
  innerIndex,
  badgeColors,
  setFilters,
  clearFilters,
  setAppliedFiltersIndices,
  setCurrentInnerIndex
}: any) => {
  const [t] = useTranslate();
  const { getValue } = useValue()
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  );
  const workOrdersList = useSelector(
    (state: StoreState) => state.workOrders.data,
  );
  const teams = useSelector(
    (state: StoreState) => state.teams.data,
  );
  const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);
  const propertyLabels = useSelector(
    (state: StoreState) => state.displayNames.data?.properties || {}
  );


  const dispatch = useDispatch();
  const allMethodList = useSelector((state: StoreState) => state.repository.allMethods.data)
  const allMethodStatus = useSelector((state: StoreState) => state.repository.allMethods.status)

  
  useEffect(() => {
      if(allMethodStatus === AsyncStates.INITIAL){
          dispatch(fetchAllMethodsRequest())
      }
  }, [allMethodStatus, dispatch])

  const getValueFilter = useCallback(
    (value: any, max: any, operator: any, parameter: any, selectedParameters?: any[]) => {
      if (parameter === "stage" && value.length > 0) {
        return value.map((s: any) => getStageName(undefined, s, t)).join(", ");
      }
      if (parameter === "work_order_id") {
        return value
          .map((w: any) => workOrdersList.find((wo) => wo.work_order_id === w).work_order_name)
          .join(", ");
      }
      if (parameter === "material") {
        return displayNames?.[parameter]?.[value]?.name;
      }
      if (parameter === "user_id") return value.map((userId: string) => teams.find(({ user_id }: any) => user_id === userId).user_name).join(", ")
      if (parameter === "lab_locations" && Array.isArray(value)) {
        return value.map((location) => labsLocationList.find((ele) => ele.lab_location_id === location).name)?.join(", ")
      }
      if (Array.isArray(value)) {
        return value.join(", ");
      } else if (max && operator === "range") return `${getValue(value)}-${getValue(max)}`;
      else if (operator === "exists") return "";
      else return getValue(value);
    },
    [displayNames, labsLocationList, teams, workOrdersList, getValue, t],
  );

  const [switchState, setSwitchState] = useState<boolean>(
    tagSelector === "or",
  );

  useEffect(() => {
    setSwitchState(tagSelector === "or");
  }, [tagSelector]);

  useEffect(() => {
    setFilters((prevState: any) => {
      const updatedState = JSON.parse(JSON.stringify(prevState));
      const selector = switchState ? "or" : "and";
      updatedState.data[outerIndex].data[innerIndex].selector = selector;
      updatedState.data[outerIndex].selector = selector
      return updatedState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchState]);

  const removeFilterTag = useCallback(
    (outerIndex: any, innerIndex: any, parameterId: any) => {
      setFilters((prevState: any) => {
        const updatedState = JSON.parse(JSON.stringify(prevState));
        updatedState.data[outerIndex].data.splice(innerIndex, 1);
        if (updatedState.data[outerIndex].data.length === 0) {
          updatedState.data.splice(outerIndex, 1);
        }
        if (updatedState.data.length === 0) {
          clearFilters();
        }
        return updatedState;
      });
      setAppliedFiltersIndices((prevState: any) => {
        Object.keys(prevState).forEach((pid: string) => {
          if (
            prevState[pid] !== -1 &&
            prevState[pid] > prevState[parameterId] &&
            prevState[pid] < innerIndex
          ) {
            prevState[pid] = prevState[pid] - 1;
          }
        });
        return { ...prevState, [`${parameterId}`]: -1 };
      });
      setCurrentInnerIndex((prev: number) => {
        if (prev > 0) return prev - 1;
        return prev;
      });
    },
    [setFilters, setAppliedFiltersIndices, setCurrentInnerIndex, clearFilters],
  );

  const paramNames: { [key: string]: string } = {
    ingredients: t("common.ingredients"),
    processing_profile: t("formulations.type.processing"),
    processing: t("formulations.type.processing"),
    characterizations: t("common.characterizations"),
    characterization_method: "Methods",
    characterization_method_parameters:
      "Characterization Parameters in Methods",
    properties: t("formulations.type.properties"),
    characterization_method_properties: "characterization_method_properties",
    work_order_id: t("common.workOrders"),
    stage: t("common.stages"),
    material: t("common.material"),
    application: t("common.application"),
    order_type: t("workOrderDetails.orderType"),
    grade: t("common.grade"),
    lab_locations: "Lab Locations"
  };

  const getPropertyLabel = useCallback((propertyId: any) => {
    const displayNamesProperty = propertyLabels?.[propertyId];
    const method = allMethodList?.find(
      (method) => method.method_id === displayNamesProperty?.method_id
    );
    return !!method
      ? InventoryUtils.getNameWithParameters(
          displayNamesProperty?.name,
          method.parameters,
          getValue
        )
      : InventoryUtils.getNameWithoutParameters(displayNamesProperty);
  }, [allMethodList, getValue, propertyLabels]);

  const getFilterName = (displayNames: { [key: string]: any; }, tagData: any, paramNames: { [key: string]: string; }): React.ReactNode  => {
    return tagData.parameter_type === "properties"
              ? Array.isArray(tagData.parameter)
                ? tagData.parameter
                    ?.map((property_id: any) => getPropertyLabel(property_id))
                    .join(", ")
                : getPropertyLabel(tagData.parameter)
              : getDisplayNameElement(
                  displayNames,
                  tagData.parameter_type,
                  tagData.parameter
                )?.name || paramNames[tagData.parameter_type];
  }

  return (
    <React.Fragment key={innerIndex}>
      {tagSelector && innerIndex !== 0 && (
        <Switch
          style={{ marginRight: 8, marginLeft: 0, outline: "none" }}
          size={"small"}
          checked={switchState}
          checkedChildren={getAndOr("or")}
          unCheckedChildren={getAndOr("and")}
          onChange={(val: boolean) => {
            setSwitchState(val);
          }}
        />
      )}
      <Tag
        style={{ marginRight: 0, marginLeft: 0 }}
        bordered={false}
        color={badgeColors[tagData.parameter_type]}
        closable
        onClose={(e) => {
          e.preventDefault();
          removeFilterTag(outerIndex, innerIndex, tagData.parameter_type);
        }}
        key={innerIndex}
      >
        <Text style={{ color: "#fff" }}>
          {tagData.parameter !== "user_id" && (
            <Text
              style={{ maxWidth: 150, color: "#fff" }}
              ellipsis={{
                tooltip: getFilterName(displayNames, tagData, paramNames),
              }}
            >
              {getFilterName(displayNames, tagData, paramNames)}
            </Text>
          )}
          {" "}
          {tagData.parameter === "user_id" ? t("common.createdBy") : getOperatorText(tagData.operator, tagData.val)}{" "}
          <Text style={{ maxWidth: 150, color: "#fff" }} ellipsis={{
            tooltip: getValueFilter(
              tagData.val,
              tagData.max,
              tagData.operator,
              tagData.parameter_type,
              tagData.parameter
            )
          }}>{getValueFilter(
            tagData.val,
            tagData.max,
            tagData.operator,
            tagData.parameter_type,
            tagData.parameter
          )}</Text>
        </Text>
      </Tag>
    </React.Fragment>
  );
};

export default FilterDisplay;

