import {
  Col,
  Form,
  Row,
  Typography,
  Input,
  DatePicker,
  Space,
  Card,
  Descriptions,
  AutoComplete,
  Select,
  message,
  Tooltip,
  Popconfirm,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { useHighlightOptions } from "src/utils/useHighlightOptions";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";
import { blue } from "@ant-design/colors";
import { StyledButton } from "src/styled_components/StyledButton";
import {
  InfoCircleFilled,
  LinkOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { editWorkOrderRequest } from "src/store/actions/workOrderDetails";
import { ChooseWorkOrderModal } from "./new-word-order";
import { AsyncStates } from "src/constants";
import { CustomFieldsModal } from "../Templates/CustomFieldsModal";
import { TemplatesOptionModal } from "../Templates/TemplatesOptionModal";
import { getExternalLink, mapFields } from "src/utils/decorator";
import { SaveTemplateModal } from "../Templates/SaveTemplateModal";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { suggestedExperimentsRequest } from "src/store/actions/suggestedExp";
import { inverseNotificationClick } from "src/store/actions/inverseModel";
import { useHistory } from "react-router-dom";
import {
  forwardPredIdRequest,
  forwardPredResultCurrentpageRequest,
  forwardPredResultFiltersRequest,
  forwardPredResultRequest,
} from "src/store/actions/formulate";
import { trackEvent } from "src/analytics";
import { setIsEditing } from "src/store/actions/isEditing";
import { labLocationInitialState } from "../Settings/tabs/LabLocation/LabLocation";
import { LabLocationModal } from "../Settings/tabs/LabLocation/LabLocationModal/LabLocationModal";
import { useValue } from "src/utils/useValue";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import isURL from "validator/lib/isURL";
dayjs.extend(isSameOrAfter);

const { Link, Text } = Typography;
const { Option } = Select;

type MoreDetailsProps = {
  selectedDate: any;
  setSelectedDate: any;
  workOrder: any;
};

const MoreDetails = ({
  selectedDate,
  setSelectedDate,
  workOrder,
}: MoreDetailsProps) => {
  const [t] = useTranslate();

  return (
    <Row gutter={32}>
      <Col span={12}>
        <Form.Item
          label={
            <Text className="highlighted">
              {t("workOrderDetails.orderType")}
            </Text>
          }
          labelCol={{ flex: "120px" }}
          labelAlign="left"
          name="work_order_type"
          rules={[
            {
              required: false,
              min: 2,
              whitespace: true,
              type: "string",
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <Input
            bordered={false}
            placeholder={t("workOrderDetails.orderType")}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<Text className="highlighted">{t("common.grade")}</Text>}
          labelCol={{ flex: "120px" }}
          labelAlign="left"
          name="grade"
          rules={[
            {
              required: false,
              min: 2,
              whitespace: true,
              type: "string",
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <Input
            bordered={false}
            placeholder={t("workOrderDetails.selectGrade")}
          ></Input>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={
            <Text className="highlighted">
              {t("workOrderDetails.closeDate")}
            </Text>
          }
          labelCol={{ flex: "120px" }}
          labelAlign="left"
          name="close_date"
        >
          <DatePicker
            bordered={false}
            disabledDate={(dates) => {
              if (selectedDate?.startDate && dates < selectedDate.startDate) {
                return true;
              }
              return false;
            }}
            onChange={(value) => {
              setSelectedDate((prev: any) => ({
                ...prev,
                closeDate: value,
              }));
            }}
          />
        </Form.Item>
        {!!Object.keys(workOrder || {}).length && (
          <Form.Item
            label={<Text className="highlighted">{t("common.createdOn")}</Text>}
            labelCol={{ flex: "120px" }}
            labelAlign="left"
            name="created"
          >
            <DatePicker disabled={true} bordered={false} />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

export const WorkOrderForm = ({
  disabled,
  setStagesModalVisible,
  setWOChanges,
  stages,
  WOChanges,
}: any) => {
  const [t] = useTranslate();
  const { getName, getId } = useMemberName();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const requiredFieldStar = useRequiredFieldStar()

  const { getDecimalSeparator } = useValue()

  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const workOrdersList = useSelector(
    (state: StoreState) => state.workOrders.data
  );
  const { material, application } = useSelector(
    (state: StoreState) => state.displayNames.data
  );
  const createWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.createWOStatus
  );
  const editWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editWOStatus
  );
  const { current, projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  );
  const currentProject = useMemo(
    () => projects.find((project: any) => project.project_id === current),
    [projects, current]
  );
  const labsLocationList = useSelector(
    (state: StoreState) => state.settings.labsLocationList
  );

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const prediction_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.prediction_id
  );

  const [materialStr, setmaterialStr] = useState("");
  const materialOptions = useHighlightOptions({
    data: Object.entries(material || {}).reduce(
      (acc: any, [key, value]: any) => ({ ...acc, [key]: value?.name }),
      {}
    ) as any,
    searchStr: materialStr,
  });

  const [applicationStr, setapplicationStr] = useState("");
  const applicationOptions = useHighlightOptions({
    data: application?.reduce(
      (acc: any, res: any) => ({ ...acc, [res]: res }),
      {}
    ) as any,
    searchStr: applicationStr,
  });

  const [copyCopyWorkOrderModal, setCopyWorkOrderModal] = useState(false);

  const [customFields, setCustomFields] = useState<any[]>([]);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [saveTemplateModalVisible, setSaveTemplateModalVisible] =
    useState(false);
  const [templateOptionsModal, setTemplateOptionsModal] = useState(false);
  const [isCustomFieldsRemoved, setIsCustomFieldsRemoved] = useState(false);

  const changeDetails = (changedValues: any) => {
    dispatch(setIsEditing(true));
    setWOChanges((state: any) => ({ ...state, ...changedValues }));
  };

  const [labLocationModalState, setLabLocationModalState] = useState(
    labLocationInitialState
  );

  const [workOrderToCopyParams, setWorkOrderToCopyParam] = useState<any | null>(
    {
      workOrder: null,
      use_ingredients: true,
      use_processing: true,
      use_characterization: true,
      use_properties: true,
    }
  );

  const [selectedDate, setSelectedDate] = useState({
    startDate: workOrder.start_date ? dayjs(workOrder.start_date) : null,
    closeDate: workOrder.close_date ? dayjs(workOrder.close_date) : null,
  });

  //reset form
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [workOrder, form]);
  useEffect(() => {
    createWOStatus === AsyncStates.SUCCESS &&
      trackEvent(userId, "New WO created", { "WO details": workOrder });
  }, [createWOStatus, workOrder, userId]);

  useEffect(() => {
    if (!!workOrder?.work_order_id) {
      form.setFieldsValue({
        material: material?.[workOrder?.material]?.name || workOrder?.material,
      });
    }
  }, [form, material, workOrder]);

  useEffect(() => {
    if (editWOStatus === AsyncStates.SUCCESS) {
      setWOChanges({});
      dispatch(setIsEditing(false));
    }
  }, [dispatch, editWOStatus, setWOChanges]);

  useEffect(() => {
    if (!!workOrder.work_order_id && !!workOrder?.additional_fields?.length) {
      setCustomFields(
        workOrder.additional_fields.map((res: any) =>
          res.field_type === "date" && (!!res.value ?? !!res.default_value)
            ? { ...res, value: dayjs(res.value ?? res.default_value) }
            : res
        )
      );
      workOrder.additional_fields.forEach((field: any) => {
        if (field.field_type === "date") {
          form.setFieldValue(field.field_name, dayjs(field.value));
        } else {
          form.setFieldValue(field.field_name, field.value);
        }
      });
    }
  }, [dispatch, workOrder, form]);

  const getInitialValues = (field: any) => {
    if (!workOrder?.work_order_id) {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field.default_value);
        } else {
          return null;
        }
      } else {
        return field?.value ?? field?.default_value;
      }
    } else {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field?.value ?? field.default_value);
        } else return null;
      } else {
        return field?.value ?? field?.default_value;
      }
    }
  };

  const submit = useCallback(
    (values: any) => {
      if (
        !!values?.close_date &&
        !!values?.start_date &&
        dayjs(values?.start_date).isSameOrAfter(dayjs(values?.close_date))
      ) {
        return message.error(
          t("common.message.closeDateGreaterEqualStartDate")
        );
      }
      const additional_fields: any = customFields.map((res: any) => res);
      Object.entries(WOChanges || {}).forEach(([key, value]: any) => {
        additional_fields.forEach((field: any) => {
          if (field.field_name === key) {
            field.value =
              field?.field_type === "date" &&
                (!!field?.value ?? !!field?.default_value)
                ? dayjs(value).toISOString()
                : value;
          }
        });
      });

      (isCustomFieldsRemoved || Object.keys(WOChanges).length) &&
        dispatch(
          editWorkOrderRequest({
            work_order_id: workOrder.work_order_id,
            data: {
              ...WOChanges,
              lab_location:
                labsLocationList.find(
                  (lablocation) =>
                    lablocation.lab_location_id === WOChanges?.lab_location
                ) ?? null,
              ...(!!values.work_order_dri?.length && {
                work_order_dri: values.work_order_dri.map((res: any) =>
                  getId(res)
                ),
              }),
              ...(!!additional_fields.length
                ? { additional_fields }
                : { additional_fields: [] }),
            },
            updateDisplayNames: true,
          })
        );
      setIsCustomFieldsRemoved(false);
    },
    [
      customFields,
      WOChanges,
      isCustomFieldsRemoved,
      dispatch,
      workOrder.work_order_id,
      labsLocationList,
      t,
      getId,
    ]
  );

  const labLocationListOptions = useMemo(
    () =>
      !!currentProject?.lab_locations?.length
        ? currentProject?.lab_locations?.map((labLocation: any) => ({
          label: `${labLocation?.name} (${labLocation?.city}, ${labLocation?.country})`,
          value: labLocation.lab_location_id,
          key: labLocation.lab_location_id,
        }))
        : [],
    [currentProject?.lab_locations]
  );

  return (
    <>
      <Form
        size="small"
        form={form}
        disabled={disabled}
        requiredMark={false}
        initialValues={{
          work_order_name: !!workOrder?.work_order_id
            ? workOrder?.work_order_name
            : `WO-${workOrdersList.length + 1} - ${new Date().toLocaleString(
              "default",
              {
                day: "numeric",
                month: "long",
              }
            )}`,
          ...workOrder,
          ...(workOrder?.lab_location?.lab_location_id && {
            lab_location: workOrder?.lab_location?.lab_location_id,
          }),
          created: !!workOrder.created ? dayjs(workOrder.created) : null,
          material: material?.[workOrder?.material]?.name,
          work_order_dri: workOrder.work_order_dri
            ? workOrder.work_order_dri.map((res: any) => getName(res))
            : [],
          start_date: !!workOrder.start_date
            ? dayjs(workOrder.start_date)
            : null,
          close_date: !!workOrder.close_date
            ? dayjs(workOrder.close_date)
            : null,
        }}
        onFinish={submit}
        onValuesChange={changeDetails}
      >
        <StyledCard
          style={{ borderTopLeftRadius: "0px" }}
          bodyStyle={{ padding: "12px 28px" }}
          headStyle={{ padding: 4, paddingInline: 16 }}
          extra={
            (!!Object.keys(WOChanges || {})?.length ||
              isCustomFieldsRemoved) && (
              <StyledButton
                htmlType="submit"
                size="small"
                type="primary"
                loading={editWOStatus === AsyncStates.LOADING}
              >
                {" "}
                {t("workOrderDetails.saveDetails")}
              </StyledButton>
            )
          }
        >
          {!!workOrder?.suggested_experiment_id?.length && (
            <>
              <Card
                type="inner"
                bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
              >
                <Row justify="space-between">
                  <Col>
                    {t("workOrderDetails.basedOnAi")}
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      strong
                      style={{ display: "inline" }}
                    >
                      {workOrder.suggested_experiment_id.join(", ")}
                    </Typography.Paragraph>
                  </Col>
                  {!workOrder.work_order_id && (
                    <Col>
                      <StyledButton
                        size="small"
                        onClick={() => {
                          dispatch(
                            suggestedExperimentsRequest({
                              prediction_id,
                              pageNum: 1,
                              version: workOrder.version,
                            })
                          );
                          dispatch(inverseNotificationClick(true));
                          push(`/ai-engine/history/formulation_prediction?predId=${prediction_id}`);
                        }}
                      >
                        {t("workOrderDetails.viewAll")}
                      </StyledButton>
                    </Col>
                  )}
                </Row>
              </Card>
              <br />
            </>
          )}
          {!!workOrder?.prediction_experiment_ids?.length && (
            <>
              <Card
                type="inner"
                bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
              >
                <Row justify="space-between">
                  <Col>
                    {t("workOrderDetails.basedOnAi")}
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      strong
                      style={{ display: "inline" }}
                    >
                      {workOrder.prediction_experiment_ids.join(", ")}
                    </Typography.Paragraph>
                  </Col>
                  {!workOrder.work_order_id && (
                    <Col>
                      <StyledButton
                        size="small"
                        onClick={() => {
                          dispatch(
                            forwardPredResultRequest({
                              prediction_id,
                              type: "history",
                            })
                          );
                          dispatch(forwardPredIdRequest(prediction_id));
                          dispatch(forwardPredResultCurrentpageRequest(1));
                          dispatch(forwardPredResultFiltersRequest({}));
                          push(`/ai-engine/history/properties_prediction?predId=${prediction_id}`);
                        }}
                      >
                        {t("workOrderDetails.viewAll")}
                      </StyledButton>
                    </Col>
                  )}
                </Row>
              </Card>
              <br />
            </>
          )}
          {!!workOrder?.doe_id?.length && (
            <>
              <Card
                type="inner"
                bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
              >
                <Row justify="space-between">
                  <Col>
                    {`${t("workOrder.basedOnDoe")} - `}
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      strong
                      style={{ display: "inline" }}
                    >
                      {workOrder.doe_id.join(", ")}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </Card>
              <br />
            </>
          )}
          {!!Object.keys(workOrder?.meta || {}).length && (
            <>
              <Card
                type="inner"
                bodyStyle={{ borderLeft: "3px solid" + blue.primary }}
              >
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text strong> {t("woDetails.reopened")}</Text>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item label={t("common.reopenedBy")}>
                      {getName(workOrder?.meta?.user_id)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("common.reopenedOn")}>
                      {new Date(workOrder?.updated).toLocaleDateString()}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("common.reasonForReopening")}>
                      {workOrder?.meta?.comment}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              </Card>
              <br />
            </>
          )}
          <Row gutter={32}>
            <Col span={12} offset={1}></Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                required={true}
                label={<Text className="highlighted">{t("common.title")}</Text>}
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="work_order_name"
                rules={[
                  {
                    required: true,
                    message: t("common.enterValidWorkOrderName"),
                    transform: (value) => value?.trim(),
                  },
                ]}
                tooltip={requiredFieldStar}
              >
                <Input
                  bordered={false}
                  placeholder={t("workOrderDetails.placeholder.title")}
                />
              </Form.Item>
              <Form.Item
                required={false}
                label={
                  <Text className="highlighted">{t("common.description")}</Text>
                }
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="work_order_desc"
              >
                <Input.TextArea
                  bordered={false}
                  placeholder={t("common.description")}
                ></Input.TextArea>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Text className="highlighted">{t("common.material")}</Text>
                }
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="material"
                rules={[
                  {
                    required: true,
                    min: 2,
                    whitespace: true,
                    type: "string",
                    transform: (value) => value?.trim(),
                  },
                ]}
                required
                tooltip={requiredFieldStar}
              >
                <AutoComplete
                  bordered={false}
                  options={materialOptions}
                  onSearch={setmaterialStr}
                  filterOption={true}
                  placeholder={t("common.selectMaterial")}
                />
              </Form.Item>
              <Form.Item
                label={
                  <Text className="highlighted">{t("common.application")}</Text>
                }
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="application"
                rules={[
                  {
                    required: true,
                    min: 2,
                    whitespace: true,
                    type: "string",
                    transform: (value) => value?.trim(),
                  },
                ]}
                required
                tooltip={requiredFieldStar}
              >
                <AutoComplete
                  bordered={false}
                  options={applicationOptions}
                  onSearch={setapplicationStr}
                  value={applicationStr}
                  filterOption={true}
                  placeholder={t("common.selectApplication")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                required={false}
                label={
                  <Text className="highlighted">{t("common.members")}</Text>
                }
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="work_order_dri"
              >
                <Select
                  style={{ maxWidth: 300 }}
                  mode="multiple"
                  showSearch
                  placeholder={t("workOrderDetails.placeholder.selectAPerson")}
                  bordered={false}
                >
                  {currentProject?.members
                    ?.map(({ user_id }: any) => user_id)
                    ?.concat(currentProject?.created_by)
                    ?.filter((res: any) => res !== userId)
                    ?.map((member: any) => (
                      <Option value={getName(member)} key={member}>
                        {getName(member)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required={false}
                label={
                  <Text className="highlighted">
                    {t("workOrderDetails.startDate")}
                  </Text>
                }
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="start_date"
              >
                <DatePicker
                  placeholder={t("common.selectDate")}
                  bordered={false}
                  disabledDate={(dates) => {
                    if (
                      selectedDate.closeDate &&
                      dayjs(selectedDate.closeDate)
                        .startOf("day")
                        .add(1, "day") < dates
                    ) {
                      return true;
                    }
                    return false;
                  }}
                  onChange={(value) => {
                    setSelectedDate((prev: any) => ({
                      ...prev,
                      startDate: value,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <MoreDetails
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            workOrder={workOrder}
          />
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                required={false}
                label={<Text className="highlighted">{t("common.labLocation")}</Text>}
                labelCol={{ flex: "120px" }}
                labelAlign="left"
                name="lab_location"
              >
                <Select
                  placeholder={t("common.selectLabLocation")}
                  allowClear
                  optionFilterProp="label"
                  showSearch
                  style={{ maxWidth: 300 }}
                  bordered={false}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      {/* <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px', float: "right" }}>
                        <StyledButton size='small' type="text" icon={<PlusOutlined />} onClick={() => setLabLocationModalState((prev) => ({
                          ...prev,
                          mode: "create",
                          isModalVisible: true,
                          project_id: current
                        }))}>
                          Add Lab Location
                        </StyledButton>
                      </Space> */}
                    </>
                  )}
                  options={labLocationListOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="is_multiunit" label={<Text className="highlighted">{t("workOrderDetails.multiUnit")}</Text>}>
                <Switch disabled />
              </Form.Item>
            </Col>
          </Row>
          <StyledCard
            bordered={false}
            headStyle={{ padding: "0" }}
            bodyStyle={{ padding: "8px 0" }}
            title={
              <Space>
                <Text className="highlighted">
                  {t("projects.customFields")}
                </Text>
                <Tooltip title={t("project.customFields.message")}>
                  <InfoCircleFilled />
                </Tooltip>
              </Space>
            }
            extra={
              <Space>
                {!workOrder?.work_order_id && (
                  <StyledButton
                    onClick={() => setTemplateOptionsModal(true)}
                    size="middle"
                  >
                    {t("template.create")}
                  </StyledButton>
                )}
                <StyledButton
                  size="middle"
                  type="primary"
                  disabled={!customFields?.length}
                  onClick={() => setSaveTemplateModalVisible(true)}
                >
                  {t("templates.saveNew")}
                </StyledButton>
              </Space>
            }
          >
            <Row>
              <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {customFields.map((field: any) => (
                    <div className="work__order__custom__field">
                      <Popconfirm
                        title={t("common.removeField")}
                        onConfirm={() => {
                          setCustomFields((prevState: any) =>
                            prevState.filter(
                              (res: any) =>
                                res?.field_name !== field?.field_name
                            )
                          );
                          setIsCustomFieldsRemoved(true);
                        }}
                      >
                        <StyledButton
                          disabled={disabled}
                          icon={<MinusCircleOutlined />}
                          type="text"
                        ></StyledButton>
                      </Popconfirm>
                      <Form.Item
                        style={{
                          width: "100%"
                        }}
                        name={field.field_name}
                        label={field.field_name}
                        initialValue={getInitialValues(field)}
                        rules={[
                          {
                            required: field.required,
                            message: "required",
                            transform: (value: any) => value?.trim(),
                          },
                          ...(field.field_type === "link"
                            ? [
                              {
                                message: "Please enter a valid URL",
                                validator: (_: any, value: string) => {
                                  if (!field.required && !value) return Promise.resolve();
                                  if (isURL(value)) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject();
                                  }
                                },
                              },
                            ]
                            : [])
                        ]}
                      >
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                          <Form.Item noStyle name={field.field_name}>
                            {mapFields(field, disabled, getDecimalSeparator())}
                          </Form.Item>
                          {!!field.value && field.field_type === 'link' &&
                            <Link href={getExternalLink(field.value)} target="_blank" rel="noreferrer">
                              <LinkOutlined />
                            </Link>
                          }
                        </div>
                      </Form.Item>
                    </div>
                  ))}
                </Space>
              </Col>
            </Row>
            <Tooltip title="This set of custom fields will be saved as a template for re-use in other Work Orders">
              <StyledButton
                disabled={disabled}
                type="text"
                icon={<PlusOutlined />}
                onClick={() => setCustomModalVisible(true)}
              >
                <Text type="secondary">{t("common.AddCustomField")}</Text>
              </StyledButton>
            </Tooltip>
          </StyledCard>
        </StyledCard>
      </Form>
      <ChooseWorkOrderModal
        workOrder={workOrderToCopyParams}
        setWorkOrder={setWorkOrderToCopyParam}
        setVisible={setCopyWorkOrderModal}
        visible={copyCopyWorkOrderModal}
      />
      <TemplatesOptionModal
        templateOptionsModal={templateOptionsModal}
        setTemplateOptionsModal={setTemplateOptionsModal}
        setCustomFields={setCustomFields}
        type="work_order"
      />
      <SaveTemplateModal
        saveTemplateModalVisible={saveTemplateModalVisible}
        setSaveTemplateModalVisible={setSaveTemplateModalVisible}
        customFields={customFields}
        type="work_order"
      />
      <CustomFieldsModal
        customModalVisible={customModalVisible}
        setCustomModalVisible={setCustomModalVisible}
        customFields={customFields}
        setCustomFields={setCustomFields}
        setWOChanges={setWOChanges}
        type="work_order"
      />

      {labLocationModalState?.isModalVisible && (
        <LabLocationModal
          labLocationModalState={labLocationModalState}
          handleClose={() => {
            setLabLocationModalState(labLocationInitialState);
          }}
        />
      )}
    </>
  );
};
