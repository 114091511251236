import { Space, Drawer, Descriptions, Spin, Collapse, Typography, List, Row, Modal } from "antd";
import { useMemberName } from "src/utils/useMemberName";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";
import { useHistory } from "react-router-dom";
import { TrialDetails } from "../TrialDetails/TrialDetails";
import Plot from "react-plotly.js";
import { AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import {
  fetchExperimentCleanup,
  fetchLinkedExperimentCleanup,
  fetchLinkedExperimentDetailsCleanup,
} from "src/store/actions/experiment";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { useState } from "react";
import { setProject } from "src/store/actions/projects";
// import { fetchSimilarExperimentsRequest } from "src/store/actions/formulationDetails";

const { Text } = Typography;

export function FormulationDetailsDrawer({
  details,
  setDetails,
  filters,
}: any) {
  const { getName } = useMemberName();
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const history = useHistory();
  const plotsData = useSelector(
    (state: StoreState) => state.workOrderDetails.plotsData
  );
  const plotsDataStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.plotsDataStatus
  );
  const attachments = useSelector(
    (state: StoreState) => state.workOrderDetails.fileList
  );
  const fileListStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.fileListStatus
  );
  // const similarExperimentsData = useSelector((state: StoreState) => state.formulationDetails.similarExperimentsData)
  const [imageVisible, setImageVisible] = useState(false);
  const [image, setImage] = useState<any>({});

  return (
    <>
      <Drawer
        width="900px"
        title={details?.formulation_display_id}
        placement="right"
        closable={true}
        onClose={() => {
          setDetails(new Map());
          dispatch(fetchExperimentCleanup());
          dispatch(fetchLinkedExperimentCleanup());
          dispatch(fetchLinkedExperimentDetailsCleanup());
        }}
        open={!!details.experiment_id}
        mask={false}
        styles={{ body: { paddingBottom: 100 } }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {!!details?.work_order && (
            <Descriptions column={1} bordered size="small">
              <Descriptions.Item
                label={<Text strong>{t("common.createdBy")}</Text>}
              >
                {getName(details?.user_id)}
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{t("common.workOrders")}</Text>}
              >
                <StyledButton
                  title={details?.work_order}
                  style={{
                    textOverflow: "ellipsis",
                    padding: 0,
                  }}
                  type="link"
                  onClick={() => {
                    dispatch(resetWorkOrder());
                    dispatch(setProject(details?.project_id));
                    history.push(
                      `/work-orders/details/${details.work_order_id}/`
                    );
                  }}
                >
                  <Text strong style={{ color: blue[6] }}>
                    {details?.work_order}
                  </Text>
                </StyledButton>
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>{t("common.stage")}</Text>}>
                {details?.stage_name}
              </Descriptions.Item>
              {!!details?.lab_location?.length &&
                <Descriptions.Item label={<Text strong>{t("common.labLocation")}</Text>}>
                  {details?.lab_location}
                </Descriptions.Item>
              }
              {!!details.note && (
                <Descriptions.Item
                  label={<Text strong>{t("workOrderDetails.note")}</Text>}
                >
                  {details.note}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            {!!attachments?.length && (
              <Collapse>
                <Collapse.Panel
                  key={"upload"}
                  header={<Text strong>{"Uploads"}</Text>}
                >
                  <List
                    size="small"
                    loading={{
                      spinning: fileListStatus === AsyncStates.LOADING,
                      indicator: <LoadingOutlined />,
                    }}
                    itemLayout="vertical"
                    dataSource={attachments}
                    renderItem={(item: any) => (
                      <List.Item>
                        <Row justify="space-between">
                          <Text>{item?.filename}</Text>
                          <Space>
                            <StyledButton
                              type="link"
                              disabled={
                                !["jpg", "jpeg", "gif", "png"].includes(
                                  item?.s3_file_key?.split(".").pop()
                                )
                              }
                              onClick={() => {
                                setImage(item);
                                setImageVisible(true);
                              }}
                            >
                              {"view"}
                            </StyledButton>
                            <StyledButton type="link" href={item?.download_link}>
                              {"download"}
                            </StyledButton>
                          </Space>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              </Collapse>
            )}
            <TrialDetails
              trialId={details?.formulation_id}
              trialType={"current"}
              from="drawer"
              filters={filters}
            />
            {!!plotsData?.line?.[details?.formulation_display_id] && (
              <Collapse defaultActiveKey={"5"}>
                <Collapse.Panel header={"Time/Temperature (Line graph)"} key="5">
                  <Spin
                    spinning={plotsDataStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}
                  >
                    <Space
                      direction="vertical"
                      style={{ width: "100%", overflowX: "auto" }}
                    >
                      <Plot
                        key={details?.formulation_display_id}
                        layout={{
                          showlegend: true,
                          hovermode: "closest",
                          width: 620,
                          legend: {
                            orientation: "h",
                            xanchor: "center",
                            y: 1.2,
                            x: 0.5,
                          },
                          xaxis: {
                            showspikes: true,
                            spikemode: "toaxis",
                            title: {
                              text: "Time",
                              font: {
                                family: "Courier New, monospace",
                                size: 12,
                                color: "#11111",
                              },
                            },
                          },
                          yaxis: {
                            showspikes: true,
                            spikemode: "toaxis",
                            title: {
                              text: "Temperature / Bath",
                              font: {
                                family: "Courier New, monospace",
                                size: 12,
                                color: "#11111",
                              },
                            },
                          },
                        }}
                        data={[
                          {
                            x:
                              plotsData?.line?.[details?.formulation_display_id]?.[
                              "time"
                              ] || ([] as any),
                            y:
                              plotsData?.line?.[details?.formulation_display_id]?.[
                              "trial_data"
                              ] || ([] as any),
                            type: "scatter",
                            name: "Temperature",
                          },
                          {
                            x:
                              plotsData?.line?.[details?.formulation_display_id]?.[
                              "time"
                              ] || ([] as any),
                            y:
                              plotsData?.line?.[details?.formulation_display_id]?.[
                              "bath"
                              ] || ([] as any),
                            type: "scatter",
                            name: "Bath",
                          },
                        ]}
                      />
                    </Space>
                  </Spin>
                </Collapse.Panel>
              </Collapse>
            )}
            {!!plotsData?.bar?.[details?.formulation_display_id] && (
              <Collapse defaultActiveKey={"6"}>
                <Collapse.Panel header={"Enthalpy/Temperature (Bar graph)"} key="6">
                  <Spin
                    spinning={plotsDataStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}
                  >
                    <Space
                      direction="vertical"
                      style={{ width: "100%", overflowX: "auto" }}
                    >
                      <Plot
                        key={details?.formulation_display_id}
                        layout={{
                          showlegend: true,
                          hovermode: "closest",
                          width: 620,
                          legend: {
                            orientation: "h",
                            xanchor: "center",
                            y: 1.2,
                            x: 0.5,
                          },
                          xaxis: {
                            autorange: 'reversed',
                            title: {
                              text: 'Temperature in °C',
                              font: {
                                family: "Courier New, monospace",
                                size: 18,
                                color: "#11111",
                              },
                            },
                          },
                          yaxis: {
                            title: {
                              text: 'Enthalpy in J/g',
                              font: {
                                family: "Courier New, monospace",
                                size: 18,
                                color: "#11111",
                              },
                            },
                          },
                        }}
                        data={[{
                          x: plotsData?.bar?.[details?.formulation_display_id]?.['trial_data'] || [] as any,
                          y: plotsData?.bar?.[details?.formulation_display_id]?.['enthalpy_freezing'] || [] as any,
                          type: 'bar',
                          name: "Enthalpy-Freezing",
                        },
                        {
                          x: plotsData?.bar?.[details?.formulation_display_id]?.['trial_data'] || [] as any,
                          y: plotsData?.bar?.[details?.formulation_display_id]?.['enthalpy_melting'] || [] as any,
                          type: 'bar',
                          name: 'Enthalpy-Melting',
                        },
                        ]}
                      />
                    </Space>
                  </Spin>
                </Collapse.Panel>
              </Collapse>
            )}
          </Space>
        </Space>
      </Drawer>
      <Modal
        title={image?.filename}
        width={1200}
        visible={imageVisible}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setImageVisible(false);
          setImage({});
        }}
      >
        <img
          style={{ marginTop: 10 }}
          width={"100%"}
          height={"100%"}
          src={image?.url}
          alt={"Preview not available"}
        />
      </Modal>
    </>
  );
}
