import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { ModelConfig } from "src/typings"
import { FooterForm } from "./footer-form"
import { UseCustomForm } from "./use-custom-model-form"
import { useData } from "./useData"
import { PayloadForm } from "./payload-form"
import { Alert, Segmented, Slider, Space, Spin, Tooltip, Typography, message } from "antd";
import useTranslate, { LanguageUnion } from "src/utils/useTranslate"
import {
  formulateRequest,
  modelsConfigRequest,
} from "src/store/actions/formulate"
import { CloseOutlined, LoadingOutlined, LockFilled } from "@ant-design/icons"
import { FormulateState } from "src/store/reducers/formulate"
import { AsyncStates } from "src/constants"
import { CustomPrompt } from "src/utils/CustomPrompts"
import { useQuery } from "src/utils/useQuery"
import { DroppedPropertyWarning } from "../../common/DroppedPropertyWarning"
import { messages } from "src/utils/hooks"
import { useValue } from "src/utils/useValue"
import { StyledButton } from "src/styled_components/StyledButton"

type P = {
  data:
  | FormulateState["mlProjectSpecificData"]
  | FormulateState["mlProjectSpecificDataZeon"]
  status: AsyncStates
  tab: string
}

export const Form = ({ data, status, tab }: P) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { convertValue } = useValue()
  const [objective, setObjective] = useState("")
  const configData = useSelector(
    (state: StoreState) => state.formulate.configData,
  ) as ModelConfig[]

  const configStatus = useSelector(
    (state: StoreState) => state.formulate.configStatus,
  )

  const ln: LanguageUnion = useSelector(
    (state: StoreState) => state.language.current,
  )

  const modelConfigData = useSelector(
    (state: StoreState) => state.formulate.modelConfigData,
  )
  const project_id = useSelector((state: StoreState) => state.projects.current)

  const [unsavedChanges, setUnsavedChanges] = useState(false)
  let query = useQuery()
  let modelVersion = query?.get("version")
  const { current: currentProject } = useSelector(
    (state: StoreState) => state.projects,
  )
  const [selectedStages, setSelectedStages] = useState<[number, number]>([1, 1])
  const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1)

  const stagesOptions = useMemo(() => {
    const options = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null)
    return options.map((res, index) => {
      const stageName = modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1]
      return {
        value: selectedStages[0] + index,
        label: stageName ?? `Stage ${selectedStages[0] + index}`,
        title: stageName ?? `Stage ${selectedStages[0] + index}`,
      }
    })
  }, [selectedStages, modelConfigData])

  const currentStageModelConfigData = useMemo(
    () => modelConfigData?.[currentSelectedStage - 1],
    [currentSelectedStage, modelConfigData],
  )

  const marks = useMemo(() => {
    return modelConfigData[0]?.all_stages?.reduce(
      (acc: any, slide: string, index: number) => {
        const stagePrefix = `Stage ${index + 1}`
        const stageName = modelConfigData[0]?.all_stages?.[index]
        return {
          ...acc,
          [index + 1]: {
            label: (
              <>
                <Tooltip title={`${stagePrefix}: ${stageName}`}>
                  <Typography.Text>{stageName}</Typography.Text>
                </Tooltip>
                {index + 1 < selectedStages[0] ||
                  index + 1 > selectedStages[1] ? (
                  <>
                    {" "}
                    <LockFilled />
                  </>
                ) : null}
              </>
            ),
            style: { width: "max-content" },
          },
        }
      },
      {},
    )
  }, [modelConfigData, selectedStages])

  const ingredientsLables = useSelector(
    (state: StoreState) => state.displayNames.data?.ingredients ?? {},
  )

  const configs = useSelector((state: StoreState) => state.configs.features)

  const companyId = useSelector(
    (state: StoreState) => state.login.loginResponse.company_id,
  )

  const [userSelection, setUserSelection] = useState<any>(
    companyId === "COMP140MGVSEQE2023" ? "workOrder" : undefined,
  )
  const [isMultiStageModel, setIsMultiStageModel] = useState(
    configData.find((modelData) => modelData.version === Number(modelVersion))
      ?.is_multistage ?? false,
  )

  const {
    addRow,
    addTrials,
    deleteTrial,
    ingredientsDataList,
    ingredientsInputs,
    ingredientsCategories,
    ingredientsParameterList,
    processingDataList,
    processingInputs,
    processingParameterList,
    removeRow,
    setIngredientsDataList,
    setIngredientsInputs,
    setIngredientsCategories,
    setIngredientsParameterList,
    setProcessingDataList,
    setProcessingInputs,
    setProcessingParameterList,
    selectedCharacterization,
    setSelectedCharacterization,
    trialDisplayNameList,
    setTrialDisplayNameList,
  } = useData()

  const clearState = () => {
    setIngredientsParameterList({ 1: [""] })
    setIngredientsDataList({ 1: [{ "": "" }] })
    setProcessingParameterList({ 1: [""] })
    setProcessingDataList({ 1: [{ "": "" }] })
    setTrialDisplayNameList({
      1: [
        {
          label: !!modelConfigData[0]?.all_stages?.[0]?.length && !!modelConfigData[0]?.is_multistage ? `${modelConfigData[0]?.all_stages?.[0]}: Trial 1` : `Stage 1: Trial 1`,
          value: "FR_Trial_1",
        },
      ],
    })
    setObjective("")
    setUnsavedChanges(false)
    setSelectedCharacterization((prev: any) => {
      return {
        ...prev,
        forward: {},
      }
    })
  }

  useEffect(() => {
    clearState()
    setCurrentSelectedStage(1)
    setSelectedStages([1, 1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelVersion, currentProject])


  useEffect(() => {
    if (!!modelConfigData[0]?.is_multistage) { // For updating Trial Name in Multistage View for the first item
      setTrialDisplayNameList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        if (newState?.[1]?.length === 1) {
          newState[1][0].label = `${modelConfigData[0]?.all_stages?.[0]}: Trial 1`
        }
        return newState
      })
    }
  }, [modelConfigData, setTrialDisplayNameList, modelVersion])


  useEffect(() => {
    if (Boolean(modelVersion)) {
      setUserSelection("version")
      const stage = currentSelectedStage - 1
      if (modelConfigData?.[stage]?.version === Number(modelVersion)) {
        const versionData = modelConfigData[stage]
        if (versionData?.input_type?.includes("ingredients")) {
          const inputIngredients = Object.entries(
            versionData?.inputs_range || {},
          )
            .filter(
              ([key, value]: any) =>
                versionData?.inputs_ingredients?.includes(key) ||
                versionData?.inputs_ingredients_others?.includes(key),
            )
            .reduce(
              (obj: any, [key, value]: any) => ({ ...obj, [key]: value }),
              {},
            )
          const trialAsIngData =
            isMultiStageModel && !!trialDisplayNameList?.[stage]?.length
              ? trialDisplayNameList?.[stage].reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.value]: {},
                }
              }, {})
              : {}
          setIngredientsInputs((prev: any) => ({
            ...prev,
            [currentSelectedStage]: { ...inputIngredients, ...trialAsIngData },
          }))
          setIngredientsCategories((prev: any) => ({
            ...prev,
            [currentSelectedStage]: versionData?.meta_ings_per_category ?? {},
          }))
        } else {
          setIngredientsInputs((prev: any) => ({
            ...prev,
            [currentSelectedStage]: {},
          }))
        }
        if (versionData?.input_type?.includes("processing")) {
          setProcessingInputs((prev: any) => ({
            ...prev,
            [currentSelectedStage]: Object.entries(
              versionData?.inputs_range || {},
            )
              .filter(([key, value]: any) =>
                versionData?.inputs_processing?.includes(key),
              )
              .reduce(
                (obj: any, [key, value]: any) => ({ ...obj, [key]: value }),
                {},
              ),
          }))
        } else {
          setProcessingInputs((prev: any) => ({
            ...prev,
            [currentSelectedStage]: {},
          }))
        }
      }
    } else if (userSelection === "workOrder") {
      const stage = currentSelectedStage - 1
      const versionData = modelConfigData[stage]
      if (versionData?.input_type?.includes("ingredients")) {
        const inputIngredients = Object.entries(versionData?.inputs_range || {})
          .filter(
            ([key, value]: any) =>
              versionData?.inputs_ingredients?.includes(key) ||
              versionData?.inputs_ingredients_others?.includes(key),
          )
          .reduce(
            (obj: any, [key, value]: any) => ({ ...obj, [key]: value }),
            {},
          )
        setIngredientsInputs((prev: any) => ({
          ...prev,
          [currentSelectedStage]: inputIngredients,
        }))
        setIngredientsCategories((prev: any) => ({
          ...prev,
          [currentSelectedStage]: versionData?.meta_ings_per_category ?? {},
        }))
      } else {
        setIngredientsInputs((prev: any) => ({
          ...prev,
          [currentSelectedStage]: {},
        }))
      }
      if (versionData?.input_type?.includes("processing")) {
        setProcessingInputs((prev: any) => ({
          ...prev,
          [currentSelectedStage]: Object.entries(
            versionData?.inputs_range || {},
          )
            .filter(([key, value]: any) =>
              versionData?.inputs_processing?.includes(key),
            )
            .reduce(
              (obj: any, [key, value]: any) => ({ ...obj, [key]: value }),
              {},
            ),
        }))
      } else {
        setProcessingInputs((prev: any) => ({
          ...prev,
          [currentSelectedStage]: {},
        }))
      }
    } else {
      setIngredientsInputs((prev: any) => ({
        ...prev,
        [currentSelectedStage]: { ...(data?.ingredients || {}) },
      }))

      setProcessingInputs((prev: any) => ({
        ...prev,
        [currentSelectedStage]: { ...(data?.processing || {}) },
      }))
    }
  }, [
    data,
    status,
    modelVersion,
    configData,
    setIngredientsInputs,
    setProcessingInputs,
    modelConfigData,
    setIngredientsCategories,
    userSelection,
    currentSelectedStage,
    isMultiStageModel,
    trialDisplayNameList,
  ])

  useEffect(() => {
    setProcessingDataList((state: any) => {
      const currentState = state?.[currentSelectedStage]
      if (currentState) {
        const data = currentState.map((id: any, idx: number) => {
          return currentState[idx]
            ? processingParameterList[currentSelectedStage]?.reduce(
              (acc, parameter) => ({
                ...acc,
                [parameter]: currentState?.[idx]?.[parameter] ?? "",
              }),
              {},
            )
            : processingParameterList[currentSelectedStage].reduce(
              (acc, parameter) => ({ ...acc, [parameter]: "" }),
              {},
            )
        })
        return {
          ...state,
          [currentSelectedStage]: data,
        }
      } else {
        return {
          ...state,
          [currentSelectedStage]: processingParameterList[
            currentSelectedStage
          ].reduce((acc, parameter) => ({ ...acc, [parameter]: null }), {}),
        }
      }
    })
  }, [processingParameterList, currentSelectedStage, setProcessingDataList])

  const groupedIngredientOptions = useMemo(() => {
    const stage = currentSelectedStage - 1
    const versionData = modelConfigData?.[stage]
    const categorized = Object.entries(
      ingredientsCategories?.[currentSelectedStage] || {},
    ).map(([groupLabel, groupValues]: any) => ({
      label: groupLabel,
      options: groupValues.map((value: any) => {
        return {
          value,
          label: value,
          category: versionData?.categories?.ingredients?.[value]?.join(", ") ?? ingredientsLables?.[value]?.category?.trim(),
        }
      }),
    }))
    const otherOptions = Object.keys(
      ingredientsInputs[currentSelectedStage] || {},
    )
      ?.filter(
        (value) =>
          !Object.values(ingredientsCategories[currentSelectedStage] || {})
            .flat()
            .includes(value),
      )
      .map((value) => ({
        value,
        label: value,
        category: versionData?.categories?.ingredients?.[value]?.join(", ") ?? ingredientsLables?.[value]?.category?.trim() ?? value,
      }))
    if (otherOptions.length > 0) {
      const uncategorized = {
        label: "OTHER",
        options: otherOptions,
      }
      return [...categorized, uncategorized]
    }
    return categorized
  }, [
    ingredientsCategories,
    ingredientsInputs,
    ingredientsLables,
    currentSelectedStage,
    modelConfigData
  ])

  const hasCategories = useMemo(
    () =>
      Object.keys(ingredientsCategories?.[currentSelectedStage] || {})
        .length !== 0,
    [ingredientsCategories, currentSelectedStage],
  )

  // const isAllIngredientsSelected = hasCategories
  //   ? ingredientsParameterList.length >=
  //   Object.keys(ingredientsCategories).length
  //   : Object.keys(ingredientsInputs).length === ingredientsParameterList.length;

  const validateCatergories = (initialTrials: any) => {
    let invalidFlag = false
    initialTrials.forEach((res: any, index: number) => {
      const categoryObj = Object.entries(
        ingredientsCategories?.[currentSelectedStage] || {},
      ).reduce(
        (acc: any, [key, value]: any) => ({
          ...acc,
          [key]: Object.entries(res?.ingredients || {}).filter(
            ([ingKey, ingVal]: any) => value.includes(ingKey) && !!ingVal,
          ).length,
        }),
        {},
      )
      const emptyValues = Object.values(res?.ingredients || {}).every((value) => value === "" || value === null || value === undefined)
      if (emptyValues) {
        message.error(
          `Please add value for atleast one ingredient in Trial ${index + 1} `,
          6,
        )
      }
      // const emptycategories = Object.entries(categoryObj || {}).filter(([key, value]: any) => !value).map(([key, value]: any) => key)
      // if (emptycategories.length === Object.keys(ingredientsCategories).length) {
      //   message.error(`Please add atleast one ingredient for category ${emptycategories.join(", ")} in Trial ${index + 1} `, 6)
      //   invlaidCategoriesFlag = true
      // }
      const invalidCatgeories = Object.entries(categoryObj || {})
        .filter(([key, value]: any) => value > 1)
        .map(([key, value]: any) => key)
      if (
        !!invalidCatgeories.length &&
        !!configs.disallow_multiple_ingredients
      ) {
        message.error(
          `Please add only one ingredient for category ${invalidCatgeories.join(
            ", ",
          )} in Trial ${index + 1} `,
          6,
        )
        invalidFlag = true
      }
    })
    return invalidFlag
  }

  const predictProperties = () => {
    const stages = isMultiStageModel
      ? Array.from(
        { length: selectedStages[1] - selectedStages[0] + 1 },
        (_, i) => selectedStages[0] + i,
      )
      : [1]
    for (let i = 0; i < stages.length; i++) {
      const currentStage = stages[i]
      const stageName =
        stages.length > 1
          ? `in ${!!modelConfigData?.[currentStage - 1]?.all_stages?.[
            currentStage - 1
          ]?.length
            ? modelConfigData?.[currentStage - 1]?.all_stages?.[
            currentStage - 1
            ]
            : `Stage ${stages[i]}`
          }`
          : ""
      if (
        !ingredientsDataList?.[currentStage] &&
        !processingDataList?.[currentStage]
      ) {
        return message.error(`Please Input Data ${stageName}`)
      }
      const checkForIngredientData = ingredientsDataList[currentStage].some(
        (res) =>
          Object.values(res).filter(
            (ingredient) =>
              typeof ingredient === "number" ||
              (typeof ingredient === "string" && !!ingredient.length),
          ).length,
      )
      const checkForProcessingData = processingDataList[currentStage].some(
        (res) => Object.values(res || {}).includes(""),
      )

      const filteredIngredientsParameterList = ingredientsParameterList?.[
        currentStage
      ].filter((ingredient) => ingredient)
      const filteredProcessingParameterList = processingParameterList?.[
        currentStage
      ].filter((processing) => processing)

      if (!checkForIngredientData) {
        message.error(`Please Input Ingredients Data ${stageName}`)
        return
      }

      if (
        checkForProcessingData &&
        currentStage === 2 &&
        Boolean(configs?.kurita_configs)
      ) {
        message.error(`Please Add Data to Processing ${stageName}`)
        return
      }

      // if (!isAllIngredientsSelected && hasCategories) {
      //   const categoryObj = Object.entries(ingredientsCategories)
      //     .reduce((acc, [key, value]: any) => ({ ...acc, [key]: value?.find((res: any) => ingredientsParameterList?.includes(res)) }), {})
      //   const messageString = Object.entries(categoryObj).filter(([key, value]: any) => !value).map(([key, value]: any) => key).join(", ")
      //   message.error(`Please select ingredients for ${messageString}`);
      //   return;
      // }

      if (
        filteredIngredientsParameterList.length < 1 &&
        filteredProcessingParameterList.length < 1
      ) {
        message.error(`Add parameters in the datasheet ${stageName}`)
        return
      }

      if (
        !checkForIngredientData &&
        !!filteredIngredientsParameterList.length
      ) {
        message.error(`${t("aiEngine.inputIngredientData")} ${stageName}`)
        return
      }

      if (
        !!filteredProcessingParameterList.length &&
        !processingParameterList?.[currentStage].every((val) => Boolean(val))
      ) {
        message.error(t("aiEngine.selectValuesFromDropdown"))
        return
      }

      if (checkForProcessingData && !!filteredProcessingParameterList.length) {
        message.error(
          `${t("aiEngine.inputProcessingCharacterization")} ${stageName}`,
        )
        return
      }

      if (
        Boolean(configs?.ai_engine_with_methods) &&
        selectedCharacterization?.[
          tab === "properties_prediction" ? "forward" : "inverse"
        ]?.variations?.length === 0
      ) {
        return message.error(
          `${t(
            "common.selectAtleastOneCharacterizationVariation",
          )} ${stageName}`,
        )
      }

      const requiredFields = Object.entries(
        processingParameterList?.[currentStage] ?? {},
      )
        .filter(([_, value]) => Array.isArray(value))
        .flatMap(([key]) => key)

      const requiredFieldsCheck = requiredFields.every((val) =>
        processingParameterList?.[currentSelectedStage].includes(val),
      )

      if (!requiredFieldsCheck) {
        message.error(
          `${requiredFields
            .map((res) => res?.[0]?.toUpperCase() + res?.slice(1))
            .join(", ")} parameter required ${stageName}`,
        )
        return
      }
    }
    let payload: any

    // const sum: any = ingredientsDataList.map((res: any) => Object.values(res || {})
    // 	.filter((res: any) => !isNaN(Number(res)))
    // 	.reduce((a: any, b: any) => Number(a) + Number(b), 0))

    const initialTrials: { [key: number]: any[] } = stages.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: ingredientsDataList[curr].reduce(
            (array, element, index: number) => [
              ...array,
              {
                ingredients: Object.entries(element)
                  .filter(([key, value]: any) =>
                    ingredientsParameterList?.[curr].includes(key),
                  )
                  .reduce(
                    (obj: any, [key, value]: any) => ({
                      ...obj,
                      ...(!key
                        ? {}
                        : { [key]: !value ? 0 : convertValue(value) }),
                    }),
                    {},
                  ),
                // ingredients_normalized: Object.entries(element).reduce(
                // 	(obj: any, [key, value]: any) => ({
                // 		...obj,
                // 		...(!key ? {} : { [key]: !value ? 0 : getValue(value / sum[index] * 100) }),
                // 	}),
                // 	{}
                // ),
              },
            ],
            [],
          ),
        }
      },
      {},
    )

    if (hasCategories) {
      for (let i = 0; i < stages.length; i++) {
        const currentStage = stages[i]
        const invalidFlag = validateCatergories(initialTrials[currentStage])
        if (invalidFlag) {
          return
        }
      }
    }

    const config = configData.find(
      (res: any) => res?.version === Number(modelVersion),
    )
    let resultTrials
    if (isMultiStageModel) {
      resultTrials = stages.reduce((acc: any, stage) => {
        const stageName: any =
          modelConfigData[0]?.all_stages?.[stage - 1] ?? `Stage ${stage}`
        const input_data = processingDataList[stage].map((trial, index) => {
          return {
            ...initialTrials[stage][index],
            processing: Object.keys(processingDataList[stage][index])
              .filter((key) => processingParameterList[stage].includes(key))
              .reduce((result: any, key) => {
                if (key) {
                  result[key] = convertValue(
                    processingDataList[stage][index][key],
                  )
                }
                return result
              }, {}),
            trial_name: trialDisplayNameList?.[stage]?.[index]?.value,
          }
        })

        acc[stageName] = {
          input_data,
          modelNames: config?.model_names?.[stageName],
          base_unit: config?.base_unit?.[stageName],
        }
        return acc
      }, {})
    } else {
      resultTrials = processingDataList[currentSelectedStage].reduce(
        (array, element, index) => [
          ...array,
          {
            ...initialTrials[currentSelectedStage][index],
            processing: Object.entries(element)
              .filter(([key, value]: any) =>
                processingParameterList?.[currentSelectedStage].includes(key),
              )
              .reduce(
                (obj: any, [key, value]: any) => ({
                  ...obj,
                  ...(!key ? {} : { [key]: !value ? 0 : convertValue(value) }),
                }),
                {},
              ),
          },
        ],
        [],
      )
    }

    if (Boolean(modelVersion) && isMultiStageModel) {
      const updatedStages = stages.length === 1 ? [stages[0], stages[0]] : stages
      const startStage = (updatedStages[0])
      const endStage = updatedStages?.[updatedStages.length - 1]
      payload = {
        isMultiStage: isMultiStageModel,
        objective,
        version: modelVersion,
        start_stage: modelConfigData?.[0]?.all_stages?.[startStage - 1] ?? `Stage ${startStage}`,
        end_stage: modelConfigData?.[0]?.all_stages?.[endStage - 1] ?? `Stage ${endStage}`,
        data: resultTrials,
      }
    } else if (Boolean(modelVersion)) {
      payload = {
        input_data: resultTrials,
        objective,
        version: modelVersion,
        modelNames: configData.find(
          (res: any) => res?.version === Number(modelVersion),
        )?.model_names,
        base_unit: configData.find(
          (res: any) => res?.version === Number(modelVersion),
        )?.base_unit,
      }
    } else if (userSelection === "workOrder") {
      payload = {
        input_data: resultTrials,
        objective,
        modelNames: configData[0].model_names,
        work_order_ids: modelConfigData[0].work_order_ids,
      }
    } else {
      payload = {
        input_data: resultTrials,
        objective,
        project_id,
      }
    }
    if (Boolean(configs?.ai_engine_with_methods)) {
      payload = {
        ...payload,
        company: "zeon",
        variations:
          selectedCharacterization?.[
            tab === "properties_prediction" ? "forward" : "inverse"
          ]?.variations,
        characterization_method_id:
          selectedCharacterization?.[
            tab === "properties_prediction" ? "forward" : "inverse"
          ]?.characterization_id,
      }
    }
    dispatch(formulateRequest(payload))
    setObjective("")
    setUnsavedChanges(false)
  }

  // const toogleCustomMLCheck = (checked: Boolean) => {
  //   const modelData = configData.find((res) => Boolean(res.is_custom));

  //   if (modelData) {
  //     setIngredientsInputs((prev: any) => ({
  //       ...prev,
  //       [currentSelectedStage]: modelData.inputs_range
  //     }));
  //     setProcessingInputs((prev: any) => ({
  //       ...prev,
  //       [currentSelectedStage]: modelData.inputs_range
  //     }))
  //   }
  // };

  const defaultModelData = useMemo(() => {
    if (!!Object.keys(data ?? {}).length) {
      return Object.keys({
        ...data?.ingredients,
        ...data?.processing,
        ...data?.properties,
      })?.length
    } else {
      return null
    }
  }, [data])

  const [tipVisible, setTipVisible] = useState(true)

  const formatter = (value: number | undefined) => {
    const stageName = value !== undefined ? modelConfigData[0]?.all_stages?.[value - 1] : 0
    if (selectedStages[0] === selectedStages[1]) {
      return `Start and End Stage: ${stageName}`
    }
    if (selectedStages[0] === value) {
      return `Start Stage: ${stageName}`
    }
    if (selectedStages[1] === value) {
      return `End Stage: ${stageName}`
    }
    return value
  }

  useEffect(() => {
    if (!Boolean(modelVersion) && isMultiStageModel) {
      setIsMultiStageModel(false)
    }
  }, [modelVersion, isMultiStageModel])

  useEffect(() => {
    if (Boolean(modelVersion)) {
      setIsMultiStageModel(
        configData.find(
          (modelData) => modelData.version === Number(modelVersion),
        )?.is_multistage ?? false,
      )
      // setSelectedStages([1, 1])
      // setCurrentSelectedStage(1)
      // clearState()
    }
  }, [modelVersion, configData])

  useEffect(() => {
    if (!Boolean(modelVersion)) {
      setCurrentSelectedStage(1)
      setSelectedStages([1, 1])
    }
  }, [modelVersion])

  const getValuesWithRange = (selectedStages = []) => {
    if (!selectedStages?.length) {
      return []
    }

    let range = []
    for (let start = Number(selectedStages[0]); start <= Number(selectedStages[1]); start++) {
      range.push(start)
    }
    return range
  }

  const removeStagesData = useCallback((prev: any, selectedStages: any = []) => {
    const previousStages = Object.keys(prev ?? {})
    const removeStages = previousStages.filter((stage) => !getValuesWithRange(selectedStages).includes(Number(stage)))
    removeStages.forEach((stage: any) => {
      delete prev[stage]
    })
    return prev
  }, [])

  const handleStateUpdatedOnStageChange = useCallback((selectedStages) => {
    const ignoreTrialStartsWith = `FR_Trial_` // For Removing Trials used as Ingredient from Previous Stage
    const initialStage = selectedStages[0]

    for (let startStage = selectedStages[0]; startStage <= selectedStages[1]; startStage++) { // Keeps Only Currently Selected Stage Data and Removes other data
      //Ingredients
      setIngredientsParameterList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        if (!newState[startStage]) {
          return {
            ...newState,
            [startStage]: [""],
          }
        }
        const stagesData = removeStagesData(newState, selectedStages)
        if (!!stagesData?.[initialStage]?.length) {
          stagesData[initialStage] = stagesData?.[initialStage]?.filter((ingredient: string) => !ingredient.startsWith(ignoreTrialStartsWith))
        }
        return stagesData
      })

      setIngredientsDataList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))

        if (!newState[startStage]) {
          return {
            ...newState,
            [startStage]: [{ "": "" }],
          }
        }
        const stagesData = removeStagesData(newState, selectedStages)
        if (!!stagesData?.[initialStage]?.length) {
          stagesData[initialStage].forEach((ingredientData: any) => {
            for (let key in ingredientData) {
              if (key.startsWith(ignoreTrialStartsWith)) {
                delete ingredientData[key];
              }
            }
          });
        }
        return stagesData
      })

      setIngredientsInputs((prev: any) => {
        const newState = JSON.parse(JSON.stringify(prev))

        const stagesData = removeStagesData(newState, selectedStages)
        if (!!stagesData?.[initialStage]?.length) {
          stagesData[initialStage].forEach((ingredientData: any) => {
            for (let key in ingredientData) {
              if (key.startsWith(ignoreTrialStartsWith)) {
                delete ingredientData[key];
              }
            }
          });
        }
        return stagesData
      })

      // PROCESSING
      setProcessingParameterList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))

        if (!newState[startStage]) {
          return {
            ...newState,
            [startStage]: [""],
          }
        }
        return removeStagesData(newState, selectedStages)
      })

      setProcessingDataList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))

        if (!newState[startStage]) {
          return {
            ...newState,
            [startStage]: [{ "": "" }],
          }
        }
        return removeStagesData(newState, selectedStages)

      })

      // Trials Dropdown List
      setTrialDisplayNameList((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))

        if (!newState[startStage]) {
          return {
            ...newState,
            [startStage]: [
              {
                label: `${modelConfigData[0]?.all_stages?.[startStage - 1] ?? `Stage ${startStage}`}: Trial 1`,
                value: "FR_Trial_1",
              },
            ],
          }
        }
        return removeStagesData(newState, selectedStages)
      })

    }
  },
    [
      setIngredientsDataList,
      setIngredientsParameterList,
      setProcessingDataList,
      setProcessingParameterList,
      setTrialDisplayNameList,
      removeStagesData,
      setIngredientsInputs,
      modelConfigData
    ],
  )

  // useEffect(() => {
  //   if (isMultiStageModel && configStatus === AsyncStates.SUCCESS) {
  //     const stage = modelConfigData[0]?.all_stages?.findIndex((element: any) => element === modelConfigData[modelConfigData.length - 1].stage_name)
  //     if (stage !== -1 && isValidNumber(stage)) {
  //       setCurrentSelectedStage(stage + 1)
  //       handleStateUpdatedOnStageChange(stage + 1)
  //     }
  //   }
  // }, [configStatus, isMultiStageModel, setCurrentSelectedStage, handleStateUpdatedOnStageChange, modelConfigData])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "17px",
      }}
    >
      <CustomPrompt
        isEditing={unsavedChanges}
        message={`${t("aiEngine.inputsFrom")} ${t("common.properties")} ${t(
          "aiEngine.predictionNotPredicted",
        )} ${t("common.unsavedChangesLost")}`}
      />
      {companyId !== "COMP140MGVSEQE2023" &&
        !!configData?.filter((res) => Boolean(res.is_custom)).length &&
        !defaultModelData &&
        !Boolean(modelVersion) &&
        tipVisible && (
          <Alert
            description={
              <Space style={{ paddingLeft: "7px" }}>
                <Typography.Text
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    margin: 0,
                  }}
                >
                  {t("aiEngine.tip")}
                  {":"}
                </Typography.Text>
                <Typography.Text style={{ color: "black" }}>
                  {t("aiEngine.noModelFound")}
                </Typography.Text>
              </Space>
            }
            style={{ border: "none", padding: "16px" }}
            type="info"
            closable
            afterClose={() => {
              setTipVisible(false)
            }}
            closeIcon={
              <StyledButton
                size="small"
                style={{
                  color: "black",
                  outline: "none",
                }}
                type="link"
              >
                <CloseOutlined />
              </StyledButton>
            }
          />
        )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {configData.length > 0 ? (
          <UseCustomForm
            data={configData}
            ingredientsDataList={ingredientsDataList[currentSelectedStage]}
            ingredientsParameterList={
              ingredientsParameterList[currentSelectedStage]
            }
            selectionState={[userSelection, setUserSelection]}
            setIsMultiStageModel={setIsMultiStageModel}
            isMultiStageModel={isMultiStageModel}
          />
        ) : (
          <Space>
            <Spin
              indicator={<LoadingOutlined />}
              spinning={configStatus === AsyncStates.LOADING}
            />
            <Typography.Text>
              {configStatus !== AsyncStates.ERROR
                ? `${t("common.loading")} ${t("common.models")}`
                : `${messages[ln].internal_server_error}`}
            </Typography.Text>
          </Space>
        )}
      </div>

      {configStatus === AsyncStates.ERROR ? (
        <div className="desc">
          <Typography.Paragraph>
            <Typography.Text
              strong
              style={{
                color: "#FF0000",
              }}
            >
              {messages[ln].modelConfigFailedToLoad}{' '}<a
                style={{ color: "#007AFF" }}
                href="mailto: contact@polymerize.io"
              >
                {"contact@polymerize.io"}
              </a>{'.'}
            </Typography.Text>
          </Typography.Paragraph>
        </div>
      ) : (
        <>
          {Boolean(modelVersion) &&
            !!currentStageModelConfigData?.missing_properties?.length ? (
            <DroppedPropertyWarning
              missingProperties={
                currentStageModelConfigData?.missing_properties
              }
            />
          ) : null}

          {/* Slider */}

          {Boolean(modelVersion) && isMultiStageModel && (
            <Space size={"large"}>
              <Typography.Text>
                {t("aiEngine.selectStagesForOptimization")}
                {": "}
              </Typography.Text>
              <Slider
                tooltip={{ formatter }}
                style={{
                  margin: "1rem",
                  transition: "all .5s",
                  width: (modelConfigData[0]?.all_stages?.length ?? 1) * 80,
                  maxWidth: "90%",
                }}
                value={selectedStages}
                range
                marks={marks}
                step={null}
                min={1}
                max={modelConfigData[0]?.all_stages?.length ?? 1}
                onChange={(val) => {
                  setSelectedStages((prev) => {
                    if (prev[0] !== val[0]) {
                      if (val[0] > prev[0]) {
                        if (
                          val[0] > currentSelectedStage &&
                          prev[1] > currentSelectedStage
                        ) {
                          if (
                            !Object.keys(modelConfigData[val[0] - 1] || {})
                              .length
                          ) {
                            dispatch(
                              modelsConfigRequest({
                                version: modelVersion,
                                isMultiStage: isMultiStageModel,
                                stage_name:
                                  modelConfigData?.[0]?.all_stages?.[
                                  val[0] - 1
                                  ],
                              }),
                            )
                          }
                          setCurrentSelectedStage(val[0])
                        }
                      }
                    } else {
                      if (val[1] < prev[1]) {
                        if (
                          !(
                            currentSelectedStage >= prev[0] &&
                            currentSelectedStage <= val[1]
                          )
                        ) {
                          if (
                            !Object.keys(modelConfigData[val[1] - 1] || {})
                              .length
                          ) {
                            dispatch(
                              modelsConfigRequest({
                                version: modelVersion,
                                isMultiStage: isMultiStageModel,
                                stage_name:
                                  modelConfigData?.[0]?.all_stages?.[
                                  val[1] - 1
                                  ],
                              }),
                            )
                          }
                          setCurrentSelectedStage(val[1])
                        }
                      }
                    }
                    return [val[0], val[1]]
                  })
                  handleStateUpdatedOnStageChange([val[0], val[1]])
                }}
              />
            </Space>
          )}

          {/* Segment */}

          {Boolean(modelVersion) && isMultiStageModel && (
            <Segmented
              options={stagesOptions}
              value={currentSelectedStage}
              style={{ transition: "all .5s" }}
              size="middle"
              onChange={(stage: any) => {
                if (!Object.keys(modelConfigData[stage - 1] || {}).length) {
                  dispatch(
                    modelsConfigRequest({
                      version: modelVersion,
                      isMultiStage: isMultiStageModel,
                      stage_name: modelConfigData?.[0]?.all_stages?.[stage - 1],
                    }),
                  )
                }
                setCurrentSelectedStage(stage)
              }}
              className="inverse-stage-segmented"
            />
          )}

          <PayloadForm
            tab={tab}
            addRow={addRow}
            addTrials={addTrials}
            deleteTrial={deleteTrial}
            ingredientsDataList={ingredientsDataList[currentSelectedStage]}
            ingredientsInputs={ingredientsInputs[currentSelectedStage]}
            ingredientsCategories={ingredientsCategories[currentSelectedStage]}
            ingredientsParameterList={ingredientsParameterList}
            processingDataList={processingDataList[currentSelectedStage]}
            processingInputs={processingInputs[currentSelectedStage]}
            processingParameterList={processingParameterList}
            removeRow={removeRow}
            setIngredientsDataList={setIngredientsDataList}
            setIngredientsParameterList={setIngredientsParameterList}
            setProcessingDataList={setProcessingDataList}
            setProcessingParameterList={setProcessingParameterList}
            setUnsavedChanges={setUnsavedChanges}
            selectedCharacterization={
              selectedCharacterization[currentSelectedStage]
            }
            setSelectedCharacterization={setSelectedCharacterization}
            versionData={configData.find(
              (res: any) => res?.version === Number(modelVersion),
            )}
            groupedIngredientOptions={groupedIngredientOptions}
            currentSelectedStage={currentSelectedStage}
            trialDisplayNameList={trialDisplayNameList}
            isMultiStageModel={isMultiStageModel}
          />

          <FooterForm
            clearState={clearState}
            objective={objective}
            onPredictClick={predictProperties}
            setObjective={setObjective}
          />
        </>
      )}
    </div>
  )
}
