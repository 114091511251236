import { Avatar, Dropdown, Table, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import {
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  BORDER_STYLE,
  StyledText
} from '../Dashboard'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { antdTheme } from 'src/constants'

const { Text } = Typography

const MembersList = () => {
  const users = useSelector((state: StoreState) => state.teams?.data)
  const { projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  )

  const { push } = useHistory()

  const [t] = useTranslate()

  const userColumns = [
    {
      title: t('common.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      width: '45%',
      render: (text: any, record: any) => {
        const name = record.user_name[0].toUpperCase() + record.user_name.slice(1)
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Avatar style={{ color: 'white', backgroundColor: TEXT_PRIMARY }}>
              {record.user_name[0].toUpperCase()}
            </Avatar>
            <Typography.Text
              style={{
                color: TEXT_PRIMARY,
                margin: 0,
                width: '200px'
              }}
              ellipsis={{ tooltip: name }}
            >
              {name}
            </Typography.Text>
          </div>
        )
      }
    },
    {
      title: t('common.added'),
      dataIndex: 'created',
      render: (text: any) => (
        <Text
          style={{
            color: TEXT_SECONDARY,
            width: '150px',
            margin: 0
          }}
          italic
          ellipsis={{ tooltip: dayjs(text).fromNow() }}
        >
          {dayjs(text).fromNow()}
        </Text>
      ),
      width: '20%',
      align: 'left' as 'left'
    },

    {
      title: t('common.allProjects'),
      dataIndex: 'projects',
      render: (text: any, record: any) => {
        const projectOptions = projects?.filter(
          (project: any) =>
            project?.members?.find(
              (proj: any) => proj?.user_id === record?.user_id
            ) || project?.created_by === record?.user_id
        )

        const items = projectOptions?.map((project: any) => ({
          key: project.project_id,
          label: StyledText(project.name),
          onClick: () => {
            push(`/projects/details/${project.project_id}`)
          }
        }))

        return items.length > 0 ? (
          <Dropdown
            menu={{
              items
            }}
            placement="bottomRight"
            overlayStyle={{
              maxHeight: 200,
              overflow: 'auto',
              boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)'
            }}
            autoAdjustOverflow
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                color: TEXT_PRIMARY,
                cursor: 'pointer'
              }}
            >
              {(projectOptions || [])?.length} {t('common.project')}{' '}
              <DownOutlined
                style={{
                  color: TEXT_PRIMARY,
                  fontSize: antdTheme.fontSize
                }}
              />
            </div>
          </Dropdown>
        ) : '-'
      },
      width: '20%'
    },
    {
      title: t('common.accessLevel'),
      dataIndex: 'role',
      render: (text: string) => {
        if (text.toLowerCase() === "admin") return <>{t("common.admin")}</>
        if (text.toLowerCase() === "member") return <>{t("common.member")}</>
        return <>{text.charAt(0).toUpperCase() + text.slice(1)}</>
      },
      width: '15%',
      align: 'right' as 'right'
    }
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        outline: BORDER_STYLE,
        gap: 12,
        padding: '12px 24px'
      }}
    >
      <HeaderSection />
      <Table
        dataSource={(users || [])
          ?.map((ele: any) => ({ ...ele, key: ele.user_id }))
          ?.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime())}
        columns={userColumns}
        pagination={false}
        style={{ width: '100%', padding: '0px 0px' }}
        scroll={{ y: 200 }}
        className="dashboard-members-table"
      />
      <FooterSection />
    </div>
  )
}

export default MembersList

const HeaderSection = () => {
  const [t] = useTranslate()
  return (
    <Typography.Title
      style={{
        color: `${TEXT_PRIMARY}`,
        lineHeight: '16px',
        margin: 0,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px'
      }}
      level={5}
    >
      {t('dashboard.recentlyAddedMembers')}
    </Typography.Title>
  )
}

const FooterSection = () => {
  const [t] = useTranslate()
  const { push } = useHistory()
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px',
        cursor: 'pointer',
        gap: 8,
        height: '30px'
      }}
      onClick={() => {
        push(`/teams`)
      }}
    >
      <Text
        style={{
          color: TEXT_PRIMARY
        }}
        strong
      >
        {t('dashboard.viewMembers')}
      </Text>
      <RightOutlined
        style={{ color: TEXT_PRIMARY, fontSize: antdTheme.fontSizeSM, fontWeight: 600 }}
      />
    </div>
  )
}
