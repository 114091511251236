import { sendPost, sendPostML } from "src/utils/sendApi"

export function inverseModelStream(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "inverse/predict/ws/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}

export function newInverseModelStream(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "inverse/predict/ws/new/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}

export function multistageInverseModelStream(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "inverse/predict/ws/multistage_inverse/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}

export function zeonInverseModelStream(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "inverse/predict/ws/zeon/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}

export const fetchVersionList = sendPost("v1/data/_suggested_experiment/versions")

export const zeonFetchVersionList = sendPost("v1/data/zeon/_suggested_experiment/versions")

export const getPredictionIdApi = sendPost("v1/data/_get_prediction_list")

export const deletePredictionIdsApi = sendPost("v1/data/_delete_suggested_exp")

export const changeTitleApi = sendPost("v1/data/_predictions/edit_title")

export const getInverseConfig = sendPostML("get_inverse_settings")

export const updateInverseConfig = sendPostML("modify_inverse_settings")

export const addFavouritesInInverseApi = sendPost("v1/data/_suggested_experiment/save_favorite")

export const fetchInverseFavouritesListApi = sendPost("v1/data/_suggested_experiment/retrieve_favorite")
