import { motion } from 'framer-motion'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import useTranslate from 'src/utils/useTranslate'
// import { DragAndDrop } from "./DragAndDrop"
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { useEffect, useState } from 'react';
import {
  qcGetFilesFiltersRequest,
  qcGetFilesRequest,
  qcGetRegisterLinkDataRequest,
  qcSetFileUploadModalVisible,
  qcSyncOnDemandRequest,
} from 'src/store/actions/qualityCheck';
import { Space, Tag, Typography } from 'antd';
import './styles.scss'
import { AsyncStates } from 'src/constants'
import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import FilesTable from './FilesTable'
import { PdfViewer } from './PdfViewer'
import { StyledButton } from 'src/styled_components/StyledButton'
import FileUpload from './FileUpload'
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { doc, onSnapshot } from 'firebase/firestore'
import { Unsubscribe } from 'redux-saga'

export enum QualityCheckStatus {
  UPLOADED = 'uploaded',
  METADATA_EXTRACTED = 'metadata_extracted',
  TABLE_EXTRACTED = 'table_extracted',
  COMPLETED = 'completed'
}

export const QualityCheck = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const [syncTaskStatus, setSyncTaskStatus] = useState<AsyncStates>(AsyncStates.INITIAL)

  const { syncOnDemandStatus, registerLinkData, syncOnDemandResponse } = useSelector(
    (state: StoreState) => state.qualityCheck
  )

  useEffect(() => {
    dispatch(qcGetFilesRequest())
    dispatch(qcGetFilesFiltersRequest())
    dispatch(qcGetRegisterLinkDataRequest())
  }, [dispatch])
  

  useEffect(() => {
    let unsub: Unsubscribe
    const listenToFilePullTaskStatus = async () => {
      const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${syncOnDemandResponse}`)
      unsub = onSnapshot(taskDocRef, (doc: any) => {
        const taskDoc = doc.data()
        if (taskDoc) {
          const taskStatus = taskDoc?.status
          if (taskStatus === "COMPLETED") {
            setSyncTaskStatus(AsyncStates.SUCCESS)
            dispatch(qcGetFilesRequest())
            unsub()
          } else if (taskStatus === "IN_PROGRESS") {
            setSyncTaskStatus(AsyncStates.LOADING)
          } else {
            setSyncTaskStatus(AsyncStates.SUCCESS)
          }
        }
      })
    }
    if(syncOnDemandResponse) listenToFilePullTaskStatus()
  }, [dispatch, syncOnDemandResponse])

  // const [uploadModalOpen, setUploadModalOpen] = useState(false)

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        height: 'calc(-2.5rem + 100vh)',
        overflowY: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <StyledPageHeader
        ghost={false}
        title={
          <Typography.Title level={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {t(`common.qualityCheck`)}
            {(syncOnDemandStatus === AsyncStates.LOADING || syncTaskStatus === AsyncStates.LOADING) && (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {t('qc.syncing')}
              </Tag>
            )}
          </Typography.Title>
        }
        style={{ height: '5%' }}
        extra={
          <Space>
            <StyledButton
              onClick={() => {
                dispatch(qcSetFileUploadModalVisible(true))
              }}
              style={{
                outline: 'none'
              }}
              icon={<UploadOutlined />}
              className='animated__hover__btn'
            >
              <span className="animated__hover__content">
                {t('common.Uploadafile')}
              </span>
            </StyledButton>

            <StyledButton
              onClick={() => {
                dispatch(qcSyncOnDemandRequest())
              }}
              style={{
                outline: 'none'
              }}
              loading={syncOnDemandStatus === AsyncStates.LOADING || syncTaskStatus === AsyncStates.LOADING}
              disabled={registerLinkData.link === ''}
              icon={<SyncOutlined />}
              className='animated__hover__btn'
            >
              <span className="animated__hover__content">
                {t('qc.syncNow')}
              </span>
            </StyledButton>

            {/* <StyledButton
              type="default"
              style={{ outline: 'none' }}
              onClick={() => {
                dispatch(qcSetRegisterLinkModalVisible(true))
              }}
              className="manage-link-btn animated__hover__btn"
              icon={<LinkOutlined />}
            >
              <span className="animated__hover__content">
                {t('qc.manageLinks')}
              </span>
            </StyledButton> */}
          </Space>
        }
      />
      <div
        style={{
          paddingTop: '1%',
          height: '94%'
        }}
      >
        <FilesTable />
      </div>
      {/* <RegisterLink /> */}
      <FileUpload />
      <PdfViewer />
    </motion.div>
  )
}
