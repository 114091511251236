import { Tag, Typography } from 'antd'
import { useMemo } from 'react'
import useTranslate from 'src/utils/useTranslate'

const TestResult = ({ qc_details }: any) => {
  const [t] = useTranslate()
  const isUnparsableFile = useMemo(()=>qc_details?.total === 0 && qc_details?.failed === 0 && qc_details?.passed === 0,[qc_details])

  return (
    <Tag
      bordered={false}
      color={qc_details.total - qc_details.passed > 0 || isUnparsableFile ? 'error' : 'processing'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        textOverflow: 'ellipsis',
        flex: 1,
        maxWidth: 112,
        height: 28
      }}
    >
      <Typography.Text
        style={{
          borderRadius: '4px',
          color:
            qc_details.total - qc_details.passed > 0 || isUnparsableFile ? '#ff4d4f' : '#1677ff'
        }}
        ellipsis={{
          tooltip: true
        }}
        strong
      >
        {qc_details.passed}/{qc_details.total} {`tests ${t('common.Passed')}`}
      </Typography.Text>
    </Tag>
  )
}

export default TestResult
