import { handleActions } from "redux-actions"
import { SmileSummaryActionTypes, SmileSummaryHistoryActionTypes, SmileSummaryHistoryDeleteActionTypes, SmileSummaryResultActionTypes } from "../actions/smileSummary"
import { AsyncStates } from "src/constants";

export interface SmileSummaryState {
    smileSummary: any,
    smileSummaryStatus: AsyncStates,
    smileSummaryResult: any,
    smileSummaryResultStatus: AsyncStates,
    smileSummaryHistory: any,
    smileSummaryHistoryStatus: AsyncStates,
    smileSummaryHistoryDeleteStatus: AsyncStates,
}

const defaultState: SmileSummaryState = {
    smileSummary: undefined,
    smileSummaryStatus: AsyncStates.INITIAL,
    smileSummaryResult: undefined,
    smileSummaryResultStatus: AsyncStates.INITIAL,
    smileSummaryHistory: undefined,
    smileSummaryHistoryStatus: AsyncStates.INITIAL,
    smileSummaryHistoryDeleteStatus: AsyncStates.INITIAL
};

const SmileSummaryReducer = handleActions(
    {
        [SmileSummaryActionTypes.REQUEST]: (state) => ({
            ...state,
            smileSummaryStatus: AsyncStates.LOADING
        }),
        [SmileSummaryActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                smileSummary: action?.payload,
                smileSummaryStatus: AsyncStates.SUCCESS
            }
        },
        [SmileSummaryActionTypes.FAILURE]: (state) => ({
            ...state,
            smileSummaryStatus: AsyncStates.ERROR
        }),
        [SmileSummaryActionTypes.CLEAR]: (state) => ({
            ...state,
            smileSummary: undefined,
            smileSummaryStatus: AsyncStates.INITIAL
        }),


        [SmileSummaryResultActionTypes.REQUEST]: (state) => ({
            ...state,
            smileSummaryResultStatus: AsyncStates.LOADING
        }),
        [SmileSummaryResultActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                smileSummaryResult: action?.payload,
                smileSummaryResultStatus: AsyncStates.SUCCESS
            }
        },
        [SmileSummaryResultActionTypes.FAILURE]: (state) => ({
            ...state,
            smileSummaryResultStatus: AsyncStates.ERROR
        }),
        [SmileSummaryResultActionTypes.CLEAR]: (state) => ({
            ...state,
            smileSummaryResult: undefined,
            smileSummaryResultStatus: AsyncStates.INITIAL
        }),

        [SmileSummaryHistoryActionTypes.REQUEST]: (state) => ({
            ...state,
            smileSummaryHistoryStatus: AsyncStates.LOADING
        }),
        [SmileSummaryHistoryActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                smileSummaryHistory: action?.payload,
                smileSummaryHistoryStatus: AsyncStates.SUCCESS
            }
        },
        [SmileSummaryHistoryActionTypes.FAILURE]: (state) => ({
            ...state,
            smileSummaryHistoryStatus: AsyncStates.ERROR
        }),
        [SmileSummaryHistoryActionTypes.CLEAR]: (state) => ({
            ...state,
            smileSummaryHistory: undefined,
            smileSummaryHistoryStatus: AsyncStates.INITIAL
        }),

        [SmileSummaryHistoryDeleteActionTypes.REQUEST]: (state) => ({
            ...state,
            smileSummaryHistoryDeleteStatus: AsyncStates.LOADING
        }),
        [SmileSummaryHistoryDeleteActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                smileSummaryHistoryDeleteStatus: AsyncStates.SUCCESS
            }
        },
        [SmileSummaryHistoryDeleteActionTypes.FAILURE]: (state) => ({
            ...state,
            smileSummaryHistoryDeleteStatus: AsyncStates.ERROR
        }),
    },
    defaultState
)

export default SmileSummaryReducer
