const InventoryUtils = {
    getPropertyOrParameterValue: (param: any, getLocalValue: any) => {
        const unit = !param.unit || param.unit === "-" ? "" : param.unit;
        const operator = param.operator || "";

        if (param.value_type === "categorical") {
            return `${param.value_str || ""} ${unit}`;
        } else if (
            param.value_type === "numerical" &&
            param.value_subtype === "single"
        ) {
            return `${getLocalValue(param.value) || ""} ${unit}`;
        } else if (param.value_subtype === "range" && param.value && param.value_max) {
            return `${getLocalValue(param.value) || ""} - ${getLocalValue(param.value_max) || ""
                } ${unit}`;
        } else if (param.value_subtype === "operator") {
            return `${operator} ${param.value || ""} ${unit}`;
        } else {
            return "";
        }
    },

    getNameWithParameters: (name: string, parameters: any[], getLocalValue: any) => {
        let tmpName = name;
        if (
            parameters &&
            parameters.length > 0
        ) {
            tmpName += ` (${parameters
                .map((parameter: any) => InventoryUtils.getPropertyOrParameterValue(parameter, getLocalValue))
                .filter((p: any) => p)
                .join(", ")})`;
        }

        return tmpName;
    },

    getNameWithoutParameters: (property: any) => {
        return `${property?.name}`;
    }

};

export default InventoryUtils;
