import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkbox, Row, Select, Space, Tooltip, Typography } from "antd";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { getTranslatedIngredientsAndProcessing } from "../../utils";
import { IngredientsTable } from "./ingredients";
import { ProcessingTable } from "./processing";
import { CharacterizationVariation } from "src/typings/characterization";
import { ZeonCharacterizationSelection } from "src/components/AIEngine/common";
import { antdTheme, commonDefaultPageSize } from "src/constants";
import { getFavoritesRequest } from "src/store/actions/formulate";
import { ModelConfig } from "src/typings";
import IngredientsSelect from "./IngredientsSelect";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
const { Text } = Typography;
const { Option } = Select;

type P = {
  tab: string;
  addTrials: (currentSelectedStage: number, stageName: string) => void;
  addRow: (inputType: string, currentSelectedStage: number) => void;
  deleteTrial: (index: number, currentSelectedStage: number) => void;
  removeRow: (parameterIndex: number, inputType: string, currentSelectedStage: number) => void;
  ingredientsInputs: any;
  ingredientsCategories: any;
  processingInputs: any;
  ingredientsParameterList: { [key: number]: string[] };
  setIngredientsParameterList: Dispatch<SetStateAction<{ [key: number]: string[] }>>;
  ingredientsDataList: any[];
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  setIngredientsDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>;
  setProcessingDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>;
  processingParameterList: { [key: number]: string[] };
  processingDataList: any[];
  setProcessingParameterList: Dispatch<SetStateAction<{ [key: number]: string[] }>>;
  selectedCharacterization: {
    characterization_name: string;
    characterization_id: string;
    variations: CharacterizationVariation[];
  };
  setSelectedCharacterization: any;
  versionData: ModelConfig | undefined;
  groupedIngredientOptions: any;
  currentSelectedStage: number,
  trialDisplayNameList: { [key: number]: any[] },
  isMultiStageModel: boolean
};

export const PayloadForm = ({
  addTrials,
  addRow,
  deleteTrial,
  removeRow,
  ingredientsInputs,
  ingredientsCategories,
  processingInputs,
  ingredientsParameterList,
  setIngredientsParameterList,
  ingredientsDataList,
  setUnsavedChanges,
  setIngredientsDataList,
  setProcessingDataList,
  processingParameterList,
  processingDataList,
  setProcessingParameterList,
  selectedCharacterization,
  setSelectedCharacterization,
  tab,
  versionData,
  groupedIngredientOptions,
  currentSelectedStage,
  trialDisplayNameList,
  isMultiStageModel
}: P) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data ?? {}
  );
  const configs = useSelector((state: StoreState) => state.configs.features);
  const [favoritesCurrentPage, setFavoritesCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getFavoritesRequest({
        page_num: favoritesCurrentPage,
        page_size: commonDefaultPageSize,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const paramterListDropDown = useCallback(
    (index: number, inputType: string) => {
      return (
        <Row
          style={{
            alignItems: "center",
            width: "100%",
            padding: 8,
            justifyContent: "space-between",
            gap: 8,
            flexWrap: "nowrap",
          }}
        >
          <Tooltip title={t("common.remove")}>
            <StyledDeleteIcon
              style={{
                fontSize: antdTheme.fontSizeHeading4,
                verticalAlign: "baseline",
                outline: "none",
              }}
              onClick={() => removeRow(index, inputType, currentSelectedStage)}
            />
          </Tooltip>
          <>
            {inputType === "ingredients" ? (
              <IngredientsSelect
                index={index}
                ingredientsCategories={ingredientsCategories}
                groupedIngredientOptions={groupedIngredientOptions}
                ingredientsDataList={ingredientsDataList}
                ingredientsInputs={ingredientsInputs}
                ingredientsParameterList={ingredientsParameterList[currentSelectedStage]}
                setIngredientsDataList={setIngredientsDataList}
                setIngredientsParameterList={setIngredientsParameterList}
                versionData={versionData}
                setUnsavedChanges={setUnsavedChanges}
                currentSelectedStage={currentSelectedStage}
                trialDisplayNameList={trialDisplayNameList}
              ></IngredientsSelect>
            ) : (
              <Select
                value={
                  displayNames?.processing?.[processingParameterList[currentSelectedStage]?.[index]]
                    ?.name || processingParameterList[currentSelectedStage][index]
                }
                bordered={false}
                filterOption={(input, option) => {
                  if (!option) return false;

                  const value: string = (
                    (option.value ?? "") as string
                  ).toLowerCase();

                  const replacedValue = value
                    .trim()
                    .replaceAll("_", " ")
                    .replaceAll("  ", " ");

                  return replacedValue.includes(input.toLowerCase());
                }}
                showSearch
                placeholder={t("aiEngine.selectInputs")}
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  verticalAlign: "bottom",
                }}
                notFoundContent={
                  !!Object.keys(processingInputs || {}).length ? (
                    <Space style={{ background: "whitesmoke", width: "100%" }}>
                      <Text>No such parameter</Text>
                    </Space>
                  ) : (
                    <Space style={{ background: "whitesmoke", width: "100%" }}>
                      <Text>No Processing's Found</Text>
                    </Space>
                  )
                }
                dropdownRender={(menu) => {
                  return (
                    <>
                      {!!Object.keys(processingInputs || {}).length && (
                        <Checkbox
                          style={{ padding: 10 }}
                          checked={
                            processingParameterList?.[currentSelectedStage].every(
                              (res: any) => !!res
                            ) &&
                            processingParameterList?.[currentSelectedStage].length ===
                            Object.keys(processingInputs || {}).length
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              setProcessingParameterList((prev) => ({
                                ...prev,
                                [currentSelectedStage]: Object.keys(processingInputs || {})
                              }));
                              setProcessingDataList((prev) => ({
                                ...prev,
                                [currentSelectedStage]: Object.keys(processingDataList).map(() =>
                                  Object.entries(processingInputs).reduce(
                                    (acc, [processing, { min, max }]: any) => ({
                                      ...acc,
                                      [processing]: (min === max && !configs?.no_autofill) ? max : "",
                                    }),
                                    {}
                                  ))
                              })
                              );
                            } else {
                              setProcessingParameterList((prev) => ({
                                ...prev,
                                [currentSelectedStage]: [""]
                              }));
                            }
                          }}
                        >{`${t("common.selectAll")} ${getTranslatedIngredientsAndProcessing(inputType, t)
                          ?.charAt(0)
                          ?.toUpperCase() +
                          getTranslatedIngredientsAndProcessing(
                            inputType,
                            t
                          )?.slice(1)
                          }`}</Checkbox>
                      )}
                      {menu}
                    </>
                  );
                }}
                onSelect={(e: any) => {
                  const { min, max } = processingInputs[e];
                  if (min === max && !configs?.no_autofill)
                    setProcessingDataList((prev) => ({
                      ...prev,
                      [currentSelectedStage]: processingDataList.map((ingredientRow) => ({
                        ...ingredientRow,
                        [e]: max,
                      }))
                    })
                    );
                  setProcessingParameterList((prevState: any) => {
                    const processings = { ...prevState }
                    const data = [...processings?.[currentSelectedStage]]
                    data.splice(index, 1, e);
                    return {
                      ...prevState,
                      [currentSelectedStage]: data
                    };
                  });
                  setUnsavedChanges(true);
                }}
              >
                {Object.keys(processingInputs || {})
                  .filter((res: any) => !processingParameterList?.[currentSelectedStage].includes(res))
                  .map((res: any) => (
                    <Option value={res} key={res}>
                      {displayNames?.processing?.[res]?.name ?? res}
                    </Option>
                  ))}
              </Select>
            )}
          </>
        </Row>
      );
    },
    [t, ingredientsCategories, groupedIngredientOptions, ingredientsDataList, ingredientsInputs, ingredientsParameterList, setIngredientsDataList, setIngredientsParameterList, versionData, setUnsavedChanges, displayNames?.processing, processingParameterList, processingInputs, removeRow, setProcessingParameterList, setProcessingDataList, processingDataList, configs?.no_autofill, currentSelectedStage, trialDisplayNameList]
  );

  const ings_procs = useMemo(() => {
    const ings =
      ingredientsDataList?.length === 0
        ? []
        : Object.keys(ingredientsDataList?.[0] || {})?.filter((ingr) => !!ingr);

    const procs =
      processingDataList?.length === 0
        ? []
        : Object.keys(processingDataList?.[0] || {})?.filter((proc) => !!proc);

    return [...procs, ...ings];
  }, [ingredientsDataList, processingDataList]);

  const disableDeletionFor = useMemo(() => {
    const usedTrials = trialDisplayNameList?.[currentSelectedStage]?.map((ele) => ele.value)
    return ingredientsParameterList?.[currentSelectedStage + 1]?.filter((ele: string) => usedTrials.includes(ele))
  }, [ingredientsParameterList, currentSelectedStage, trialDisplayNameList])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        paddingBottom: '32px'
      }}
    >
      <IngredientsTable
        addRow={addRow}
        addTrials={addTrials}
        deleteTrial={deleteTrial}
        ingredientsDataList={ingredientsDataList || []}
        ingredientsInputs={ingredientsInputs}
        ingredientsCategories={ingredientsCategories}
        ingredientsParameterList={ingredientsParameterList[currentSelectedStage] || []}
        paramterListDropDown={paramterListDropDown}
        setIngredientsDataList={setIngredientsDataList}
        setIngredientsParameterList={setIngredientsParameterList}
        setProcessingParameterList={setProcessingParameterList}
        processingDataList={processingDataList || []}
        setProcessingDataList={setProcessingDataList}
        setUnsavedChanges={setUnsavedChanges}
        processingParameterList={processingParameterList[currentSelectedStage] || []}
        setFavoritesCurrentPage={setFavoritesCurrentPage}
        favoritesCurrentPage={favoritesCurrentPage}
        processingInputs={processingInputs}
        versionData={versionData}
        groupedIngredientOptions={groupedIngredientOptions}
        currentSelectedStage={currentSelectedStage}
        trialDisplayNameList={trialDisplayNameList}
        disableDeletionFor={disableDeletionFor}
        isMultiStageModel={isMultiStageModel}
      />

      {!Boolean(configs?.ai_engine_with_methods) && (
        <ProcessingTable
          addRow={addRow}
          deleteTrial={deleteTrial}
          paramterListDropDown={paramterListDropDown}
          processingDataList={processingDataList || []}
          processingInputs={processingInputs}
          processingParameterList={processingParameterList[currentSelectedStage] || []}
          setProcessingDataList={setProcessingDataList}
          setProcessingParameterList={setProcessingParameterList}
          setUnsavedChanges={setUnsavedChanges}
          currentSelectedStage={currentSelectedStage}
          trialDisplayNameList={trialDisplayNameList}
          disableDeletionFor={disableDeletionFor}
          isMultiStageModel={isMultiStageModel}
        />
      )}
      {Boolean(configs?.ai_engine_with_methods) && (
        <ZeonCharacterizationSelection
          ings_procs={ings_procs}
          properties={[]}
          tab={tab}
          selectedCharacterization={selectedCharacterization}
          setSelectedCharacterization={setSelectedCharacterization}
          currentSelectedStage={currentSelectedStage}
        />
      )}
    </div>
  );
};
