import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row, Table, Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { DisplayNames } from "src/typings";
import { useValue } from "src/utils/useValue";

const SmileNumericalData = () => {
    const [t] = useTranslate();
    const smileSummaryResult = useSelector((state: StoreState) => state.smileSummary.smileSummaryResult)
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames;
    const { getValue: getLocalValue } = useValue();

    const smileDataSummary = useMemo(() => {
        return smileSummaryResult?.results?.data_summary;
    }, [smileSummaryResult])

    const formatStringToLocaleValue = useCallback((text: string) => {
        // NOTE -  NOT A GENERIC FUNCTION, DON'T USE ANYWHERE ELSE
        const hasNumber = /\d/.test(text);
        if (!hasNumber) return text;

        if (text && String(text).includes("-")) return text.split("-").map(t => t.trim()).map(t => getLocalValue(t, 2)).join(" - ");
        if (text && String(text).includes("%")) return getLocalValue(text.split("%")[0], 2) + "%";

        return getLocalValue(text);
    }, [getLocalValue])

    const getColumns = useCallback((data: { [key: string]: any }, displayNames: { [key: string]: { [key: string]: any } }) => {
        if (Object.keys(data || {}).length === 0) return [];

        const getTitle = (text: string) => {
            const titleText = displayNames[text]?.name || text
            return <div className="header">{String(titleText || "").length > 25 ? <Tooltip title={titleText}>{String(titleText || "").substring(0, 25)}...</Tooltip> : titleText}</div>
        }
        const renderLeadCell = (text: string) => <div className="content">{text}</div>
        const renderCell = (text: string | null) => {
            if (text === "_NA_" || !text) return "-";
            return formatStringToLocaleValue(text);
        }

        const cols: any = [
            {
                title: t("smiles.stats"),
                dataIndex: 'stats',
                key: 'stats',
                fixed: 'left',
                width: 120,
                render: (text: string) => renderLeadCell(text)
            }
        ];
        Object.keys(data).forEach(i => cols.push({ title: getTitle(i), dataIndex: i, key: i, width: 120, render: (text: string) => renderCell(text) }));

        return cols;
    }, [formatStringToLocaleValue, t]);

    const getTranslatedStat = useCallback((key: string) => {
        if (!key) return "";

        if (key === "% Usage") return t("smiles.percentUsage");
        if (key === "Range") return t("smiles.range");
        if (key === "Average") return t("smiles.average");
        if (key === "Std. Dev") return t("smiles.stdDev");
        if (key === "Units") return t("smiles.units");

        return key;
    }, [t])

    const getTableData = useCallback((sourceData: { [key: string]: any }) => {
        if (Object.keys(sourceData || {}).length === 0) return [];

        // Get 1st col
        const statsCol = Object.keys(sourceData[Object.keys(sourceData).at(0) as string]).map((key: string) => ({ key: key, label: getTranslatedStat(key) }));
        const data: { [key: string]: any }[] = [];

        statsCol.forEach((stat, idx) => {
            const rowData = Object.keys(sourceData).map(key => ({ key: key, value: sourceData[key][stat.key] }));
            const rowObject = rowData.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

            data.push({ key: idx, stats: stat.label, ...rowObject })
        });

        return data;
    }, [getTranslatedStat]);

    const ingredientsColumns = useMemo(() => smileDataSummary && getColumns(smileDataSummary.ingredients, names.ingredients), [smileDataSummary, names, getColumns]);
    const ingredientsDataSource = useMemo(() => smileDataSummary && getTableData(smileDataSummary.ingredients), [smileDataSummary, getTableData]);

    const propertiesColumns = useMemo(() => smileDataSummary && getColumns(smileDataSummary.properties, names.properties), [smileDataSummary, names, getColumns]);
    const propertiesDataSource = useMemo(() => smileDataSummary && getTableData(smileDataSummary.properties), [smileDataSummary, getTableData]);

    return (
        <div className="numerical-container" id="num-data-summary">
            <Row justify="start" gutter={[8, 0]}>
                <Col className="heading">{t("dataSummary.numericalDataSummary")}</Col>
                <Col>
                    <Tooltip title={t("aiEngine.tab.dataSummary.numericaldatasummary")}><InfoCircleOutlined /></Tooltip>
                </Col>
            </Row>
            {ingredientsDataSource && Array.isArray(ingredientsDataSource) && ingredientsDataSource.length > 0 && (
                <>
                    <div className="section-title">{t("common.ingredients")}</div>
                    <div className="table-container">
                        <Table bordered={false} pagination={false} dataSource={ingredientsDataSource} columns={ingredientsColumns} scroll={{ x: "max-content" }} />
                    </div>
                </>
            )}
            {propertiesDataSource && Array.isArray(propertiesDataSource) && propertiesDataSource.length > 0 && (
                <>
                    <div className="section-title">{t("common.properties")}</div>
                    <div className="table-container">
                        <Table bordered={false} pagination={false} dataSource={propertiesDataSource} columns={propertiesColumns} scroll={{ x: "max-content" }} />
                    </div>
                </>
            )}
        </div>
    )
}

export default SmileNumericalData;