import { useState, useEffect, useMemo, useCallback } from "react"
import {
	Space,
	Typography,
	Checkbox,
	Row,
	Form,
	message,
	Modal,
	Spin,
	Pagination,
	Tooltip,
} from "antd"
import { useSelector, useDispatch } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	DotChartOutlined,
	FileExcelOutlined,
	LoadingOutlined,
} from "@ant-design/icons"
import {
	AsyncStates,
	EXP_PAGE_SIZE,
	permissions,
	projectStatus,
} from "src/constants"
import { markForMlRequest } from "../../store/actions/formulationDetails"
import {
	dataExportRequest,
	editWorkOrderRequest,
	fetchFileListRequest,
} from "src/store/actions/workOrderDetails"
import useTranslate from "src/utils/useTranslate"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import { getStageName } from "src/utils/decorator"
import { usePermission } from "src/utils/usePermissions"
import { fetchExperimentRequest, fetchLinkedExperimentRequest } from "src/store/actions/experiment"
import { WorkOrderTableWrapper } from "./WorkOrderTableWrapper"
import { IngredientModal } from "src/modules/InventoryV2/components/IngredientModal"
import { displayNamesRequest } from "src/store/actions/displayNames"
import { fetchAllMethodsRequest } from "src/store/actions/repository"

const { Text } = Typography

export const WoReportPreview = ({ currentExpIdIndex, from }: any) => {
	const dispatch = useDispatch()
	const [t] = useTranslate()
	const editWOStatus = useSelector((state: StoreState) => state.workOrderDetails.editWOStatus)
	const formulationList = useSelector((state: StoreState) => state.workOrderDetails.experiment)
	// const configs = useSelector((state: StoreState) => state.configs.features)
	const { dataExportStatus } = useSelector((state: StoreState) => state.workOrderDetails)
	const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)
	const { markForMlStatus } = useSelector((state: StoreState) => state.formulationDetails)
	const [useForTraining, setUseForTraining] = useState(false)
	const [formulationsCheck, setFormulationsCheck] = useState<string[]>([])
	const [modalVisible, setModalVisible] = useState(false)
	const [isCreateIngredinentModalVisible, setIsCreateIngredinentModalVisible] = useState(false)
	const userAccess = usePermission()
	const disabled = useMemo(
		() =>
			userAccess.permission === permissions.viewer ||
			userAccess?.status !== projectStatus.in_progress,
		[userAccess]
	)
	const displayNames = useSelector((state: StoreState) => state.displayNames.data)
	const createIngredientFromTrialStatus = useSelector((state: StoreState) => state.inventoryV2.createIngredientFromTrial.createIngredientFromTrialStatus);
	const linkedExperimentList = useSelector((state: StoreState) => state.workOrderDetails.linkedExperiment);
	const [currentExpId, setCurrentExpId] = useState("")
	// const isProcessingExists = !!formulationList?.flatMap(({ processing }: any) => processing)?.map((processingData: any) => Object.keys(processingData?.processing || {}))?.flat()?.length
	const [form] = Form.useForm()

	const [visible, setVisible] = useState(false)
	const currentStage = useMemo(() => {
		const index = workOrder?.experiment_id.findIndex((res: any) => res === currentExpId)
		return workOrder?.stages?.[index]
	}, [workOrder, currentExpId])
	const total = useSelector((state: StoreState) => state.workOrderDetails.total)
	const [current, setCurrent] = useState<number>(1)
	const [pageNum, setPageNum] = useState(1)

	useEffect(() => {
		dispatch(displayNamesRequest({ backgroundFetch: true }))
		dispatch(fetchAllMethodsRequest())
	}, [dispatch])

	useEffect(() => {
		if (editWOStatus === AsyncStates.SUCCESS) {
			setVisible(false)
		}
	}, [editWOStatus])

	useEffect(() => {
		if (from === "stage_preview") {
			setCurrentExpId(workOrder?.experiment_id?.[currentExpIdIndex])
		} else {
			setCurrentExpId(workOrder?.experiment_id?.[0])
		}
	}, [workOrder, currentExpIdIndex, from])

	useEffect(() => {
		if (currentExpIdIndex === undefined) {
			setFormulationsCheck([])
			dispatch(fetchExperimentRequest({ experiment_id: [workOrder.experiment_id?.[0]], page_num: 1, page_size: EXP_PAGE_SIZE }))
		}
	}, [dispatch, workOrder?.experiment_id, currentExpIdIndex])

	useEffect(() => {
		if (markForMlStatus === AsyncStates.SUCCESS) {
			setModalVisible(false)
		}
	}, [markForMlStatus])

	const checkBoxChange = (e: any, formulationId: any) => {
		if (e.target.checked) {
			setFormulationsCheck((prevState: string[]) => [
				...new Set([...prevState, formulationId]),
			])
		} else {
			setFormulationsCheck((prevState: string[]) =>
				prevState.filter((id: any) => id !== formulationId)
			)
		}
	}

	const selectAll = (e: any) => {
		if (e.target.checked) {
			setFormulationsCheck(formulationList.map((res: any) => res?.id_set?.formulation_id))
		} else {
			setFormulationsCheck([])
		}
	}

	const changeTrialsStatus = (value: boolean) => {
		if (!!formulationsCheck.length) {
			dispatch(
				markForMlRequest({
					usage_map: formulationsCheck.map((formulation_id: string) => ({
						formulation_id,
						use_for_ml: value,
					})),
					experiment_id: currentExpId,
					page_num: pageNum,
				})
			)
		} else message.error(t("report.trialsNotSelected"))
	}

	useEffect(() => {
		dispatch(fetchFileListRequest({ "work_order_id": workOrder.work_order_id, }))
	}, [workOrder.work_order_id, dispatch])

	const getTabList = useCallback(() => {
		if (from === "stage_preview") {
			return []
		} else {
			return workOrder.stages.map((stage: any, index: number) => ({ key: workOrder.experiment_id[index], tab: getStageName(stage?.name, index, t) }))
		}
	}, [workOrder, from, t])

	const changeStageStatus = (values: any) => {
		const { use_for_ml_training, use_processing } = values
		dispatch(editWorkOrderRequest({
			work_order_id: workOrder?.work_order_id,
			status: currentStage?.status === "open" ? "closed" : "open",
			experiment_id: currentExpId,
			use_for_ml_training: use_for_ml_training,
			use_processing: use_for_ml_training ? use_processing ?? false : false,
			data: {},
			updateDisplayNames: true
		}))
	}

	const handleClose = () => {
		form.resetFields();
		setUseForTraining(false)
		setVisible(false)
	}

	const handleCreateIngredients = () => {
		setIsCreateIngredinentModalVisible(true)
	}

	const createSubCategories = useCallback((title: string, key: string, data: any, displayNames: any) => {
		const children = Object.keys(data).map((item) => ({
			title: displayNames?.[key]?.[item]?.name || linkedExperimentList?.find((res: any) => res.formulation_id === item)?.formulation_display_id,
			label: displayNames?.[key]?.[item]?.name || linkedExperimentList?.find((res: any) => res.formulation_id === item)?.formulation_display_id,
			key: item,
			value: item,
			parameterType: key
		}));
		return [{ label: title, title, parameterType: key, key, value: key, children }]
	}, [linkedExperimentList])

	const forumationsMetaData = useMemo(() => {
		if (!!formulationsCheck.length && formulationsCheck.length === 1) {
			const filteredFormulation = formulationList.find((formulation) => formulationsCheck.includes(formulation.id_set.formulation_id))
			const options = [filteredFormulation].reduce((acc, curr) => {
				if (Object.keys(curr?.ingredients || {}).length) {
					acc = [
						...acc,
						...createSubCategories('Ingredients', 'ingredients', curr?.ingredients, displayNames),
					]
				}
				if (Object.keys(curr?.properties?.[0]?.properties || {}).length) {
					acc = [
						...acc,
						...createSubCategories('Properties', 'properties', curr?.properties?.[0]?.properties, displayNames),
					]
				}
				if (Object.keys(curr?.processing?.[0]?.processing || {}).length) {
					acc = [
						...acc,
						...createSubCategories('Processing', 'processing', curr?.processing?.[0]?.processing, displayNames),
					]
				}
				if (Object.keys(curr?.characterizations?.[0]?.characterizations || {}).length) {
					acc = [
						...acc,
						...createSubCategories('Characterizations', 'characterizations', curr?.characterizations?.[0]?.characterizations, displayNames),
					]
				}

				return acc;
			}, [])
			return {
				trialName: linkedExperimentList?.find((res: any) => res.formulation_id === filteredFormulation.id_set.formulation_id)?.formulation_display_id || filteredFormulation?.meta?.display_id,
				options,
				ingredients: Object.keys(filteredFormulation?.ingredients ?? {}),
				formulationData: filteredFormulation
			}
		} else {
			return {
				trialName: null,
				options: [],
				ingredients: [],
				formulationData: {}
			}
		}
	}, [createSubCategories, displayNames, formulationList, formulationsCheck, linkedExperimentList])

	const formulationsDataExist = useMemo(() => formulationList?.some((curr) => {
		if (!!Object.keys(curr?.ingredients || {}).length) {
			return true;
		}
		if (!!Object.keys(curr?.processing?.[0]?.processing || {}).length) {
			return true;
		}
		if (!!Object.keys(curr?.processing?.[0]?.meta?.profile_id || {}).length) {
			return true;
		}
		if (!!Object.keys(curr?.characterizations?.[0]?.characterizations || {}).length) {
			return true;
		}
		if (!!Object.keys(curr?.properties?.[0]?.properties || {}).length) {
			return true;
		}
		if ((Array.isArray(curr?.characterizations?.[0]?.characterizations) && curr?.characterizations?.[0]?.characterizations?.length) || (Array.isArray(curr?.properties?.[0]?.properties) && curr?.properties?.[0]?.properties?.length)) {
			return true;
		}
		return false
	}), [formulationList])

	return (
		<StyledCard
			activeTabKey={currentExpId}
			id={"workorder__datasheet"}
			bodyStyle={{}}
			tabList={getTabList()}
			onTabChange={e => {
				setFormulationsCheck([])
				setCurrentExpId(e)
				setCurrent(1)
				setFormulationsCheck([])
				dispatch(fetchExperimentRequest({ experiment_id: [e], page_num: 1, page_size: EXP_PAGE_SIZE }))
				const currentExpIdIndex = workOrder?.experiment_id?.findIndex((id: any) => id === e)
				dispatch(fetchLinkedExperimentRequest({ experiment_id: workOrder.experiment_id.slice(0, currentExpIdIndex), access: false, linked_stages: true }))
			}}
			extra={
				!!formulationList?.length && from === "report_preview" && (
					<StyledButton
						loading={dataExportStatus === AsyncStates.LOADING}
						onClick={() => {
							const payload: any = {}
							if (!!formulationsCheck?.length) {
								payload["formulation_ids"] = formulationsCheck
							} else {
								payload["work_order_id"] = workOrder?.work_order_id
							}
							dispatch(dataExportRequest(payload))
						}}
						type="primary"
						icon={<FileExcelOutlined />}
						ghost
					>
						{t("workOrderDetails.exportDatasheet")}
					</StyledButton>
				)
			}
		>
			<Space size="large" direction="vertical" style={{ width: "100%" }}>
				{<Space>
					<Text strong>{t("common.status")} :</Text>
					{currentStage?.status === "open" ? <Text strong type="success">{t("common.open")}</Text> : <Text strong type={"danger"}>{t("common.closed")}</Text>}
				</Space>
				}
				{!disabled && formulationsDataExist &&
					<Row justify="space-between" style={{ margin: "16px 0px" }}>
						{currentStage?.status === "open" ?
							<StyledButton type={"primary"} onClick={() => setVisible(true)}>
								{t("common.closeStage")}
							</StyledButton> :
							<StyledButton type={"primary"} onClick={() => setVisible(true)}>
								{t("common.reopenStage")}
							</StyledButton>
						}
						{currentStage?.status === "open" &&
							<Space>
								<Tooltip title={!formulationsCheck.length ? t("common.PleaseSelectATrial") : (formulationsCheck.length > 1 ? t("common.PleaseselectAtmostoneTrial") : null)}>
									<StyledButton
										onClick={() => handleCreateIngredients()}
										type="primary"
										size="middle"
										ghost
										loading={createIngredientFromTrialStatus === AsyncStates.LOADING}
										disabled={formulationsCheck.length !== 1}
									>
										{t("inventory.CreateIngredient")}
									</StyledButton>
								</Tooltip>
								<StyledButton
									onClick={() => setModalVisible(true)}
									type="primary"
									size="middle"
									icon={<DotChartOutlined />}
									ghost
									disabled={!formulationsCheck.length}
								>
									{t("woReportPreview.changeTrialStatus")}
								</StyledButton>
								<Checkbox checked={formulationList.length === formulationsCheck.length} onChange={selectAll}>{t("common.selectAll")}</Checkbox>
							</Space>
						}
					</Row>
				}
				{formulationsDataExist && <>
					<Row justify="end">
						<Pagination total={total} current={current} pageSize={20} showSizeChanger={false} onChange={(e) => {
							setCurrent(e)
							setFormulationsCheck([])
							setPageNum(e)
							dispatch(fetchExperimentRequest({
								experiment_id: [currentExpId],
								page_num: e,
								page_size: EXP_PAGE_SIZE
							}))
						}} />
					</Row>
					<WorkOrderTableWrapper
						disbaled={disabled}
						userAccess={userAccess}
						checkBoxChange={checkBoxChange}
						formulationsCheck={formulationsCheck}
						formulationList={formulationList}
						tableId={"wo_preview"}
					/>
				</>
				}
				<Modal
					okText={t("common.ok")}
					cancelText={t("common.cancel")}
					title={t("common.changeTrialStatus")}
					visible={modalVisible}
					onCancel={() => setModalVisible(false)}
					footer={""}
				>

					<Spin
						spinning={markForMlStatus === AsyncStates.LOADING}
						indicator={<LoadingOutlined />}
					>
						<Space
							size="large"
							direction="vertical"
							style={{ width: "100%", textAlign: "center" }}
						>
							<Text strong>{t("woReportPreview.markSelectedTrials")}</Text>
							<Row justify="space-around">
								<StyledButton
									icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
									onClick={() => changeTrialsStatus(true)}
								>
									{t("common.successful")}
								</StyledButton>
								<StyledButton
									danger
									icon={<CloseCircleTwoTone twoToneColor="#ff0000" />}
									onClick={() => changeTrialsStatus(false)}
								>
									{t("common.failed")}
								</StyledButton>
							</Row>
						</Space>
					</Spin>
				</Modal>

				<Modal
					okText={t("common.ok")}
					cancelText={t("common.cancel")}
					width={600} visible={visible} onCancel={handleClose} footer={null}
					title={currentStage?.status === "open" ? "Complete and close stage" : "Reopen Stage"}>
					<Spin spinning={editWOStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
						<Space style={{ width: "100%" }} direction="vertical" size="large">
							<Form onFinish={(values: any) =>
								useForTraining && currentStage?.status === "open" ?
									Modal.confirm({
										title: "Ensure that all data submitted is correct as data cannot be retracted from AI model training",
										okText: t("common.submit"),
										cancelText: t("common.cancel"),
										okButtonProps: { style: { borderRadius: "12px" } },
										cancelButtonProps: { style: { borderRadius: "12px" } },
										onOk: () => changeStageStatus(values)
									}) : changeStageStatus(values)
							} form={form} layout="vertical">

								{currentStage?.status === "open" ?
									// !Boolean(configs?.disable_custom_training_stage) ? <>
									// 	<Form.Item name={"use_for_ml_training"} valuePropName={"checked"}>
									// 		<Checkbox onChange={(e: any) => setUseForTraining(e.target.checked)} checked={useForTraining} value={false}>
									// 			<Text strong>{`Use Stage for AI training`}</Text>
									// 		</Checkbox>
									// 	</Form.Item>
									// 	{
									// 		useForTraining && <Space>
									// 			<Form.Item name={"use_ingredients"} >
									// 				<Checkbox defaultChecked={true} disabled>
									// 					<Text strong> {t("common.useIngredients")}</Text>
									// 				</Checkbox>
									// 			</Form.Item>
									// 			{
									// 				isProcessingExists &&
									// 				<Form.Item name={"use_processing"} valuePropName={"checked"}>
									// 					<Checkbox>
									// 						<Text strong> {t("common.useProcessing")}</Text>
									// 					</Checkbox>
									// 				</Form.Item>
									// 			}
									// 		</Space>
									// 	}
									// </> : 
									<Text>{t("common.confirmCloseStage")}</Text> :
									<Text>{t("common.confirmReopenStage")}</Text>
								}
								<Form.Item shouldUpdate>
									{() => (
										<Row justify='end'>
											<StyledButton htmlType='submit' type="primary" ghost>
												{t("common.confirm")}
											</StyledButton>
										</Row>
									)}
								</Form.Item>
							</Form>
						</Space>
					</Spin>
				</Modal>
				<IngredientModal
					open={isCreateIngredinentModalVisible}
					setOpen={setIsCreateIngredinentModalVisible}
					mode="create"
					from="create-from-trial"
					trialData={forumationsMetaData}
				/>
			</Space>
		</StyledCard >
	)
}
