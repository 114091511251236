import axios from 'axios'
import { Headers } from "../interface"

export const filterDataApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'filter_data',
    params,
    {
      headers
    }
  )
}

export const multiStageFilterDataApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'multistage/filter_data',
    params,
    {
      headers
    }
  )
}

export const getCustomTraingsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_custom_training_logs',
    params,
    {
      headers
    }
  )
}

export const customDataTrainMultistageApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'multistage/custom_data_train_new',
    params,
    {
      headers
    }
  )
}

export const customDataTrainApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'custom_data_train_new',
    params,
    {
      headers
    }
  )
}

export const equationModelListApi = (headers: Headers) => {
  return axios.get<any>(
    process.env.REACT_APP_ML_URL + 'get_equations_models_runs',
    {
      headers
    }
  )
}

export const equationModelApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_equations_models',
    params,
    {
      headers
    }
  )
}

export const equationApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'load_equation',
    params,
    {
      headers
    }
  )
}

export const compareEquationApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'compare_work_orders',
    params,
    {
      headers
    }
  )
}

export const deleteCustomModelsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'delete_custom_models',
    params,
    {
      headers
    }
  )
}

export const downloadEquationModelReportApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'export_excel_report',
    params,
    {
      headers
    }
  )
}