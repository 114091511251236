import { blue, orange } from "@ant-design/colors";
import { LoadingOutlined, PushpinOutlined } from "@ant-design/icons";
import {
  Avatar,
  Table,
  Tooltip,
  Typography,
  Empty,
  Space,
  Tag,
  Card,
  Collapse,
  List,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { antdTheme, AsyncStates } from "src/constants";
import { setProject, updateProjectRequest } from "src/store/actions/projects";
import { StoreState } from "src/store/configureStore";
import { useMemberName } from "src/utils/useMemberName";
import { DropdownMenu } from "../Project/DropdDownMenu/DropDownMenu";
import "./Projects.scss";
import useTranslate from "src/utils/useTranslate";
import { workOrdersRequest } from "src/store/actions/workOrders";
import dayjs from "dayjs";
import { celsureWoListRequest } from "src/store/actions/celsureWo";
import { useQuery } from "src/utils/useQuery";

const { Text } = Typography;

export const ProjectTable = ({
  filteredProjects,
  projectType,
  getPinAccess,
  getProjectStatus,
}: any) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();
  const { getName } = useMemberName();
  const query = useQuery();
  const { updateProjectStatus, projectListStatus } = useSelector(
    (state: StoreState) => state.projects
  );
  const { status: workOrderListStatus, data: workOrderListData } = useSelector(
    (state: StoreState) => state.workOrders
  );
  const { celsureWoList, celsureWoListStatus } = useSelector(
    (state: StoreState) => state.celsureWo
  );
  const [expandedRows, setExpandedRows] = useState<any[]>([]);
  const { project_type } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const [workOrders, setWorkOrder] = useState<any>({
    workOrdersStatus: AsyncStates.INITIAL,
    workOrdersData: [],
  });
  const { workOrdersStatus, workOrdersData } = workOrders;

  useEffect(() => {
    setExpandedRows([]);
  }, [project_type]);

  useEffect(() => {
    const expandedProjectId = query?.get("expandedProjectId");
    if (
      expandedProjectId &&
      filteredProjects.length &&
      filteredProjects.find(
        ({ project_id }: any) => project_id === expandedProjectId
      )
    ) {
      const expandedProjectIndex = filteredProjects.findIndex(
        (filteredProject: any) =>
          filteredProject.project_id === expandedProjectId
      );
      setExpandedRows([expandedProjectIndex]);
      project_type === "celsure"
        ? dispatch(celsureWoListRequest({ project_id: expandedProjectId }))
        : dispatch(workOrdersRequest({ project_id: expandedProjectId }));
    }
  }, [project_type, filteredProjects, query, dispatch, push]);

  useEffect(() => {
    setWorkOrder(
      project_type === "celsure"
        ? {
          workOrdersData: celsureWoList,
          workOrdersStatus: celsureWoListStatus,
        }
        : {
          workOrdersStatus: workOrderListStatus,
          workOrdersData: workOrderListData,
        }
    );
  }, [
    celsureWoList,
    celsureWoListStatus,
    project_type,
    workOrderListData,
    workOrderListStatus,
  ]);

  const categories = useMemo(
    () =>
      [...new Set(filteredProjects.map((project: any) => project.category))]
        .sort((a: any, b: any) => 1)
        .map((res: any) => ({
          value: res,
          text: res,
        })),
    [filteredProjects]
  );

  const tagFilterOptions = useMemo(() => {
    return (
      [...new Set(filteredProjects.flatMap((project: any) => project.tags))]
        ?.sort((a: any, b: any) => 1)
        .filter((res) => res) ?? []
    )?.map((tag, idx) => ({
      value: tag,
      text: tag,
      key: `${tag}_${idx}`,
    }));
  }, [filteredProjects]);

  const labLocationsFilterOptions = useMemo(() => {
    const filteredLocations = filteredProjects.reduce((acc: string[], curr: any) => {
      const locations = (curr.lab_locations ?? []).map((location: any) => location.name)
      if (!!locations.length) {
        acc.push(...locations)
      }
      return [...new Set(acc)]
    }, [])
    return filteredLocations?.map((location: string, idx: number) => ({
      value: location,
      text: location,
      key: `${location}_${idx}`,
    }))
  }, [filteredProjects]);

  const projectOnwers = useMemo(
    () =>
      [
        ...new Set(filteredProjects.map((project: any) => project.created_by)),
      ].map((res: any) => ({ text: getName(res), value: res })),
    [filteredProjects, getName]
  );

  const columns = useMemo(() => {
    return [
      {
        dataIndex: "name",
        title: t("common.title"),
        key: "name",
        width: 200,
        ellipsis: true,
        render: (text: string, record: any) => {
          const access = getPinAccess(record);
          return (
            <Space style={{ paddingLeft: 10 }}>
              <Tooltip
                title={
                  access
                    ? `Click to  ${!Boolean(record?.favourite) ? "pin" : "unpin"
                    } this project`
                    : ""
                }
              >
                <PushpinOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access) {
                      dispatch(
                        updateProjectRequest({
                          project_id: record.project_id,
                          ...record,
                          favourite: !Boolean(record?.favourite),
                        })
                      );
                    }
                  }}
                  style={{
                    fontSize: antdTheme.fontSizeHeading4,
                    marginRight: 5,
                    color: Boolean(record?.favourite) ? blue[4] : "",
                    cursor: access ? "pointer" : "auto",
                  }}
                />
              </Tooltip>
              <Text
                className="project__title"
                strong
                style={{ width: 140 }}
                ellipsis={{ tooltip: text }}
              >
                <span className="project__title">{text}</span>
              </Text>
            </Space>
          );
        },
      },
      {
        dataIndex: "description",
        title: t("common.description"),
        key: "description",
        width: 150,
        ellipsis: true,
        render: (text: string) => (
          <Text style={{ width: 350 }} ellipsis={{ tooltip: text }}>
            {text}
          </Text>
        ),
      },
      {
        dataIndex: "tags",
        title: t("project.tags"),
        key: "tags",
        width: 100,
        filters: tagFilterOptions,
        onFilter: (value: any, record: any) => {
          return record.tags?.includes(value);
        },
        render: (tags: string[] | undefined) => {
          return !!tags?.length ? (
            <Text
              style={{ width: 200 }}
              ellipsis={{ tooltip: tags.join(", ") }}
            >
              {tags?.map((tag: string, idx: number) => (
                <Tag color="geekblue" key={idx}>
                  {tag}
                </Tag>
              ))}
            </Text>
          ) : null;
        },
      },
      {
        dataIndex: "lab_locations",
        title: t("common.labLocation"),
        key: "lab_locations",
        width: 150,
        filters: labLocationsFilterOptions,
        onFilter: (value: any, record: any) => {
          const availableLocationsOptions = record?.lab_locations?.map((location: any) => location.name)
          return availableLocationsOptions?.includes(value);
        },
        render: (lab_locations: any[] = []) => {
          const locationNames = lab_locations?.map((location) => location.name)
          return !!locationNames?.length ? (
            <Text
              style={{ width: 200 }}
              ellipsis={{ tooltip: locationNames.join(", ") }}
            >
              {locationNames?.map((location, idx: number) => (
                <Tag color="geekblue" key={idx}>
                  {location}
                </Tag>
              ))}
            </Text>
          ) : null;
        },
      },
      {
        dataIndex: "category",
        title: t("common.category"),
        key: "category",
        filters: categories,
        width: 150,
        onFilter: (value: any, record: any) =>
          record.category.indexOf(value) === 0,
        sorter: (a: any, b: any) => {
          return a.category.localeCompare(b.category);
        },
        sortDirections: ["ascend", "descend"],
        render: (text: string) => (
          <Text
            strong
            type="secondary"
            style={{ width: 150 }}
            ellipsis={{ tooltip: text }}
          >
            {text}
          </Text>
        ),
      },
      {
        dataIndex: "created_by",
        title: t("common.createdBy"),
        key: "created_by",
        align: "center" as any,
        filters: projectOnwers,
        onFilter: (value: any, record: any) =>
          record.created_by.indexOf(value) === 0,
        render: (text: any) => {
          const userName = getName(text);
          return (
            <Tooltip title={userName} placement="top">
              <Avatar style={{ background: orange[5] }} size="small">
                {userName?.[0]}
              </Avatar>
            </Tooltip>
          );
        },
        width: 100,
      },
      ...(projectType === "all"
        ? [
          {
            dataIndex: "status",
            title: t("common.status"),
            key: "status",
            width: 100,
            align: "center" as any,
            sorter: (a: any, b: any) => {
              return a.status.localeCompare(b.status);
            },
            sortDirections: ["ascend", "descend"],
            render: (text: string) => {
              return getProjectStatus(text);
            },
          },
        ]
        : []),
      {
        dataIndex: "actions",
        title: t("common.actions"),
        key: "actions",
        align: "center" as any,
        width: 100,
        render: (text: any, record: any) => {
          return <DropdownMenu type="projects" id={record.project_id} />;
        },
      },
    ];
  }, [t, tagFilterOptions, labLocationsFilterOptions, categories, projectOnwers, projectType, getPinAccess, dispatch, getName, getProjectStatus]);

  const getWoDetails = (item: any) => {
    if (project_type === "celsure") {
      const { work_order_id, work_order_name, created, work_order_desc } = item;
      return (
        <List.Item>
          {work_order_name === "Work Order" ? (
            <strong className="project-wo">{t("common.workOrder")}</strong>
          ) : (
            <Link
              className="project-wo"
              to={`/work-orders/details/${work_order_id}/`}
            >
              {work_order_name}
            </Link>
          )}
          {created === "Created On" ? (
            <strong className="project-wo">{t("common.createdOn")}</strong>
          ) : (
            <span className="project-wo">
              {dayjs(created).format("YYYY-MM-DD")}
            </span>
          )}
          {work_order_desc === "Description" ? (
            <strong className="project-wo">{t("common.description")}</strong>
          ) : (
            <span className="project-wo">{work_order_desc}</span>
          )}
        </List.Item>
      );
    } else {
      const {
        work_order_id,
        work_order_name,
        created,
        application,
        material_name,
      } = item;
      return (
        <List.Item>
          {work_order_name === "Work Order" ? (
            <strong className="project-wo">{t("common.workOrder")}</strong>
          ) : (
            <Link
              className="project-wo"
              to={`/work-orders/details/${work_order_id}/`}
            >
              {work_order_name}
            </Link>
          )}
          {created === "Created On" ? (
            <strong className="project-wo">{t("common.createdOn")}</strong>
          ) : (
            <span className="project-wo">
              {dayjs(created).format("YYYY-MM-DD")}
            </span>
          )}
          {application === "Application" ? (
            <strong className="project-wo">{t("common.application")}</strong>
          ) : (
            <span className="project-wo">{application}</span>
          )}
          {material_name === "Material" ? (
            <strong className="project-wo">{t("common.material")}</strong>
          ) : (
            <span className="project-wo">{material_name}</span>
          )}
        </List.Item>
      );
    }
  };

  const [currentDataSource, setCurrentDataSource] = useState<any[]>(filteredProjects);

  useEffect(() => {
    setCurrentDataSource(filteredProjects);
  }, [filteredProjects])

  return (
    <Table
      size="small"
      title={() => <Text strong>{`${t("common.Total")}:  ${currentDataSource?.length}`}</Text>}
      columns={columns as any}
      onChange={(pagination, filters,sorter, extra) => {
        setCurrentDataSource(extra.currentDataSource);
      }}
      expandable={{
        expandedRowRender: () => {
          const items =
            workOrdersStatus === "SUCCESS"
              ? project_type === "celsure"
                ? [
                    {
                      key: "1",
                      label: (
                        <strong>Work Orders ({workOrdersData.length})</strong>
                      ),
                      children: (
                        <List
                          size="small"
                          bordered
                          dataSource={[
                            {
                              work_order_name: "Work Order",
                              created: "Created On",
                              work_order_stage: "Stage",
                              work_order_desc: "Description",
                            },
                            ...workOrdersData,
                          ]}
                          renderItem={getWoDetails}
                        />
                      ),
                      showArrow: false,
                    },
                  ]
                : [
                    {
                      key: "1",
                      label: (
                        <strong>
                          Open Work Orders (
                          {
                            workOrdersData.filter(
                              (workorder: any) => workorder.status === "open"
                            ).length
                          }
                          )
                        </strong>
                      ),
                      children: (
                        <List
                          size="small"
                          bordered
                          dataSource={[
                            {
                              work_order_name: "Work Order",
                              created: "Created On",
                              application: "Application",
                              material_name: "Material",
                            },
                            ...workOrdersData.filter(
                              (workorder: any) => workorder.status === "open"
                            ),
                          ]}
                          renderItem={getWoDetails}
                        />
                      ),
                      showArrow: false,
                    },
                    {
                      key: "2",
                      label: (
                        <strong>
                          Closed Work Orders (
                          {
                            workOrdersData.filter(
                              (workorder: any) => workorder.status === "closed"
                            ).length
                          }
                          )
                        </strong>
                      ),
                      children: (
                        <List
                          size="small"
                          bordered
                          dataSource={[
                            {
                              work_order_name: "Work Order",
                              created: "Created On",
                              application: "Application",
                              material_name: "Material",
                            },
                            ...workOrdersData.filter(
                              (workorder: any) => workorder.status === "closed"
                            ),
                          ]}
                          renderItem={getWoDetails}
                        />
                      ),
                      showArrow: false,
                    },
                  ]
              : [];
          if (workOrdersStatus === "SUCCESS")
            return (
              <Card>
                <h3>Total Work Orders: {workOrdersData.length}</h3>
                <Collapse
                  items={items}
                  activeKey={[
                    project_type === "celsure" && workOrdersData.length && "1",
                    workOrdersData.filter(
                      (workorder: any) => workorder.status === "closed"
                    ).length && "2",
                    workOrdersData.filter(
                      (workorder: any) => workorder.status === "open"
                    ).length && "1",
                  ]}
                />
              </Card>
            );
          else return <span>{workOrdersStatus}...</span>;
        },
        expandedRowKeys: expandedRows,
        onExpand: (record, event) => {
          if (record) {
            setExpandedRows([event.key]);
            push(`/projects?expandedProjectId=${event.project_id}`);
            project_type === "celsure"
              ? dispatch(celsureWoListRequest({ project_id: event.project_id }))
              : dispatch(workOrdersRequest({ project_id: event.project_id }));
          } else {
            push("/projects");
            setExpandedRows([]);
          }
        },
      }}
      dataSource={filteredProjects.map((data: any, index: number) => ({
        key: index,
        ...data,
      }))}
      bordered
      scroll={{ x: 450 }}
      rowClassName={() => "cursor-row"}
      pagination={{ showSizeChanger: false }}
      loading={{
        spinning:
          updateProjectStatus === AsyncStates.LOADING ||
          projectListStatus === AsyncStates.LOADING,
        indicator: <LoadingOutlined />,
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (e: any) => {
            const classNames = e?.target?.className?.split(" ")?.[0];
            if (
              [
                "ant-typography",
                "project__title",
                "ant-tag",
                "ant-avatar",
                "ant-table-cell",
                "ant-avatar-string",
              ].includes(classNames)
            ) {
              dispatch(setProject(record.project_id));
              push(`/projects/details/${record.project_id}`);
            }
          },
        };
      }}
      locale={{
        emptyText:
          projectListStatus === AsyncStates.LOADING ? (
            ""
          ) : (
            <Empty description={"No Project found under selected status"} />
          ),
      }}
    />
  );
};
