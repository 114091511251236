import { InfoCircleOutlined } from "@ant-design/icons";
import {
    Collapse,
    Flex,
    InputNumber,
    Radio,
    Slider,
    Switch,
    Tag,
    Tooltip,
} from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { RESIDUAL_IMAGE_OPTIONS } from "./ResidualAnalysisDetails";
import { StyledButton } from "src/styled_components/StyledButton";

type TProps = {
    filteredData: any;
    imgToShow: RESIDUAL_IMAGE_OPTIONS;
    setImgToShow: React.Dispatch<React.SetStateAction<RESIDUAL_IMAGE_OPTIONS>>;
    minArea: number;
    maxArea: number;
    showAllMasks: boolean;
    updateShowAllMasksSettings: (value: boolean) => void;
    showBBox: boolean;
    updateShowBBoxSettings: (value: boolean) => void;
    imgOpacity: number;
    setImgOpacity: React.Dispatch<React.SetStateAction<number>>;
    fetchImageSubstractionMasks: (parameters?: Record<string, any>) => void;
};

const ResidualAnalysisDetailsSidebar = ({
    filteredData,
    imgToShow,
    setImgToShow,
    minArea,
    maxArea,
    showAllMasks,
    updateShowAllMasksSettings,
    showBBox,
    updateShowBBoxSettings,
    setImgOpacity,
    imgOpacity,
    fetchImageSubstractionMasks,
}: TProps) => {
    const [areaRange, setAreaRange] = useState<any[]>([]);
    const { imageSubtraction } = useSelector(
        (state: StoreState) => state.semAnalysis
    );
    const [inputEditModes, setInputEditModes] = useState({
        detection_threshold: false,
        morphological_adjustment: false,
    });
    const [parameters, setPatameters] = useState<{
        detection_threshold: number | null;
        morphological_adjustment: number | null;
        remove_lines: boolean;
    }>({
        detection_threshold: 23,
        morphological_adjustment: 0,
        remove_lines: true,
    });

    useEffect(() => {
        if (
            imageSubtraction?.parameters?.area_range &&
            Array.isArray(imageSubtraction?.parameters?.area_range)
        ) {
            setAreaRange(imageSubtraction?.parameters?.area_range);
        } else {
            setAreaRange([minArea, maxArea]);
        }
    }, [imageSubtraction?.parameters, maxArea, minArea]);

    useEffect(() => {
        if (
            imageSubtraction?.parameters &&
            Object.keys(imageSubtraction?.parameters).length > 0
        ) {
            setPatameters({
                detection_threshold:
                    imageSubtraction?.parameters?.detection_threshold ?? 23,
                morphological_adjustment:
                    imageSubtraction?.parameters?.morphological_adjustment,
                remove_lines: imageSubtraction?.parameters?.remove_lines ?? true,
            });
        }
    }, [imageSubtraction?.parameters]);

    const validate = useCallback(
        (field: string) => {
            if (
                field === "detection_threshold" &&
                parameters.detection_threshold !== null &&
                parameters.detection_threshold >= 0 &&
                parameters.detection_threshold <= 150
            )
                return true;
            if (
                field === "morphological_adjustment" &&
                (
                    (
                        parameters.morphological_adjustment !== null &&
                        parameters.morphological_adjustment >= -10 &&
                        parameters.morphological_adjustment <= 10
                    ) || parameters.morphological_adjustment === null
                )
            )
                return true;

            return false;
        },
        [parameters]
    );

    const fetchMasks = useCallback(
        (params?: Record<string, any>) => {
            if (
                validate("detection_threshold") &&
                validate("morphological_adjustment")
            ) {
                setInputEditModes({
                    detection_threshold: false,
                    morphological_adjustment: false,
                });

                const parametersPayload: any = params || parameters;
                parametersPayload.area_range = areaRange;
                fetchImageSubstractionMasks(parametersPayload);
            }
        },
        [fetchImageSubstractionMasks, parameters, validate, areaRange]
    );

    const isEmpty = (val: any) => val === null || val === undefined;

    return (
        <div className="sidebar">
            <div className="content">
                <div className="option">
                    <Radio.Group
                        value={imgToShow}
                        onChange={(e) => setImgToShow(e.target.value)}
                    >
                        <Radio.Button
                            style={{ padding: "0 10px" }}
                            value={RESIDUAL_IMAGE_OPTIONS.AFTER}
                        >
                            After
                        </Radio.Button>
                        <Radio.Button
                            style={{ padding: "0 10px" }}
                            value={RESIDUAL_IMAGE_OPTIONS.BEFORE}
                        >
                            Before
                        </Radio.Button>
                        <Radio.Button
                            style={{ padding: "0 10px" }}
                            value={RESIDUAL_IMAGE_OPTIONS.NONE}
                        >
                            None
                        </Radio.Button>
                    </Radio.Group>
                </div>

                <div className="option">
                    <div className="label">Show All Masks</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showAllMasks}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            updateShowAllMasksSettings(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Bounding Box</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showBBox}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            updateShowBBoxSettings(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                {imgToShow && (
                    <div className="option">
                        <div className="label">Opacity</div>
                        <Slider
                            min={0}
                            max={100}
                            defaultValue={imgOpacity}
                            onChangeComplete={(val) => {
                                setImgOpacity(val);
                            }}
                            style={{ width: 200 }}
                            rootClassName="sem-slider"
                        />
                    </div>
                )}

                <div className="option">
                    <div className="label">Total Anamolies</div>
                    <Tag color="#424242">
                        {filteredData?.length || 0}
                    </Tag>
                </div>

                {imageSubtraction?.pcb_type !== "PCB_A" &&
                    imageSubtraction?.parameters &&
                    Object.keys(imageSubtraction?.parameters).length > 0 && (
                        <Collapse
                            expandIconPosition="end"
                            rootClassName="setting-collapse"
                            items={[
                                {
                                    key: "1",
                                    label: "Advanced Settings",
                                    children: (
                                        <>
                                            <div className="option">
                                                <div className="label">Detection Threshold</div>
                                                {!inputEditModes.detection_threshold ? (
                                                    <>
                                                        <Tag
                                                            color="#424242"
                                                            onClick={() =>
                                                                setInputEditModes((prev) => ({
                                                                    ...prev,
                                                                    detection_threshold: true,
                                                                }))
                                                            }
                                                        >
                                                            {parameters?.detection_threshold}
                                                        </Tag>
                                                        <Tooltip
                                                            title={
                                                                <div>
                                                                    On the grey scale the differences that are
                                                                    spotted between before and after images, the
                                                                    pixels that fall below the threshold are
                                                                    filtered. In order to spot the anomalies which
                                                                    are dark(close to black) in colour this
                                                                    threshold can be set high and to spot
                                                                    light(close to white) anomalies threshold
                                                                    should be set low.
                                                                    <br />
                                                                    {!isEmpty(
                                                                        imageSubtraction?.default_parameters
                                                                            ?.detection_threshold
                                                                    ) && (
                                                                            <>
                                                                                <div>
                                                                                    Default Value:{" "}
                                                                                    {
                                                                                        imageSubtraction?.default_parameters
                                                                                            ?.detection_threshold
                                                                                    }
                                                                                </div>
                                                                                <div>Ideal Range: [15, 50]</div>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            }
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <Flex vertical>
                                                        <InputNumber
                                                            value={parameters.detection_threshold}
                                                            size="small"
                                                            onChange={(val) =>
                                                                setPatameters((prev) => ({
                                                                    ...prev,
                                                                    detection_threshold: val,
                                                                }))
                                                            }
                                                        />
                                                        {!validate("detection_threshold") && (
                                                            <div className="error-div">Min 0, Max 150</div>
                                                        )}
                                                    </Flex>
                                                )}
                                            </div>

                                            <div className="option">
                                                <div className="label">Morphological adjustment</div>
                                                {!inputEditModes.morphological_adjustment ? (
                                                    <>
                                                        <Tag
                                                            color="#424242"
                                                            onClick={() =>
                                                                setInputEditModes((prev) => ({
                                                                    ...prev,
                                                                    morphological_adjustment: true,
                                                                }))
                                                            }
                                                        >
                                                            {parameters?.morphological_adjustment ?? "NA"}
                                                        </Tag>
                                                        <Tooltip
                                                            title={
                                                                <div>
                                                                    Because of lighting conditions differences are
                                                                    being spotted inside the black circles of the
                                                                    PCB which are not actual anomalies, the black
                                                                    circles are identified and anomalies that fall
                                                                    inside the circles are being filtered out.
                                                                    This Morphological adjustment with help to
                                                                    increase/decrease the actual black circles
                                                                    identified by the system in order to
                                                                    include/exclude the peripheral differences
                                                                    <br />
                                                                    {!isEmpty(
                                                                        imageSubtraction?.default_parameters
                                                                            ?.morphological_adjustment
                                                                    ) && (
                                                                            <>
                                                                                <div>
                                                                                    Default Value:{" "}
                                                                                    {
                                                                                        imageSubtraction?.default_parameters
                                                                                            ?.morphological_adjustment
                                                                                    }
                                                                                </div>
                                                                                <div>Range: [-10, 10]</div>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            }
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <Flex vertical>
                                                        <InputNumber
                                                            size="small"
                                                            value={parameters.morphological_adjustment}
                                                            onChange={(val) =>
                                                                setPatameters((prev) => ({
                                                                    ...prev,
                                                                    morphological_adjustment: val,
                                                                }))
                                                            }
                                                        />
                                                        {!validate("morphological_adjustment") && (
                                                            <div className="error-div">Min -10, Max 10</div>
                                                        )}
                                                    </Flex>
                                                )}
                                            </div>

                                            <div className="option">
                                                <div className="label">Remove lines</div>
                                                <Switch
                                                    checked={parameters.remove_lines}
                                                    onChange={(val: boolean) => {
                                                        setPatameters((prev) => ({
                                                            ...prev,
                                                            remove_lines: val,
                                                        }));
                                                    }}
                                                    style={{
                                                        outline: "none",
                                                    }}
                                                />
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            Remove Horizontal and Vertical Lines. System
                                                            identifies two points that elapses the maximum
                                                            length for a mask, if the two points are forming
                                                            Horizontal/Vertical line then they are removed.
                                                            <br />
                                                            {!isEmpty(
                                                                imageSubtraction?.default_parameters
                                                                    ?.remove_lines
                                                            ) && (
                                                                    <>
                                                                        Default Value:{" "}
                                                                        <span style={{ textTransform: "capitalize" }}>
                                                                            {String(
                                                                                imageSubtraction?.default_parameters
                                                                                    ?.remove_lines
                                                                            )}
                                                                        </span>
                                                                    </>
                                                                )}
                                                        </div>
                                                    }
                                                >
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className="option">
                                                <StyledButton
                                                    size="small"
                                                    type="primary"
                                                    onClick={() => fetchMasks()}
                                                >
                                                    Apply
                                                </StyledButton>
                                                <StyledButton
                                                    size="small"
                                                    onClick={() =>
                                                        fetchImageSubstractionMasks(
                                                            imageSubtraction?.default_parameters
                                                        )
                                                    }
                                                >
                                                    Reset to Default
                                                </StyledButton>
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    )}

                <div className="option">
                    <div className="label">Threshold Area</div>
                    <Slider
                        range
                        step={0.1}
                        min={minArea}
                        max={maxArea}
                        value={areaRange}
                        onChange={(val) => setAreaRange(val)}
                        onChangeComplete={(val) => {
                            fetchImageSubstractionMasks({
                                ...imageSubtraction?.parameters,
                                area_range: val,
                            });
                        }}
                        style={{ width: 200 }}
                        rootClassName="sem-slider"
                    />
                </div>

                <div className="option">
                    <div className="label">Quality</div>
                    <Tag color="#424242">
                        {imageSubtraction?.image_quality}
                    </Tag>
                </div>
            </div>
        </div>
    );
};

export default memo(ResidualAnalysisDetailsSidebar);
