import { createActions } from "redux-actions";

export enum FilterDataActionTypes {
  REQUEST = "FILTER_DATA_REQUEST",
  SUCCESS = "FILTER_DATA_SUCCESS",
  FORMULATION_SUCCESS = "FILTER_DATA_FORMULATION_SUCCESS",
  FAILURE = "FILTER_DATA_FAILURE",
  CLEAR = "FILTER_DATA_CLEAR",
}

export enum CustomTraingsActionTypes {
  REQUEST = "GET_CUSTOM_TRAINGS_REQUEST",
  SUCCESS = "GET_CUSTOM_TRAINGS_SUCCESS",
  FAILURE = "GET_CUSTOM_TRAINGS_FAILURE",
  CLEAR = "GET_CUSTOM_TRAINGS_CLEAR",
}

export enum CustomTrainActionTypes {
  REQUEST = "CUSTOM_TRAIN_REQUEST",
  SUCCESS = "CUSTOM_TRAIN_SUCCESS",
  FAILURE = "CUSTOM_TRAIN_FAILURE",
  CLEAR = "CUSTOM_TRAIN_CLEAR",
}

export enum CompareEquationActionTypes {
  REQUEST = "COMPARE_EQUATION_REQUEST",
  SUCCESS = "COMPARE_EQUATION_SUCCESS",
  FAILURE = "COMPARE_EQUATION_FAILURE",
  CLEAR = "COMPARE_EQUATION_CLEAR",
}

export enum EquationActionTypes {
  REQUEST = "EQUATION_REQUEST",
  SUCCESS = "EQUATION_SUCCESS",
  FAILURE = "EQUATION_FAILURE",
  CLEAR = "EQUATION_CLEAR",
}

export enum EquationModelListActionTypes {
  REQUEST = "EQUATION_MODEL_LIST_REQUEST",
  SUCCESS = "EQUATION_MODEL_LIST_SUCCESS",
  FAILURE = "EQUATION_MODEL_LIST_FAILURE",
  CLEAR = "EQUATION_MODEL_LIST_CLEAR",
}

export enum EquationModelActionTypes {
  REQUEST = "EQUATION_MODEL_REQUEST",
  SUCCESS = "EQUATION_MODEL_SUCCESS",
  FAILURE = "EQUATION_MODEL_FAILURE",
  CLEAR = "EQUATION_MODEL_CLEAR",
}

export enum DownloadEquationModelReportActionTypes {
  REQUEST = "DOWNLOAD_EQUATION_MODEL_REPORT_REQUEST",
  SUCCESS = "DOWNLOAD_EQUATION_MODEL_REPORT_SUCCESS",
  FAILURE = "DOWNLOAD_EQUATION_MODEL_REPORT_FAILURE",
  CLEAR = "DOWNLOAD_EQUATION_MODEL_REPORT_CLEAR",
}

export enum DeleteModelsActionTypes {
  REQUEST = "DELETE_MODEL_REQUEST",
  SUCCESS = "DELETE_MODEL_SUCCESS",
  FAILURE = "DELETE_MODEL_FAILURE",
}

export const {
  filterDataRequest,
  filterDataSuccess,
  filterDataFormulationSuccess,
  filterDataFailure,
  filterDataClear,
  getCustomTraingsRequest,
  getCustomTraingsSuccess,
  getCustomTraingsFailure,
  getCustomTraingsClear,
  customTrainRequest,
  customTrainSuccess,
  customTrainFailure,
  customTrainClear,
  compareEquationRequest,
  compareEquationSuccess,
  compareEquationFailure,
  compareEquationClear,
  equationRequest,
  equationSuccess,
  equationFailure,
  equationClear,
  equationModelListRequest,
  equationModelListSuccess,
  equationModelListFailure,
  equationModelListClear,
  downloadEquationModelReportRequest,
  downloadEquationModelReportSuccess,
  downloadEquationModelReportFailure,
  downloadEquationModelReportClear,
  equationModelRequest,
  equationModelSuccess,
  equationModelFailure,
  equationModelClear,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelFailure,
} = createActions({
  [FilterDataActionTypes.REQUEST]: (payload) => payload,
  [FilterDataActionTypes.SUCCESS]: (response) => response,
  [FilterDataActionTypes.FORMULATION_SUCCESS]: (response) => response,
  [FilterDataActionTypes.FAILURE]: () => {},
  [FilterDataActionTypes.CLEAR]: () => {},
  [CustomTraingsActionTypes.REQUEST]: (payload) => payload,
  [CustomTraingsActionTypes.SUCCESS]: (response) => response,
  [CustomTraingsActionTypes.FAILURE]: () => {},
  [CustomTraingsActionTypes.CLEAR]: () => {},
  [CustomTrainActionTypes.REQUEST]: (payload) => payload,
  [CustomTrainActionTypes.SUCCESS]: (response) => response,
  [CustomTrainActionTypes.FAILURE]: () => {},
  [CustomTrainActionTypes.CLEAR]: () => {},
  [CompareEquationActionTypes.REQUEST]: (payload) => payload,
  [CompareEquationActionTypes.SUCCESS]: (response) => response,
  [CompareEquationActionTypes.FAILURE]: () => {},
  [CompareEquationActionTypes.CLEAR]: () => {},
  [EquationActionTypes.REQUEST]: (payload) => payload,
  [EquationActionTypes.SUCCESS]: (response) => response,
  [EquationActionTypes.FAILURE]: () => {},
  [EquationActionTypes.CLEAR]: () => {},
  [EquationModelListActionTypes.REQUEST]: (payload) => payload,
  [EquationModelListActionTypes.SUCCESS]: (response) => response,
  [EquationModelListActionTypes.FAILURE]: () => {},
  [EquationModelListActionTypes.CLEAR]: () => {},
  [DownloadEquationModelReportActionTypes.REQUEST]: (payload) => payload,
  [DownloadEquationModelReportActionTypes.SUCCESS]: (response) => response,
  [DownloadEquationModelReportActionTypes.FAILURE]: () => {},
  [DownloadEquationModelReportActionTypes.CLEAR]: () => {},
  [EquationModelActionTypes.REQUEST]: (payload) => payload,
  [EquationModelActionTypes.SUCCESS]: (response) => response,
  [EquationModelActionTypes.FAILURE]: () => {},
  [EquationModelActionTypes.CLEAR]: () => {},
  [DeleteModelsActionTypes.REQUEST]: (payload) => payload,
  [DeleteModelsActionTypes.SUCCESS]: (response) => response,
  [DeleteModelsActionTypes.FAILURE]: () => {},
});
