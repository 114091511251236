import { CloseCircleFilled, DeleteFilled, LoadingOutlined, PlusOutlined, } from '@ant-design/icons'
import {
    Form,
    Row,
    Space,
    Col,
    Select,
    Typography,
    InputNumber,
    Input,
    Cascader,
    message,
    Tag,
    Spin,
} from 'antd';
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { toTitleCase } from "src/utils/general"
import { getDropdownFilteredValue } from "src/utils/decorator"
import { StyledCard } from 'src/styled_components/StyledCard'
import './FilterForm.scss'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useValue } from 'src/utils/useValue'
import { AsyncStates } from 'src/constants'
import InventoryUtils from 'src/modules/InventoryV2/utils'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

const { Option } = Select
const { Text } = Typography


interface FilterFormProps {
    selectedProjects: string[]
    formState: any
    setFormState: React.Dispatch<React.SetStateAction<any>>
    applyFilters: (values: any) => void
    clearFilters: () => void
    form: any
    innerSelector: string[]
    setInnerSelector: React.Dispatch<React.SetStateAction<string[]>>
    outerSelector: any
    setOuterSelector: React.Dispatch<React.SetStateAction<any>>
    filtersLoading: boolean
}


export const FilterForm = ({ selectedProjects, formState, applyFilters, clearFilters, form, setFormState,
    setInnerSelector, outerSelector, setOuterSelector, filtersLoading,
    innerSelector }: FilterFormProps) => {
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()
    const { getDecimalSeparator } = useValue()
    const workOrdersList = useSelector((state: StoreState) => state.workOrders.data)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const properties = useSelector((state: StoreState) => state.displayNames.data?.properties)
    const propertiesLabels = useMemo(() => {
        return Object.entries(properties || {}).reduce((acc: any, [key, value]: any) => ({
            ...acc, [key]: value?.category
        }), {})
    }, [properties])
    const teams = useSelector(
        (state: StoreState) => state.teams.data,
    );

    const allMethods = useSelector(
        (state: StoreState) => state.repository.allMethods.data
    );

    const { getValue: getLocalValue } = useValue();

    const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);

    const ingredients = useSelector((state: StoreState) => state.displayNames.data?.ingredients)
    const ingredientAndPropertyCategories = useSelector((state: StoreState) => state.repository.allCategories.data);
    const ingredientAndPropertyCategoriesStatus = useSelector((state: StoreState) => state.repository.allCategories.status);


    const ingredientsLabels = useMemo(() => {
        return Object.entries(ingredients || {}).reduce((acc: any, [key, value]: any) => ({
            ...acc, [key]: value?.category === null ? "uncategorized" : value?.category
        }), {})
    }, [ingredients])

    const processing = useSelector((state: StoreState) => state.displayNames.data?.processing)
    const processingLabels = useMemo(() => {
        return Object.entries(processing || {}).reduce((acc: any, [key, value]: any) => ({
            ...acc, [key]: value?.category
        }), {})
    }, [processing])
    const processingProfiles = useSelector((state: StoreState) => state.workOrderDetails.processingProfilesList)

    const characterizations = useSelector((state: StoreState) => state.displayNames.data?.characterizations)
    const characterizationLabels = useMemo(() => {
        return Object.entries(characterizations || {}).reduce((acc: any, [key, value]: any) => ({
            ...acc, [key]: value?.category
        }), {})
    }, [characterizations])
    const methodList = useSelector((state: StoreState) => state.characterizationMethods.methodList)
    const configs = useSelector((state: StoreState) => state.configs.features)
    const sortedMethodList = useMemo(() => methodList.sort((a, b) => a.name?.localeCompare(b.name)), [methodList])

    const getCategoryNamefromId = useCallback((categoryId: string, type: "ingredient_category" | "property_category" = "ingredient_category") => {
        return ingredientAndPropertyCategories[type]?.find((category: any) => category.category_id === categoryId)?.name ?? categoryId
    }, [ingredientAndPropertyCategories])

    const getDropdownOptions = useCallback((type: string, parameter: string = "") => {
        if (type === "work_order_id") {
            return workOrdersList?.filter((res: any) => selectedProjects.includes(res?.project_id)).map((res: any, index: number) => ({
                label: res?.work_order_name,
                value: res?.work_order_id,
                key: index
            }))
        }
        else if (type === "stage") {
            return [...new Set(workOrdersList.flatMap((wo: any) => wo.stages
                ?.map((res: any, index: number) => index)))].map((res: any, index: number) => ({ value: index, label: `Stage ${index + 1}`, key: index }))
        }
        else if (type === "characterizations") {
            return displayNames?.characterizations?.[parameter]?.options?.map((value: any, index: number) => ({ label: value, value, key: index }))
        }
        else if (["application", "grade", "order_type"]?.includes(type)) {
            return displayNames?.[type]?.map((res: any, index: number) => ({ label: res, value: res, key: index }))
        } else if (type === "lab_locations") {
            return labsLocationList.map((location: any, index: number) => ({
                label: location?.name,
                value: location.lab_location_id,
                key: location.lab_location_id,
            }),
            );
        } else if (type === "user_id") {
            return teams?.filter(({ user_id }) => !formState?.outer_conditions?.flatMap((data: any) => data?.inner_conditions)?.filter((data: any) => data?.parameter?.[0] === 'user_id')?.flatMap(({ val }: any) => val)?.includes(user_id))?.map(({ user_name, user_id }: any, index: number) => ({
                label: user_name,
                value: user_id,
                key: index,
            }))
        } else {
            return Object.entries(displayNames?.[type] || {})?.map(([key, value]: any, index: number) => ({ label: value?.name, value: key, key: index }))
        }
    }, [displayNames, formState?.outer_conditions, labsLocationList, selectedProjects, teams, workOrdersList])

    function filter(inputValue: string, path: any) {
        return path.some(
            (option: any) => typeof option?.label === "string" ?
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 :
                option.label?.props?.children?.props?.children?.[1]?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )
    }

    const onChangeOperator = (outerField: any, field: any) => {
        const { outer_conditions } = form.getFieldsValue()
        const { inner_conditions } = outer_conditions[outerField.name]
        inner_conditions[field.name].val = null
        inner_conditions[field.name].max = null
        form.setFieldsValue({ outer_conditions })
    }

    const getPropertyLabel = (label: any[], selectedOptions: any) => {
        if (!selectedOptions) return;

        let keys = Array.isArray(label[1]) ? label[1] : [label[1]];
        let subKeys = Array.isArray(label[2]) ? label[2] : [label[2]];
        const returnValue: any[] = [];

        keys.forEach((key, idx) => {
            const labelValue = [label[0]];
            labelValue.push(key);

            // Find subkey label
            const subKey = subKeys[idx];
            const subKeylabel = selectedOptions?.[0]?.children?.find((c: any) => c?.value === key || c?.label === key)?.children?.find((c: any) => c?.value === subKey || c?.label === subKey)?.label || "";
            labelValue.push(subKeylabel);

            returnValue.push(labelValue.join("/"));
        })

        return returnValue.join(" , ")
    }

    const getOpertors = (outerField: any, field: any) => {
        const type = formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0]
        const parameter = formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[2]
        if ((["material", "application", "grade", "order_type"].includes(type)) ||
            (["characterization_method_parameters", "characterizations"].includes(type) && !!displayNames?.characterizations?.[parameter]?.options?.length)) {
            return (
                <Select
                    onChange={() => onChangeOperator(outerField, field)}
                    style={{ width: "100%" }}
                    placeholder={t("formulations.placeholder.operator")}
                >
                    <Select.Option value="eq">{"="}</Select.Option>
                </Select>
            )
        } else if (["work_order_id", "stage", "user_id", "lab_locations"].includes(type)) {
            return (
                <Select
                    onChange={() => onChangeOperator(outerField, field)}
                    style={{ width: "100%" }}
                    placeholder={t("formulations.placeholder.operator")}
                >
                    <Select.Option value="in">{type === "user_id" ? t("common.createdBy") : t("common.in")}</Select.Option>
                </Select>
            )
        } else if (["characterization_method", "processing_profile"].includes(type)) {
            return (
                <Select
                    onChange={() => onChangeOperator(outerField, field)}
                    style={{ width: "100%" }}
                    placeholder={t("formulations.placeholder.operator")}
                >
                    <Select.Option value="exists">{t("common.exists")}</Select.Option>
                </Select>
            )
        } else
            return (
                <Select
                    onChange={() => onChangeOperator(outerField, field)}
                    style={{ width: "100%" }}
                    placeholder={t("formulations.placeholder.operator")}
                >
                    <Select.Option value="eq">{"="}</Select.Option>
                    <Select.Option value="lt">{"<"}</Select.Option>
                    <Select.Option value="gt">{">"}</Select.Option>
                    <Select.Option value="range">{t("common.range")}</Select.Option>
                    <Select.Option value="exists">{t("common.exists")}</Select.Option>
                </Select>
            )
    }

    const getComponentFromOpertors = (outerField: any, field: any) => {
        const type = formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.operator
        const parameterType = formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0]
        const parameter = formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[2]
        if (type === "range") {
            return (
                <>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t('common.required'),
                                transform: (val: any) => val?.trim()
                            }
                        ]}
                        {...field}
                        name={[field.name, 'val']}
                        fieldKey={[field.key, 'val']}
                        required tooltip={requiredFieldStar}
                    >
                        <InputNumber
                            decimalSeparator={getDecimalSeparator()}
                            style={{ width: "100%" }}
                            placeholder={t('common.minimum')}
                            onBlur={(e) => {
                                const value = Number(e.target.value)
                                if (
                                    value &&
                                    formState?.outer_conditions?.[outerField?.name]
                                        ?.inner_conditions?.[field.name].max &&
                                    value >=
                                    formState?.outer_conditions?.[outerField?.name]
                                        ?.inner_conditions?.[field.name].max
                                ) {
                                    message.warning(t('aiEngine.minShouldBeLessThanMax'))
                                    const { outer_conditions } = form.getFieldsValue()
                                    outer_conditions[outerField.name].inner_conditions[
                                        field.name
                                    ].val = null
                                    form.setFieldsValue({ outer_conditions })
                                }
                            }}
                        />
                    </Form.Item>
                    <Text>{'~'}</Text>
                    <Form.Item
                        {...field}
                        rules={[
                            {
                                required: true,
                                message: t('common.required'),
                                transform: (val: any) => val?.trim()
                            }
                        ]}
                        name={[field.name, 'max']}
                        fieldKey={[field.key, 'max']}
                        required tooltip={requiredFieldStar}
                    >
                        <InputNumber
                            decimalSeparator={getDecimalSeparator()}
                            style={{ width: "100%" }}
                            placeholder={t('formulations.placeholder.maximum')}
                            onBlur={(e) => {
                                const value = Number(e.target.value)
                                if (
                                    value &&
                                    value <=
                                    formState?.outer_conditions?.[outerField?.name]
                                        ?.inner_conditions?.[field.name].val
                                ) {
                                    message.warning(t('aiEngine.maxShouldBeGreaterThanMin'))
                                    const { outer_conditions } = form.getFieldsValue()
                                    outer_conditions[outerField.name].inner_conditions[
                                        field.name
                                    ].max = null
                                    form.setFieldsValue({ outer_conditions })
                                }
                            }}
                        />
                    </Form.Item>
                </>
            )
        } else if (type === "exists") {
            return (
                <Form.Item
                    rules={[{ required: true, message: t("common.required") }]}
                    {...field}
                    name={[field.name, "val"]}
                    fieldKey={[field.key, "val"]}
                    required tooltip={requiredFieldStar}
                >
                    <Select style={{ width: "100%" }} placeholder={t("formulations.placeholder.value")}>
                        <Option value={true}>{t("common.true")}</Option>
                        <Option value={false}>{t("common.false")}</Option>
                    </Select>
                </Form.Item>
            )
        }
        else if (["gt", "lt"].includes(type)) {
            return (
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: t("common.required"),
                        },
                    ]}
                    {...field}
                    name={[field.name, "val"]}
                    fieldKey={[field.key, "val"]}
                    required tooltip={requiredFieldStar}
                >
                    <InputNumber
                        decimalSeparator={getDecimalSeparator()}
                        style={{ width: "100%" }}
                        placeholder={t("formulations.placeholder.value")}
                    />
                </Form.Item>
            )
        }
        else {
            if ((["characterization_method_parameters", "characterizations"].includes(parameterType) && !!displayNames?.characterizations?.[parameter]?.options?.length)) {
                return (<Form.Item
                    {...field}
                    name={[field.name, "val"]}
                    fieldKey={[field.key, "val"]}
                >
                    <Select
                        showSearch
                        filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                        style={{ width: "100%" }}
                        options={getDropdownOptions("characterizations", parameter)}
                        placeholder={t("formulations.placeholder.value")}
                    />
                </Form.Item>
                )
            }
            else {
                return (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t("common.required"),
                                transform: (val: any) => val?.trim(),
                            },
                        ]}
                        {...field}
                        name={[field.name, "val"]}
                        fieldKey={[field.key, "val"]}
                        required tooltip={requiredFieldStar}
                    >
                        <Input
                            style={{ width: "100%" }}
                            placeholder={t("formulations.placeholder.value")}
                        />
                    </Form.Item>
                )
            }
        }
    }

    const arraySorter = (inputArray: string[] = []): string[] | [] => {
        const sortedArray = inputArray?.sort((a: any, b: any) => a?.localeCompare(b))
        return sortedArray || []
    }

    const filterData = useMemo(() => {
        const responseData: any = [
            {
                value: "ingredients",
                label: t("common.ingredients"),
                children:
                    ingredientsLabels &&
                    arraySorter(Array.from(new Set(Object.values(ingredientsLabels))))?.map(
                        (label) => ({
                            label: String(getCategoryNamefromId(label, "ingredient_category") || t("formulations.type.uncategorized")),
                            value: label,
                            children: Object.entries(ingredientsLabels)
                                .filter(([key, value]) => value === label)
                                .map(([key]) => ({
                                    label: `${ingredients?.[key]?.name}(${ingredients?.[key]?.unit?.[0] || ""})`,
                                    value: key,
                                })),
                        })
                    ),
            }
        ]
        if (Boolean(configs?.processing_profile) && processingProfiles?.length) {
            responseData.push({
                value: "processing_profile",
                label: t("formulations.type.processing"),
                key: "processing_profile",
                children: processingProfiles.map((res: any) => ({
                    label: res?.name,
                    value: res?.profile_id,
                })),
            })
        }
        if (!Boolean(configs?.processing_profile)) {
            responseData.push({
                value: "processing",
                label: t("formulations.type.processing"),
                children:
                    processingLabels &&
                    arraySorter(Array.from(new Set(Object.values(processingLabels)))).map((label) => ({
                        label: String(label || t("formulations.type.uncategorized")),
                        value: label,
                        children: Object.entries(processingLabels)
                            .filter(([key, value]) => value === label)
                            .map(([key]) => ({
                                label: `${processing?.[key]?.name}(${processing?.[key]?.unit || ""})`,
                                value: key,
                            })),
                    })),
            },)
        }
        responseData.push({
            value: "characterizations",
            label: t("common.characterizations"),
            key: "characterizations",
            children:
                characterizationLabels &&
                arraySorter(Array.from(new Set(Object.values(characterizationLabels)))).map(
                    (label) => ({
                        label: String(label || t("formulations.type.uncategorized")),
                        value: label,
                        children: Object.entries(characterizationLabels)
                            .filter(([key, value]) => value === label)
                            .map(([key]) => ({
                                label: `${characterizations?.[key]?.name}(${characterizations?.[key]?.unit || ""
                                    })`,
                                value: key,
                            })),
                    })
                ),
        })
        if (Boolean(configs?.characterization_methods) && !!sortedMethodList?.length) {
            responseData.push({
                value: "characterization_method",
                label: "Methods",
                key: "characterization_method",
                children: sortedMethodList.map((res: any) => ({
                    label: res?.name,
                    value: res?.variations?.[0]?.variation_id,
                    // children: res?.variations.map((val: any) => ({
                    //     label: val?.name,
                    //     value: val?.variation_id
                    // }))
                }))
            })
            responseData.push({
                value: "characterization_method_parameters",
                label: "Characterization Parameters in Methods",
                key: "characterization_method_parameters",
                children: sortedMethodList.map((res: any) => ({
                    label: res?.name,
                    value: res?.variations?.[0]?.variation_id,
                    // children: res?.variations.map((val: any) => ({
                    //     label: val?.name,
                    //     value: val?.variation_id,
                    children: Object.entries(displayNames?.characterizations || {}).map(([key, value]: any) => ({
                        label: value?.name,
                        value: key
                    }))
                }))
                // }))
            })
            responseData.push({
                value: "properties",
                label: t("formulations.type.properties"),
                children: arraySorter(Array.from(new Set(Object.values(propertiesLabels)))).map((label) => ({
                    label: String(getCategoryNamefromId(label, "property_category") || t("formulations.type.uncategorized")),
                    value: label,
                    children: Object.entries(propertiesLabels)
                        .filter(([key, value]) => value === label)
                        .map(([key]) => ({
                            label: `${properties?.[key]?.name}(${properties?.[key]?.unit || ""})`,
                            value: key,
                        })),
                })),
            })
            responseData.push({
                value: "characterization_method_properties",
                label: "Properties Parameters in Methods",
                key: "characterization_method_properties",
                children: sortedMethodList.map((res: any) => ({
                    label: res?.name,
                    value: res?.variations?.[0]?.variation_id,
                    // children: res?.variations.map((val: any) => ({
                    //     label: val?.name,
                    //     value: val?.variation_id,
                    children: res.variations?.[0]?.properties?.sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter))?.map(({ parameter }: any) => ({
                        label: displayNames.properties?.[parameter]?.name ?? parameter,
                        value: parameter
                    }))
                }))
                // }))
            })
        }
        if (!Boolean(configs?.characterization_methods)) {
            responseData.push({
                value: "properties",
                label: t("formulations.type.properties"),
                children: arraySorter(Array.from(new Set(Object.values(propertiesLabels)))).map((label) => ({
                    label: String(label),
                    value: label,
                    children: Object.entries(propertiesLabels)
                        .filter(([key, value]) => value === label)
                        .map(([key]) => {
                            const displayNamesProperty = properties?.[key];
                            const method = allMethods?.find(
                            (method) => method.method_id === displayNamesProperty?.method_id
                            );
                            return {
                            label: !!method
                                ? InventoryUtils.getNameWithParameters(
                                    displayNamesProperty?.name,
                                    method.parameters,
                                    getLocalValue
                                )
                                : `${displayNamesProperty?.name}(${
                                    displayNamesProperty?.unit || ""
                                })`,
                            value: key,
                            };
                        }),
                })),
            })
        }
        responseData.push({
            value: "work_order_id",
            label: t("common.workOrders"),
            key: "Work Orders",
        })
        responseData.push({
            value: "stage",
            label: "Stages",
            key: "stage",
        })
        responseData.push({
            value: "user_id",
            label: t("common.users"),
            key: "users",
        });
        if (!!Object.keys(displayNames?.material || {}).length) {
            responseData.push({
                value: "material",
                label: t("common.material"),
                key: "material",
            })
        }
        if (!!Object.keys(displayNames?.application || {}).length) {
            responseData.push({
                value: "application",
                label: t("common.application"),
                key: "application",
            })
        }
        if (!!Object.keys(displayNames?.order_type || {}).length) {
            responseData.push({
                value: "order_type",
                label: t("workOrderDetails.orderType"),
                key: "order_type",
            })
        }
        if (!!Object.keys(displayNames?.grade || {}).length) {
            responseData.push({
                value: "grade",
                label: t("common.grade"),
                key: "grade",
            })
        }
        // if (!!labsLocationList.length) {
        //     responseData.push({
        //         value: "lab_locations",
        //         label: "Lab Locations",
        //         key: "lab_locations",
        //     });
        // }
        return responseData
    }, [displayNames, t, ingredients, ingredientsLabels, processing, processingLabels, properties, propertiesLabels, characterizations, characterizationLabels, sortedMethodList, processingProfiles, configs, getCategoryNamefromId, allMethods, getLocalValue])

    const tagRender = (props: CustomTagProps, tagType: string) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={value}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                className='form-filter-tag-renderer'
            >
                {tagType === "user_id" ? teams.find(({ user_id }: any) => user_id === label).user_name : label}
            </Tag>
        );
    };

    return (
        <Form
            name="form_filters"
            onFinish={applyFilters}
            form={form}
            size="middle"
            onFieldsChange={(e: any, t: any) => {
                return setFormState(form.getFieldsValue())
            }
            }
            requiredMark={false}
        >
            <Form.List name="outer_conditions">
                {(outerFields, { add: outerAdd, remove: outerRemove }) => {
                    if (!outerFields.length) {
                        setInnerSelector((state) => [...state, ""])
                        outerAdd()
                    }
                    return (
                        <Space
                            size="middle"
                            direction="vertical"
                            style={{ width: "100%" }}
                            className='filter_form_gap'
                        >
                            {outerFields.map((outerField) => (
                                <Row align="middle" justify={"start"} gutter={16} style={{ gap: "8px" }}>
                                    <Col>
                                        {outerField.name === 0 && outerFields.length > 1 && (
                                            <Space style={{ width: 100, paddingLeft: "15px" }}>
                                                {" "}
                                                <Text strong>{t("common.where")}</Text>{" "}
                                            </Space>
                                        )}
                                        {outerField.name > 0 && (
                                            <Select
                                                value={outerSelector || null}
                                                placeholder={`${toTitleCase(
                                                    t("common.and")
                                                )}/${toTitleCase(t("common.or"))}`}
                                                style={{ width: 100, paddingLeft: "11px" }}
                                                onChange={setOuterSelector}
                                            >
                                                <Option value="and" key="and">
                                                    {toTitleCase(t("common.and"))}
                                                </Option>
                                                <Option value="or" key="or">
                                                    {toTitleCase(t("common.or"))}
                                                </Option>
                                            </Select>
                                        )}
                                    </Col>
                                    <Col style={{ flexGrow: 1 }}>
                                        <StyledCard
                                            key={outerField.name}
                                            style={{ width: "100%", overflowX: "auto" }}
                                        >
                                            <Form.List
                                                name={[outerField.name, "inner_conditions"]}
                                            >
                                                {(fields, { add, remove }) => {
                                                    if (!fields.length) {
                                                        setTimeout(() => add(), 50)
                                                    }
                                                    return (
                                                        <Space
                                                            size="small"
                                                            direction="vertical"
                                                            style={{ width: "100%" }}
                                                        >
                                                            {outerFields.length > 1 && (
                                                                <StyledButton
                                                                    size="middle"
                                                                    icon={<CloseCircleFilled />}
                                                                    style={{
                                                                        color: "red",
                                                                        float: "right",
                                                                    }}
                                                                    type="link"
                                                                    onClick={() => {
                                                                        setInnerSelector((state) =>
                                                                            state.filter(
                                                                                (o, idx) =>
                                                                                    idx !== outerField.name
                                                                            )
                                                                        )
                                                                        outerRemove(outerField.name)
                                                                    }}
                                                                ></StyledButton>
                                                            )}
                                                            {fields.map((field) => (
                                                                <Space key={field.key} align="baseline" direction='vertical' style={{ width: "100%" }} styles={{ item: { width: "100%" } }}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, "selector"]}
                                                                        fieldKey={[field.key, "selector"]}
                                                                    >
                                                                        {field.name === 0 &&
                                                                            fields.length > 1 && (
                                                                                <Space style={{ width: 100 }}>
                                                                                    {" "}
                                                                                    <Text strong>
                                                                                        {t("common.where")}
                                                                                    </Text>{" "}
                                                                                </Space>
                                                                            )}
                                                                        {field.name > 0 && (
                                                                            <Select
                                                                                value={
                                                                                    innerSelector?.[
                                                                                    outerField.key
                                                                                    ] || null
                                                                                }
                                                                                placeholder={`${toTitleCase(t(
                                                                                    "common.and"
                                                                                ))}/${toTitleCase(t("common.or"))}`}
                                                                                style={{ width: 100 }}
                                                                                onChange={(e) =>
                                                                                    setInnerSelector(
                                                                                        (state: string[]) => {
                                                                                            state[outerField.key] = e
                                                                                            return [...state]
                                                                                        }
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Option value="and" key="and">
                                                                                    {toTitleCase(t("common.and"))}
                                                                                </Option>
                                                                                <Option value="or" key="or">
                                                                                    {toTitleCase(t("common.or"))}
                                                                                </Option>
                                                                            </Select>
                                                                        )}
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...field}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t("common.required"),
                                                                                transform: (val: any) => val?.trim(),
                                                                            },
                                                                        ]}
                                                                        name={[field.name, "parameter"]}
                                                                        fieldKey={[field.key, "parameter"]}
                                                                        required tooltip={requiredFieldStar}
                                                                    >
                                                                        <Cascader
                                                                            options={filterData}
                                                                            onChange={(e: any) => {
                                                                                const { outer_conditions } = form.getFieldsValue()
                                                                                const { inner_conditions } = outer_conditions[outerField.name]
                                                                                let operatorValue = null
                                                                                if (["work_order_id", "stage", "user_id"].includes(e?.[0]))
                                                                                    operatorValue = "in"
                                                                                else if (
                                                                                    (["material", "application", "grade", "order_type",].includes(e?.[0]) || (["characterization_method_parameters", "characterizations"].includes(e?.[0]) && !!displayNames?.characterizations_options?.data?.[e?.[2]]?.length))
                                                                                ) {
                                                                                    operatorValue = "eq"
                                                                                } else if (["characterization_method", "processing_profile"].includes(e?.[0])) {
                                                                                    operatorValue = "exists"
                                                                                }
                                                                                inner_conditions[field.name] = {
                                                                                    parameter: e,
                                                                                    operator: operatorValue,
                                                                                    val: ["work_order_id", "stage", "user_id"].includes(e?.[0]) ? [] : null,
                                                                                    max: null,
                                                                                }
                                                                                form.setFieldsValue({ outer_conditions })
                                                                            }}
                                                                            displayRender={(label, selectedOptions) => {
                                                                                if (label[0] === "Properties") {
                                                                                    return getPropertyLabel(label, selectedOptions);
                                                                                }
                                                                                return label.join("/")
                                                                            }}
                                                                            placeholder={t("formulations.placeholder.selectOrType")}
                                                                            showSearch={{ filter }}
                                                                            style={{
                                                                                width: "100%",
                                                                                background: "none",
                                                                            }}
                                                                            dropdownStyle={{ maxWidth: "100%" }}
                                                                            dropdownRender={(menu) => {
                                                                                return (
                                                                                    <Spin spinning={ingredientAndPropertyCategoriesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                                                                                        {menu}
                                                                                    </Spin>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                    <Row gutter={24} style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                                                                        <Col span={8} style={{ paddingLeft: 0 }}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: t("common.required"),
                                                                                        transform: (val: any) =>
                                                                                            val?.trim(),
                                                                                    },
                                                                                ]}
                                                                                name={[field.name, "operator"]}
                                                                                fieldKey={[field.key, "operator"]}
                                                                                required tooltip={requiredFieldStar}
                                                                            >
                                                                                {getOpertors(outerField, field)}
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={16} style={{ paddingRight: 0 }}>
                                                                            {[
                                                                                "ingredients",
                                                                                "processing",
                                                                                "characterizations",
                                                                                "properties",
                                                                                "characterization_method",
                                                                                "characterization_method_parameters",
                                                                                "characterization_method_properties",
                                                                                "processing_profile"
                                                                            ].includes(
                                                                                formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0]
                                                                            ) ? (getComponentFromOpertors(outerField, field)) : (
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: t("common.required"),
                                                                                        },
                                                                                    ]}
                                                                                    {...field}
                                                                                    name={[field.name, "val"]}
                                                                                    fieldKey={[field.key, "val"]}
                                                                                    required tooltip={requiredFieldStar}
                                                                                >
                                                                                    {["work_order_id", "stage", "user_id", "lab_locations"].includes(formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0]) ? (
                                                                                        <Select
                                                                                            showSearch
                                                                                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                                                                                            tagRender={(props: CustomTagProps) => tagRender(props, formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0])}
                                                                                            style={{ width: "100%" }}
                                                                                            mode="multiple"
                                                                                            options={getDropdownOptions(formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0])}
                                                                                            placeholder={formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0] === "user_id" ? t("common.users") : t("formulations.placeholder.value")}
                                                                                        />
                                                                                    ) : (
                                                                                        <Select
                                                                                            showSearch
                                                                                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                                                                                            style={{ width: "100%" }}
                                                                                            options={getDropdownOptions(formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter?.[0]
                                                                                            )}
                                                                                            placeholder={t("formulations.placeholder.value")}
                                                                                        />
                                                                                    )
                                                                                    }
                                                                                </Form.Item >
                                                                            )}
                                                                        </Col >
                                                                    </Row >
                                                                    {
                                                                        fields.length > 1 && (
                                                                            <StyledButton
                                                                                icon={<DeleteFilled />}
                                                                                style={{ color: "black" }}
                                                                                type="link"
                                                                                onClick={() => {
                                                                                    remove(field.name)
                                                                                }}
                                                                            ></StyledButton>
                                                                        )
                                                                    }
                                                                </Space >
                                                            ))}
                                                            <StyledButton
                                                                size="middle"
                                                                onClick={() => {
                                                                    add()
                                                                }}
                                                                type="text"
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {t("common.addACondition")}
                                                            </StyledButton>
                                                        </Space >
                                                    )
                                                }}
                                            </Form.List >
                                        </StyledCard >
                                    </Col >
                                </Row >
                            ))}
                            <Row justify="space-between" style={{ marginTop: 20 }}>
                                <StyledButton
                                    size="middle"
                                    onClick={() => {
                                        setInnerSelector((state) => [...state, ""])
                                        outerAdd()
                                    }}
                                    type="text"
                                    icon={<PlusOutlined />}
                                >
                                    {" "}
                                    {t("common.addAConditionGroup")}
                                </StyledButton>
                                <Space size={"middle"}>
                                    <StyledButton
                                        size="middle"
                                        disabled={!innerSelector.length}
                                        onClick={clearFilters}
                                        type="text"
                                    >
                                        {t("common.clearFilter")}
                                    </StyledButton>
                                    <StyledButton
                                        size="middle"
                                        disabled={!innerSelector.length}
                                        loading={filtersLoading}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t("common.applyFilter")}
                                    </StyledButton>
                                </Space>
                            </Row>
                        </Space >
                    )
                }}
            </Form.List >
        </Form >
    )
}
