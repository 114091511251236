import { Col, Collapse, DatePicker, Form, Input, message, Row, Select, Space, Spin } from "antd"
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image, pdf } from '@react-pdf/renderer';
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchExperimentCleanup, fetchExperimentRequest, fetchLinkedExperimentCleanup, fetchLinkedExperimentRequest } from "src/store/actions/experiment";
import { antdTheme, AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { fetchFileListRequest } from "src/store/actions/workOrderDetails";
import { StyledButton } from "src/styled_components/StyledButton";
import { getDropdownFilteredValue } from "src/utils/decorator";
import { reportUploadRequest } from "src/store/actions/compareFormulations";
import { CharacterizationsPlots } from "../Datasheet/Pluss/CharacterizationsPlots";
dayjs.extend(isSameOrAfter)

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf' }
    ]
})

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        margin: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 20,
    },
    header: {
        textAlign: "center",
        marginTop: 40,
    },
    section: {
        paddingRight: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 20,
    },
    index: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
        marginRight: 5
    },
    title: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSizeHeading4,
    },
    subtitle: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
    },
    trialName: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
        color: "grey"
    },
    text: {
        fontFamily: 'Open Sans',
        fontWeight: "thin",
        fontSize: antdTheme.fontSize,
        marginLeft: 10
    },
    image: { position: 'absolute', top: 2, right: 16, height: 100, width: 100 },
    imageContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: 15,
        marginTop: 15,
        marginBottom: 5
    },
    imageView: {
        maxWidth: 400,
        maxHeight: "800px",
        borderStyle: "solid",
        borderWidth: 1,
    },
    tableContainer: { paddingRight: 15, marginTop: 15 },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#F5F5F5"
    },
    tableHeaderRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#C0C0C0"
    },
    tableCol: {
        width: "16%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: antdTheme.fontSizeSM
    },
    tableHeader: {
        margin: "auto",
        marginTop: 5,
        fontSize: antdTheme.fontSizeSM,
        fontFamily: 'Open Sans',
        fontWeight: "extrabold",
        color: "white"
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        right: 30,
        fontSize: 10,
        marginTop: 20,
        marginLeft: 20
    },
})


export const PolymersNote = () => {
    const dispatch = useDispatch()

    const { data: workOrdersList, status: workOrdersStatus } = useSelector((state: StoreState) => state.workOrders)
    const { linkedExperiment: experimentsList, linkedExperimentStatus: experimentListStatus,
        experiment: experimentDetials, experimentStatus, fileList, plotsDataStatus } = useSelector((state: StoreState) => state.workOrderDetails)
    const reportUploadStatus = useSelector((state: StoreState) => state.compareFormulations.uploadStatus)
    const logo = useSelector((state: StoreState) => state.login.loginResponse.logo)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const [formState, setFormState] = useState<any>({})
    const [workOrder, setWorkOrder] = useState<any>({})
    const [generate, setGenerate] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [graphImage, setGraphImage] = useState<any>("")
    const [caption, setCaption] = useState<any>("")
    const [form] = Form.useForm()
    const experimentsBreakDown: any = useMemo(() => {
        let result = []
        for (let i = 0; i < experimentDetials.length; i += 6) {
            result.push(experimentDetials.slice(i, i + 6))
        }
        return result
    }, [experimentDetials])

    const getTableColumnStyle: any = (expList: any) => ({
        width: `${100 / (expList?.length + 1)}%`,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    })

    useEffect(() => {
        return () => {
            dispatch(fetchExperimentCleanup())
            dispatch(fetchLinkedExperimentCleanup())
        }
    }, [dispatch])

    const onWorkOrderChange = (e: string) => {
        const currentWo = workOrdersList.find((res: any) => res?.work_order_id === e)
        form.setFieldsValue({
            experiment: [],
            background: currentWo?.work_order_desc,
            trial_date: dayjs(currentWo?.created)
        })
        setFormState((prevState: any) => ({
            ...prevState,
            background: currentWo?.work_order_desc,
            trial_date: currentWo?.created
        }))
        dispatch(fetchLinkedExperimentRequest({ experiment_id: currentWo?.experiment_id, linked_stages: true }))
        dispatch(fetchFileListRequest({ work_order_id: currentWo?.work_order_id }))
        setWorkOrder(currentWo)
    }

    const onExperimentChange = (e: string) => {
        dispatch(fetchExperimentRequest({ formulation_id: e }))
        // dispatch(plotsDataRequest({ formulation_id: e }))
        // const currentExps = experimentsList.filter((res: any) => e.includes(res?.formulation_id))
        const currentFiles = fileList.filter((res: any) => e.includes(res?.formulation_id))
        form.setFieldsValue({
            // trial_no: currentExps.map((res: any) => res?.formulation_display_id).join(", "),
            file_reference: currentFiles?.map((res: any) => res?.filename).join(", "),
        })
        setFormState((prevState: any) => ({
            ...prevState,
            // trial_no: currentExps.map((res: any) => res?.formulation_display_id).join(", "),
            file_reference: currentFiles?.map((res: any) => res?.filename).join(", "),
        }))
    }

    const renderValue = (key: string, value: any) => {
        if (key === "trial_date") {
            return new Date(value).toLocaleDateString()
        }
        else if (key === "work_order") {
            return workOrdersList.find((res: any) => res?.work_order_id === workOrder?.work_order_id)?.work_order_name
        }
        return value
    }
    console.log({ experimentDetials, experimentsBreakDown })

    const getTableComponent = (expList: any, parameter: any) => {
        const columnStyle = getTableColumnStyle(expList)
        return expList.some((exp: any) => !!Object.entries(exp?.[parameter]?.[0]?.[parameter] || {}).filter(([key, value]: any) => value?.value)?.length) ?
            <>
                <Text style={styles.subtitle}>{`${(String(parameter)).split("_").map((res: string) => res?.[0]?.toUpperCase() + res?.slice(1)).join(" ")}`}</Text>
                <View style={styles.table}>
                    <View style={styles.tableHeaderRow}>
                        <View style={columnStyle}>
                            <Text style={styles.tableHeader}>{"Parameter"}</Text>
                        </View>
                        {expList.map((exp: any) => (
                            <View style={columnStyle}>
                                <Text style={styles.tableHeader}>{exp?.meta?.display_id}</Text>
                            </View>
                        ))}
                    </View>
                    {Object.keys(expList?.[0]?.[parameter]?.[0]?.[parameter] || {})
                        .map((key: any) => (
                            <View style={styles.tableRow}>
                                <View style={columnStyle}>
                                    <Text style={styles.tableCell}>{displayNames?.[parameter]?.[key]?.name || key}</Text>
                                </View>
                                {expList.map((exp: any) => (
                                    <View style={columnStyle}>
                                        <Text style={styles.tableCell}>{exp?.[parameter]?.[0]?.[parameter]?.[key]?.value}</Text>
                                    </View>
                                ))}
                            </View>
                        ))}
                </View>
            </>
            : null
    }

    const pdfDocument = (
        <Document title={"TRIAL R&D DEVELOPMENT / WORK REPORT"} >
            <Page size="A4" style={styles.page} orientation="portrait" key={'page_'} wrap>
                <View style={styles.image} fixed>
                    <Image fixed={true} cache src={logo + "?not-from-cache-please"} />
                </View>
                <View style={styles.header}>
                    <Text style={styles.title}>{formState?.title}</Text>
                </View>
                {Object.entries(formState || {}).filter(([key, value]: any) => !["experiment", "work_order", "title"].includes(key) && !!value)
                    .map(([key, value]: any, index: number) => (
                        <View style={styles.section} >
                            <Text style={styles.subtitle}>{`${(String(key)).split("_").map((res: string) => res?.[0]?.toUpperCase() + res?.slice(1)).join(" ")}:`}</Text>
                            <Text style={styles.text}>{renderValue(key, value)}</Text>
                        </View>
                    ))}
                {!!experimentDetials?.length &&
                    <View style={styles.section} >
                        <Text style={styles.subtitle}>{"Experimental Procedure:"}</Text>
                    </View>
                }
                {experimentsBreakDown.map((expList: any) => {
                    const columnStyle = getTableColumnStyle(expList)
                    return (
                        <View style={styles.tableContainer}>
                            <Text style={styles.subtitle}>{"Formulation"}</Text>
                            <View style={styles.table}>
                                <View style={styles.tableHeaderRow}>
                                    <View style={columnStyle}>
                                        <Text style={styles.tableHeader}>{""}</Text>
                                    </View>
                                    {expList.map((exp: any) => (
                                        <View style={columnStyle}>
                                            <Text style={styles.tableHeader}>{(Object.values(exp?.ingredients || {})?.[0] as any)?.unit}</Text>
                                        </View>
                                    ))}
                                </View>
                                <View style={styles.tableHeaderRow}>
                                    <View style={columnStyle}>
                                        <Text style={styles.tableHeader}>{"Parameter"}</Text>
                                    </View>
                                    {expList.map((exp: any) => (
                                        <View style={columnStyle}>
                                            <Text style={styles.tableHeader}>{exp?.meta?.display_id}</Text>
                                        </View>
                                    ))}
                                </View>
                                {Object.keys(expList?.[0]?.ingredients || {})
                                    .map((ingredient: any) => (
                                        <View style={styles.tableRow}>
                                            <View style={columnStyle}>
                                                <Text style={styles.tableCell}>{displayNames?.ingredients?.[ingredient]?.name || ingredient}</Text>
                                            </View>
                                            {expList.map((exp: any) => (
                                                <View style={columnStyle}>
                                                    <Text style={styles.tableCell}>{exp?.ingredients?.[ingredient]?.value}</Text>
                                                </View>
                                            ))}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )
                })}
                {experimentsBreakDown.map((expList: any) => (
                    <View style={styles.tableContainer}>
                        {getTableComponent(expList, "processing")}
                    </View>
                ))}
                {experimentsBreakDown.map((expList: any) => (
                    <View style={styles.tableContainer}>
                        {getTableComponent(expList, "characterizations")}
                    </View>
                ))}
                {experimentsBreakDown.map((expList: any) => (
                    <View style={styles.tableContainer}>
                        {getTableComponent(expList, "properties")}
                    </View>
                ))}
                <View style={styles.section} >
                    <Text style={styles.subtitle}>{`Conclusions:`}</Text>
                    <Text style={styles.text}>{renderValue("conclusion", formState?.conclusion)}</Text>
                </View>
                <View style={styles.imageContainer}>
                    {!!graphImage &&
                        <View style={styles.imageView}>
                            <Image src={graphImage}
                                style={{
                                    maxWidth: 400,
                                }}
                            />
                        </View>
                    }
                </View>
                {!!graphImage && <Text style={styles.text}>{caption}</Text>}
                <View style={styles.footer} fixed render={({ pageNumber }) => (
                    <Text fixed
                        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                    />
                )} />
            </Page>
        </Document>
    )


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify={"space-between"}>
                <Col span={16}>
                    <Spin
                        spinning={[workOrdersStatus, experimentStatus, plotsDataStatus, experimentListStatus].includes(AsyncStates.LOADING)}
                        indicator={<LoadingOutlined />}
                    >
                        <Form name="trial_verification" layout="vertical" form={form}
                            onFieldsChange={(_, allFields) => {
                                setFormState(allFields
                                    .filter((field: any) => !["experiment"].includes(field?.name[0]))
                                    .reduce((obj: any, field: any) => ({ ...obj, [field.name[0]]: field?.value }), {})
                                )
                            }}>
                            <Form.Item label={"Title"} name={"title"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Work order"} name={"work_order"}>
                                <Select onChange={onWorkOrderChange} showSearch
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}>
                                    {workOrdersList.map((res: any) => (
                                        <Select.Option key={res?.work_order_id} value={res?.work_order_id}>
                                            {res?.work_order_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Experiment Procedure"} name={"experiment"}>
                                <Select onChange={onExperimentChange} showSearch mode="multiple"
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}>
                                    {experimentsList.map((res: any) => (
                                        <Select.Option key={res?.formulation_id} value={res?.formulation_id}>
                                            {res?.formulation_display_id}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Trial No"} name={"trial_no"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"File Reference"} name={"file_reference"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Back ground"} name={"background"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Trial Date"} name={"trial_date"}>
                                <DatePicker />
                            </Form.Item>
                            <Form.Item label={"Criteria of Success"} name={"criteria_of_success"}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                            <Form.Item label={"Conclusion"} name={"conclusion"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Collapse defaultActiveKey={["graphs"]} style={{ marginBottom: 20 }}>
                                <Collapse.Panel header={"Graph"} key="graphs">
                                    <CharacterizationsPlots from={"reports"} setGraphImage={setGraphImage} setCaption={setCaption} />
                                </Collapse.Panel>
                            </Collapse>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <Collapse onChange={(e) => setGenerate(Boolean(e?.length))}>
                <Collapse.Panel header={"Preview"} key={"preview"} extra={
                    <Space onClick={(e) => e.stopPropagation()}>
                        <Input style={{ width: 250 }} value={fileName} onChange={e => setFileName(e.target.value)} />
                        <StyledButton loading={reportUploadStatus === AsyncStates.LOADING} onClick={() => {
                            if (!fileName) {
                                message.error("Please add a file name")
                                return
                            }
                            pdf(pdfDocument).toBlob().then((report) => {
                                dispatch(reportUploadRequest({
                                    report,
                                    type: "polymers_trial_note",
                                    file_name: fileName,
                                    data: formState,
                                    from: "modularReports"
                                }))
                            })
                        }} type="primary">{"Save"}</StyledButton>
                    </Space>
                }>
                    {generate &&
                        <PDFViewer style={{ width: "100%", height: 1280 }}>
                            {pdfDocument}
                        </PDFViewer>
                    }
                </Collapse.Panel>
            </Collapse>
        </Space>
    )
}