import {
    Avatar,
    Form,
    Pagination,
    Space,
    Spin,
    Switch,
    Tooltip,
    Typography
} from "antd"
import { orange } from "@ant-design/colors";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "../../store/configureStore"
// import { Stages } from "../Stages"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import {
    deleteWorkOrderRequest,
    resetWorkOrder,
    workOrderRequest,
} from "src/store/actions/workOrderDetails"
import { AsyncStates } from "src/constants"
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { HorizontalStages } from "../HorizontalStages"
import { AllClosedWorkOrders } from "./AllClosedWorkOrders"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import CustomTable from "../CustomTable/CustomTable"
import { ActionTypes, DataTypes, makeData, randomColor } from "../CustomTable/utils"
import { useMemberName } from "src/utils/useMemberName"
import { history } from "src";
import useTranslate from "src/utils/useTranslate";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { predefinedWorkOrderStages } from "../CustomTable/header/Header";
import CustomTableFilter from "../CustomTable/CustomTableFilter";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { Text } = Typography
const initialValues = {
    close_date: dayjs(),
    work_order_desc: "Experiment for PCM for latent heat for 20Degrees",
    work_order_name: "Test-WorkOrder",
    work_order_stage: "Project Initiated",
    work_order_type: "Internal",
    work_order_seq: 1,
    work_order_id: undefined,
};

enum Layout {
    vertical,
    horizontal,
}

function reducer(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.ADD_OPTION_TO_COLUMN:
            // const optionIndex = state.columns.findIndex(
            //     (column: any) => column.id === action.columnId
            // );
            return [];
        case ActionTypes.ADD_ROW:
            return [];
        case ActionTypes.UPDATE_COLUMN_TYPE:
            const typeIndex = state.columns.findIndex(
                (column: any) => column.id === action.columnId
            );
            switch (action.dataType) {
                case DataTypes.NUMBER:
                    if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
                        return state;
                    } else {
                        return [];
                    }
                case DataTypes.SELECT:
                    if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
                        return state;
                    } else {
                        let options = [];
                        state.data.forEach((row: any) => {
                            if (row[action.columnId]) {
                                options.push({
                                    label: row[action.columnId],
                                    backgroundColor: randomColor(),
                                });
                            }
                        });
                        return [];
                    }
                case DataTypes.TEXT:
                    if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
                        return state;
                    } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
                        return [];
                    } else {
                        return [];
                    }
                default:
                    return state;
            }
        // case ActionTypes.UPDATE_COLUMN_HEADER:
        //   const index = state.columns.findIndex(
        //     (column) => column.id === action.columnId
        //   );
        //   return update(state, {
        //     skipReset: { $set: true },
        //     columns: { [index]: { label: { $set: action.label } } },
        //   });
        // case ActionTypes.UPDATE_CELL:
        //     return update(state, {
        //         skipReset: { $set: true },
        //         data: {
        //             [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        //         },
        //     });
        // case ActionTypes.ADD_COLUMN_TO_LEFT:
        //   const leftIndex = state.columns.findIndex(
        //     (column) => column.id === action.columnId
        //   );
        //   let leftId = shortId();
        //   return update(state, {
        //     skipReset: { $set: true },
        //     columns: {
        //       $splice: [
        //         [
        //           leftIndex,
        //           0,
        //           {
        //             id: leftId,
        //             label: "Column",
        //             accessor: leftId,
        //             dataType: DataTypes.TEXT,
        //             created: action.focus && true,
        //             options: [],
        //           },
        //         ],
        //       ],
        //     },
        //   });
        // case ActionTypes.ADD_COLUMN_TO_RIGHT:
        //   const rightIndex = state.columns.findIndex(
        //     (column) => column.id === action.columnId
        //   );
        //   const rightId = shortId();
        //   return update(state, {
        //     skipReset: { $set: true },
        //     columns: {
        //       $splice: [
        //         [
        //           rightIndex + 1,
        //           0,
        //           {
        //             id: rightId,
        //             label: "Column",
        //             accessor: rightId,
        //             dataType: DataTypes.TEXT,
        //             created: action.focus && true,
        //             options: [],
        //           },
        //         ],
        //       ],
        //     },
        //   });
        // case ActionTypes.DELETE_COLUMN:
        //   const deleteIndex = state.columns.findIndex(
        //     (column) => column.id === action.columnId
        //   );
        //   return update(state, {
        //     skipReset: { $set: true },
        //     columns: { $splice: [[deleteIndex, 1]] },
        //   });
        // case ActionTypes.ENABLE_RESET:
        // return update(state, { skipReset: { $set: true } });
        default:
            return state;
    }
}

export const WorkOrdersPage = React.memo(({
    tabKey,
    layout,
    setTabKey,
    projects = undefined,
    showAll, setShowAll,
    projectIds,
    setProjectIds,
    showPagination,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    woFilters,
    setWorkOrderFilters,
    tabFilter,
    setTabFilters
}: any) => {
    const [t] = useTranslate();
    const dispatch = useDispatch()
    const { push } = useHistory()
    const { data: workOrders, status, count: workOrderCount, filterOptions, filterOptionsStatus } = useSelector(
        (state: StoreState) => state.workOrders
    )
    const projectList = useSelector(
        (state: StoreState) => state.projects.projectList
    )
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteWOId, setDeleteWOId] = useState<string>("");
    const [woSearchTerm, setWoSearchTerm] = useState("");

    useEffect(() => {
        setWorkOrderData(workOrders)
    }, [workOrders])

    const [form] = Form.useForm()

    const [state, setState] = useState({
        values: initialValues,
    })
    const [tableState, dispatchTableAction] = useReducer(reducer, makeData(10));
    const stagesData = useSelector((state: StoreState) => state.displayNames.data?.stages || {})
    const { getName } = useMemberName()

    const { values } = state

    useEffect(() => {
        form.resetFields()
    }, [values, form]);

    const workOrderNameOptions = useMemo(() => {
        return filterOptions?.work_orders?.map((wo: any) => wo?.work_order_name) || []
    }, [filterOptions]);

    const workOrderDescOptions = useMemo(() => {
        return filterOptions?.work_order_desc || []
    }, [filterOptions]);

    const workOrderLabLocationOptions = useMemo(() => {
        return filterOptions?.lab_locations?.map((l: any) => l?.name) || []
    }, [filterOptions]);

    const workOrderCreatedByOptions = useMemo(() => {
        return filterOptions?.users?.map((u: any) => u?.user_id) || []
    }, [filterOptions]);

    const workOrderCreatedDateOptions = useMemo(() => {
        return filterOptions?.created_date || []
    }, [filterOptions]);

    const woColumns = useMemo(() => [
        {
            label: t('common.name'),
            accessor: 'work_order_name',
            key: 'work_order_name',
            filter: "work_order_name",
            dataType: "text",
            filterOptions: workOrderNameOptions,
            Cell: (prop: any) => {
                const rowData = prop.row.original
                return <Text style={{ width: 'max-content' }} ellipsis={{ tooltip: rowData.work_order_name }}>{rowData.work_order_name} {rowData.status === "closed" && <LockOutlined />}</Text>
            }
        },
        {
            label: t('common.description'),
            accessor: 'work_order_desc',
            key: 'work_order_desc',
            filter: "work_order_desc",
            dataType: "text",
            filterOptions: workOrderDescOptions,
            Cell: (prop: any) => {
                const rowData = prop.row.original
                return <Text style={{ width: 'max-content' }} ellipsis={{ tooltip: rowData.work_order_desc }}>{rowData.work_order_desc}</Text>
            }
        },
        {
            label: t('common.stage'),
            accessor: 'work_order_stage',
            key: 'work_order_stage',
            dataType: "text",
            filter: "work_order_stage",
            Cell: (prop: any) => {
                // const stage = prop.row.original?.work_order_stage === "closed" ? stagesData["work_order_status"] : predefinedWorkOrderStages.find(stage => stage.value === prop.row.original?.work_order_stage)?.label ? `${t(predefinedWorkOrderStages.find(stage => stage.value === prop.row.original?.work_order_stage)?.label as any)}` : stagesData[prop.row.original?.work_order_stage]
                let stageVal = prop.row.original?.work_order_stage;
                if(prop.row.original?.work_order_stage === "closed") stageVal = "work_order_status";
                const stage = predefinedWorkOrderStages.find(stage => stage.value === stageVal)?.label ? `${t(predefinedWorkOrderStages.find(stage => stage.value === stageVal)?.label as any)}` : stagesData[prop.row.original?.work_order_stage]
                return <Tooltip title={stage}><Text style={{ width: 'max-content' }} ellipsis={true}>{stage}</Text></Tooltip>
            }
        },
        {
            label: t('common.labLocation'),
            accessor: 'lab_location_display',
            key: 'lab_location_display',
            dataType: "text",
            filter: "lab_location_display",
            filterOptions: workOrderLabLocationOptions,
            Cell: (prop: any) => {
                const location = prop.row.original?.lab_location_display;
                return <Text style={{ width: 'max-content' }} ellipsis={{ tooltip: location }}>{location}</Text>
            }
        },
        {
            label: t('common.createdBy'),
            accessor: 'created_by',
            key: 'created_by',
            dataType: "text",
            filter: 'created_by',
            filterOptions: workOrderCreatedByOptions,
            Cell: (prop: any) => {
                const createdBy = prop.row.original?.created_by
                const userName = getName(createdBy);
                return <div style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
                    <Avatar style={{ background: orange[5],minHeight:24, maxHeight:24, minWidth:24, maxWidth:24 }} size="small">
                        {userName?.[0]}
                    </Avatar>
                    {/* < Avatar src={getUserProfilePic(createdBy)} alt={getName(createdBy)} /> */}
                    <Text strong ellipsis={{tooltip:getName(createdBy)}}>
                        {getName(createdBy)}
                    </Text>
                </div >
            }
        },
        {
            label: t('common.createdOn'),
            accessor: 'created',
            key: 'created',
            filter: "created",
            dataType: "text",
            filterOptions: workOrderCreatedDateOptions,
            Cell: (prop: any) => {
                const date = dayjs(prop.row.original?.created).format("YYYY-MM-DD")
                return <Text style={{ width: 'max-content' }} ellipsis={{ tooltip: date }}>{date}</Text>
            }
        },
        {
            label: t('common.project'),
            accessor: 'project',
            key: "project",
            dataType: "text",
            Cell: (prop: any) => {
                const projectId = prop.row.original?.project_id;
                const projectName = projectList.find((p: any) => p.project_id === projectId)?.name;
                return <Tooltip title={projectName}><Text style={{ width: 'max-content' }} ellipsis={true}>{projectName}</Text></Tooltip>
            },
        },
        {
            label: t('common.actions'),
            accessor: 'actions',
            key: "actions",
            Cell: (prop: any) => {
                return <div style={{ display: "flex", padding: "10px" }} onClick={(e) => {
                    e.stopPropagation();
                }
                }>
                    <StyledButton size="small" onClick={() => onDelete(prop.data[prop.row.id].work_order_id)} danger icon={<StyledDeleteIcon />}></StyledButton>
                </div >
            },
        }
    ], [getName, projectList, stagesData, t, workOrderCreatedByOptions, workOrderCreatedDateOptions, workOrderDescOptions, workOrderLabLocationOptions, workOrderNameOptions])

    function showDetails(this: any) {
        dispatch(resetWorkOrder())
        dispatch(workOrderRequest(this?.work_order_id))
        push(`/work-orders/details/${this.work_order_id}`, {
            id: this?.work_order_id,
            name: this?.work_order_name,
        })
        setState((state) => ({
            ...state,
            values: { ...this, close_date: dayjs(this.close_date) },
        }))
    }

    function handleDelete(this: any) {
        setDeleteWOId(this.work_order_id);
        setDeleteModalVisible(true);
    }

    const onDelete = (woId: string) => {
        setDeleteWOId(woId);
        setDeleteModalVisible(true);
    };

    const handleDeleteConfirm = () => {
        const payload: any = { work_order_id: deleteWOId }
        payload.fetchWoProjectIdsPayload = {
            project_ids: projectIds,
            is_table: !projects
        }
        if (showPagination) {
            payload.fetchWoProjectIdsPayload = {
                ...payload.fetchWoProjectIdsPayload,
                page_num: pageNumber,
                page_size: pageSize
            }
        }
        if (Object.keys(woFilters || {}).length > 0 || Object.keys(tabFilter || {}).length > 0) {
            payload.fetchWoProjectIdsPayload = {
                ...payload.fetchWoProjectIdsPayload,
                filters: { ...(woFilters || {}), ...(tabFilter || {}) }
            }
        }
        dispatch(deleteWorkOrderRequest(payload))
        setDeleteModalVisible(false);
    }

    const getLabLocation = (lab_location: Record<any, any>) => {
        return lab_location && Object.keys(lab_location).length > 0 ? `${lab_location.name} (${lab_location.city}, ${lab_location.country})` : null
    }

    const [workOrderData, setWorkOrderData] = useState<any>(() => workOrders.map(wo => {
        return {
            ...wo,
            lab_location_display: getLabLocation(wo.lab_location)
        }
    }))

    const [filters, setFilters] = useState<{
        title: string
        dateRange: any
        member: string
    }>({ title: "", dateRange: [], member: "" })

    useEffect(() => {
        setWorkOrderData(
            workOrders.filter((wo: any) => {
                const getByTitle = () =>
                    wo?.work_order_name
                        .toLowerCase()
                        .includes(filters.title.trim().toLowerCase())
                const getByDateRange = () => {
                    let flag = true
                    if (filters.dateRange[0] && filters.dateRange[1]) {
                        flag = dayjs(wo?.created).startOf('day').isSameOrAfter(filters.dateRange[0].startOf('day')) && dayjs(wo?.created).startOf('day').isSameOrBefore(filters.dateRange[1].startOf('day'))
                    } else if (filters.dateRange[0]) {
                        flag = dayjs(wo?.created).startOf('day').isSameOrAfter(filters.dateRange[0].startOf('day'))
                    } else if (filters.dateRange[1]) {
                        flag = dayjs(wo?.created).startOf('day').isSameOrBefore(filters.dateRange[1].startOf('day'))
                    }
                    return flag
                }
                const getByMember = () => wo?.created_by?.includes(filters.member)
                return getByTitle() && getByDateRange() && getByMember()
            }).map(wo => {
                return {
                    ...wo,
                    lab_location_display: getLabLocation(wo.lab_location)
                }
            })
        )
    }, [filters, workOrders])

    const filterByTitle = (e: any) =>
        setFilters((state) => ({ ...state, title: e.target.value }))
    const filterByDateRange = (dateRange: any) =>
        setFilters((state) => ({ ...state, dateRange: dateRange ?? [] }))
    const filterByMember = (member: string = "") =>
        setFilters((state) => ({ ...state, member }))

    const handleRowClick = useCallback((row: any) => {
        const rowData = row.original;
        dispatch(resetWorkOrder())
        dispatch(workOrderRequest(rowData?.work_order_id))
        push(`/work-orders/details/${rowData.work_order_id}`, {
            id: rowData?.work_order_id,
            name: rowData?.work_order_name,
        })
        setState((state) => ({
            ...state,
            values: { ...rowData, close_date: dayjs(rowData.close_date) },
        }))
    }, [dispatch, push])

    const handleCloseToggle = () => {
        setTabKey(tabKey === 'open' ? 'closed' : 'open');
        projects ? history.push(`?closed=${tabKey === 'closed' ? true : false}`) : history.push(`/work-orders?closed=${tabKey === 'closed' ? true : false}`);
        setShowAll(false)
        if (setTabFilters) setTabFilters({ status: tabKey === 'open' ? 'closed' : 'open' })
    }

    const handleShowAllToggle = (value: any) => {
        setShowAll(value)
        setTabKey('open')
        if (setTabFilters) {
            if (value) setTabFilters({});
            else setTabFilters({ status: "open" });
        }
    }

    const tableData = useMemo(() => {
        return showAll ? workOrderData : workOrderData.filter((res: any) => tabKey === 'closed' ? res?.status === "closed" : res?.status !== "closed")
    }, [showAll, tabKey, workOrderData])

    return (
        <Space direction="vertical" style={{ width: "100%", gap: 0, paddingTop: 16 }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 4, paddingLeft: 16 }}>
                <Text strong style={{ marginRight: "4px" }}>{t('common.showClosed')}: </Text> <Switch size="small" style={{ outline: 'none' }} checked={tabKey === 'closed'} onChange={handleCloseToggle} />
                <Text strong style={{ marginLeft: "14px", marginRight: "4px" }}>{t('common.showAll')}: </Text> <Switch size="small" style={{ outline: 'none' }} checked={showAll} onChange={handleShowAllToggle} />
            </div>
            <Spin
                indicator={<LoadingOutlined />}
                size="large"
                spinning={[status, filterOptionsStatus].includes(AsyncStates.LOADING)}
            >
                {
                    layout === Layout.horizontal ?
                        <>
                            {
                                tabKey === 'open' ?
                                    <HorizontalStages
                                        data={workOrderData}
                                        callbacks={{
                                            showDetails,
                                            handleDelete,
                                            filterByTitle,
                                            filterByDateRange,
                                            filterByMember,
                                        }}
                                    /> :
                                    <AllClosedWorkOrders workOrders={workOrders} />
                            }
                        </> :
                        <>
                            <div
                                className="custom__table__container"
                                style={{
                                    maxWidth: "100vw",
                                    // height: "80vh",
                                    padding: 10,
                                    paddingTop: 0
                                }}
                            >
                                <div className={"custom__table__filter"}>
                                    <CustomTableFilter
                                        isProjects={projects}
                                        projectIds={projectIds}
                                        setWoSearchTerm={setWoSearchTerm}
                                        setProjectIds={setProjectIds}
                                        setWorkOrderFilters={setWorkOrderFilters}
                                    />
                                </div>
                                <CustomTable
                                    columns={woColumns}
                                    noDataText={t(tabKey === 'closed' ? 'closeWO.noCloseWO' : 'common.noWorkOrdersFoundInThisProject')}
                                    data={tableData}
                                    dispatch={dispatchTableAction}
                                    skipReset={tableState.skipReset}
                                    allowEditing={false}
                                    onRow={handleRowClick}
                                    rowClassName="workOrder__table__row"
                                    headerClassName="workOrder__table__header"
                                    isProjects={projects}
                                    tabKey={tabKey}
                                    woSearchTerm={woSearchTerm}
                                    setWorkOrderFilters={setWorkOrderFilters}
                                />
                                {showPagination &&
                                    <div style={{ display: "flex", marginTop: "20px", justifyContent: "end" }}>
                                        <Pagination
                                            showSizeChanger
                                            current={pageNumber}
                                            pageSize={pageSize}
                                            onChange={(page) => setPageNumber && setPageNumber(page)}
                                            onShowSizeChange={(_, size) => setPageSize && setPageSize(size)}
                                            total={workOrderCount}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                }
            </Spin>
            <DeleteConfirmationModal
                title={t("workOrder.confirmDelete")}
                description={t("workOrder.deleteMessage")}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                onConfirm={handleDeleteConfirm}
            />
            {/* <DeleteWoModal
                workOrder={workOrder}
                deleteModalVisible={deleteModalVisible}
                setDeleteModalVisible={setDeleteModalVisible}
            /> */}
        </Space>
    )
})
