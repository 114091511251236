import { useMemo, useState } from 'react'
import { DatePicker, Form, Input, Modal, Select, Space, Tooltip, Switch, InputNumber, Typography, Divider, Row, message } from 'antd'
import { ExclamationCircleOutlined, InfoCircleFilled, PlusOutlined } from '@ant-design/icons'
import { StyledButton } from 'src/styled_components/StyledButton'
import { setIsEditing } from 'src/store/actions/isEditing'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import dayjs from 'dayjs'
import { useValue } from 'src/utils/useValue'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'
import isURL from 'validator/lib/isURL'

const { Text, Link } = Typography
const { Option } = Select

interface CustomFieldsModalFields {
    customModalVisible: boolean
    setCustomModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    customFields: any[]
    setCustomFields: React.Dispatch<React.SetStateAction<any[]>>
    setWOChanges?: React.Dispatch<React.SetStateAction<any>>
    type: "work_order" | "project" | "ingredient" | "celsure_formulations"
    version?: string
}


export const CustomFieldsModal = ({ customModalVisible, setCustomModalVisible, customFields, setCustomFields, setWOChanges, type, version }: CustomFieldsModalFields) => {
    const [fieldType, setFieldType] = useState("")
    const requiredFieldStar = useRequiredFieldStar()

    const [requiredField, setRequiredField] = useState(false)
    const [items, setItems] = useState<any>([])
    const [name, setName] = useState('')
    const [form] = Form.useForm()
    const [t] = useTranslate()
    const { getDecimalSeparator } = useValue()

    const dispatch = useDispatch()
    const isEditing = useSelector((state: StoreState) => state.isEditing)

    const getDefaultvalue = useMemo(() => {
        switch (fieldType) {
            case "text":
            case "link":
                return <Input />
            case "description":
                return <Input.TextArea rows={5} />
            case "number":
                return <InputNumber decimalSeparator={getDecimalSeparator()} />
            case "date":
                return <DatePicker />
            case "select":
                return <Select placeholder={"Select option(s)"} style={{ width: "100%" }} mode="multiple" allowClear>
                    {items?.map((res: any) => (
                        <Option value={res} key={res}>{res}</Option>
                    ))}
                </Select>
            default: return null
        }
    }, [fieldType, items, getDecimalSeparator])

    const addItem = (e: any) => {
        const trimmedName = name?.trim()
        if (!trimmedName) {
            message.error(t("inventory.Addcontentintheitem"))
            return
        }
        if (items.includes(trimmedName)) {
            message.error(`${trimmedName} is already added in options`)
            return
        }
        e.preventDefault()
        setItems([...items, trimmedName])
        setName('')
        form.setFieldsValue({ options: [...items, trimmedName] })
    }

    const closeModal = () => {
        setName("")
        setItems([])
        setRequiredField(false)
        setFieldType("")
        form.resetFields()
        setCustomModalVisible(false)
    }

    const createCustomFieldSubmit = (values: any) => {
        values.field_name = values.field_name.trim()
        values.field_type = values.field_type.trim()

        if (!!customFields.find((res: any) => res.field_name?.toLowerCase().trim() === values.field_name?.toLowerCase()?.trim())) {
            message.error(`Custom field ${values.field_name} name already exists`)
            return
        }
        const data = [...customFields, { ...values, required: requiredField }]

        const newField = { ...values, default_value: values.field_type === "date" ? dayjs(values.default_value || new Date()).format('YYYY-MM-DD') : values.default_value, required: requiredField };
        if (version === "2") {
            setCustomFields(newField);
        } else {
            setCustomFields(data)
            if (type === "work_order" && setWOChanges) {
                setWOChanges((prevState: any) => {
                    return ({ ...prevState, ...data.reduce((acc: any, res: any) => ({ ...acc, [res.field_name]: res?.value ?? res?.default_value }), {}) })
                })
            }
            message.success(t("common.AddCustomField"))
        }
        closeModal()
    }
    const validInputFields = (value: any) => {
        if (!value.trim().length) {
            return Promise.reject("Enter Valid Input")
        }
        return Promise.resolve()
    }
    const capitalizeLabel = (label: string) => {
        if (label === "number") {
            return "Value"
        }
        return label?.[0].toUpperCase() + label?.slice(1);
    }

    const handleCloseModal = () => {
        if (isEditing) {
            Modal.confirm({
                title: t("common.unsavedChangesPresent"),
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                    closeModal()
                }
            })
        } else {
            closeModal()
        }
    }


    return (
        <Modal
            visible={customModalVisible}
            width={600} maskClosable={false}
            onCancel={handleCloseModal}
            title={t("projects.customField")} footer={""}>
            <Form layout="vertical" form={form} onFinish={createCustomFieldSubmit} onFieldsChange={() => { dispatch(setIsEditing(true)) }} requiredMark={false}>
                <Form.Item
                    name="field_name"
                    label={t("common.fieldName")}
                    validateFirst
                    required tooltip={requiredFieldStar}
                    rules={[{ required: true, message: "required" }, {
                        validator: (_, value: any) => validInputFields(value),
                    }]}
                >
                    <Input placeholder={t("common.Enterthenameofthefield")} />
                </Form.Item>
                <Form.Item
                    name="field_type"
                    label={t("common.fieldType")}
                    required tooltip={requiredFieldStar}
                    rules={[{ required: true, message: "required" }]}
                >
                    <Select placeholder={t("common.Selectthefieldtype")}
                        style={{ width: "100%" }}
                        onChange={(e: string) => {
                            form.setFieldsValue({ default_value: undefined, options: [] })
                            setFieldType(e)
                        }}>
                        <Option value={"text"}>{t("common.text")}</Option>
                        <Option value={"description"}>{t("common.description")}</Option>
                        <Option value={"number"}>{t("common.number")}</Option>
                        <Option value={"select"}>{t("common.select")}</Option>
                        <Option value={"date"}>{t("common.date")}</Option>
                        <Option value={"link"}>{t("common.link")}</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Text type="secondary">{t("common.requiredField")}</Text>
                        <Switch onChange={setRequiredField} checked={requiredField} />
                    </Space>
                </Form.Item>
                {fieldType === "select" &&
                    <Form.Item name={"options"}
                        label={"Options"}
                        required tooltip={requiredFieldStar}
                        rules={[{ required: true, message: "required" }]}>
                        <Select mode="multiple" style={{ width: "100%" }}
                            placeholder={t("inventory.Selectalltheavailableoptionsforthisselecttypefield")}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Input placeholder={t("common.addAnOption")} value={name} onChange={(e) => setName(e.target.value)} minLength={1} />
                                        <Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                                            <PlusOutlined />{t("common.addOption")}
                                        </Link>
                                    </Space>
                                </>
                            )}>
                            {items.map((res: any) => (
                                <Option value={res} key={res}>{res}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                }
                {fieldType === "date" &&
                    <Form.Item name={"date_format"}
                        label={t("common.dateFormat")}
                        required tooltip={requiredFieldStar}
                        rules={[{ required: true, message: "required" }]}>
                        <Select placeholder={t("project.selectDateFormat")}>
                            <Option value={"YYYY-MM-DD"}>{"YYYY-MM-DD"}</Option>
                        </Select>
                    </Form.Item>
                }
                {!!fieldType &&
                    <Form.Item
                        rules={
                            [
                                {
                                    required: requiredField,
                                    message: "required",
                                    transform: (value: any) => value?.trim(),
                                },
                              ...(fieldType === "link"
                                ? [
                                    {
                                      message: "Please enter a valid URL",
                                      validator: (_: any, value: string) => {
                                        if (!requiredField && !value) return Promise.resolve();
                                        if (isURL(value)) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject();
                                        }
                                      },
                                    },
                                  ]
                                : []),
                            ]
                          }
                    name="default_value" label={<Space>
                        <Text>{`${t("common.default")} ${capitalizeLabel(fieldType)}`}</Text>
                        <Tooltip title={t("common.Defaultvalueswillbeautomaticallyappliedtotheproject.Youcanstilleditthevaluesaftertheprojectiscreated.")}> <InfoCircleFilled /> </Tooltip>
                    </Space>}>
                        {getDefaultvalue}
                    </Form.Item>
                }
                <Row justify="end" style={{ marginTop: 30 }}>
                    <Space size={"large"}>
                        <StyledButton type="text" onClick={closeModal}>{t("common.cancel")}</StyledButton>
                        <StyledButton type="primary" htmlType='submit'>{t("common.addField")}</StyledButton>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
