import { DeleteOutlined, EditOutlined, LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { Collapse, Dropdown, Empty, notification, Spin, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";

import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import RepositoryService from "src/services/repository/v3";
import jwtManager from "src/utils/jwtManager";
import { Headers } from "src/services/interface";
import PropertySpecimenModal from '../Common/PropertySpecimenModal'
import { DeleteSpecimenAndStandardModal } from "./DeleteSpecimenAndStandardModal/DeleteSpecimenAndStandardModal";
import { messages } from "src/utils/hooks";
import { AsyncStates } from "src/constants";


const { Text } = Typography;

type TProps = {
  selectedProperty: string;
};

export type ShowDeleteModalTypes = {
  type: "specimen" | "standard",
  data: { [key: string]: any } | null,
  showModal: boolean,
  title: string | null
}

const PropertySpecimens = ({ selectedProperty }: TProps) => {
  const [t] = useTranslate();
  const [showModal, setShowModal] = useState(false);
  const [specimens, setSpecimens] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteSpecimenLoadingState, setDeleteSpecimenLoadingState] = useState<{ [key: string]: AsyncStates }>({});

  const [showDeleteSpecimenModalData, setShowDeleteSpecimenModalData] = useState<ShowDeleteModalTypes>({
    type: "specimen",
    title: null,
    data: null,
    showModal: false
  });

  const language = useSelector((state: StoreState) => state.language.current)


  useEffect(() => {

    return () => {
      setShowDeleteSpecimenModalData({
        type: "specimen",
        title: null,
        data: null,
        showModal: false
      })
      setDeleteSpecimenLoadingState({})
    }
  }, [])



  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const fetchSpecimens = useCallback(async () => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const specimensResponse: any = await RepositoryService.getSpecimens(
      selectedProperty,
      headers
    );
    setLoading(false);
    setSpecimens(specimensResponse.data?.result?.data?.specimens ?? []);
  }, [defaultHeaders, selectedProperty]);

  const handleDeleteSpecimen = useCallback(async () => {
    const payload = { specimen: showDeleteSpecimenModalData.data?.specimen }

    setDeleteSpecimenLoadingState((prev) => ({
      ...prev,
      [payload.specimen]: AsyncStates.LOADING
    }))
    try {
      const headers = {
        ...defaultHeaders,
        token: jwtManager.getToken(),
      } as Headers;
      const { data: { result: { status, message } } }: any = await RepositoryService.deleteSpecimen(
        selectedProperty,
        payload,
        headers
      );

      if (status === "Success") {
        setDeleteSpecimenLoadingState((prev) => ({
          ...prev,
          [payload.specimen]: AsyncStates.SUCCESS
        }))
        setSpecimens((prev) => prev.filter((specimen) => specimen !== payload.specimen))
        setShowDeleteSpecimenModalData({
          type: "specimen",
          title: null,
          data: null,
          showModal: false
        })
        notification.success({
          message: message
        })
      } else {
        setDeleteSpecimenLoadingState((prev) => ({
          ...prev,
          [payload.specimen]: AsyncStates.ERROR
        }))
        notification.error({
          message: message
        })
      }
    } catch (error) {
      setDeleteSpecimenLoadingState((prev) => ({
        ...prev,
        [payload.specimen]: AsyncStates.ERROR
      }))
      notification.error({
        message: messages[language].internal_server_error
      })
    }
  }, [defaultHeaders, selectedProperty, showDeleteSpecimenModalData, language]);

  useEffect(() => {
    fetchSpecimens();
  }, [fetchSpecimens]);

  const dropdownMenu = useCallback(() => {
    return [
      {
        label: (
          <div style={{ color: "#F5222D" }}>
            <DeleteOutlined /> {t("common.delete")}
          </div>
        ),
        key: "delete",
      },
    ];
  }, [t]);

  const takeAction = useCallback((action: string, specimen: string) => {
    if (action === "delete") {
      setShowDeleteSpecimenModalData({
        type: "specimen",
        title: specimen,
        showModal: true,
        data: {
          specimen: specimen,
          property_id: selectedProperty
        },
      })
    }

  }, [selectedProperty]);

  const handleDeleteModalClose = () => {
    setShowDeleteSpecimenModalData({
      type: "specimen",
      title: null,
      data: null,
      showModal: false
    })
  }

  return (
    <div className="property-specimen-container">
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Collapse
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              showArrow: false,
              collapsible: "icon",
              label: (
                <div className="collapse-header-container">
                  <span className="collapse-header">
                    {t("inventory.specimens")}
                  </span>
                  <span
                    className="add-new-cta"
                    onClick={() => setShowModal(true)}
                  >
                    <EditOutlined /> {t("common.update")}
                  </span>
                </div>
              ),
              children: (
                <>
                  {specimens.length === 0 && loading && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}

                  {specimens.sort((a, b) => a.localeCompare(b))
                    .map((specimen, i) => (
                      <div key={specimen} className="specimen-list">
                        <span>{specimen}</span>
                        <div>
                          <Dropdown
                            menu={{
                              items: dropdownMenu(),
                              onClick: (event) =>
                                takeAction(event.key, specimen),
                            }}
                            trigger={["click"]}
                          >
                            <MoreOutlined />
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                </>
              ),
            },
          ]}
        />
      </Spin>
      <PropertySpecimenModal
        key={selectedProperty}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedPropertyId={selectedProperty}
        specimens={specimens}
        setSpecimens={setSpecimens}
      />
      {
        !!showDeleteSpecimenModalData.showModal &&
        <DeleteSpecimenAndStandardModal
          title={
            <Text>
              {t("common.deleteConfirmation")}{" "}
              <Text strong>{`${showDeleteSpecimenModalData.title}`}</Text>?
            </Text>
          }
          showDeleteModalData={showDeleteSpecimenModalData}
          handleModalClose={handleDeleteModalClose}
          errorContent={null}
          loadingState={deleteSpecimenLoadingState?.[showDeleteSpecimenModalData?.data?.specimen] === AsyncStates.LOADING}
          onConfim={() => handleDeleteSpecimen()}
        />
      }
    </div>
  );
};

export default PropertySpecimens;
