import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
	Checkbox,
	Col,
	Collapse,
	Descriptions,
	Drawer as AntdDrawer,
	Form,
	Input,
	List,
	message,
	Modal,
	Row,
	Select,
	Spin,
	Typography,
} from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { AsyncStates } from "src/constants"
import { StyledCard } from "src/styled_components/StyledCard"
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux"
import { ScrollToTopInDrawer } from "src/components/Common"
import { StoreState } from "src/store/configureStore"
import {
	favouriteModelCleanup,
	forwardPredDeleteRequest,
	forwardPredIdRequest,
	forwardPredListRequest,
	forwardPredResultCurrentpageRequest,
	forwardPredResultFiltersRequest,
	forwardPredResultRequest,
	setMetric,
} from "src/store/actions/formulate"
import { geekblue } from "@ant-design/colors"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { ModelConfig } from "src/typings"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
const { Title } = Typography
const { Option } = Select

type P = {
	visible: boolean
	setVisible: Dispatch<SetStateAction<boolean>>
}

type Filter = {
	pageNum: number
	user_ids: string[]
	versions: string[]
	objectives: string[]
}

type FormData = {
	versions: string[]
	user_ids: string[]
	objective: string
}

export const Drawer = ({ setVisible, visible }: P) => {
	const [t] = useTranslate()
	const forwardDrawerRef = useRef<HTMLDivElement>(null)
	const [forwardForm] = Form.useForm<FormData>()
	const dispatch = useDispatch()
	const configData = useSelector(
		(state: StoreState) => state.formulate.configData
	) as ModelConfig[]
	const {
		configStatus,
		forwardPredList: { forwardData, forwardTotal },
		forwardPredListStatus,
		forwardPredDeleteStatus,
	} = useSelector((state: StoreState) => state.formulate)
	const teams = useSelector((state: StoreState) => state.teams.data) ?? []
	const [selectedForwardRecord, setSelectedForwardRecord] = useState("")
	const inverseModel = useSelector((state: StoreState) => state.inverseModel)
	const [forwardCurrent, setForwardCurrent] = useState(1)
	const [deletePredictionIds, setDeletePredictionIds] = useState<string[]>([])
	const [forwardFilters, setForwardFilters] = useState<Filter | null>(null)

	useEffect(() => {
		dispatch(forwardPredListRequest({ pageNum: 1 }))
	}, [dispatch])

	useEffect(() => {
		if (forwardPredDeleteStatus === AsyncStates.SUCCESS) {
			dispatch(forwardPredListRequest(forwardFilters ?? {}))
			setForwardCurrent(1)
		}
	}, [dispatch, forwardFilters, forwardPredDeleteStatus])

	function clearFilters() {
		forwardForm.resetFields()
		setForwardFilters(null)
		setForwardCurrent(1)
		dispatch(forwardPredListRequest({ pageNum: 1 }))
	}

	function deleteObjectives() {
		if (deletePredictionIds.length) {
			dispatch(
				forwardPredDeleteRequest({ prediction_ids: deletePredictionIds })
			)
			setForwardCurrent(1)
		} else message.error(t("aiEngine.recordNotSelected"))
	}

	function drawerCheckboxChange(e: CheckboxChangeEvent, pred_id: string) {
		if (e.target.checked) {
			setDeletePredictionIds((prev) => [...prev, pred_id])
		} else {
			setDeletePredictionIds((prev) => prev.filter((res) => res !== pred_id))
		}
	}

	function applyFilters(values: FormData) {
		const { versions, user_ids, objective } = values

		const payload: Filter = {
			pageNum: 1,
			user_ids: user_ids ?? [],
			versions: versions ?? [],
			objectives: objective ? [objective] : [],
		}
		setForwardCurrent(1)
		setForwardFilters(payload)
		dispatch(forwardPredListRequest(payload))
	}

	function pageChange(pageNum: number, pageSize: number) {
		const payload = { ...forwardFilters, pageNum, pageSize }
		setForwardCurrent(pageNum)
		dispatch(forwardPredListRequest(payload))
	}

	return (
		<AntdDrawer
			open={visible}
			width={550}
			onClose={() => setVisible(false)}
			mask={false}
			title={
				<Row justify="space-between" align="middle">
					<Title level={4} style={{ margin: 0 }}>
						{t("aiEngine.history")}
					</Title>

					<StyledButton
						onClick={() => {
							Modal.confirm({
								title: `${t("common.delete")} ${t("aiEngine.selectedRecords")}`,
								icon: <StyledDeleteIcon />,
								okText: t("common.confirm"),
								cancelText: t("common.cancel"),
								okButtonProps: { style: { borderRadius: "12px" } },
								cancelButtonProps: { style: { borderRadius: "12px" } },
								onOk: () => {
									deleteObjectives()
								},
							})
						}}
						loading={forwardPredDeleteStatus === AsyncStates.LOADING}
						style={{ marginRight: 35 }}
						type="primary"
					>
						{t("aiEngine.deleteRecords")}
					</StyledButton>
				</Row>
			}
		>
			<StyledCard ref={forwardDrawerRef}>
				<Collapse>
					<Collapse.Panel key="filters" header={t("common.addFilters")}>
						<StyledCard>
							<Form
								layout="vertical"
								onFinish={(e) => applyFilters(e)}
								form={forwardForm}
							>
								<Form.Item label={t("aiEngine.objectives")} name="objective">
									<Input />
								</Form.Item>

								<Form.Item label={t("aiEngine.version")} name="versions">
									<Select mode="multiple" allowClear>
										{configData
											.filter((res) => !!res.is_custom)
											.map((res, i) => (
												<Option key={res.version} value={res.version}>
													{res.comments}
												</Option>
											))}
									</Select>
								</Form.Item>

								<Form.Item label={t("aiEngine.user")} name="user_ids">
									<Select mode="multiple" allowClear>
										{teams.map((res) => (
											<Option key={res.user_id} value={res.user_id}>
												{res.user_name}
											</Option>
										))}
									</Select>
								</Form.Item>

								<Row justify="end" gutter={16}>
									<Col>
										<StyledButton
											disabled={
												inverseModel.statusPredictionIds === AsyncStates.LOADING
											}
											htmlType="submit"
											type="primary"
										>
											{t("aiEngine.button.apply")}
										</StyledButton>
									</Col>

									<Col>
										<StyledButton
											disabled={
												inverseModel.statusPredictionIds === AsyncStates.LOADING
											}
											onClick={() => clearFilters()}
											type="dashed"
										>
											{t("common.clear")}
										</StyledButton>
									</Col>
								</Row>
							</Form>
						</StyledCard>
					</Collapse.Panel>
				</Collapse>

				<Spin
					indicator={<LoadingOutlined />}
					spinning={
						forwardPredListStatus === AsyncStates.LOADING ||
						configStatus === AsyncStates.LOADING
					}
				>
					<List
						pagination={{
							current: forwardCurrent,
							showSizeChanger: true,
							responsive: true,
							position: "top",
							total: forwardTotal,
							onChange: pageChange,
						}}
						size="small"
						grid={{ column: 1, gutter: 16 }}
						dataSource={forwardData?.predictions_list}
						renderItem={(item: any) => (
							<List.Item style={{ marginTop: 10 }}>
								<StyledCard
									type="inner"
									title={
										<Checkbox
											onChange={(e) =>
												drawerCheckboxChange(e, item.prediction_id)
											}
											onClick={(e) => e.stopPropagation()}
										/>
									}
									style={
										item?.prediction_id === selectedForwardRecord
											? { cursor: "pointer", background: geekblue[0] }
											: { cursor: "pointer" }
									}
									onClick={() => {
										dispatch(setMetric({}))
										dispatch(favouriteModelCleanup())
										dispatch(
											forwardPredResultRequest({
												prediction_id: item?.prediction_id,
												type: "history"
											})
										)
										dispatch(forwardPredIdRequest(item?.prediction_id))
										dispatch(forwardPredResultCurrentpageRequest(1))
										dispatch(forwardPredResultFiltersRequest({}))
										setSelectedForwardRecord(item?.prediction_id)
										setVisible(false)
									}}
								>
									<Descriptions
										bordered
										style={{ padding: 10, background: "white" }}
										column={1}
										size="small"
									>
										{Object.entries(item || {}).map(
											([key, value]: [any, any], i) => (
												<>
													{key === "objective" && value !== '' && (
														<Descriptions.Item label={t("aiEngine.objective")}>
															{value}
														</Descriptions.Item>
													)}
													{key === "updated" && (
														<Descriptions.Item
															label={t("aiEngine.requestedOn")}
														>
															{<>{new Date(value).toLocaleString()} </>}
														</Descriptions.Item>
													)}
													{key === "version" && (
														<Descriptions.Item
															label={t("aiEngine.version")}
														>{`${configData.find(
															(res: any) => res.version === value
														)?.objective
															} (${configData.find(
																(res: any) => res.version === value
															)?.comments || "NA"
															})`}</Descriptions.Item>
													)}
													{key === "user_name" && (
														<Descriptions.Item label={t("aiEngine.user")}>
															{value}
														</Descriptions.Item>
													)}
												</>
											)
										)}
									</Descriptions>
								</StyledCard>
							</List.Item>
						)}
					/>

					<ScrollToTopInDrawer sectionRef={forwardDrawerRef} />
				</Spin>
			</StyledCard>
		</AntdDrawer>
	)
}
