import { Button, Modal } from 'antd'
import React from 'react'
import useTranslate from 'src/utils/useTranslate'
import { DeleteOutlined } from '@ant-design/icons';
import { ShowDeleteModalTypes } from '../PropertySpecimens';
import "./DeleteSpecimenAndStandardModal.scss"

type DeleteSpecimenAndStandardModalPropsType = {
    title: React.ReactNode
    showDeleteModalData: ShowDeleteModalTypes,
    handleModalClose: () => void,
    errorContent: string | null,
    loadingState: boolean,
    onConfim: () => void
}

export const DeleteSpecimenAndStandardModal = ({ title, showDeleteModalData, handleModalClose, errorContent, onConfim, loadingState }: DeleteSpecimenAndStandardModalPropsType) => {
    const [t] = useTranslate()
    return (
        <Modal
            footer={null}
            open={showDeleteModalData.showModal}
            onCancel={() => handleModalClose()}
            title={title}>
            <div className='delete__specimen__and__standard__modal__content__container'>
                {errorContent ? <div>{errorContent}</div> : null}
                <div className='delete__specimen__and__standard__modal__actions__container'>
                    <Button danger loading={loadingState} onClick={() => onConfim()}>
                        <DeleteOutlined /> {t("common.delete")}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
