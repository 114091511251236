import {
    Anchor,
    Col,
    Flex,
    Row,
    Space,
    Spin,
    Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workOrdersRequest } from "src/store/actions/workOrders";
import { StoreState } from "src/store/configureStore";
import "./SmileAnalytics.scss";
import { CustomPrompt } from "src/utils/CustomPrompts";
import useTranslate from "src/utils/useTranslate";
import { AsyncStates } from "src/constants";
import SmileOverview from "./SmileResults/SmileOverview";
import SmileNumericalData from "./SmileResults/SmileNumericalData";
import SmileNumericalHeatmap from "./SmileResults/SmileNumericalHeatmap";
import { ArrowLeftOutlined, HistoryOutlined, LoadingOutlined } from "@ant-design/icons";
import SmileAnalyticsForm from "./SmileAnalyticsForm";
import { StyledButton } from "src/styled_components/StyledButton";
import { smileSummaryHistoryRequest, smileSummaryResultClear } from "src/store/actions/smileSummary";
import SmileResultHeader from "./SmileResults/SmileResultHeader";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import SmileAnalyticsHistoryDrawer from "./SmileAnalyticsHistoryDrawer";

const anchorOptions = (t: any) => [
    {
        key: "overview",
        href: "#overview",
        title: t("projects.overview"),
    },
    {
        key: "num-data-summary",
        href: "#num-data-summary",
        title: t("smiles.numDataSummary"),
    },
    {
        key: "num-correlation-heatmap",
        href: "#num-correlation-heatmap",
        title: t("smiles.numCorrelationHeatmap"),
    },
    {
        key: "fingerprint-analysis",
        href: "#fingerprint-analysis",
        title: t("smiles.fingerprintAnalysis"),
    },
    {
        key: "graphs",
        href: "#graphs",
        title: t("common.graphs"),
    },
];

const SmileAnalytics = () => {
    const { current: currentProject } = useSelector(
        (state: StoreState) => state.projects
    );
    const isEditing = useSelector((state: StoreState) => state.isEditing);

    const dispatch = useDispatch();
    const [t] = useTranslate();
    const [showData, setShowData] = useState(false);
    const { smileSummaryStatus, smileSummaryResultStatus, smileSummaryResult, smileSummaryHistoryDeleteStatus } = useSelector(
        (state: StoreState) => state.smileSummary
    );
    const [smileReportDownloadLink, setSmileRepostDownloadLink] =
        useState<string>();
    const [hasHeatmapData, setHasHeatmapData] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (smileSummaryResultStatus === AsyncStates.SUCCESS) setShowData(true);
        else setShowData(false);
    }, [smileSummaryResultStatus]);

    useEffect(() => {
        dispatch(
            workOrdersRequest({
                project_id: currentProject,
                closed_stages: true,
            })
        );

        return () => {
            dispatch(smileSummaryResultClear());
            setShowHistory(false);
            setShowData(false);
        }
    }, [dispatch, currentProject]);

    useEffect(() => {
        if (showHistory) {
            dispatch(smileSummaryHistoryRequest());
        }
    }, [dispatch, showHistory]);

    useEffect(() => {
        if (showHistory && smileSummaryHistoryDeleteStatus === AsyncStates.SUCCESS) {
            dispatch(smileSummaryHistoryRequest());
        }
    }, [dispatch, showHistory, smileSummaryHistoryDeleteStatus]);

    const clearSmileSummaryResult = () => {
        dispatch(smileSummaryResultClear());
        setShowData(false);
    }

    return (
        <div className="smile-analytics-container">
            <CustomPrompt
                isEditing={isEditing as boolean}
                message={`${t("common.unsavedChangesLost")}!.`}
            />
            <Space
                direction="vertical"
                style={{ width: "100%", marginBottom: 20 }}
                size="large"
            >
                <StyledPageHeader
                    title={t("smiles.analytics")}
                    extra={
                        <Tooltip title={`${t("smiles.analytics")} ${t("aiEngine.history")}`}>
                            <StyledButton
                                onClick={() => setShowHistory(true)}
                                type="default"
                                icon={<HistoryOutlined />}
                            />
                        </Tooltip>
                    }
                />
                <Spin
                    spinning={[smileSummaryStatus, smileSummaryResultStatus].includes(
                        AsyncStates.LOADING
                    )}
                    indicator={<LoadingOutlined />}
                    tip={<div>{t("dataAnalytics.loading")}</div>}
                >
                    {!showData ? (
                        <SmileAnalyticsForm
                            setSmileRepostDownloadLink={setSmileRepostDownloadLink}
                            setHasHeatmapData={setHasHeatmapData}
                        />
                    ) : (
                        <>
                            <Flex className="back-header" >
                                <ArrowLeftOutlined onClick={clearSmileSummaryResult} style={{ cursor: "pointer" }} />
                                <span>{t("smiles.runTitle")}: {smileSummaryResult?.experiment_details?.title || t("common.back")}</span>
                            </Flex>
                            <Row>
                                <SmileResultHeader />
                            </Row>
                            <Row justify="start" gutter={[32, 8]}>
                                <Col span={20}>
                                    <SmileOverview
                                        smileReportDownloadLink={smileReportDownloadLink}
                                    />
                                    <SmileNumericalData />
                                    <SmileNumericalHeatmap
                                        setSmileRepostDownloadLink={setSmileRepostDownloadLink}
                                        setHasHeatmapData={setHasHeatmapData}
                                    />
                                </Col>
                                <Col span={4}>
                                    <div className="anchor-container">
                                        <div className="heading">{t("common.contents")}</div>
                                        <Anchor
                                            targetOffset={280}
                                            items={
                                                hasHeatmapData ? anchorOptions(t) : anchorOptions(t).slice(0, 3)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Spin>

                <SmileAnalyticsHistoryDrawer showHistory={showHistory} setShowHistory={setShowHistory} />
            </Space>
        </div>
    );
};

export default SmileAnalytics;
