import {
    Checkbox,
    Descriptions,
    Drawer,
    Flex,
    List,
    Popconfirm,
    Row,
} from "antd";
import { StyledCard } from "src/styled_components/StyledCard";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useMemberName } from "src/utils/useMemberName";
import { AsyncStates } from "src/constants";
import { smileSummaryHistoryDeleteRequest, smileSummaryResultRequest } from "src/store/actions/smileSummary";
import useTranslate from "src/utils/useTranslate";
import { memo, useEffect, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import { DeleteOutlined } from "@ant-design/icons";

const SmileAnalyticsHistoryDrawer = ({ showHistory, setShowHistory }: any) => {
    const [t] = useTranslate();
    const dispatch = useDispatch();
    const { getName } = useMemberName();
    const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
    const { smileSummaryHistoryStatus, smileSummaryHistory, smileSummaryHistoryDeleteStatus } = useSelector(
        (state: StoreState) => state.smileSummary
    );

    const getSmilesResult = (run_id: string | undefined) => {
        if (!run_id) return;
        dispatch(smileSummaryResultRequest({ run_id }));
        setShowHistory(false);
    }

    useEffect(() => {
        if (smileSummaryHistory && Array.isArray(smileSummaryHistory)) {
            const availableIds = smileSummaryHistory.map(t => t.run_id);
            setSelectedRecords(prev => prev.filter(id => availableIds.includes(id)));
        }
    }, [smileSummaryHistory]);

    const deleteRecords = () => {
        dispatch(smileSummaryHistoryDeleteRequest({ run_ids: selectedRecords }))
    }

    const deleteRecord = (recordId: string) => {
        dispatch(smileSummaryHistoryDeleteRequest({ run_ids: [recordId] }))
    }

    return (
        <Drawer
            open={showHistory}
            width={500}
            onClose={() => setShowHistory(false)}
            title={
                <Row justify="space-between">
                    <Title level={4} style={{ margin: 0 }}>
                        {t("aiEngine.history")}
                    </Title>
                    <Popconfirm
                        title={t("common.delete")}
                        description={t("common.deleteConfirmation")}
                        onConfirm={deleteRecords}
                        okText={t("common.Yes")}
                        cancelText={t("common.no")}
                    >
                        <StyledButton
                            type="default"
                            disabled={selectedRecords.length === 0}
                        >
                            <DeleteOutlined />
                            {t("aiEngine.deleteRecords")}
                        </StyledButton>
                    </Popconfirm>
                </Row>
            }
        >
            <List
                loading={[smileSummaryHistoryStatus, smileSummaryHistoryDeleteStatus].includes(AsyncStates.LOADING)}
                pagination={
                    {
                        defaultCurrent: 1,
                        total: (smileSummaryHistory || [])?.length,
                        showSizeChanger: true,
                        position: "top",
                    }
                }
                dataSource={Array.isArray(smileSummaryHistory) ? smileSummaryHistory.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()) : []}
                renderItem={(item: any) => (
                    <div style={{ marginTop: 10 }}>
                        {["completed", "failed"].includes(String(item?.status || "").toLowerCase()) ? <Flex justify="space-between" align="center" style={{ margin: "0 1.5rem" }}>
                            <div><Checkbox checked={selectedRecords.includes(item?.run_id)} onChange={(e) => {
                                if (e.target.checked) setSelectedRecords(prev => [...prev, item?.run_id]);
                                else setSelectedRecords(prev => prev.filter(id => id !== item?.run_id));
                            }} />
                            </div>
                            <Popconfirm
                                title={t("common.delete")}
                                description={t("common.deleteConfirmation")}
                                onConfirm={() => deleteRecord(item.run_id)}
                                okText={t("common.Yes")}
                                cancelText={t("common.no")}
                            >
                                <div style={{ cursor: "pointer", color: "red", padding: "0 8px" }}><DeleteOutlined /></div>
                            </Popconfirm>
                        </Flex> : <></>}

                        <List.Item style={{ padding: "0 0 12px 0", borderBottom: "1px solid #f0f0f0" }}>
                            <StyledCard
                                type="inner"
                                style={{ width: "100%", cursor: String(item?.status || "").toLowerCase() === "completed" ? "pointer" : "not-allowed" }}
                                onClick={() => String(item?.status || "").toLowerCase() === "completed" && getSmilesResult(item?.run_id)}
                            >
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label={t("smiles.runTitle")}>
                                        {item?.title}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("common.type")}>
                                        {item?.upload_file ? "File Upload" : "Work Order"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("common.createdOn")}>
                                        {new Date(item?.created).toLocaleString()}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("common.createdBy")}>
                                        {getName(item?.created_by)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={t("common.status")}>
                                        {item?.status}
                                    </Descriptions.Item>
                                </Descriptions>
                            </StyledCard>
                        </List.Item>
                    </div>
                )}
            />
        </Drawer>
    )
}

export default memo(SmileAnalyticsHistoryDrawer);