import React, { useCallback, useEffect, useMemo, useState } from "react"
import { StoreState } from "src/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "src/components/Spinner/Spinner"
import { AsyncStates } from "src/constants"
import GenericError from "src/components/GenericError/GenericError"
import "./Compare.scss"
import { Space, Tooltip, Checkbox } from "antd";
import { FileExcelOutlined, FundOutlined, PlusOutlined } from "@ant-design/icons"
import {
	linkedCompareFormulationsRequest,
} from "src/store/actions/compareFormulations"
import Text from "antd/lib/typography/Text"
import { Chart } from "./Chart"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import useTranslate from "src/utils/useTranslate"
import { CelsureCompareFormulation } from "../../Celsure/Formulations/CelsureCompareFormulation"
import { setIsEditing } from "src/store/actions/isEditing"
import { CustomPrompt } from "src/utils/CustomPrompts"
import {
	CharacterizationsComparisonTable,
	IngredientsComparisonTable,
	ProcessingComparisonTable,
	PropertiesComparisonTable,
} from "./ComparisonTables"
import { apply } from "json-logic-js"
import { dataExportRequest } from "src/store/actions/workOrderDetails"
import { dataSummaryRequest } from "src/store/actions/dataSummary"
import { catDataSummaryRequest } from "src/store/actions/catDataSummary"
import { useValue } from "src/utils/useValue"
import { StyledButton } from "src/styled_components/StyledButton"

export const CompareFormulation = () => {
	const dispatch = useDispatch()
	const { getValue } = useValue()
	const { numericalSummaryStatus } = useSelector((state: StoreState) => state.dataSummary)
	const { status: categoricalSummaryStatus } = useSelector((state: StoreState) => state.catDataSummary)
	const configs = useSelector((state: StoreState) => state.configs.features)
	const formulationList = useSelector((state: StoreState) => state.compareFormulations.formulationDetailsData)
	const { project_type } = useSelector((state: StoreState) => state.login.loginResponse)
	const { projectList } = useSelector((state: StoreState) => state.projects)

	const unitList = useSelector((state: StoreState) => state.conversion.unitList)
	const processingLabels = useSelector((state: StoreState) => state.displayNames?.data?.processing || {})
	const propertyLabels = useSelector((state: StoreState) => state.displayNames.data?.properties || {})
	const characterizationLabels = useSelector((state: StoreState) => state.displayNames.data?.characterizations || {})
	const [loading, setLoading] = useState(false)
	const [plots, setPlots] = useState<any[]>([])
	const [disableButton, setDisableButton] = useState<boolean>(false)
	const [displayTables, setDisplayTables] = useState<any>({
		formulations: false,
		processing: false,
		properties: false,
		characterizations: false,
	})
	const [ingredientsUnit, setIngredientsUnit] = useState<any>(
		(Object.values(formulationList?.[0]?.ingredients || {})?.[0] as any)
			?.unit || "-"
	)
	const [processingBaseUnits, setProcessingBaseUnits] = useState<string[]>([])
	const [propertiesBaseUnits, setPropertiesBaseUnits] = useState<string[]>([])
	const [characterizationsBaseUnits, setCharacterizationsBaseUnits] = useState<
		string[]
	>([])

	const isEditing = useSelector((state: StoreState) => state.isEditing)
	const [selectedFormulations, setSelectedFormulations] = useState<string[]>([])

	const [t] = useTranslate()

	const valueConversion = useCallback((type: string, formulation: any, name: string) => {
		const category = formulation?.[type]?.[name]?.category || "-"
		setLoading(true)
		if (type === "ingredients") {
			let convertedValue = formulation.ingredients?.[name]?.value
			let parametrValue = formulation.ingredients?.[name]?.value
			if (ingredientsUnit === "wt%") {
				if (formulation.ingredients?.[name]?.unit !== "wt%") {
					let batchSizeConversionValue: any = formulation?.meta?.batch_size?.value_grams ?? 100
					let batchSizeUnit = formulation?.meta?.batch_size?.pref_unit ?? 'g'
					if (batchSizeUnit !== formulation.ingredients?.[name]?.unit) {
						const batchSizeConversionString = `${batchSizeUnit}_to_${formulation.ingredients?.[name]?.unit}`
						const batchSizeFormula = unitList.find((res: any) => res.name === batchSizeUnit)?.conversion_metric?.[batchSizeConversionString]
						if (batchSizeFormula) {
							batchSizeConversionValue = !!formulation?.meta?.batch_size?.value_grams ? apply(batchSizeFormula, [formulation?.meta?.batch_size?.value_grams]) : formulation?.meta?.batch_size?.value_grams
						}
					}
					if (!!batchSizeConversionValue && !!parametrValue && !isNaN(parametrValue)) {
						convertedValue = Number((parametrValue * 100) / batchSizeConversionValue)
					}
				}
			}
			else if (formulation.ingredients?.[name]?.unit === "wt%") {
				if (ingredientsUnit !== "wt%") {
					let batchSizeConversionValue: any = formulation?.meta?.batch_size?.value_grams ?? 100
					let batchSizeUnit = formulation?.meta?.batch_size?.pref_unit ?? 'g'
					if (batchSizeUnit !== ingredientsUnit) {
						const batchSizeConversionString = `${batchSizeUnit}_to_${ingredientsUnit}`
						const batchSizeFormula = unitList.find((res: any) => res.name === batchSizeUnit)?.conversion_metric?.[batchSizeConversionString]
						if (batchSizeFormula) {
							batchSizeConversionValue = !!formulation?.meta?.batch_size?.value_grams ? apply(batchSizeFormula, [formulation?.meta?.batch_size?.value_grams]) : formulation?.meta?.batch_size?.value_grams
						}
					}
					if (batchSizeConversionValue && !!parametrValue && !isNaN(parametrValue)) {
						convertedValue = Number((parametrValue * batchSizeConversionValue) / 100)
					}
				}
			}

			else {
				const conversionString = `${formulation.ingredients?.[name]?.unit}_to_${ingredientsUnit}`
				const formula = unitList.find((res: any) => res.name === formulation.ingredients?.[name]?.unit)?.conversion_metric?.[conversionString]
				convertedValue = !!formula && !!parametrValue && !isNaN(parametrValue) ? apply(formula, [parametrValue]) : parametrValue
			}
			setLoading(false)

			return {
				category: category,
				name: name,
				unit: ingredientsUnit || "-",
				value: getValue(convertedValue) || null,
			}
		}
	}, [ingredientsUnit, unitList, getValue])

	const chartsFormulationList = useMemo(() => {
		return formulationList.reduce((accFormulation, currentFormulation) => {
			accFormulation.push({
				...currentFormulation,
				ingredients: Object.keys(currentFormulation.ingredients || {}).reduce((ingAcc, name: string) => {
					return {
						...ingAcc,
						[name]: valueConversion("ingredients", currentFormulation, name)
					}
				}, {}),
				processing: currentFormulation.processing?.reduce((accProcessing: any, currProcessing: any) => {
					accProcessing.push({
						...accProcessing,
						...currProcessing,
						processing: Object.keys(currProcessing.processing || {}).reduce((accProcessingVal: any, currProcessingName: string, paramIndex: number) => {
							const value = currProcessing?.processing?.[currProcessingName]?.value
							const unit = currProcessing?.processing?.[currProcessingName]?.unit
							const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${processingBaseUnits[paramIndex]}`]
							// @ts-ignore
							const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
							return {
								...accProcessingVal,
								[currProcessingName]: {
									category: processingLabels[currProcessingName]?.category || "-",
									processing: processingLabels[currProcessingName]?.name || currProcessingName,
									unit: processingBaseUnits[paramIndex],
									value: getValue(convertedValue)
								}
							}
						}, {})
					})
					return accProcessing
				}, []),
				properties: currentFormulation.properties.reduce((accProperties: any, currProperties: any) => {
					accProperties.push({
						...accProperties,
						...currProperties,
						properties: Object.keys(currProperties.properties || {}).reduce((accPropertiesVal: any, propertyName: string, paramIndex: number) => {
							const value = currProperties?.properties?.[propertyName]?.value
							const unit = currProperties?.properties?.[propertyName]?.unit
							const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${propertiesBaseUnits[paramIndex]}`]
							// @ts-ignore
							const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
							return {
								...accPropertiesVal,
								[propertyName]: {
									category: propertyLabels[propertyName]?.category || "-",
									properties: propertyLabels[propertyName]?.name || propertyName,
									unit: propertiesBaseUnits[paramIndex],
									value: getValue(convertedValue)
								}
							}
						}, {})
					})
					return accProperties
				}, []),
				characterizations: currentFormulation.characterizations.reduce((accCharacterization: any, currCharacterization: any) => {
					accCharacterization.push({
						...accCharacterization,
						...currCharacterization,
						properties: Object.keys(currCharacterization.characterizations || {}).reduce((accCharacterizationVal: any, characterizationIdentifier: string, paramIndex: number) => {
							const value = currCharacterization?.characterizations?.[characterizationIdentifier]?.value
							const unit = currCharacterization?.characterizations?.[characterizationIdentifier]?.unit
							const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${characterizationsBaseUnits[paramIndex]}`]
							// @ts-ignore
							const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
							return {
								...accCharacterizationVal,
								[characterizationIdentifier]: {
									category: characterizationLabels[characterizationIdentifier]?.category || "-",
									properties: characterizationLabels[characterizationIdentifier]?.name || characterizationIdentifier,
									unit: characterizationsBaseUnits[paramIndex],
									value: getValue(convertedValue)
								}
							}
						}, {})
					})
					return accCharacterization
				}, []),
			})
			return accFormulation
		}, [])
	}, [processingBaseUnits, propertiesBaseUnits, characterizationsBaseUnits, characterizationLabels, processingLabels, propertyLabels, formulationList, unitList, valueConversion, getValue])


	useEffect(() => {
		const obj = {
			formulations: false,
			processing: false,
			properties: false,
			characterizations: false,
		}
		formulationList.forEach((res: any) => {
			if (Object.keys(res?.ingredients || {}).length) obj.formulations = true
			if (Object.keys(res?.processing?.[0]?.processing || {}).length)
				obj.processing = true
			if (
				Object.keys(res?.characterizations?.[0]?.characterizations || {}).length
			)
				obj.characterizations = true
			if (Object.keys(res?.properties?.[0]?.properties || {}).length)
				obj.properties = true
		})
		setDisplayTables(obj)
	}, [formulationList])

	const dataExport = () => {
		// if (selected.size) {
		let payload
		if (!!selectedFormulations.length) {
			payload = {
				formulation_ids: selectedFormulations
			}
		} else {
			if (Boolean(configs?.characterization_methods)) {
				// const ids = [...selected.keys()]
				payload = {
					formulation_ids: [...formulationList?.map(({ id_set }: any) => id_set.formulation_id)] // generateZeonDataExportFormulationPayload(workOrderIds, ids, formulations?.formulation_data),
				}
			} else {
				payload = {
					formulation_ids: [...formulationList?.map(({ id_set }: any) => id_set.formulation_id)]
				}
			}
		}
		dispatch(dataExportRequest(payload))
	}

	const generate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation()
		const graphImages = await Promise.all(
			plots.filter((currentPlot) => !!currentPlot.selectedPropertySets.series.length)?.map(async (plot: any, key: any) => {
				let svgDoms: any = document?.getElementsByClassName(
					"highcharts-root" || ""
				)
				svgDoms[key]?.setAttribute("id", "plot" + plot?.id)
				const canvas = document.createElement("canvas")
				const ctx = canvas.getContext("2d")
				const svgXml = new XMLSerializer()?.serializeToString(svgDoms[key])
				canvas.width = svgDoms[key]?.width?.baseVal?.value
				canvas.height = svgDoms[key]?.height?.baseVal?.value
				const img = document.createElement("img")
				img.setAttribute(
					"src",
					"data:image/svg+xml;base64," +
					window.btoa(unescape(encodeURIComponent(svgXml)))
				)

				try {
					await img.decode()
					ctx?.drawImage(
						img,
						0,
						0,
						svgDoms[key]?.width?.baseVal?.value,
						svgDoms[key]?.height?.baseVal?.value
					)
					return Promise.resolve({
						...{ [plots[key].id]: canvas.toDataURL("image/png", 1.0)?.replace("data:image/png;base64,", "") },
					})
				} catch (error) {
					return Promise.reject()
				}
			})
		)
		const payload = {
			from: "compare",
			graphs: plots.filter((ele) => !!ele.selectedPropertySets.series.length).map((plot: any, key) => ({
				image: graphImages?.[key]?.[plot.id],
				id: plot.id,
				selected_trials: plot.selectedTrials,
				data: plot.selectedPropertySets.series,
				trial_names: plot.selectedPropertySets.xAxis.categories.map((cat: any, index: number) => {
					const stage_name = formulationList.find((ele) => ele.id_set.formulation_id === plot.selectedTrials?.[index])?.stage_name
					const data = stage_name ? `${cat} - ${stage_name}` : cat
					return data
				}),
				title: plot.title ?? "",
				description: plot.description,
				x_label: plot.xLabel ?? "",
				y_label: plot.selectedPropertySets.yAxis,
				work_order_ids: [...new Set(formulationList.reduce((acc, curr) => {
					if (plot.selectedTrials.includes(curr.id_set.formulation_id)) {
						acc.push(curr.work_order_id)
					}
					return acc
				}, []))]
			}
			)),
		}
		dispatch(dataExportRequest(payload))
	}

	const apiStatus = useSelector((state: StoreState) => state.displayNames.status)

	useEffect(() => {
		if (!!plots.length && plots?.filter(({ selectedPropertySets }) => !!selectedPropertySets?.series?.length).length !== plots.length) {
			setDisableButton(true)
		} else {
			setDisableButton(false)
		}
	}, [plots])

	useEffect(() => {
		const filteredFormulations = formulationList.map(({ ingredients }: any) => ingredients)?.flatMap((ele: any) =>
			Object.entries(ele).filter(
				([key, value]: any) => {
					return value?.type === "trials"
				}
			).map(([key, value]: any) => key))
		const uniqueFormulationIds = [...new Set(filteredFormulations)]
		if (!!uniqueFormulationIds.length) {
			dispatch(linkedCompareFormulationsRequest({ formulation_id: uniqueFormulationIds }))
		}
	}, [formulationList, dispatch])

	const checkBoxChange = (e: any, formulationId: any) => {
		if (e.target.checked) {
			if (formulationId === "all") {
				const data = formulationList.map((ele) => ele.id_set?.formulation_id)
				setSelectedFormulations((prevState: string[]) => [
					...new Set([...prevState, ...data]),
				])
			} else {
				setSelectedFormulations((prevState: string[]) => [
					...new Set([...prevState, formulationId]),
				])
			}
		} else {
			if (formulationId === "all") {
				setSelectedFormulations([])
			} else {
				setSelectedFormulations((prevState: string[]) =>
					prevState.filter((id: any) => id !== formulationId)
				)
			}
		}
	}

	const handleRunAnalytics = () => {
		const formulations: any[] = [...new Set(formulationList.filter((formulation) => selectedFormulations.includes(formulation?.id_set?.formulation_id)).map((formulationOption) => formulationOption.project_id + "_________" + formulationOption.work_order_id + "_________" + formulationOption.work_order_name))]
		const formulationOptions = formulations?.map((formulationOption) => {
			const [projectId, workOrderId, workOrderName] = formulationOption.split("_________")
			return {
				label: projectList.find((res) => res.project_id === projectId)?.name + "-" + workOrderName,
				options: formulationList.reduce((acc, curr) => {
					if (curr.project_id === projectId && workOrderId === curr.work_order_id) {
						return [...acc, {
							label: curr?.meta?.display_id + "-" + curr.stage_name,
							value: curr?.id_set?.formulation_id,
							key: curr?.id_set?.formulation_id
						}]
					}
					return acc
				}, [])
			}
		})

		const payload = {
			...(Boolean(configs?.nomralised_data_summary) && {
				normalise: false
			}),
			formulation_ids: selectedFormulations,
			from: "compare-formulation",
			meta: { formulationOptions, selectedFormulationIds: selectedFormulations }
		}
		dispatch(dataSummaryRequest(payload))
		dispatch(catDataSummaryRequest(payload))
	}

	return (
		<>
			<CustomPrompt
				isEditing={isEditing}
				message={`${t("common.unsavedChangesLost")}!.`}
			/>
			{project_type !== "celsure" ? (
				<>
					{apiStatus === AsyncStates.ERROR || formulationList.length === 0 ? (
						<GenericError />
					) : (
						<>
							<Space
								direction="vertical"
								size="large"
								style={{
									width: "100%",
									marginBottom: "50px"
								}}
							>
								<StyledPageHeader
									ghost={false}
									title={t("common.compare")}
									onBack={() => window.history.back()}
								/>
								<StyledCard className="overflow-scroll compare-table">
									<Space
										direction="vertical"
										style={{ width: "100%" }}
										size="large"
									>
										<div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem", alignItems: "center" }}>
											<Checkbox
												checked={formulationList.length === selectedFormulations.length}
												onChange={(e) => {
													checkBoxChange(e, "all")
												}}
											>
												{t("formulations.selectAllFormulations")}
											</Checkbox>
											<Tooltip title={selectedFormulations.length < 5 ? t("formulations.selectFiveTrial") : null}>
												<StyledButton icon={<FundOutlined />}
													loading={numericalSummaryStatus === AsyncStates.LOADING && categoricalSummaryStatus === AsyncStates.LOADING}
													disabled={selectedFormulations.length < 5}
													onClick={() => handleRunAnalytics()}
													size="large"
												>{t("formulations.runAnalytics")}</StyledButton>
											</Tooltip>
											<StyledButton icon={<FileExcelOutlined />}
												onClick={dataExport}
												size="large"
											>{t("formulations.button.exportResults")}</StyledButton>
										</div>
										{displayTables.formulations && (
											<IngredientsComparisonTable
												formulationList={formulationList}
												unit={ingredientsUnit}
												setUnit={setIngredientsUnit}
												checkbox={true}
												formulationsCheck={selectedFormulations}
												checkBoxChange={checkBoxChange}
											/>
										)}
										{displayTables.processing && (
											<ProcessingComparisonTable
												formulationList={formulationList}
												baseUnits={processingBaseUnits}
												setbaseUnits={setProcessingBaseUnits}
												checkbox={true}
												formulationsCheck={selectedFormulations}
												checkBoxChange={checkBoxChange}
											/>
										)}
										{displayTables.characterizations && (
											<CharacterizationsComparisonTable
												formulationList={formulationList}
												baseUnits={characterizationsBaseUnits}
												setbaseUnits={setCharacterizationsBaseUnits}
												checkbox={true}
												formulationsCheck={selectedFormulations}
												checkBoxChange={checkBoxChange}
											/>
										)}
										{displayTables.properties && (
											<PropertiesComparisonTable
												formulationList={formulationList}
												baseUnits={propertiesBaseUnits}
												setbaseUnits={setPropertiesBaseUnits}
												checkbox={true}
												formulationsCheck={selectedFormulations}
												checkBoxChange={checkBoxChange}
											/>
										)}
									</Space>
								</StyledCard>
								<StyledCard
									title={
										<Space>
											<Text>{t("common.buildAReport")}</Text>
										</Space>
									}
								>
									<Space
										size="large"
										direction="vertical"
										style={{ width: "100%" }}
									>
										{plots?.map((plot, index) => (
											<Chart
												allPlots={plots}
												plot={plot}
												setPlots={setPlots}
												key={plot?.id}
												chartIndex={index}
												formulationList={chartsFormulationList}
												isChartLoading={loading}
											/>
										))}
										<Space style={{ display: "flex", justifyContent: "center", gap: 10 }}>
											<StyledButton
												style={{ margin: "auto", display: "block" }}
												size="large"
												onClick={() => {
													setPlots((state) =>
														state.concat([
															{
																id:
																	"report_section_" +
																	(Number(
																		state
																			.slice(-1)[0]
																			?.id.split("_")
																			.slice(-1)[0]
																	) + 1 || 0),
															},
														])
													)
													dispatch(setIsEditing(true))
												}}
												type="dashed"
												icon={<PlusOutlined />}
											>
												{t("common.addChart")}
											</StyledButton>
											{!!plots?.length &&
												<Tooltip title={
													disableButton
														? "Please add remaining chart details"
														: t("common.generateReport")
												}>
													<StyledButton
														size="large"
														type="primary"
														onClick={generate}
														disabled={disableButton}
													>
														{t('report.downloadReport')}
													</StyledButton>
												</Tooltip>
											}
										</Space>
									</Space>
								</StyledCard>
							</Space>
						</>
					)}
				</>
			) : (
				<CelsureCompareFormulation />
			)
			}
			{apiStatus === AsyncStates.LOADING && <Spinner />}
		</>
	)
}
